import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { BaseComponent, DialogStore, property, StrataPlanDetails, TableHeaderFilterType } from 'common-lib';

import { BuildingSelectorContext } from './building-selector.context';
import { Store } from './building-selector.store';

@Component({
  selector: 'app-building-selector',
  templateUrl: './building-selector.component.html',
  styleUrls: ['./building-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Store]
})
export class BuildingSelectorComponent extends BaseComponent implements OnInit {

  readonly TableHeaderFilterType = TableHeaderFilterType;

  readonly buildingPropertyState = property<StrataPlanDetails>('state');
  readonly buildingPropertyCompany = property<StrataPlanDetails>('company');
  readonly buildingPropertyPlanName = property<StrataPlanDetails>('planName');
  readonly buildingPropertyAddress = property<StrataPlanDetails>('address');

  @Input() showHeader = true;

  static showInDialog(dialogStore: DialogStore) {

    dialogStore.showComponent(BuildingSelectorComponent, {
      context: new BuildingSelectorContext(),
      dismissableMask: true
    });
  }

  constructor(
    readonly store: Store,
    private readonly dialogStore: DialogStore) {

    super();
  }

  ngOnInit(): void {

    this.subscribe(this.store.getBuildingSummaries());

    this.mobxReaction('BuildingSelectorComponent: store.buildings',
      () => this.store.buildings,
      buildings => this.centreDialog());
  }

  onBuildingSelected() {

    this.hideDialog();
  }

  onBuildingUnselected() {

    this.hideDialog();
  }

  getBuildingState(building: StrataPlanDetails) {

    return building.state;
  }

  getBuildingCompany(building: StrataPlanDetails) {

    return building.company;
  }

  getBuildingPlanName(building: StrataPlanDetails) {

    return building.planName;
  }

  getBuildingAddress(building: StrataPlanDetails) {

    return building.address;
  }

  private hideDialog() {

    if (this.dialogStore.context instanceof BuildingSelectorContext) {

      this.dialogStore.hide();
    }
  }

  private centreDialog() {

    if (this.dialogStore.context instanceof BuildingSelectorContext) {

      this.dialogStore.positionOverlay();
    }
  }
}
