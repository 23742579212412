import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {Subject} from 'rxjs';

import {ButtonKind} from '../../directives/button.directive';
import {Owner, Unit, User} from '../../generated';
import {switchMapCatch} from '../../rxjs/operators';
import {BaseDomainStore} from '../../store/base-domain.store';
import {DialogStore} from '../../store/dialog.store';
import {formatArray} from '../../utils/array.utils';
import {formatPhoneNumberForDisplay} from '../../utils/form.utils';
import {property} from '../../utils/object.utils';
import {BaseComponent} from '../base/base.component';
import {TableHeaderFilterType} from '../table-header/table-header.component';

import {Store, TabPanel} from './owner-browser.store';
import {TableSelectionMode} from "../../models/table";
import {tap} from "rxjs/operators";

@Component({
  selector: 'app-owner-browser',
  templateUrl: './owner-browser.component.html',
  styleUrls: ['./owner-browser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Store]
})
export class OwnerBrowserComponent extends BaseComponent implements OnInit {

  private readonly getOwners$ = new Subject<{strataId: string, includeExternalCommittee: boolean}>();

  @Input() rowSelectable: boolean = false;
  @Input() multi: boolean = false;
  @Input() lazyLoad: boolean = false;
  @Input() includeExternalCommittee: boolean = false;
  @Output() ownersSelected = new EventEmitter<Owner[]>();

  @ViewChild('ownerDetailsTpl') private readonly ownerDetailsTpl?: TemplateRef<any>;

  readonly TableHeaderFilterType = TableHeaderFilterType;
  readonly ButtonKind = ButtonKind;

  readonly ownerUserProp = property<Owner>('user');
  readonly ownerUnitsProp = property<Owner>('units');
  readonly ownerCommitteeCodesProp = property<Owner>('committeeCodes');

  readonly userLastNameProp = property<User>('lastName');
  readonly userFullNameProp = property<User>('fullName');
  readonly userMobileProp = property<User>('mobile');
  readonly userEmailProp = property<User>('email');

  readonly ownerUserLastNameProp = `${this.ownerUserProp}.${this.userLastNameProp}`;
  readonly ownerUserFullNameProp = `${this.ownerUserProp}.${this.userFullNameProp}`;
  readonly ownerUserMobileProp = `${this.ownerUserProp}.${this.userMobileProp}`;
  readonly ownerUserEmailProp = `${this.ownerUserProp}.${this.userEmailProp}`;

  constructor(
    readonly store: Store,
    private readonly domainStore: BaseDomainStore,
    private readonly dialogStore: DialogStore) {

    super();
  }

  ngOnInit(): void {

    this.subscribe(this.getOwners$.pipe(
      switchMapCatch(params => this.store.getOwners(params.strataId, params.includeExternalCommittee)
        .pipe(
          tap(() => this.setSelectedOwners([]))
        )
      )
    ));

    this.mobxReaction('OwnerBrowserComponent: domainStore.selectedStrataId',
      () => this.domainStore.selectedStrataId,
      selectedStrataId => {

        if (!selectedStrataId) {

          return;
        }

        if (!this.lazyLoad) {

          this.getOwners$.next({strataId: selectedStrataId, includeExternalCommittee: this.includeExternalCommittee});
        }

      });

    this.mobxReaction('OwnerBrowserComponent: store.selectedOwners',
      () => this.store.selectedOwners,
      selectedOwners => {

        if (selectedOwners) {

          this.ownersSelected.emit(selectedOwners);
        }
      });
  }

  /**
   * lazyLoad call e.g. when a tab is selected.
   */
  getOwners(strataId: string, includeExternalCommittee: boolean) {
    this.getOwners$.next({strataId: strataId, includeExternalCommittee: includeExternalCommittee});
  }

  public fetchedOwners() {
    return this.store.owners;
  }

  public fetchOwners(params: {strataId: string, includeExternalCommittee: boolean}) {
    this.getOwners$.next(params);
  }

  get rowSelectionMode(): TableSelectionMode | undefined {

    if (this.rowSelectable && this.multi) {
      return TableSelectionMode.Multiple;
    } else if (this.rowSelectable && !this.multi) {
      return TableSelectionMode.Single;
    } else {
      return undefined;
    }
  }

  private showOwnerDetailsDialog(args: {
    owner: Owner;
    unit: Unit;
  }) {

    this.store.setSelectedOwners([args.owner]);
    this.store.setSelectedUnit(args.unit);

    // tslint:disable-next-line: no-non-null-assertion
    this.dialogStore.showTemplate(this.ownerDetailsTpl!, {
      showHeader: true,
      header: this.domainStore.getSelectedPlanStrataNumber({ title: 'Owner Details' })
    });
  }

  onUnitLinkClick(args: {
    owner: Owner;
    unit: Unit;
  }) {

    this.store.setSelectedTabPanel(TabPanel.Unit)

    this.showOwnerDetailsDialog(args);
  }

  // showOwnerProfile(args: {
  //   ownerId: string;
  //   unitId: string;
  // }) {

  //   this.store.setSelectedTabPanel(TabPanel.Profile)

  //   // tslint:disable-next-line: no-non-null-assertion
  //   const owner = this.store.owners.find(o => o.user.id === args.ownerId)!;

  //   this.showOwnerDetailsDialog({
  //     owner,
  //     unitId: args.unitId
  //   });
  // }

  getName(owner: Owner) {

    return `${owner.user.firstName} ${owner.user.lastName}`.trim();
  }

  getRegisteredName(owner: Owner) {

    return owner.user.registeredOwner;
  }

  getUnits(owner: Owner) {

    return owner.units;
  }

  getCommitteeCodes(owner: Owner) {

    return formatArray(owner.committeeCodes);
  }

  getMobile(owner: Owner) {

    return formatPhoneNumberForDisplay(owner.user.mobile);
  }

  getEmail(owner: Owner) {

    return owner.user.email;
  }

  setSelectedOwners(owners: Owner[]) {
    this.store.setSelectedOwners(owners);
  }
}
