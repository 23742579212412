import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { action, observable } from 'mobx';
import { tap } from 'rxjs/operators';

@Injectable()
export class Store {

  @observable
  private _loginMessage = '';

  constructor(
    private readonly httpClient: HttpClient) {
  }

  getLoginMessage() {

    return this.httpClient.get('/assets/login-message.html', {
      responseType: 'text'
    }).pipe(
      tap(response => this.setLoginMessage(response))
    );
  }

  @action
  setLoginMessage(val: string) {

    this._loginMessage = val;
  }

  get loginMessage() {

    return this._loginMessage;
  }
}
