import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { BaseAppProperties } from '../../base-app-properties';
import { PortalType } from '../../models/portal-type';
import { DialogStore } from '../../store/dialog.store';
import { BaseComponent } from '../base/base.component';
import { HelpSelectorComponent } from '../help-selector/help-selector.component';
import { HelpSelectorContext } from '../help-selector/help-selector.context';

@Component({
  selector: 'app-help-icon',
  templateUrl: './help-icon.component.html',
  styleUrls: ['./help-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpIconComponent extends BaseComponent implements OnInit {

  @Input() tags: string | string[] = '';
  @Input() helpIcon: string = 'video';

  constructor(
    private readonly appProperties: BaseAppProperties,
    private readonly dialogStore: DialogStore) {

    super();
  }

  ngOnInit(): void {
  }

  onClick() {

    HelpSelectorComponent.showInDialog(this.dialogStore, new HelpSelectorContext({
      tags: this.tags,
      autoShow: this.appProperties.portalType === PortalType.Owner
    }));
  }

  get icon() {
    return 'video' === this.helpIcon ? 'fas fa-video' : 'far fa-file-pdf';
  }
}
