<app-route-container *mobxAutorun [title]="store.pageTitle" [helpTags]="HelpTag.Invoice" [helpIconVisible]="true">
  <div class="p-grid">
    <ng-container *ngIf="store.hasSecondAuthorityApprovedAlerts">
      <div class="p-col-12">
        <app-info-message [message]="store.secondAuthorityApprovedAlertMessage"></app-info-message>
      </div>
    </ng-container>
    <div class="p-col-12 p-md-7">
      <app-pdf-viewer [src]="store.selectedInvoiceDetails?.document?.data"
        [filename]="store.selectedInvoiceDetails?.document?.filename"></app-pdf-viewer>
    </div>
    <div class="p-col-12 p-md-5">
      <ng-container *ngIf="store.isSecondAuthorityRequired || store.isOnHoldReasonVisible">
        <div class="p-col-12">
          <app-info-message *ngIf="store.isSecondAuthorityRequired" [message]="store.secondAuthorityMessage">
          </app-info-message>
          <app-info-message *ngIf="store.isOnHoldReasonVisible" [message]="store.onHoldReason"></app-info-message>
        </div>
        <ng-container *ngIf="store.rejectedAlert">
          <div class="p-col-12">
            <app-info-message [message]="store.rejectedAlertMessage"></app-info-message>
          </div>
        </ng-container>
        <div class="p-col-12">
          <hr>
        </div>
      </ng-container>
      <div class="p-col-12">
        <div class="p-grid form-group">
          <div class="p-col-12 p-md-4">
            <label>Creditor</label>
          </div>
          <div class="p-col-12 p-md-8">
            <div>{{store.selectedInvoiceSummary?.creditor}}</div>
          </div>
          <div class="p-col-12 p-md-4">
            <label>Creditor Code</label>
          </div>
          <div class="p-col-12 p-md-8">
            <div>{{store.selectedInvoiceSummary?.creditorCode}}</div>
          </div>
          <div class="p-col-12 p-md-4">
            <label>Amount</label>
          </div>
          <div class="p-col-12 p-md-8">
            <div>{{store.selectedInvoiceSummary?.amount | currencyFormat}}</div>
          </div>
          <div class="p-col-12 p-md-4">
            <label fxLayoutAlign="start center" style="height: 100%">
              Plan Number
            </label>
          </div>
          <div class="p-col-12 p-md-8 p-grid">
            <div class="p-col-4 ui-g-nopad" fxLayoutAlign="start center" style="height: 100%">
              {{store.selectedInvoiceSummary?.planNumber}}
            </div>
            <div *ngIf="store.isChangePlanButtonVisible" class="p-col-8 ui-g-nopad" fxLayoutAlign="start center"
              style="height: 100%">
              <button [disabled]="!store.isAllowedToChangeAccountOrPlan" pButton type="button" [kind]="ButtonKind.ChangePlan" (click)="onChangePlan()"></button>
            </div>
          </div>
          <div class="p-col-12 p-md-4">
            <label>GST Registered</label>
          </div>
          <div class="p-col-12 p-md-8">
            <div>{{store.gstRegistered}}</div>
          </div>
          <div class="p-col-12 p-md-4">
            <label>Address</label>
          </div>
          <div class="p-col-12 p-md-8">
            <div>{{store.selectedInvoiceSummary?.planAddress}}</div>
          </div>
          <ng-container *ngIf="store.isSecondAuthorityRequired">
            <div class="p-col-12 p-md-4">
              <label>2nd Authority</label>
            </div>
            <div class="p-col-12 p-md-8">
              <p-checkbox [binary]="true" [ngModel]="store.secondAuthorityFlag"
                [disabled]="!store.isSecondAuthorityCheckboxEnabled" (onChange)="onSecondAuthorityChange($event.checked)"
                [label]="store.secondAuthorityCheckboxLabel"></p-checkbox>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="p-col-12">
        <hr>
      </div>
      <div class="p-col-12">
        <app-button-container layoutAlign="center center">
          <button *ngIf="store.isApproveButtonVisible" pButton type="button" class="green-btn" icon="ui-icon-check"
            label="approve" [disabled]="!store.isAllowedToApproveInvoice"
            (click)="onApprove(approveInvoiceTpl)"></button>
          <button *ngIf="store.isRejectButtonVisible" pButton type="button" class="red-btn" icon="ui-icon-clear"
            label="reject" [disabled]="!store.isAllowedToActionInvoice || !store.isAllowedToRejectInvoice" (click)="onReject(rejectInvoiceTpl)"></button>
          <button *ngIf="store.isOnHoldButtonVisible" pButton type="button"
            [kind]="store.selectedInvoiceOnHoldButtonKind" [disabled]="!store.isAllowedToActionInvoice"
            (click)="onInvoiceOnHold(invoiceOnHoldTpl)"></button>
          <button *ngIf="store.isEmailApprovalButtonVisible" pButton type="button" icon="ui-icon-email"
            label="email approval" [disabled]="!store.isAllowedToActionInvoice"
            (click)="onEmailApproval(emailApprovalTpl)"></button>
          <button *ngIf="store.isOverrideButtonVisible" pButton type="button" icon="ui-icon-assistant-photo"
            label="override" [disabled]="!store.isAllowedToOverrideInvoice" (click)="onOverride(overrideTpl)"></button>
          <button *ngIf="store.isReprocessRejectedButtonVisible" pButton type="button" class="cyan-btn"
            icon="ui-icon-undo" label="return to manager" [disabled]="!store.isAllowedToReprocessRejected"
            (click)="onReprocessRejected(reprocessRejectedTpl)"></button>
          <button *ngIf="store.isProcessRejectedButtonVisible" pButton type="button" icon="ui-icon-redo"
            label="process rejected" [disabled]="!store.isAllowedToProcessRejected"
            (click)="onProcessRejected(processRejectedTpl)"></button>
          <button *ngIf="store.isAccountCodesButtonVisible" pButton type="button" icon="ui-icon-attach-money"
            label="account codes" [disabled]="!store.isAllowedToActionInvoice || !store.isAllowedToChangeAccountOrPlan"
            (click)="onAccountCodes(accountCodesTpl)"></button>
        </app-button-container>
      </div>
      <div class="p-col-12 p-grid">
        <div *ngIf="store.isInvoiceFilterVisible" class="p-grid p-col-12 p-md-8 p-mt-1">
          <div class="p-col-12 p-md-3">
            <label class="wht-label">Filter</label>
          </div>
          <div class="p-col-12 p-md-9">
            <p-dropdown optionLabel="label" [options]="store.invoiceFilter" [ngModel]="store.selectedInvoiceFilter"
              (ngModelChange)="store.setSelectedInvoiceFilter($event)">
            </p-dropdown>
          </div>
        </div>
        <div class="p-col-12" [class.p-md-4]="store.isInvoiceFilterVisible">
          <app-button-container layoutAlign="center center" layoutSM="row" layoutAlignSM="center center">
            <button pButton type="button" icon="fas" [disabled]="!store.isPreviosInvoiceSelectionEnabled"
              (click)="store.onSelectPreviousInvoice()">
              <span class="p-button-icon-round fas fa-backward"></span>
            </button>
            <button pButton type="button" icon="fas" [disabled]="!store.isNextInvoiceSelectionEnabled"
              (click)="store.onSelectNextInvoice()">
              <span class="p-button-icon-round fas fa-forward"></span>
            </button>
          </app-button-container>
        </div>
        <div *ngIf="store.isBranchDropdownVisible" class="p-col-12 p-md-8">
          <div class="p-col-12 p-md-3">
            <label class="wht-label">Branch</label>
          </div>
          <div class="p-col-12 p-md-9">
            <p-dropdown optionLabel="branch" [options]="domainStore.branchesForUserOptional"
              [ngModel]="domainStore.selectedInvoiceApprovalCompany"
              (ngModelChange)="domainStore.setSelectedInvoiceApprovalCompany($event)"
              [disabled]="!store.isBranchDropdownEnabled">
            </p-dropdown>
          </div>
        </div>
      </div>
      <div class="p-col-12">
        <hr>
      </div>
      <div class="p-col-12">
        <ng-container *ngTemplateOutlet="accountCodesTableTpl; context: {
          readOnly: true,
          inlineEdit: store.isAllowedToInlineEditPosting,
          showMoreIndicator: true
        }">
        </ng-container>
      </div>
      <ng-container>
        <div class="p-col-12">
          <hr>
        </div>
        <div class="p-col-12" *ngIf="store.isAllowedToSelectWorkOrder">
          <app-info-message *ngIf="store.candidateWorkOrders && !store.isLinkedWorkOrderVisible"
            message="Select work orders related to this invoice for closure."></app-info-message>
          <app-info-message *ngIf="store.isLinkedWorkOrderVisible" [message]="store.linkedWorkOrderReason"></app-info-message>
          <app-info-message *ngIf="store.jobSafePendingMessageVisible" [message]=store.jobSafePendingMessage></app-info-message>
        </div>
        <div class="p-col-12">
          <ng-container *ngTemplateOutlet="workOrdersTableTpl; context: {
            readOnly: !store.isAllowedToSelectWorkOrder
          }">
          </ng-container>
        </div>
      </ng-container>
      <div class="p-col-12">
        <hr>
      </div>
      <div class="p-col-12">
        <app-table [value]="store.selectedInvoiceDetails?.approvers">
          <ng-template pTemplate="caption">Approvers</ng-template>
          <ng-template pTemplate="header">
            <tr appTableRow>
              <th [appTableHeader]="approversPropertyName" title="approver" pSortableColumn></th>
              <th [appTableHeader]="approversPropertyPosition" title="position" pSortableColumn></th>
              <th [appTableHeader]="approversPropertyStatus" title="status" pSortableColumn></th>
              <th [appTableHeader]="approversPropertyDate" title="date" pSortableColumn></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-approver>
            <tr appTableRow>
              <td appTableData>
                <ng-container *ngIf="!store.isEmailApproverLinkEnabled(approver); else emailApprover">
                  {{getApproverName(approver)}}
                </ng-container>
                <ng-template #emailApprover>
                  <app-content-container>
                    <a appAnchor (click)="onEmailApproval(emailApprovalTpl, approver)">{{getApproverName(approver)}}</a>
                    <app-icon-button *ngIf="store.isEmailApproverDeleteEnabled(approver)" [kind]="IconKind.Delete"
                      (iconClick)="onEmailApproverDelete(approver)"></app-icon-button>
                  </app-content-container>
                </ng-template>
              </td>
              <td appTableData>{{getApproverPosition(approver)}}</td>
              <td appTableData>
                <app-content-container>
                  <div>{{getApproverStatus(approver)}}</div>
                  <app-more-indicator *ngIf="getApproverDetails(approver)"
                    (moreClick)="onApproverStatusMoreClick($event, approver, opMoreIndicator)"></app-more-indicator>
                </app-content-container>
              </td>
              <td appTableData>{{getApproverDate(approver) | dateFormat}}</td>
            </tr>
          </ng-template>
        </app-table>
      </div>
    </div>
    <div class="p-col-12">
      <app-info-message message="Select an invoice from the table below."></app-info-message>
    </div>
    <div class="p-col-12">
      <app-table selectionMode="single" [dataKey]="invoiceSummaryPropertyCreditorInvoiceEntryHeaderId"
        [value]="store.invoiceSummaries" [rows]="store.invoiceSummaryTableRows"
        [selection]="store.selectedInvoiceSummary" (selectionChange)="store.setSelectedInvoiceSummary($event)"
        [first]="store.invoiceSummaryTableFirst" (firstChange)="store.setInvoiceSummaryTableFirst($event)"
        [resetPageOnValueChange]="false">
        <ng-template pTemplate="caption">Invoices</ng-template>
        <ng-template pTemplate="header">
          <tr appTableRow>
            <th [appTableHeader]="invoiceSummaryPropertyDateEntered" title="date of invoice"></th>
            <th [appTableHeader]="invoiceSummaryPropertyPlanNumber" title="plan #"
              [filter]="TableHeaderFilterType.Input"></th>
            <th [appTableHeader]="invoiceSummaryPropertyCreditor" title="creditor"
              [filter]="TableHeaderFilterType.Input"></th>
            <th [appTableHeader]="invoiceSummaryPropertyDescription" title="invoice"
              [filter]="TableHeaderFilterType.Input">
            </th>
            <th [appTableHeader]="invoiceSummaryAmountPropertyValue" title="amount"></th>
            <th [appTableHeader]="invoiceSummaryPropertyStatus" title="status" [filter]="TableHeaderFilterType.Input">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-invoiceSummary>
          <tr appTableRow [pSelectableRow]="invoiceSummary">
            <td appTableData>{{getInvoiceSummaryDateEntered(invoiceSummary) | dateFormat}}</td>
            <td appTableData>{{getInvoiceSummaryPlanNumber(invoiceSummary)}}</td>
            <td appTableData>{{getInvoiceSummaryCreditor(invoiceSummary)}}</td>
            <td appTableData>{{getInvoiceSummaryDescription(invoiceSummary)}}</td>
            <td appTableData>{{getInvoiceSummaryAmount(invoiceSummary) | currencyFormat}}</td>
            <td appTableData>
              <app-content-container>
                <div>{{getInvoiceSummaryStatus(invoiceSummary)}}</div>
                <i *ngIf="appProperties.portalType === PortalType.Manager && getInvoiceSummaryOnHold(invoiceSummary)"
                  class="material-icons">block</i>
              </app-content-container>
            </td>
          </tr>
        </ng-template>
      </app-table>
    </div>
  </div>
</app-route-container>

<p-overlayPanel #opMoreIndicator>
  <app-text-block [lines]="store.moreIndicatorText"></app-text-block>
</p-overlayPanel>

<ng-template #approveRejectInvoiveDetailsTpl>
  <div class="p-col-12 p-md-4">
    <label>Plan #</label>
  </div>
  <div class="p-col-12 p-md-8">
    {{store.selectedInvoiceSummary?.planNumber}}
  </div>
  <div class="p-col-12 p-md-4">
    <label>Date</label>
  </div>
  <div class="p-col-12 p-md-8">
    {{store.selectedInvoiceSummary?.dateEntered | dateFormat}}
  </div>
  <div class="p-col-12 p-md-4">
    <label>Invoice</label>
  </div>
  <div class="p-col-12 p-md-8">
    {{store.selectedInvoiceSummary?.description}}
  </div>
  <div class="p-col-12 p-md-4">
    <label>Creditor</label>
  </div>
  <div class="p-col-12 p-md-8">
    {{store.selectedInvoiceSummary?.creditor}}
  </div>
  <div class="p-col-12 p-md-4">
    <label>Amount</label>
  </div>
  <div class="p-col-12 p-md-8">
    {{store.selectedInvoiceSummary?.amount | currencyFormat}}
  </div>
</ng-template>

<ng-template #actionInvoiceTpl let-action="action">
  <form [formGroup]="actionInvoiceForm" (ngSubmit)="onActionInvoiceFormSubmit(action)">
    <div class="ui-fluid p-grid form-group">
      <ng-container *ngTemplateOutlet="approveRejectInvoiveDetailsTpl"></ng-container>
      <div class="p-col-12">
        <hr>
      </div>
      <div class="p-col-12">
        <ng-container *ngTemplateOutlet="accountCodesTableTpl; context: {
          readOnly: true
        }">
        </ng-container>
      </div>
      <div class="p-col-12" *ngIf="isActionInvoiceWarningsVisible(action)">
        <app-info-message *ngIf="store.isSecondAuthorityPending" message="<b>Warning:</b> Pending second authority"
          [severity]="MessageSeverity.Error"></app-info-message>
        <app-info-message *ngIf="store.isEmailApprovalOustanding" message="<b>Warning:</b> Pending external approval"
          [severity]="MessageSeverity.Error"></app-info-message>
        <app-info-message *ngIf="store.linkedWorkorderNotSelected" [message]="store.linkedWorkOrderReason">
        </app-info-message>
      </div>
      <ng-container *ngIf="store.selectedWorkOrders.length > 0">
        <div class="p-col-12">
          <ng-container *ngTemplateOutlet="workOrdersTableTpl; context: {
            workOrders: store.selectedWorkOrders,
            readOnly: true,
            hideMoreIndicator: true
          }">
          </ng-container>
        </div>
      </ng-container>
      <div *ngIf="action === Action.Approve" class="p-col-12">
        <textarea pInputTextarea [rows]="3" [formControl]="approvalNotes" class="p-w-100"
          placeholder="Enter approval notes {{store.isApprovalNotesRequired ? '(required due to warnings)' : '(optional)'}}"></textarea>
        <span class="md-inputfield">
          <app-form-error [form]="actionInvoiceForm" [control]="approvalNotes" [untouched]="true"></app-form-error>
        </span>
      </div>
    </div>
    <div appDialogFooter>
      <button pButton autofocus="true" [kind]="ButtonKind.Ok" type="submit" [disabled]="!actionInvoiceForm.valid"></button>
      <button pButton [kind]="ButtonKind.Cancel" type="button" (click)="onDialogCancel()"></button>
    </div>
  </form>
</ng-template>

<ng-template #approveInvoiceTpl>
  <ng-container *ngTemplateOutlet="actionInvoiceTpl; context: {
    action: Action.Approve
  }"></ng-container>
</ng-template>

<ng-template #processRejectedTpl>
  <ng-container *ngTemplateOutlet="actionInvoiceTpl; context: {
    action: Action.ProcessRejected
  }"></ng-container>
</ng-template>

<ng-template #reprocessRejectedTpl>
  <form [formGroup]="reprocessRejectedForm" (ngSubmit)="onReprocessRejectedFormSubmit()">
    <div class="ui-fluid p-grid form-group">
      <ng-container *ngTemplateOutlet="approveRejectInvoiveDetailsTpl"></ng-container>
      <div class="p-col-12">
        <hr>
      </div>
      <div class="p-col-12">
        <textarea pInputTextarea [rows]="10" [formControl]="reprocessRejectedReason" class="p-w-100"
          placeholder="Enter the reason for returning to manager"></textarea>
        <span class="md-inputfield">
          <app-form-error [form]="reprocessRejectedForm" [control]="reprocessRejectedReason" [untouched]="true">
          </app-form-error>
        </span>
      </div>
    </div>
    <div appDialogFooter>
      <button pButton [kind]="ButtonKind.Ok" type="submit" [disabled]="!reprocessRejectedForm.valid"></button>
      <button pButton [kind]="ButtonKind.Cancel" type="button" (click)="onDialogCancel()"></button>
    </div>
  </form>
</ng-template>

<ng-template #rejectInvoiceTpl>
  <form [formGroup]="rejectForm" (ngSubmit)="onRejectFormSubmit()">
    <div class="ui-fluid p-grid form-group">
      <ng-container *ngTemplateOutlet="approveRejectInvoiveDetailsTpl"></ng-container>
      <div class="p-col-12">
        <hr>
      </div>
      <div class="p-col-12">
        <p-checkbox label="Invoice incorrect, return to creditor" [formControl]="invoiceIncorrect" [binary]="true">
        </p-checkbox>
      </div>
      <div class="p-col-12">
        <textarea pInputTextarea [rows]="10" [formControl]="rejectionReason" class="p-w-100"
          placeholder="Enter the reason for invoice rejection"></textarea>
        <span class="md-inputfield">
          <app-form-error [form]="rejectForm" [control]="rejectionReason" [untouched]="true"></app-form-error>
        </span>
      </div>
    </div>
    <div appDialogFooter>
      <button pButton [kind]="ButtonKind.Ok" type="submit" [disabled]="!rejectForm.valid"></button>
      <button pButton [kind]="ButtonKind.Cancel" type="button" (click)="onDialogCancel()"></button>
    </div>
  </form>
</ng-template>

<ng-template #accountCodesTpl>
  <app-info-message *ngIf="store.isAllowedToActionInvoice"
    message="Select an entry to modify its Amount/Account allocation."></app-info-message>
  <ng-container *ngTemplateOutlet="accountCodesTableTpl"></ng-container>
  <form [formGroup]="postingsForm" (ngSubmit)="onAccountCodesFormSubmit()">
    <div *ngIf="store.isAllowedToActionInvoice" class="ui-fluid p-grid form-group">
      <div class="p-col-12">
        <hr>
      </div>
      <div class="p-col-12 p-md-4">
        <label>Account</label>
      </div>
      <div class="p-col-12 p-md-8">
        <p-dropdown [kind]="DropdownKind.AccountCode" [options]="store.chartOfAccounts" [formControl]="postingsAccount">
        </p-dropdown>
        <span class="md-inputfield">
          <app-form-error [form]="postingsForm" [control]="postingsAccount" [untouched]="true"></app-form-error>
        </span>
      </div>
      <div class="p-col-12">
        <span class="md-inputfield" style="padding-bottom: 15px;">
          <p-checkbox [binary]="true" [ngModel]="store.filterByBudgetCodesFlag"
                      (onChange)="onFilterByBudgetCodesChange($event.checked)"
                      label="Show only budgeted codes" [ngModelOptions]="{standalone: true}"></p-checkbox>
        </span>
      </div>
      <div class="p-col-12 p-md-4">
        <label>Fund Code</label>
      </div>
      <div class="p-col-12 p-md-8">
        <p-dropdown placeholder="Select Fund Code" [options]="store.fundCodes" [formControl]="postingsFundCode">
        </p-dropdown>
        <span class="md-inputfield">
          <app-form-error [form]="postingsForm" [control]="postingsFundCode" [untouched]="true"></app-form-error>
        </span>
      </div>
      <div class="p-col-12 p-md-4">
        <label>Amount</label>
      </div>
      <div class="p-col-12 p-md-8">
        <div fxLayout="row">
          <app-currency-input [formControl]="postingsAmount"></app-currency-input>
          <div *ngIf="store.originalSelectedPosting?.amount" style="margin-left: 5px">(Original Amount
            {{store.originalSelectedPosting?.amount | currencyFormat}})</div>
        </div>
        <span class="md-inputfield">
          <app-form-error [form]="postingsForm" [control]="postingsAmount" [untouched]="true"></app-form-error>
        </span>
      </div>
      <div class="p-col-12 p-md-4">
        <label>Comment</label>
      </div>
      <div class="p-col-12 p-md-8">
        <input type="text" pInputText [formControl]="postingsComment" style="width: 100%;">
      </div>
      <div class="p-col-12">
        <hr>
      </div>
      <div class="p-col-12" style="text-align: center">
        <div fxLayoutAlign="center center" fxLayoutGap="5px">
          <button pButton type="button" label="add" icon="ui-icon-add" (click)="onAddPosting()"
            [disabled]="!store.isAllowedToAddPosting"></button>
          <button pButton type="button" label="remove" icon="ui-icon-remove"
            [disabled]="!store.isAllowedToDeletePosting" (click)="onRemovePosting()"></button>
        </div>
        <span class="md-inputfield">
          <app-form-error [positionStatic]="true" [fontSize]="FormErrorFontSize.Normal"
            [visible]="!store.isOriginalAndRunningTotalInvoicePostingsAmountEqual">
            <ng-container *ngIf="!store.isOriginalAndRunningTotalInvoicePostingsAmountEqual">All postings must total
              {{store.originalTotalInvoicePostingsAmount | currencyFormat}} (currently
              {{store.runningTotalInvoicePostingsAmount
              | currencyFormat}})</ng-container>
          </app-form-error>
        </span>
      </div>
    </div>
    <div appDialogFooter>
      <button pButton [kind]="ButtonKind.Ok" type="submit" [disabled]="!store.isAllowedToOKPosting"></button>
      <button pButton [kind]="ButtonKind.Cancel" type="button" (click)="onDialogCancel()"></button>
    </div>
  </form>
</ng-template>

<ng-template #workOrdersTableTpl let-workOrders="workOrders" let-readOnly="readOnly"
  let-hideMoreIndicator="hideMoreIndicator">
  <app-table [dataKey]="workOrderPropertyId" [value]="workOrders || store.selectedInvoiceDetails?.candidateWorkOrders"
    [rows]="5" [selection]="readOnly ? [] : store.selectedWorkOrders"
    (selectionChange)="store.setSelectedWorkOrders($event)">
    <ng-template pTemplate="caption">Close Work Orders</ng-template>
    <ng-template pTemplate="header">
      <tr appTableRow>
        <th *ngIf="!readOnly" appTableHeader [checkboxHeader]="true">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th [appTableHeader]="workOrderPropertyOrderNumber" title="order number" pSortableColumn></th>
        <th [appTableHeader]="workOrderPropertyDescriptionOfWork" title="description" pSortableColumn></th>
        <th [appTableHeader]="workOrderPropertyDateIssued" title="date" pSortableColumn></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-workOrder>
      <tr appTableRow [pSelectableRow]="workOrder" [ngClass]="store.getTableRowCssClass(workOrder)">
        <td *ngIf="!readOnly" appTableData>
          <p-tableCheckbox [value]="workOrder"></p-tableCheckbox>
        </td>
        <td appTableData>{{getWorkOrderNumber(workOrder)}}</td>
        <td appTableData>
          <app-content-container>
            <div>{{getWorkOrderDescriptionOfWork(workOrder)}}</div>
            <app-more-indicator *ngIf="getWorkOrderDetails(workOrder) && !hideMoreIndicator"
              (moreClick)="onWorkOrdersDescriptionMoreClick($event, workOrder, opMoreIndicator)"></app-more-indicator>
          </app-content-container>
        </td>
        <td appTableData>{{getWorkOrderDateIssued(workOrder) | dateFormat}}</td>
      </tr>
    </ng-template>
  </app-table>
</ng-template>

<ng-template #accountCodesTableTpl let-readOnly="readOnly" let-inlineEdit="inlineEdit"
  let-showMoreIndicator="showMoreIndicator">
  <app-table #accountCodesTable [showCaption]="readOnly || !store.isAllowedToActionInvoice"
    [value]="readOnly ? store.selectedInvoiceDetails?.postings : store.postings"
    [selection]="readOnly ? null : store.selectedPosting" (selectionChange)="store.setSelectedPosting($event)"
    [rows]="5" [selectionMode]="store.isAllowedToActionInvoice && !readOnly ? 'single' : null">
    <ng-template pTemplate="caption">Account Codes</ng-template>
    <ng-template pTemplate="header">
      <tr appTableRow>
        <th [appTableHeader]="postingPropertyAccountNumber" title="account #" pSortableColumn></th>
        <th [appTableHeader]="postingPropertyFundCode" title="fund" pSortableColumn></th>
        <th [appTableHeader]="postingPropertyAccountDescription" title="description" pSortableColumn></th>
        <th [appTableHeader]="postingAmountPropertyValue" title="amount" pSortableColumn></th>
        <th *ngIf="budgetColumnsVisible" [appTableHeader] title="budget"></th>
        <th *ngIf="budgetColumnsVisible" [appTableHeader] title="actual"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-posting>
      <tr appTableRow [pSelectableRow]="posting">
        <td appTableData pEditableColumn [pEditableColumnDisabled]="!inlineEdit">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-dropdown [ngModel]="getPostingAccountNumber(posting)"
                [options]="store.getRecentlyUsedAccountCodes(posting)"
                (onChange)="onChangePostingAccountNumber($event.value, posting)" [appendTo]="accountCodesTable.table">
              </p-dropdown>
            </ng-template>
            <ng-template pTemplate="output">
              <ng-container
                *ngIf="store.isPostingAccountNumberValid(getPostingAccountNumber(posting)); else accountNumberInvalidTpl">
                {{getPostingAccountNumber(posting)}}
              </ng-container>
              <ng-template #accountNumberInvalidTpl>
                <span class="wht-attention">*</span>
              </ng-template>
            </ng-template>
          </p-cellEditor>
        </td>
        <td appTableData pEditableColumn [pEditableColumnDisabled]="!inlineEdit">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-dropdown [ngModel]="getPostingFundCode(posting)" [options]="store.fundCodes"
                (onChange)="onChangePostingFundCode($event.value, posting)" [appendTo]="accountCodesTable.table">
              </p-dropdown>
            </ng-template>
            <ng-template pTemplate="output">
              {{getPostingFundCode(posting)}}
            </ng-template>
          </p-cellEditor>
        </td>
        <td appTableData>
          <ng-container
            *ngIf="store.isPostingAccountNumberValid(getPostingAccountNumber(posting)); else accountDescriptionInvalidTpl">
            <app-content-container>
              <div>{{getPostingAccountDescription(posting)}}</div>
              <app-more-indicator *ngIf="getPostingComments(posting) && showMoreIndicator"
                (moreClick)="onAccountCodesDescriptionMoreClick($event, posting, opMoreIndicator)"></app-more-indicator>
            </app-content-container>
          </ng-container>
          <ng-template #accountDescriptionInvalidTpl>
            <span class="wht-attention">*</span>
          </ng-template>
        </td>
        <td appTableData>
          <span
            [ngClass]="{'wht-attention': !store.isPostingAmountValid(getPostingAmount(posting).value)}">{{getPostingAmount(posting) | currencyFormat}}</span>
          <span class="wht-attention"
            [ngStyle]="{display: store.isPostingAmountModified(posting) ? 'initial': 'none'}">&nbsp;*</span>
        </td>
        <td *ngIf="budgetColumnsVisible" appTableData>
          <div>{{store.getPostingBudgetValue(posting)?.budget | currencyFormat}}</div>
        </td>
        <td *ngIf="budgetColumnsVisible" appTableData>
          <div>{{store.getPostingBudgetValue(posting)?.actual | currencyFormat}}</div>
        </td>
      </tr>
    </ng-template>
  </app-table>
</ng-template>

<ng-template #invoiceOnHoldTpl>
  <form [formGroup]="invoiceOnHoldForm" (ngSubmit)="onInvoiceOnHoldFormSubmit()">
    <div class="ui-fluid p-grid form-group">
      <div class="p-col-12">
        <textarea pInputTextarea [rows]="10" [formControl]="invoiceOnHoldReason" placeholder="Description" class="p-w-100"></textarea>
        <span class="md-inputfield">
          <app-form-error [form]="invoiceOnHoldForm" [control]="invoiceOnHoldReason" [untouched]="true">
          </app-form-error>
        </span>
      </div>
    </div>
    <div appDialogFooter>
      <button pButton [kind]="ButtonKind.Ok" type="submit" [disabled]="!invoiceOnHoldForm.valid"></button>
      <button pButton [kind]="ButtonKind.Cancel" type="button" (click)="onDialogCancel()"></button>
    </div>
  </form>
</ng-template>

<ng-template #overrideTpl>
  <form [formGroup]="overrideForm" (ngSubmit)="onOverrideFormSubmit()">
    <div class="ui-fluid p-grid form-group">
      <div class="p-col-12">
        <textarea pInputTextarea [rows]="10" [formControl]="overrideReason" placeholder="Description" class="p-w-100"></textarea>
        <span class="md-inputfield">
          <app-form-error [form]="overrideForm" [control]="overrideReason" [untouched]="true"></app-form-error>
        </span>
      </div>
    </div>
    <div appDialogFooter>
      <button pButton [kind]="ButtonKind.Ok" type="submit" [disabled]="!overrideForm.valid"></button>
      <button pButton [kind]="ButtonKind.Cancel" type="button" (click)="onDialogCancel()"></button>
    </div>
  </form>
</ng-template>

<ng-template #emailApprovalTpl>
  <form [formGroup]="emailApprovalForm" (ngSubmit)="onEmailApprovalSubmit()">
    <div class="ui-fluid p-grid form-group">
      <div class="p-col-12 p-md-2">
        <label>To</label>
      </div>
      <div class="p-col-12 p-md-10">
        <span class="md-inputfield p-w-100">
          <p-autoComplete [formControl]="emailApprovalAddress" [suggestions]="store.emailApprovalAddressSuggestions"
            (completeMethod)="store.emailApprovalAddressComplete($event.query)" class="p-w-100"
            [readonly]="store.isEmailApprovalFormReadonly"></p-autoComplete>
          <app-form-error [form]="emailApprovalForm" [control]="emailApprovalAddress" [detectStatusChange]="true">
          </app-form-error>
        </span>
      </div>
      <div class="p-col-12">
        <textarea pInputTextarea [rows]="20" [formControl]="emailApprovalBody" placeholder="Email Body" class="p-w-100"
          [readonly]="store.isEmailApprovalFormReadonly"></textarea>
        <span class="md-inputfield">
          <app-form-error [form]="emailApprovalForm" [control]="emailApprovalBody"></app-form-error>
        </span>
      </div>
    </div>
    <div appDialogFooter>
      <button pButton [kind]="ButtonKind.Ok" type="submit" [disabled]="!emailApprovalForm.valid"></button>
      <button pButton [kind]="ButtonKind.Cancel" type="button" (click)="onDialogCancel()"></button>
    </div>
  </form>
</ng-template>
