/**
 * Whittles API
 * Whittles API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type TaskType = 'COMMITTEE_MEMBER_APPROVES_INVOICE' | 'MANAGER_APPROVES_INVOICE' | 'ACCOUNTS_PROCESSES_REJECTED_INVOICE' | 'AGM_ACCOUNTS_RECONCILE_EOY' | 'AGM_BCM_REVIEWS_IE' | 'AGM_ACCOUNTS_PROCESS_JOURNALS' | 'AGM_ACCOUNTS_FINALIZE_IE' | 'AGM_ACCOUNTS_AUDIT_FINANCIAL_RECORDS' | 'AGM_BCM_PREPARES_BUDGET' | 'AGM_BCM_CONDUCTS_BUDGET_AGENDA_REVIEW' | 'AGM_BCM_COLLATES_NOTICE' | 'AGM_BCM_SENDS_NOTICE' | 'AGM_BCM_CONDUCTS_MEETING' | 'AGM_BCM_PROCESSES_PROXY_FORMS' | 'AGM_BCM_RECORDS_QUORUM' | 'AGM_BCM_RECONVENES_MEETING' | 'AGM_INTERIM_RESOLUTIONS_PERIOD' | 'AGM_BCM_ADOPTS_INTERIM_RESOLUTIONS' | 'AGM_BCM_CONDUCTS_ADHOC_SGM_PROCESS' | 'AGM_COMMITTEE_REVIEWS_DRAFT_MINUTES' | 'AGM_BCM_COMPLETES_MEETING_SUMMARY_SHEET' | 'AGM_ACCOUNTS_PROCESS_MEETING_SUMMARY_SHEET' | 'AGM_BCM_UPDATES_ADOPTS_BUDGET' | 'AGM_BCM_UPDATES_PLAN_DETAILS' | 'AGM_BCM_UPDATES_COMMITTEE' | 'AGM_BCM_CREATES_WORK_ORDERS' | 'AGM_BCM_UPLOADS_FILES' | 'AGM_BCM_UPLOADS_PROXY_VOTING_PAPERS' | 'AGM_BCM_COLLATES_MINUTES' | 'AGM_BCM_SENDS_MINUTES' | 'JOURNAL_ACCOUNTS_PROCESS_JOURNALS';

export const TaskType = {
    COMMITTEEMEMBERAPPROVESINVOICE: 'COMMITTEE_MEMBER_APPROVES_INVOICE' as TaskType,
    MANAGERAPPROVESINVOICE: 'MANAGER_APPROVES_INVOICE' as TaskType,
    ACCOUNTSPROCESSESREJECTEDINVOICE: 'ACCOUNTS_PROCESSES_REJECTED_INVOICE' as TaskType,
    AGMACCOUNTSRECONCILEEOY: 'AGM_ACCOUNTS_RECONCILE_EOY' as TaskType,
    AGMBCMREVIEWSIE: 'AGM_BCM_REVIEWS_IE' as TaskType,
    AGMACCOUNTSPROCESSJOURNALS: 'AGM_ACCOUNTS_PROCESS_JOURNALS' as TaskType,
    AGMACCOUNTSFINALIZEIE: 'AGM_ACCOUNTS_FINALIZE_IE' as TaskType,
    AGMACCOUNTSAUDITFINANCIALRECORDS: 'AGM_ACCOUNTS_AUDIT_FINANCIAL_RECORDS' as TaskType,
    AGMBCMPREPARESBUDGET: 'AGM_BCM_PREPARES_BUDGET' as TaskType,
    AGMBCMCONDUCTSBUDGETAGENDAREVIEW: 'AGM_BCM_CONDUCTS_BUDGET_AGENDA_REVIEW' as TaskType,
    AGMBCMCOLLATESNOTICE: 'AGM_BCM_COLLATES_NOTICE' as TaskType,
    AGMBCMSENDSNOTICE: 'AGM_BCM_SENDS_NOTICE' as TaskType,
    AGMBCMCONDUCTSMEETING: 'AGM_BCM_CONDUCTS_MEETING' as TaskType,
    AGMBCMPROCESSESPROXYFORMS: 'AGM_BCM_PROCESSES_PROXY_FORMS' as TaskType,
    AGMBCMRECORDSQUORUM: 'AGM_BCM_RECORDS_QUORUM' as TaskType,
    AGMBCMRECONVENESMEETING: 'AGM_BCM_RECONVENES_MEETING' as TaskType,
    AGMINTERIMRESOLUTIONSPERIOD: 'AGM_INTERIM_RESOLUTIONS_PERIOD' as TaskType,
    AGMBCMADOPTSINTERIMRESOLUTIONS: 'AGM_BCM_ADOPTS_INTERIM_RESOLUTIONS' as TaskType,
    AGMBCMCONDUCTSADHOCSGMPROCESS: 'AGM_BCM_CONDUCTS_ADHOC_SGM_PROCESS' as TaskType,
    AGMCOMMITTEEREVIEWSDRAFTMINUTES: 'AGM_COMMITTEE_REVIEWS_DRAFT_MINUTES' as TaskType,
    AGMBCMCOMPLETESMEETINGSUMMARYSHEET: 'AGM_BCM_COMPLETES_MEETING_SUMMARY_SHEET' as TaskType,
    AGMACCOUNTSPROCESSMEETINGSUMMARYSHEET: 'AGM_ACCOUNTS_PROCESS_MEETING_SUMMARY_SHEET' as TaskType,
    AGMBCMUPDATESADOPTSBUDGET: 'AGM_BCM_UPDATES_ADOPTS_BUDGET' as TaskType,
    AGMBCMUPDATESPLANDETAILS: 'AGM_BCM_UPDATES_PLAN_DETAILS' as TaskType,
    AGMBCMUPDATESCOMMITTEE: 'AGM_BCM_UPDATES_COMMITTEE' as TaskType,
    AGMBCMCREATESWORKORDERS: 'AGM_BCM_CREATES_WORK_ORDERS' as TaskType,
    AGMBCMUPLOADSFILES: 'AGM_BCM_UPLOADS_FILES' as TaskType,
    AGMBCMUPLOADSPROXYVOTINGPAPERS: 'AGM_BCM_UPLOADS_PROXY_VOTING_PAPERS' as TaskType,
    AGMBCMCOLLATESMINUTES: 'AGM_BCM_COLLATES_MINUTES' as TaskType,
    AGMBCMSENDSMINUTES: 'AGM_BCM_SENDS_MINUTES' as TaskType,
    JOURNALACCOUNTSPROCESSJOURNALS: 'JOURNAL_ACCOUNTS_PROCESS_JOURNALS' as TaskType
};
