import { OnInit, Directive, ElementRef, Input, HostListener } from '@angular/core';
import { NG_VALIDATORS, AbstractControl } from '@angular/forms';

import { KeyFilter } from 'primeng/keyfilter';
import { DomHandler } from 'primeng/dom';

export type ValidationFunc = (value: string) => boolean;

const DEFAULT_MASKS = {
    pint: /[\d]/,
    'int': /[\d\-]/,
    pnum: /[\d\.]/,
    money: /[\d\.\s,]/,
    num: /[\d\-\.]/,
    hex: /[0-9a-f]/i,
    email: /[a-z0-9_\.\-@]/i,
    alpha: /[a-z_]/i,
    alphanum: /[a-z0-9_]/i
};

const KEYS = {
    TAB: 9,
    RETURN: 13,
    ESC: 27,
    BACKSPACE: 8,
    DELETE: 46
};
@Directive({
  selector: '[appKeyFilter]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: KeyFilterDirective,
      multi: true
    }]
})
export class KeyFilterDirective extends KeyFilter {

  private _validationFunction?: ValidationFunc;

  constructor(readonly el: ElementRef) {

    super(el);
  }

  @Input('appKeyFilter') set validationFunction(val: string | ValidationFunc) {

    if (typeof val === 'string') {

      this._validationFunction = (DEFAULT_MASKS as any)[val];

    } else {

      this._validationFunction = val;
    }
  }

  onKeyPress(e: KeyboardEvent): void {

    if (this.pValidateOnly || e.ctrlKey || e.altKey) {

      return;
    }

    const browser = DomHandler.getBrowser();
    const k = this.getKey(e);

    if (browser.mozilla && (this.isNavKeyPress(e) || k === KEYS.BACKSPACE || (k === KEYS.DELETE && e.charCode === 0))) {

      return;
    }

    const c = this.getCharCode(e);
    const cc = String.fromCharCode(c);

    if (browser.mozilla && (this.isSpecialKey(e) || !cc)) {

      return;
    }

    let ok = true;

    const currentValue = (this.el.nativeElement.value || '') as string;

    const cursorPosition = this.el.nativeElement.selectionStart;

    const valueToValidate = currentValue.slice(0, cursorPosition) + cc + currentValue.slice(cursorPosition);

    if (this._validationFunction) {

      ok = this._validationFunction(valueToValidate);

    } else if (this.regex) {

      ok = this.regex.test(valueToValidate);
    }

    if (!ok) {

      e.preventDefault();
    }
  }

  validate(c: AbstractControl): {
    [key: string]: any;
  } {

    if (this._validationFunction) {

      const ok = this._validationFunction(this.el.nativeElement.value || '');

      if (!ok) {

        return {
          keyFilterValidatePattern: false
        };
      }

    } else if (this.regex) {

      return super.validate(c);
    }

    return null as any;
  }

  @HostListener('paste', ['$event'])
  onPaste(e: Event) {

    // e: ClipboardEvent breaks in IE
    if (!((e as any).clipboardData)) {

      return;
    }

    const data = (e as any).clipboardData.getData('text/plain');

    let ok = true;

    if (this._validationFunction) {

      ok = this._validationFunction(data);

    } else if (this.regex) {

      ok = this.regex.test(data);
    }

    if (!ok) {

      e.preventDefault();
    }
  }
}
