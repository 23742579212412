import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  Configuration,
  OwnerService as OwnerServiceGenerated,
  UpdateEmailParams,
  UpdateMobileParams,
  UpdatePasswordParams,
  UpdateProfileDetailsParams
} from '../generated';

import { BaseAppProperties } from '../base-app-properties';
import { formatPhoneNumberForPersistance } from '../utils/form.utils';

@Injectable({
  providedIn: 'root'
})
export class OwnerService {

  private readonly ownerServiceGenerated: OwnerServiceGenerated;

  constructor(httpClient: HttpClient, appProperties: BaseAppProperties) {

    this.ownerServiceGenerated = new OwnerServiceGenerated(httpClient, appProperties.irisServerBasePath, new Configuration());
  }

  getProfileSummary(showActivityIndicator = false) {

    return this.ownerServiceGenerated.getProfileSummary(showActivityIndicator);
  }

  getContactSummary(showActivityIndicator = false) {

    return this.ownerServiceGenerated.getContactSummary(showActivityIndicator);
  }

  getProfileDetails(args?: {
    userId?: string;
    unitId?: string;
  }) {

    return this.ownerServiceGenerated.getProfileDetails(args && args.userId, args && args.unitId);
  }

  getImportantDocuments(showActivityIndicator = false) {

    return this.ownerServiceGenerated.getImportantDocuments(showActivityIndicator);
  }

  getRecentDocuments(type: string, number: number, showActivityIndicator = false) {

    return this.ownerServiceGenerated.getRecentDocuments(type, number, showActivityIndicator);
  }

  getDocumentTree(params: {
    filterIndex: number;
    strataId?: string;
  }) {

    return this.ownerServiceGenerated.getDocumentTree(params.filterIndex, params.strataId);
  }

  getInvoices() {

    return this.ownerServiceGenerated.getInvoices();
  }

  getCovidFile() {

    return this.ownerServiceGenerated.getCovidFile();
  }

  getOwnerDocumentFile(params: {
    documentId: string;
    type: string;
    strataId?: string;
  }) {

    return this.ownerServiceGenerated.getOwnerDocumentFile(params.documentId, params.type, params.strataId);
  }

  updateProfileDetails(updateProfileDetailsParams: UpdateProfileDetailsParams) {

    return this.ownerServiceGenerated.updateProfileDetails({
      ...updateProfileDetailsParams,
      owner: {
        ...updateProfileDetailsParams.owner,
        homePhone: formatPhoneNumberForPersistance(updateProfileDetailsParams.owner.homePhone),
        busPhone: formatPhoneNumberForPersistance(updateProfileDetailsParams.owner.busPhone),
        fax: formatPhoneNumberForPersistance(updateProfileDetailsParams.owner.fax),
        mobile: formatPhoneNumberForPersistance(updateProfileDetailsParams.owner.mobile)
      }
    });
  }

  getContactDetails() {

    return this.ownerServiceGenerated.getContactDetails();
  }

  getNumberUnits() {

    return this.ownerServiceGenerated.getNumberUnits();
  }

  getPaymentDetails(showRelatedUnitPayments: boolean) {

    return this.ownerServiceGenerated.getPaymentDetails(showRelatedUnitPayments);
  }

  getUnitsSummary() {

    return this.ownerServiceGenerated.getUnitsSummary();
  }

  getUnitsSummaryFinancials(unitId: string, showParams?: {
    showActivityIndicator?: boolean
  }) {

    return this.ownerServiceGenerated.getUnitsSummaryFinancials(
      unitId,
      (showParams && showParams.showActivityIndicator) || false);
  }

  getPaymentSummary(showRelatedUnitPayments: boolean, showActivityIndicator = false) {

    return this.ownerServiceGenerated.getPaymentSummary(showRelatedUnitPayments, showActivityIndicator);
  }

  getContactDetailsStatus(showActivityIndicator = false) {

    return this.ownerServiceGenerated.getContactDetailsStatus(showActivityIndicator);
  }

  updateEmail(updateEmailParams: UpdateEmailParams) {

    return this.ownerServiceGenerated.updateEmail(updateEmailParams);
  }

  updateMobile(updateMobileParams: UpdateMobileParams) {

    return this.ownerServiceGenerated.updateMobile({
      ...updateMobileParams,
      mobile: formatPhoneNumberForPersistance(updateMobileParams.mobile)
    });
  }

  updatePassword(updatePasswordParams: UpdatePasswordParams) {

    return this.ownerServiceGenerated.updatePassword(updatePasswordParams);
  }

  findOwners(params: {
    email?: string;
    mobile?: string;
  }) {

    return this.ownerServiceGenerated.findOwners(params.email, params.mobile);
  }

  getNumberOwnersWithEmailAddresses(params: {
    selection: string[];
    filter: 'BRANCH' | 'COMPANY' | 'PORTFOLIO';
  }) {

    return this.ownerServiceGenerated.getNumberOwnersWithEmailAddresses(params.selection, params.filter);
  }
}
