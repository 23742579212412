export enum Authority {
  Administrator = 'Administrator',
  Accounts = 'Accounts',
  Assistant = 'Assistant',
  AssistantManager = 'Assistant Manager',
  AssistantToContractedBCM = 'Assistant to Contracted BCM',
  Auditor = 'Auditor',
  Bank = 'Bank',
  BranchManagers = 'Branch Managers',
  BuildingManager = 'Building_Manager',
  DebtCollectionAgencies = 'DebtCollectionAgencies',
  CallCentreAfterHours = 'Call Centre After Hours',
  CaretakerQld = 'Caretaker_Qld',
  ChangeAccountCode = 'Change Account Code',
  Executive = 'Executive',
  ExternalCommitteeMember = 'External_Committee_Member',
  InsuranceValuations = 'Insurance_Valuations',
  InsuranceBroker = 'Insurance_Broker',
  Manager = 'Manager',
  OnlineAccessContractors = 'Online Access Contractors',
  Owner = 'Owner',
  Public = 'Public',
  ReadOnlyAccess = 'ReadOnlyAccess',
  RealEstateAgent = 'Real Estate Agent',
  Reception = 'Reception',
  SearchAgentNsw = 'Search Agent NSW',
  SwiftCollect = 'SWIFT_COLLECT',
  SmsSender = 'SMS Sender',
  Trainee = 'Trainee',
  WhittlesMaintenance = 'Whittles Maintenance',
  // "New Feature" groups
  AccountsTest = 'Accounts-test',
  AssistantTest = 'Assistant-test',
  ManagerTest = 'Manager-test',
  ClientExperience = 'Client-Experience',
  RejectWhittlesInvoice = 'Reject-Whittles-Invoice',
  StrataVote = 'StrataVote'
}

export const Authorities = {

  get Manager() {

    return [
      Authority.BranchManagers,
      Authority.Manager
    ];
  },

  get PortfolioManagement() {

    return [
      Authority.Assistant,
      Authority.AssistantManager,
      Authority.AssistantToContractedBCM,
      Authority.BranchManagers,
      Authority.Manager
    ];
  },

  get SearchAgent() {

    return [
      Authority.RealEstateAgent,
      Authority.SearchAgentNsw
    ];
  }
};
