<p-dropdown
    #manageableDropdown
    class="manageable-filter-dropdown"
    optionLabel="value"
    itemSize="30"
    (onChange)="selectedChange($event)"
    [(ngModel)]="selectedItem"
    [options]="items"
    [filter]="filter"
    [showClear]="true"
    [placeholder]="placeholder"
    [appendTo]="appendTo"
    [autofocusFilter]="false"
    [disabled]="disabled"
    [virtualScroll]="virtualScroll"
    [resetFilterOnHide]="true"
    
    (onClick)="clicked($event)"
    (onClear)="onClear($event)"
    (onHide)="onHidden()"
    (onFilter)="onFilter($event)">

    <ng-template pTemplate="header">
        <div class="flex justify-content-end searchable-header" [class.searchable-header-none]="!showButtons" style="z-index: 1" [class.manageableHidden]="filter">
            <app-content-container class="manageable-filter-dropdown-btn-container">
                <div id="manageablefiltercontainner">
                    <input pInputText type="text" [(ngModel)]="filterString" (input)="onFilter($event)"/>
                    <i class="pi pi-search" style="position:absolute; right: 14%;"></i>
                </div>
                <button *ngIf="showButtons" pButton type="button" class="manageable-lazyloading-btn" label="New" icon="" (click)="addNewItem();$event.stopPropagation()" style="z-index: 1; background-color: rgb(148 83 213);"></button>
            </app-content-container>
        </div>
    </ng-template>

    <ng-template pTemplate="selectedItem">
        <div class="manageable-dropdown-input"  *ngIf="item">
            <div>{{item?.value}}</div>
        </div>
    </ng-template>

    <ng-template let-manageableItem pTemplate="item">
        <div class="manageableItem-item"
            appElementObserve
            (isIntersecting)="isIntersecting($event)"
            [debounceTime]="0"
            [shouldEmit]="shouldEmit(manageableItem)"
            [isContinuous]="isContinuous"
            #intersection="intersection">
            <p>{{manageableItem.value}}</p>
            <app-content-container *ngIf="showButtons" layoutAlign="center center">
                <button pButton type="button" label="Edit" icon="" (click)="editItem(manageableItem);$event.stopPropagation()"></button>
            </app-content-container>
        </div>
    </ng-template>

</p-dropdown>
