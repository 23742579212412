import {BankStatementsSummary, StrataPlanDetails, Task} from '../generated';

export enum ServerEventKind {
  AuthenticationFailed = 'AUTHENTICATION_FAILED',
  OutstandingInvoicesCount = 'OUTSTANDING_INVOICES_COUNT',
  ExpiringInsurancePolicyCounts= 'EXPIRING_INSURANCE_POLICY_COUNTS',
  Task = 'TASK',
  IncomingCall = 'INCOMING_CALL',
  BankStatementsSummary = 'BANK_STATEMENTS_SUMMARY'
}

export interface AuthenticationFailedEvent {
  readonly kind: ServerEventKind.AuthenticationFailed;
}

export interface OutstandingInvoicesCountEvent {
  readonly kind: ServerEventKind.OutstandingInvoicesCount;
  readonly portfolioId: string;
  readonly count: number;
}

export interface ExpiringInsurancePolicyCountEvent {
  readonly kind: ServerEventKind.ExpiringInsurancePolicyCounts;
  readonly portfolioId: string;
  readonly expired: number;
  readonly expiringSoon: number;
  readonly noPolicy: string;
}

export enum TaskAction {
  Assigned = 'ASSIGNED',
  Created = 'CREATED',
  Completed = 'COMPLETED',
  Deleted = 'DELETED'
}

export interface TaskEvent {
  readonly kind: ServerEventKind.Task;
  readonly action: TaskAction;
  readonly task: Task;
}

export enum IncomingCallCandidateKind {
  Owner = 'OWNER'
}

export interface IncomingCallCandidatePlan extends StrataPlanDetails {
  readonly unitIds: string[];
}

export interface IncomingCallCandidate {
  readonly kind: IncomingCallCandidateKind;
  readonly firstName: string;
  readonly surname: string;
  readonly plans: IncomingCallCandidatePlan[];
}

export interface IncomingCallEvent {
  readonly kind: ServerEventKind.IncomingCall;
  readonly phoneNumber: string;
  readonly candidates: IncomingCallCandidate[];
}

export interface BankStatementsSummaryEvent {
  readonly kind: ServerEventKind.BankStatementsSummary;
  readonly statementFilename: string;
  readonly filename: string;
  readonly count: number;
  readonly totalCount: number;
  readonly success: boolean;
}

export type ServerEvent =
  | AuthenticationFailedEvent
  | OutstandingInvoicesCountEvent
  | ExpiringInsurancePolicyCountEvent
  | TaskEvent
  | IncomingCallEvent
  | BankStatementsSummaryEvent
