import { Injectable } from '@angular/core';
import {
  OwnerService,
  OwnerUnitSummary as OwnerUnitSummaryCommon,
  OwnerUnitSummaryFinancials,
  startWithTap
} from 'common-lib';
import { action, observable } from 'mobx';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { DomainStore } from '../../stores/domain.store';

export interface OwnerUnitSummary extends Readonly<OwnerUnitSummaryCommon> {
  financials?: OwnerUnitSummaryFinancials;
  financialsError?: boolean;
}

@Injectable()
export class Store {

  @observable
  private _units: OwnerUnitSummary[] = [];

  constructor(
    private readonly domainStore: DomainStore,
    private readonly ownerService: OwnerService) {
  }

  getUnitsSummary() {

    return this.ownerService.getUnitsSummary().pipe(
      startWithTap(() => this.setUnits([])),
      tap(units => this.setUnits(units))
    );
  }

  getUnitsSummaryFinancials(args: {
    unitId: string;
  }) {

    return this.ownerService.getUnitsSummaryFinancials(args.unitId).pipe(
      tap(financials => this.setOwnerUnitSummaryFinancials({
        unitId: args.unitId,
        financials
      })),
      catchError(e => {

        this.setOwnerUnitSummaryFinancialsError({
          unitId: args.unitId
        });

        return throwError(e);
      })
    );
  }

  @action
  private setUnits(units: OwnerUnitSummary[]) {

    this._units = units;

    if (!this.domainStore.selectedUnit) {

      return;
    }

    const selectedUnitId = this.domainStore.selectedUnit.unitId;

    const selectedUnit = this._units.find(unit => unit.unitId === selectedUnitId);

    if (!selectedUnit) {

      return;
    }

    this.domainStore.setSelectedUnit(selectedUnit);
  }

  @action
  private setOwnerUnitSummaryFinancials(args: {
    unitId: string;
    financials: OwnerUnitSummaryFinancials;
  }) {

    const unit = this._units.find(u => u.unitId === args.unitId);

    if (!unit) {

      return;
    }

    unit.financials = args.financials;
  }

  @action
  private setOwnerUnitSummaryFinancialsError(args: {
    unitId: string;
  }) {

    const unit = this._units.find(u => u.unitId === args.unitId);

    if (!unit) {

      return;
    }

    unit.financialsError = true;
  }

  showPayBill(ownerUnitSummary: OwnerUnitSummary): boolean {

    return ownerUnitSummary.financials != null && ownerUnitSummary.financials.balanceOwing.value > 0;
  }

  showUnitFinancialsDataSpinner(ownerUnitSummary: OwnerUnitSummary): boolean {

    if (ownerUnitSummary.financialsError) {

      return false;
    }

    return ownerUnitSummary.financials == null;
  }

  @action
  setSelectedUnit(selectedUnit: OwnerUnitSummary | undefined) {

    if (selectedUnit) {

      this.domainStore.setSelectedUnit(selectedUnit);
    }
  }

  get selectedUnitTitle() {

    return this.domainStore.selectedUnitBuildingTitle;
  }

  get units() {

    return this._units;
  }

  get selectedUnit() {

    return this.domainStore.selectedUnit;
  }
}

