import { Injectable } from '@angular/core';

import { BaseAppProperties } from '../base-app-properties';
import { PortalType } from '../models/portal-type';

enum LogLevel {
  OFF,
  ERROR,
  WARN,
  INFO,
  DEBUG,
  TRACE
}

@Injectable({
  providedIn: 'root'
})
export class Logger {

  private logLevel = this.appProperties.portalType === PortalType.Owner ? LogLevel.WARN : LogLevel.TRACE;

  constructor(private readonly appProperties: BaseAppProperties) {

    this.setLogLevelFromLocalStorage();
  }

  trace(message: any, ...optionalParams: any[]) {

    if (this.logIfLevel(LogLevel.TRACE)) {

      // tslint:disable-next-line:no-console
      console.trace(message, ...optionalParams);
    }
  }

  debug(message: any, ...optionalParams: any[]) {

    if (this.logIfLevel(LogLevel.DEBUG)) {

      // tslint:disable-next-line:no-console
      console.debug(message, ...optionalParams);
    }
  }

  info(message: any, ...optionalParams: any[]) {

    if (this.logIfLevel(LogLevel.INFO)) {

      // tslint:disable-next-line:no-console
      console.info(message, ...optionalParams);
    }
  }

  warn(message: any, ...optionalParams: any[]) {

    if (this.logIfLevel(LogLevel.WARN)) {

      console.warn(message, ...optionalParams);
    }
  }

  error(message: any, ...optionalParams: any[]) {

    if (this.logIfLevel(LogLevel.ERROR)) {

      console.error(message, ...optionalParams);
    }
  }

  private logIfLevel(logLevel: LogLevel): boolean {

    return logLevel <= this.logLevel;
  }

  private setLogLevelFromLocalStorage() {

    try {

      // Some devices have localStorage = null
      const logLevel = localStorage && localStorage.getItem('logLevel');

      if (!logLevel) {

        return;
      }

      const logLevelKey = logLevel.toUpperCase() as (keyof typeof LogLevel);

      this.logLevel = LogLevel[logLevelKey];

    } catch (e) {

      console.warn('Error caught in Logger.setLogLevelFromLocalStorage', e);
    }
  }
}
