import { Injectable } from '@angular/core';
import { action, observable } from 'mobx';

import { Environment } from '../environment';
import { WindowRef } from '../services/window-ref.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsStore {

  private static readonly CALLBACK_NAME = '__onGmapsReady';
  @observable
  private _initialised = false;

  constructor(
    private readonly windowRef: WindowRef,
    private readonly environment: Environment) {
  }

   public async initiliseMapEngine() {
    if (!this._initialised)
    {
      await this.loadMapsApi();
    }
  }

  private async loadMapsApi() {

    console.log("*** MAPS LOADED ***");
    (this.windowRef.nativeWindow as any)[GoogleMapsStore.CALLBACK_NAME] = () => this.setInitialised(true);

    const scriptElement = this.windowRef.nativeWindow.document.createElement('script');

    // https://developers.google.com/maps/documentation/javascript/tutorial
    scriptElement.src =
      `https://maps.googleapis.com/maps/api/js?key=${this.environment.gmapsApiKey}&callback=${GoogleMapsStore.CALLBACK_NAME}`;
    scriptElement.type = 'text/javascript';
    scriptElement.defer = true;
    scriptElement.async = true;

    this.windowRef.nativeWindow.document.body.appendChild(scriptElement);
  }

  @action
  private setInitialised(initialised: boolean) {
    this._initialised = initialised;
  }

  get initialised():boolean {
    return this._initialised;
  }
}
