/**
 * Whittles API
 * Whittles API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Document } from '../model/document';
import { FileData } from '../model/fileData';
import { OwnerContactDetails } from '../model/ownerContactDetails';
import { OwnerContactDetailsStatus } from '../model/ownerContactDetailsStatus';
import { OwnerContactSummary } from '../model/ownerContactSummary';
import { OwnerDocumentTree } from '../model/ownerDocumentTree';
import { OwnerImportantDocuments } from '../model/ownerImportantDocuments';
import { OwnerPaymentDetails } from '../model/ownerPaymentDetails';
import { OwnerProfileDetails } from '../model/ownerProfileDetails';
import { OwnerProfileSummary } from '../model/ownerProfileSummary';
import { OwnerUnitSummary } from '../model/ownerUnitSummary';
import { OwnerUnitSummaryBalances } from '../model/ownerUnitSummaryBalances';
import { OwnerUnitSummaryFinancials } from '../model/ownerUnitSummaryFinancials';
import { UpdateEmailParams } from '../model/updateEmailParams';
import { UpdateMobileParams } from '../model/updateMobileParams';
import { UpdatePasswordParams } from '../model/updatePasswordParams';
import { UpdateProfileDetailsParams } from '../model/updateProfileDetailsParams';
import { User } from '../model/user';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class OwnerService {

    protected basePath = 'https://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param email 
     * @param mobile 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findOwners(email?: string, mobile?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<User>>;
    public findOwners(email?: string, mobile?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<User>>>;
    public findOwners(email?: string, mobile?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<User>>>;
    public findOwners(email?: string, mobile?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (mobile !== undefined && mobile !== null) {
            queryParameters = queryParameters.set('mobile', <any>mobile);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<User>>(`${this.basePath}/owner/owners`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContactDetails(observe?: 'body', reportProgress?: boolean): Observable<OwnerContactDetails>;
    public getContactDetails(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OwnerContactDetails>>;
    public getContactDetails(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OwnerContactDetails>>;
    public getContactDetails(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OwnerContactDetails>(`${this.basePath}/owner/contact/details`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContactDetailsStatus(showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<OwnerContactDetailsStatus>;
    public getContactDetailsStatus(showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OwnerContactDetailsStatus>>;
    public getContactDetailsStatus(showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OwnerContactDetailsStatus>>;
    public getContactDetailsStatus(showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OwnerContactDetailsStatus>(`${this.basePath}/owner/contact/details/status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContactSummary(showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<OwnerContactSummary>;
    public getContactSummary(showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OwnerContactSummary>>;
    public getContactSummary(showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OwnerContactSummary>>;
    public getContactSummary(showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OwnerContactSummary>(`${this.basePath}/owner/contact/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCovidFile(observe?: 'body', reportProgress?: boolean): Observable<FileData>;
    public getCovidFile(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FileData>>;
    public getCovidFile(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FileData>>;
    public getCovidFile(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<FileData>(`${this.basePath}/owner/document/covid`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param filterIndex 
     * @param strataId 
     * @param yearId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentTree(filterIndex?: number, strataId?: string, yearId?: string, observe?: 'body', reportProgress?: boolean): Observable<OwnerDocumentTree>;
    public getDocumentTree(filterIndex?: number, strataId?: string, yearId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OwnerDocumentTree>>;
    public getDocumentTree(filterIndex?: number, strataId?: string, yearId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OwnerDocumentTree>>;
    public getDocumentTree(filterIndex?: number, strataId?: string, yearId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filterIndex !== undefined && filterIndex !== null) {
            queryParameters = queryParameters.set('filterIndex', <any>filterIndex);
        }
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }
        if (yearId !== undefined && yearId !== null) {
            queryParameters = queryParameters.set('yearId', <any>yearId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OwnerDocumentTree>(`${this.basePath}/owner/document/tree`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getImportantDocuments(showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<OwnerImportantDocuments>;
    public getImportantDocuments(showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OwnerImportantDocuments>>;
    public getImportantDocuments(showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OwnerImportantDocuments>>;
    public getImportantDocuments(showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OwnerImportantDocuments>(`${this.basePath}/owner/document/important`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInvoices(observe?: 'body', reportProgress?: boolean): Observable<Array<Document>>;
    public getInvoices(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Document>>>;
    public getInvoices(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Document>>>;
    public getInvoices(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Document>>(`${this.basePath}/owner/document/invoices`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param selection 
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNumberOwnersWithEmailAddresses(selection: Array<string>, filter: 'BRANCH' | 'COMPANY' | 'PORTFOLIO', observe?: 'body', reportProgress?: boolean): Observable<number>;
    public getNumberOwnersWithEmailAddresses(selection: Array<string>, filter: 'BRANCH' | 'COMPANY' | 'PORTFOLIO', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public getNumberOwnersWithEmailAddresses(selection: Array<string>, filter: 'BRANCH' | 'COMPANY' | 'PORTFOLIO', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public getNumberOwnersWithEmailAddresses(selection: Array<string>, filter: 'BRANCH' | 'COMPANY' | 'PORTFOLIO', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (selection === null || selection === undefined) {
            throw new Error('Required parameter selection was null or undefined when calling getNumberOwnersWithEmailAddresses.');
        }

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling getNumberOwnersWithEmailAddresses.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (selection) {
            queryParameters = queryParameters.set('selection', selection.join(COLLECTION_FORMATS['csv']));
        }
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<number>(`${this.basePath}/owner/email-address/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNumberUnits(observe?: 'body', reportProgress?: boolean): Observable<number>;
    public getNumberUnits(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public getNumberUnits(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public getNumberUnits(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<number>(`${this.basePath}/owner/units/number`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param documentId 
     * @param type 
     * @param strataId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOwnerDocumentFile(documentId: string, type: string, strataId?: string, observe?: 'body', reportProgress?: boolean): Observable<FileData>;
    public getOwnerDocumentFile(documentId: string, type: string, strataId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FileData>>;
    public getOwnerDocumentFile(documentId: string, type: string, strataId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FileData>>;
    public getOwnerDocumentFile(documentId: string, type: string, strataId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling getOwnerDocumentFile.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling getOwnerDocumentFile.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (documentId !== undefined && documentId !== null) {
            queryParameters = queryParameters.set('documentId', <any>documentId);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<FileData>(`${this.basePath}/owner/document/download`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param showRelatedUnitPayments 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPaymentDetails(showRelatedUnitPayments: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<OwnerPaymentDetails>>;
    public getPaymentDetails(showRelatedUnitPayments: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OwnerPaymentDetails>>>;
    public getPaymentDetails(showRelatedUnitPayments: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OwnerPaymentDetails>>>;
    public getPaymentDetails(showRelatedUnitPayments: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (showRelatedUnitPayments === null || showRelatedUnitPayments === undefined) {
            throw new Error('Required parameter showRelatedUnitPayments was null or undefined when calling getPaymentDetails.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (showRelatedUnitPayments !== undefined && showRelatedUnitPayments !== null) {
            queryParameters = queryParameters.set('showRelatedUnitPayments', <any>showRelatedUnitPayments);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<OwnerPaymentDetails>>(`${this.basePath}/owner/payment/details`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param showRelatedUnitPayments 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPaymentSummary(showRelatedUnitPayments: boolean, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<OwnerUnitSummaryBalances>;
    public getPaymentSummary(showRelatedUnitPayments: boolean, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OwnerUnitSummaryBalances>>;
    public getPaymentSummary(showRelatedUnitPayments: boolean, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OwnerUnitSummaryBalances>>;
    public getPaymentSummary(showRelatedUnitPayments: boolean, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (showRelatedUnitPayments === null || showRelatedUnitPayments === undefined) {
            throw new Error('Required parameter showRelatedUnitPayments was null or undefined when calling getPaymentSummary.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (showRelatedUnitPayments !== undefined && showRelatedUnitPayments !== null) {
            queryParameters = queryParameters.set('showRelatedUnitPayments', <any>showRelatedUnitPayments);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OwnerUnitSummaryBalances>(`${this.basePath}/owner/payment/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param userId 
     * @param unitId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProfileDetails(userId?: string, unitId?: string, observe?: 'body', reportProgress?: boolean): Observable<OwnerProfileDetails>;
    public getProfileDetails(userId?: string, unitId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OwnerProfileDetails>>;
    public getProfileDetails(userId?: string, unitId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OwnerProfileDetails>>;
    public getProfileDetails(userId?: string, unitId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (unitId !== undefined && unitId !== null) {
            queryParameters = queryParameters.set('unitId', <any>unitId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OwnerProfileDetails>(`${this.basePath}/owner/profile/details`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProfileSummary(showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<OwnerProfileSummary>;
    public getProfileSummary(showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OwnerProfileSummary>>;
    public getProfileSummary(showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OwnerProfileSummary>>;
    public getProfileSummary(showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OwnerProfileSummary>(`${this.basePath}/owner/profile/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param type 
     * @param number 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRecentDocuments(type: string, number?: number, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<Document>>;
    public getRecentDocuments(type: string, number?: number, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Document>>>;
    public getRecentDocuments(type: string, number?: number, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Document>>>;
    public getRecentDocuments(type: string, number?: number, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling getRecentDocuments.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }
        if (number !== undefined && number !== null) {
            queryParameters = queryParameters.set('number', <any>number);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Document>>(`${this.basePath}/owner/document/recent`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUnitsSummary(observe?: 'body', reportProgress?: boolean): Observable<Array<OwnerUnitSummary>>;
    public getUnitsSummary(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OwnerUnitSummary>>>;
    public getUnitsSummary(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OwnerUnitSummary>>>;
    public getUnitsSummary(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<OwnerUnitSummary>>(`${this.basePath}/owner/units/summary`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param unitId 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUnitsSummaryFinancials(unitId: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<OwnerUnitSummaryFinancials>;
    public getUnitsSummaryFinancials(unitId: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OwnerUnitSummaryFinancials>>;
    public getUnitsSummaryFinancials(unitId: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OwnerUnitSummaryFinancials>>;
    public getUnitsSummaryFinancials(unitId: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (unitId === null || unitId === undefined) {
            throw new Error('Required parameter unitId was null or undefined when calling getUnitsSummaryFinancials.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (unitId !== undefined && unitId !== null) {
            queryParameters = queryParameters.set('unitId', <any>unitId);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OwnerUnitSummaryFinancials>(`${this.basePath}/owner/units/summary/financials`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param updateEmailParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateEmail(updateEmailParams: UpdateEmailParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateEmail(updateEmailParams: UpdateEmailParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateEmail(updateEmailParams: UpdateEmailParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateEmail(updateEmailParams: UpdateEmailParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (updateEmailParams === null || updateEmailParams === undefined) {
            throw new Error('Required parameter updateEmailParams was null or undefined when calling updateEmail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/owner/contact/details/update-email`,
            updateEmailParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param updateMobileParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMobile(updateMobileParams: UpdateMobileParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateMobile(updateMobileParams: UpdateMobileParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateMobile(updateMobileParams: UpdateMobileParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateMobile(updateMobileParams: UpdateMobileParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (updateMobileParams === null || updateMobileParams === undefined) {
            throw new Error('Required parameter updateMobileParams was null or undefined when calling updateMobile.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/owner/contact/details/update-mobile`,
            updateMobileParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param updatePasswordParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePassword(updatePasswordParams: UpdatePasswordParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updatePassword(updatePasswordParams: UpdatePasswordParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updatePassword(updatePasswordParams: UpdatePasswordParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updatePassword(updatePasswordParams: UpdatePasswordParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (updatePasswordParams === null || updatePasswordParams === undefined) {
            throw new Error('Required parameter updatePasswordParams was null or undefined when calling updatePassword.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/owner/profile/update-password`,
            updatePasswordParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param updateProfileDetailsParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProfileDetails(updateProfileDetailsParams: UpdateProfileDetailsParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateProfileDetails(updateProfileDetailsParams: UpdateProfileDetailsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateProfileDetails(updateProfileDetailsParams: UpdateProfileDetailsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateProfileDetails(updateProfileDetailsParams: UpdateProfileDetailsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (updateProfileDetailsParams === null || updateProfileDetailsParams === undefined) {
            throw new Error('Required parameter updateProfileDetailsParams was null or undefined when calling updateProfileDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/owner/profile/update`,
            updateProfileDetailsParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
