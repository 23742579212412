import { Injectable } from '@angular/core';
import { action, computed, observable } from 'mobx';
import { tap } from 'rxjs/operators';

import { OwnerProfileDetails, UpdateProfileDetailsParams } from '../../generated';
import { OwnerService } from '../../services/owner.service';
import { BaseAuthenticationStore } from '../../store/base-authentication.store';
import { MessageStore } from '../../store/message.store';

export enum AccountsNoticesMethod {
  MailOnly = 'Mail Only',
  EmailOnly = 'Email Only'
}

@Injectable()
export class Store {

  @observable
  private _unitId?: string;

  @observable
  private _userId?: string;

  @observable
  private _ownerProfileDetails?: OwnerProfileDetails;

  @observable
  private _passwordVisible = false;

  constructor(
    private readonly messageStore: MessageStore,
    private readonly ownerService: OwnerService,
    private readonly authenticationStore: BaseAuthenticationStore) {
  }

  getOwnerProfileDetails() {

    return this.ownerService.getProfileDetails({
      userId: this._userId,
      unitId: this._unitId
    }).pipe(
      tap(ownerProfileDetails => this.setOwnerProfileDetails(ownerProfileDetails))
    );
  }

  updateProfileDetails(updateProfileDetailsParams: UpdateProfileDetailsParams) {

    return this.ownerService.updateProfileDetails(updateProfileDetailsParams).pipe(
      tap(() => {

        this.afterSuccessfulUpdate(updateProfileDetailsParams);

        this.messageStore.pushMessage('profile details updated');
      }));
  }

  @action
  private afterSuccessfulUpdate(updateProfileDetailsParams: UpdateProfileDetailsParams) {

    // tslint:disable-next-line:no-non-null-assertion
    const ownerProfileDetails = this.ownerProfileDetails!;

    // Re-assign to trigger UI sync via mobx reaction
    this._ownerProfileDetails = {
      ...ownerProfileDetails,
      owner: {
        ...ownerProfileDetails.owner,
        homePhone: updateProfileDetailsParams.owner.homePhone,
        busPhone: updateProfileDetailsParams.owner.busPhone,
        mobile: updateProfileDetailsParams.owner.mobile,
        email: updateProfileDetailsParams.owner.email,
        emailCc: updateProfileDetailsParams.owner.emailCc,
        mailMethod: updateProfileDetailsParams.owner.mailMethod,
        password: undefined
      }
    };

    if (this.userId) {

      // Since this.userId is set, this means we are NOT updating the profile for the logged in user.
      return;
    }

    // Update the modified in memory details for the logged in user.
    if (updateProfileDetailsParams.owner.password && updateProfileDetailsParams.owner.password.trim()) {

      this.authenticationStore.setPassword(updateProfileDetailsParams.owner.password);
    }

    if (updateProfileDetailsParams.owner.email) {

      this.authenticationStore.setEmail(updateProfileDetailsParams.owner.email);
    }

    if (updateProfileDetailsParams.owner.mobile) {

      this.authenticationStore.setMobile(updateProfileDetailsParams.owner.mobile);
    }
  }

  @action
  private setOwnerProfileDetails(ownerProfileDetails: OwnerProfileDetails) {

    this._ownerProfileDetails = ownerProfileDetails;
  }

  @computed
  get accountsNoticesMethod(): AccountsNoticesMethod {

    return this.ownerProfileDetails != null && this.ownerProfileDetails.owner.sendAccountNoticesByEmail === 1 ?
      AccountsNoticesMethod.EmailOnly : AccountsNoticesMethod.MailOnly;
  }

  @computed
  get passwordInputType(): string {

    return this.passwordVisible ? 'text' : 'password';
  }

  @action
  togglePasswordVisible() {

    this._passwordVisible = !this.passwordVisible;
  }

  @action
  setPasswordVisible(flag: boolean) {

    this._passwordVisible = flag;
  }

  @action
  setUnitId(unitId: string | undefined) {

    this._unitId = unitId;
  }

  @action
  setUserId(userId: string | undefined) {

    this._userId = userId;
  }

  @computed
  get isExistingMobileValid(): boolean {

    return !!this.ownerProfileDetails && !!this.ownerProfileDetails.owner.mobile && !!this.ownerProfileDetails.owner.mobile.trim();
  }

  @computed
  get isExistingEmailValid(): boolean {

    return !!this.ownerProfileDetails && !!this.ownerProfileDetails.owner.email && !!this.ownerProfileDetails.owner.email.trim();
  }

  get ownerProfileDetails() {

    return this._ownerProfileDetails;
  }

  get passwordVisible() {

    return this._passwordVisible;
  }

  get unitId() {

    return this._unitId;
  }

  get userId() {

    return this._userId;
  }
}
