
import { Task } from '../generated';

import { NoteData } from './note-data';
import { PlanSummaryTabPanel } from './plan-summary-tab-panel';

export enum RouteDataKind {
  Task,
  PlanSummary
}

export interface PlanSummaryRouteData {
  readonly kind: RouteDataKind.PlanSummary;
  readonly tabPanel?: PlanSummaryTabPanel;
  readonly noteData?: NoteData;
}

export interface TaskRouteData {
  readonly kind: RouteDataKind.Task;
  readonly task: Task;
}

export type RouteData =
  | TaskRouteData
  | PlanSummaryRouteData
