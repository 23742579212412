import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AuthenticationService,
  Authority, CompanyId,
  ConfirmationStore,
  formatPhoneNumberForPersistance,
  getIrisErrorMessage,
  LegacyOwnerCredentials,
  LoginType,
  ValidateEmailLoginCredentialsResponse,
  ValidateLegacyOwnerCredentialsResponse,
  ValidateTemporaryPinLoginCredentialsResponse
} from 'common-lib';
import { action, computed, observable } from 'mobx';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Authentication } from '../../models/authentication';
import { AppStore } from '../../stores/app.store';
import { AuthenticationStore } from '../../stores/authentication.store';

export function isValidPassword(password: string | undefined): boolean {

  return password != null && password.trim().length > 0;
}

export function isTempPassword(password: string | undefined): boolean {

  return password != null && password.startsWith('whttmp');
}

interface LoginCredentials {
  identity: Authentication['identity'];
  password: string;
}

type ValidateLoginCredentialsResponse =
  | ValidateLegacyOwnerCredentialsResponse
  | ValidateEmailLoginCredentialsResponse
  | ValidateTemporaryPinLoginCredentialsResponse
  ;

export enum LoginMethod {
  AccountDetails = 'Account Details',
  Email = 'Email',
  Mobile = 'Mobile'
}

@Injectable({
  providedIn: 'root'
})
export class Store {

  private credentialsResponse?: ValidateLoginCredentialsResponse;

  @observable
  private _passwordUpdateFailed = false;

  @observable
  private _loginMethod = LoginMethod.AccountDetails;

  @observable
  private _loginError?: Error;

  @observable
  private _isImpersonationLogin = false;

  @observable
  private _forgottenPassword = false;

  @observable
  private _loginCodeInvalid = false;

  constructor(
    private readonly authenticationStore: AuthenticationStore,
    private readonly authenticationService: AuthenticationService,
    private readonly appStore: AppStore,
    private readonly confirmationStore: ConfirmationStore) {
  }

  @action
  validateCredentials(params: LoginCredentials) {

    this.setLoginError(undefined);

    return this.validateCredentials$(params).pipe(
      tap(response => {

        this.credentialsResponse = response;

        this.setLoginError(response.credentials.valid ? undefined : new Error('!response.credentials.valid'));

        const validNonTempPassword = isValidPassword(params.password) && !isTempPassword(params.password);

        if (response.credentials.valid && (validNonTempPassword || this.isImpersonationLogin)) {

          this.setAuthenticationAndRedirect(params);
        }
      }),
      map(response => response.credentials),
      catchError(e => {

        this.setLoginError(e);

        return throwError(e);
      }),
    );
  }

  private validateCredentials$(params: LoginCredentials): Observable<ValidateLoginCredentialsResponse> {

    switch (this.loginMethod) {

      case LoginMethod.Email:

        return this.authenticationService.validateEmailLoginCredentials({
          impersonatorUsername: params.identity.impersonatorUsername,
          loginType: this.authenticationStore.loginType,
          email: params.identity.email || '',
          password: params.password
        });

      case LoginMethod.Mobile:

        return this.authenticationService.validateMobileLoginCredentials({
          impersonatorUsername: params.identity.impersonatorUsername,
          loginType: this.authenticationStore.loginType,
          mobile: params.identity.mobile || '',
          password: params.password
        });

      case LoginMethod.AccountDetails:

        return this.authenticationService.validateLegacyOwnerCredentials({
          impersonatorUsername: params.identity.impersonatorUsername,
          accountNumber: params.identity.accountNumber || '',
          plan: params.identity.plan || '',
          unitNumber: params.identity.unitNumber || '',
          password: params.password || undefined
        });

      default:

        throw new Error(this.invalidLoginMethodMessage('Store.validateCredentials$'));
    }
  }

  private passwordResetRequest$(params: LegacyOwnerCredentials & {
    email: string;
  }) {

    if (this.loginMethod === LoginMethod.Email) {

      return this.authenticationService.passwordResetRequest({
        email: params.email,
        loginType: this.authenticationStore.loginType
      });

    } else if (this.loginMethod === LoginMethod.AccountDetails) {

      return this.authenticationService.legacyOwnerCredentialsPasswordResetRequest(params);
    }

    throw new Error(this.invalidLoginMethodMessage('Store.passwordResetRequest$'));
  }

  @action
  passwordResetRequest(params: LegacyOwnerCredentials & {
    email: string;
  }) {

    this.setLoginError(undefined);

    return this.passwordResetRequest$(params).pipe(
      tap(() => {

        this.confirmationStore.confirm({
          header: 'password reset successful',
          icon: 'ui-icon-info',
          message: `Your password reset request was successful.<br><br>
          You should receive an email at <b>${params.email}</b> with instructions regarding what steps to take next.
          `
        });

        this.setForgottenPassword(false);
      }),
      catchError(e => {

        this.setLoginError(e);

        return throwError(e);
      }),
    );
  }

  private updateCredentialsPassword$(params: LegacyOwnerCredentials & {
    email: string;
    password: string;
  }) {

    if (this.loginMethod === LoginMethod.Email) {

      return this.authenticationService.updatePasswordOnLogin({
        email: params.email,
        password: params.password,
        loginType: this.authenticationStore.loginType
      });

    } else if (this.loginMethod === LoginMethod.AccountDetails) {

      return this.authenticationService.updateLegacyOwnerCredentialsPasswordOnLogin({
        accountNumber: params.accountNumber,
        plan: params.plan,
        unitNumber: params.unitNumber,
        password: params.password
      });
    }

    throw new Error(this.invalidLoginMethodMessage('Store.updateCredentialsPassword$'));
  }

  updateCredentialsPassword(params: LegacyOwnerCredentials & {
    email: string;
    password: string;
  }) {

    this.setPasswordUpdateFailed(false);

    return this.updateCredentialsPassword$(params).pipe(
      tap(() => this.setAuthenticationAndRedirect({
        identity: {
          accountNumber: params.accountNumber,
          plan: params.plan,
          unitNumber: params.unitNumber,
          email: params.email,
        },
        password: params.password
      })),
      catchError(e => {

        this.setPasswordUpdateFailed(true);

        return throwError(e);
      }),
    );
  }

  sendEmailTempPin(params: {
    email: string;
  }) {

    this.setLoginError(undefined);

    return this.authenticationService.sendEmailTempPin(params).pipe(
      catchError(e => {

        this.setLoginError(e);

        return throwError(e);
      }),
    );
  }

  sendMobileTempPin(params: {
    mobile: string;
  }) {

    this.setLoginError(undefined);

    return this.authenticationService.sendMobileTempPin(params).pipe(
      catchError(e => {

        this.setLoginError(e);

        return throwError(e);
      }),
    );
  }

  private validateLoginCode$(params: {
    emailOrMobile: string;
    pin: string;
  }) {

    switch (this.loginMethod) {

      case LoginMethod.Email:

        return this.authenticationService.validateEmailTempPinLoginCredentials({
          email: params.emailOrMobile,
          pin: params.pin
        });

      case LoginMethod.Mobile:

        return this.authenticationService.validateMobileTempPinLoginCredentials({
          mobile: params.emailOrMobile,
          pin: params.pin
        });

      default:

        throw new Error(this.invalidLoginMethodMessage('Store.validateLoginCode$'));
    }
  }

  validateLoginCode(params: {
    emailOrMobile: string;
    pin: string;
  }) {

    this.setLoginCodeInvalid(false);

    return this.validateLoginCode$(params).pipe(
      tap(response => {

        this.credentialsResponse = response;

        this.setLoginCodeInvalid(!response.credentials.valid);

        if (response.credentials.valid) {

          this.setAuthenticationAndRedirect({
            identity: {
              email: this.loginMethod === LoginMethod.Email ? params.emailOrMobile : undefined,
              mobile: this.loginMethod === LoginMethod.Mobile ? formatPhoneNumberForPersistance(params.emailOrMobile) : undefined,
              usingLoginCode: true
            },
            password: response.sessionGuid
          });
        }
      }),
      catchError(e => {

        this.setLoginCodeInvalid(true);

        return throwError(e);
      })
    );
  }

  @action
  setLoginMethod(loginMethod: LoginMethod) {

    this._loginMethod = loginMethod;
  }

  @action
  setLoginError(error: Error | undefined) {

    this._loginError = error;
  }

  @action
  setLoginCodeInvalid(flag: boolean) {

    this._loginCodeInvalid = flag;
  }

  @action
  setIsImpersonationLogin(flag: boolean) {

    this._isImpersonationLogin = flag;
  }

  @action
  toggleForgottenPasswordLink() {

    this._forgottenPassword = !this.forgottenPassword;
  }

  @action
  setForgottenPassword(forgottenPassword: boolean) {

    this._forgottenPassword = forgottenPassword;
  }

  @action
  setPasswordUpdateFailed(passwordUpdateFailed: boolean) {

    this._passwordUpdateFailed = passwordUpdateFailed;
  }

  invalidLoginMethodMessage(callsite: string) {

    return `${callsite}: Invalid loginMethod: ${this.loginMethod}`;
  }

  private setAuthenticationAndRedirect(params: LoginCredentials) {

    // tslint:disable-next-line: no-non-null-assertion
    const credentialsResponse = this.credentialsResponse!; // Should be valid here

    this.authenticationStore.setAuthentication({
      identity: {
        ...params.identity,
        loginType: this.authenticationStore.loginType,
        userId: credentialsResponse.credentials.userId
      },
      password: params.password || '',
      firstName: credentialsResponse.credentials.firstName,
      lastName: credentialsResponse.credentials.lastName,
      combinedName: credentialsResponse.credentials.lastName,
      authorities: credentialsResponse.credentials.authorities,
      sharedDocumentDomain: credentialsResponse.credentials.sharedDocumentDomain,
      selectedStrataId: credentialsResponse.credentials.selectedStrataId,
      selectedUnit: credentialsResponse.unit,
    });

    if (credentialsResponse.unit && credentialsResponse.unit.canChangeAccountCode) {

      this.authenticationStore.addAuthority(Authority.ChangeAccountCode);

    } else {

      this.authenticationStore.removeAuthority(Authority.ChangeAccountCode);
    }

    this.appStore.redirectAfterValidAuthentication();
  }

  @computed
  get isEmailVisible(): boolean {

    return this.loginMethod === LoginMethod.Email || (!this.isImpersonationLogin && this.forgottenPassword);
  }

  @computed
  get isMobileVisible(): boolean {

    return this.loginMethod === LoginMethod.Mobile;
  }

  @computed
  get isPasswordVisible(): boolean {

    return !this.isImpersonationLogin && !this.forgottenPassword;
  }

  @computed
  get isPasswordDescriptionVisible(): boolean {

    return this.loginMethod === LoginMethod.AccountDetails;
  }

  @computed
  get isDontKnowEmailVisible(): boolean {

    return this.loginMethod === LoginMethod.AccountDetails && this.forgottenPassword;
  }

  @computed
  get isForgotYouPasswordVisible(): boolean {

    return this.loginMethod !== LoginMethod.Mobile;
  }

  @computed
  get backgroundCSS(): string {

    let loginType = '';

    switch (this.authenticationStore.loginType) {

      case LoginType.BUILDING:

        loginType = LoginType.BUILDING;
    }

    return `wht${loginType ? '-' + loginType.toLowerCase() : ''}-background-splash`;
  }

  @computed
  get isLoginMethodVisible(): boolean {

    return this.authenticationStore.loginType === LoginType.OWNER;
  }

  @computed
  get oneTimePinButtonVisible(): boolean {

    return this.loginMethod !== LoginMethod.AccountDetails &&
      !this.forgottenPassword &&
      !this.isImpersonationLogin &&
      this.authenticationStore.loginType === LoginType.OWNER;
  }

  @computed
  get oneTimePinButtonIcon(): string | undefined {

    switch (this.loginMethod) {

      case LoginMethod.Email:

        return 'ui-icon-email';

      case LoginMethod.Mobile:

        return 'ui-icon-phone-android';

      default:

        return undefined;
    }
  }

  @computed
  get loginErrorMessage(): string {

    if (!this._loginError) {

      return '';
    }

    if (this._loginError instanceof HttpErrorResponse) {

      const irisErrorMessage = getIrisErrorMessage(this._loginError);

      if (irisErrorMessage) {

        return irisErrorMessage;
      }
    }

    if (this._forgottenPassword) {

      return 'Password reset request failed';
    }

    return 'Login failed';
  }

  get passwordUpdateFailed() {

    return this._passwordUpdateFailed;
  }

  get loginMethod() {

    return this._loginMethod;
  }

  get isImpersonationLogin() {

    return this._isImpersonationLogin;
  }

  get forgottenPassword() {

    return this._forgottenPassword;
  }

  get loginCodeInvalid() {

    return this._loginCodeInvalid;
  }
}
