import { Injectable } from '@angular/core';

import { Observable, throwError, from } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { BaseAppStore } from '../store/base-app.store';
import { Logger } from '../services/logger.service';
import { MessageStore } from '../store/message.store';
import { MessageSeverity } from '../models/message';
import { BaseAppProperties } from '../base-app-properties';
import { PortalType } from '../models/portal-type';

export interface JSReport {
  serverUrl?: string;
  render(placeholder: string | HTMLElement, request: JSReportRequest): void;
  renderAsync(request: JSReportRequest): Promise<JSReportResponse>;
}

export interface JSReportRequest {
  readonly template?: {
    readonly name?: string;
    readonly shortid?: string;
    readonly content?: string;
    readonly engine?: 'none' | 'handlebars' | 'jsrender';
    readonly recipe?: 'html-with-browser-client' | 'phantom-pdf' | 'chrome-pdf' | 'html-to-xlsx' | 'xlsx';
  };
  readonly data: object;
}

export interface JSReportResponse extends ArrayBuffer {
  download(filename: string): void;
  toDataURI(): string;
  toString(): string;
  toObjectURL(): string;
  toBlob(): Blob;
}

declare const jsreport: JSReport;

export enum JSReportName {
  CreditCardReceipt = 'Credit-Card-Receipt'
}

// Wrapper around the global jsreport.
@Injectable({
  providedIn: 'root'
})
export class JSReportService {

  constructor(
    private readonly appProperties: BaseAppProperties,
    private readonly appStore: BaseAppStore,
    private readonly messageStore: MessageStore,
    private readonly logger: Logger) {
  }

  getReportData(request: JSReportRequest, params?: {
    showActivityIndicator?: boolean;
  }): Observable<JSReportResponse> {

    const showActivityIndicator = !params || params.showActivityIndicator == null || params.showActivityIndicator;

    if (showActivityIndicator) {

      this.appStore.showActivityIndicator({ flag: true });
    }

    return from(jsreport.renderAsync(request)).pipe(
      catchError(e => {

        this.logger.error('JSReportService: error invoking jsreport.renderAsync', e);

        if (this.appProperties.portalType === PortalType.Manager) {

          this.messageStore.pushMessage({
            severity: MessageSeverity.Error,
            summary: 'error contacting the JSReport server!'
          });
        }

        return throwError(e);
      }),
      finalize(() => {

        if (showActivityIndicator) {

          this.appStore.showActivityIndicator({ flag: false });
        }
      }));
  }
}
