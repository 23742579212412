import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
  ActionKind,
  AppEventBus,
  BaseComponent,
  DialogStore,
  DialogWidth,
  fadeInOutTrigger,
  formatPhoneNumberForDisplay,
  FormModel,
  notEmptyValidator,
  phoneValidator,
  property,
  validationError
} from 'common-lib';
import { tap } from 'rxjs/operators';

import { Store } from './contact-details.store';
import {DomainStore} from "../../stores/domain.store";

interface ContactDetailsFormModel {
  readonly emails: {
    readonly email: string;
    readonly repeatEmail: string;
  };
  readonly mobile: string;
}

export interface ContactDetailsUpdateEmailParams {
  email: string;
}

export interface ContactDetailsUpdateMobileParams {
  mobile: string;
}

export interface ContactDetailsProceedParams {
  emailUpdated: boolean;
  mobileUpdated: boolean;
}

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Store],
  animations: [fadeInOutTrigger]
})
export class ContactDetailsComponent extends BaseComponent implements OnInit {

  private static readonly confirm = 'confirm';
  private static readonly update = 'update';




  // tslint:disable-next-line: no-input-rename
  @Input('mobile') mobileInput?: string;
  // tslint:disable-next-line: no-input-rename
  @Input('email') emailInput?: string;

  @Input() set emailUpdated(val: boolean) {

    if (this.contactDetailsForm && val) {

      this.emails.markAsPristine();
    }

    this.store.setEmailUpdated(val);
  }

  get emailUpdated() {

    return this.store.emailUpdated;
  }

  @Input() set mobileUpdated(val: boolean) {

    if (this.contactDetailsForm && val) {

      this.mobile.markAsPristine();
    }

    this.store.setMobileUpdated(val);
  }

  get mobileUpdated() {

    return this.store.mobileUpdated;
  }

  @Output() readonly updateEmailClick = new EventEmitter<ContactDetailsUpdateEmailParams>();
  @Output() readonly updateMobileClick = new EventEmitter<ContactDetailsUpdateMobileParams>();
  @Output() readonly proceedClick = new EventEmitter<ContactDetailsProceedParams>();

  contactDetailsForm: UntypedFormGroup;

  static showInDialog(dialogStore: DialogStore, template: TemplateRef<any>) {

    dialogStore.showTemplate(template, {
      header: 'please confirm contact details',
      showHeader: true,
      closable: false,
      width: DialogWidth.Default
    });
  }

  constructor(
    readonly store: Store,
    readonly domainStore: DomainStore,
    private readonly fb: UntypedFormBuilder,
    private readonly appEventBus: AppEventBus) {

    super();
  }

  ngOnInit(): void {

    const emailsFormModel: FormModel<ContactDetailsFormModel['emails']> = {
      email: [this.emailInput, [this.existingEmailValidator, Validators.email]],
      repeatEmail: ['', [Validators.email]],
    };

    const contactDetailsFormModel: FormModel<ContactDetailsFormModel> = {
      emails: this.fb.group(emailsFormModel, { validators: this.emailValidator }),
      mobile: [formatPhoneNumberForDisplay(this.mobileInput), [this.existingMobileValidator, phoneValidator]]
    };

    this.contactDetailsForm = this.fb.group(contactDetailsFormModel);

    this.subscribe(this.email.valueChanges.pipe(
      tap(() => {

        if (this.email.dirty && !this.store.emailDirtied) {

          this.store.setEmailDirtied(true);
        }
      })
    ));
  }

  get gatherContactDetailsMessage() {
    const company = 'Whittles';
    return `To help ${company} communicate with you, please enter, update or confirm your email and mobile information in the fields below.<br><br>
    Please then click both the Email and Mobile update buttons before proceeding to the Owner Portal.`;
  }

  private existingMobileValidator: ValidatorFn = control => {

    if (this.mobileInput && this.mobileInput.trim()) {

      // Don't allow null if mobile exists
      return notEmptyValidator(control);
    }

    return null;
  }

  private existingEmailValidator: ValidatorFn = control => {

    if (this.emailInput && this.emailInput.trim()) {

      // Don't allow null if email exists
      return notEmptyValidator(control);
    }

    return null;
  }

  private emailValidator: ValidatorFn = (control) => {

    const emailValues = control.value as ContactDetailsFormModel['emails'];

    return !this.isRetypeEmailVisible || emailValues.email.toLowerCase() === emailValues.repeatEmail.toLowerCase() ?
      null : validationError(`Emails don't match`);
  }

  onUpdateEmail() {

    this.updateEmailClick.emit({
      email: this.contactDetailsFormValue.emails.email,
    });
  }

  onUpdateMobile() {

    this.updateMobileClick.emit({
      mobile: this.contactDetailsFormValue.mobile
    });
  }

  onRepeatEmailPaste(evt: Event) {

    evt.preventDefault();
  }

  onSubmit() {

    const contactDetailsProceedParams: ContactDetailsProceedParams = {
      emailUpdated: this.store.emailUpdated,
      mobileUpdated: this.store.mobileUpdated
    };

    this.proceedClick.emit(contactDetailsProceedParams);

    this.appEventBus.emit({
      kind: ActionKind.ContactDetailsProceed,
      ...contactDetailsProceedParams
    });
  }

  get submitButtonEnabled(): boolean {

    if (this.emailInput && this.mobileInput) {

      return this.emailUpdated && this.mobileUpdated;
    }

    if (this.emailInput) {

      return this.emailUpdated;
    }

    if (this.mobileInput) {

      return this.mobileUpdated;
    }

    return true;
  }

  get isRetypeEmailVisible(): boolean {

    return this.store.emailDirtied;
  }

  get emailButtonLabel(): string {

    return this.emailInput && this.emails.pristine ? ContactDetailsComponent.confirm : ContactDetailsComponent.update;
  }

  get mobileButtonLabel(): string {

    return this.mobileInput && this.mobile.pristine ? ContactDetailsComponent.confirm : ContactDetailsComponent.update;
  }

  get isUpdateEmailEnabled(): boolean {

    return !!this.contactDetailsFormValue.emails.email && this.email.valid;
  }

  get isRepeatEmailEnabled(): boolean {

    return !!this.contactDetailsFormValue.emails.repeatEmail && this.emails.valid;
  }

  get isUpdateMobileEnabled(): boolean {

    return !!this.contactDetailsFormValue.mobile && this.mobile.valid;
  }

  get isEmailTickVisible(): boolean {

    return this.emailUpdated && this.emails.pristine;
  }

  get isMobileTickVisible(): boolean {

    return this.mobileUpdated && this.mobile.pristine;
  }

  get contactDetailsFormValue() {

    return this.contactDetailsForm.value as ContactDetailsFormModel;
  }

  get emails() {

    return this.contactDetailsForm.controls[property<ContactDetailsFormModel>('emails')] as UntypedFormGroup;
  }

  get email() {

    return this.emails.controls[property<ContactDetailsFormModel['emails']>('email')];
  }

  get repeatEmail() {

    return this.emails.controls[property<ContactDetailsFormModel['emails']>('repeatEmail')];
  }

  get mobile() {

    return this.contactDetailsForm.controls[property<ContactDetailsFormModel>('mobile')];
  }
}
