/**
 * Whittles API
 * Whittles API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AssignTaskParams } from '../model/assignTaskParams';
import { AssignTasksParams } from '../model/assignTasksParams';
import { CancelWorkflowParams } from '../model/cancelWorkflowParams';
import { ClaimTaskParams } from '../model/claimTaskParams';
import { ClaimTaskResponse } from '../model/claimTaskResponse';
import { CompleteTaskParams } from '../model/completeTaskParams';
import { FileData } from '../model/fileData';
import { HoldTaskParams } from '../model/holdTaskParams';
import { ProcessNotesResponse } from '../model/processNotesResponse';
import { Task } from '../model/task';
import { TaskActionParams } from '../model/taskActionParams';
import { TaskCategoryMap } from '../model/taskCategoryMap';
import { TaskHistory } from '../model/taskHistory';
import { TaskPreferencesResponse } from '../model/taskPreferencesResponse';
import { UpdateProcessNotesParams } from '../model/updateProcessNotesParams';
import { UpdateTaskPreferencesParams } from '../model/updateTaskPreferencesParams';
import { User } from '../model/user';
import { WorkflowPreferencesResponse } from '../model/workflowPreferencesResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class WorkflowService {

    protected basePath = 'https://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param assignTaskParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assignTask(assignTaskParams: AssignTaskParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public assignTask(assignTaskParams: AssignTaskParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public assignTask(assignTaskParams: AssignTaskParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public assignTask(assignTaskParams: AssignTaskParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (assignTaskParams === null || assignTaskParams === undefined) {
            throw new Error('Required parameter assignTaskParams was null or undefined when calling assignTask.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/workflow/task/assign`,
            assignTaskParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param assignTasksParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assignTasks(assignTasksParams: AssignTasksParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public assignTasks(assignTasksParams: AssignTasksParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public assignTasks(assignTasksParams: AssignTasksParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public assignTasks(assignTasksParams: AssignTasksParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (assignTasksParams === null || assignTasksParams === undefined) {
            throw new Error('Required parameter assignTasksParams was null or undefined when calling assignTasks.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/workflow/tasks/assign`,
            assignTasksParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param cancelWorkflowParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelWorkflow(cancelWorkflowParams: CancelWorkflowParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public cancelWorkflow(cancelWorkflowParams: CancelWorkflowParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public cancelWorkflow(cancelWorkflowParams: CancelWorkflowParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public cancelWorkflow(cancelWorkflowParams: CancelWorkflowParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cancelWorkflowParams === null || cancelWorkflowParams === undefined) {
            throw new Error('Required parameter cancelWorkflowParams was null or undefined when calling cancelWorkflow.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/workflow/cancel`,
            cancelWorkflowParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param claimTaskParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public claimTask(claimTaskParams: ClaimTaskParams, observe?: 'body', reportProgress?: boolean): Observable<ClaimTaskResponse>;
    public claimTask(claimTaskParams: ClaimTaskParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClaimTaskResponse>>;
    public claimTask(claimTaskParams: ClaimTaskParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClaimTaskResponse>>;
    public claimTask(claimTaskParams: ClaimTaskParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (claimTaskParams === null || claimTaskParams === undefined) {
            throw new Error('Required parameter claimTaskParams was null or undefined when calling claimTask.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ClaimTaskResponse>(`${this.basePath}/workflow/task/claim`,
            claimTaskParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param completeTaskParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public completeTask(completeTaskParams: CompleteTaskParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public completeTask(completeTaskParams: CompleteTaskParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public completeTask(completeTaskParams: CompleteTaskParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public completeTask(completeTaskParams: CompleteTaskParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (completeTaskParams === null || completeTaskParams === undefined) {
            throw new Error('Required parameter completeTaskParams was null or undefined when calling completeTask.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/workflow/task/complete`,
            completeTaskParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataId 
     * @param taskType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCandidateAssigneesByTaskType(strataId: string, taskType: string, observe?: 'body', reportProgress?: boolean): Observable<Array<User>>;
    public getCandidateAssigneesByTaskType(strataId: string, taskType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<User>>>;
    public getCandidateAssigneesByTaskType(strataId: string, taskType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<User>>>;
    public getCandidateAssigneesByTaskType(strataId: string, taskType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataId === null || strataId === undefined) {
            throw new Error('Required parameter strataId was null or undefined when calling getCandidateAssigneesByTaskType.');
        }

        if (taskType === null || taskType === undefined) {
            throw new Error('Required parameter taskType was null or undefined when calling getCandidateAssigneesByTaskType.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (taskType !== undefined && taskType !== null) {
            queryParameters = queryParameters.set('taskType', <any>taskType);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<User>>(`${this.basePath}/workflow/tasks/candidate-assignees/${encodeURIComponent(String(strataId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the workflow diagram for the specifed task
     * 
     * @param taskId 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProcessInstanceDiagramForTask(taskId: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<FileData>;
    public getProcessInstanceDiagramForTask(taskId: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FileData>>;
    public getProcessInstanceDiagramForTask(taskId: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FileData>>;
    public getProcessInstanceDiagramForTask(taskId: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (taskId === null || taskId === undefined) {
            throw new Error('Required parameter taskId was null or undefined when calling getProcessInstanceDiagramForTask.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (taskId !== undefined && taskId !== null) {
            queryParameters = queryParameters.set('taskId', <any>taskId);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<FileData>(`${this.basePath}/workflow/task/diagram`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the workflow history
     * 
     * @param taskId 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProcessInstanceHistory(taskId: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<TaskHistory>>;
    public getProcessInstanceHistory(taskId: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TaskHistory>>>;
    public getProcessInstanceHistory(taskId: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TaskHistory>>>;
    public getProcessInstanceHistory(taskId: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (taskId === null || taskId === undefined) {
            throw new Error('Required parameter taskId was null or undefined when calling getProcessInstanceHistory.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (taskId !== undefined && taskId !== null) {
            queryParameters = queryParameters.set('taskId', <any>taskId);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TaskHistory>>(`${this.basePath}/workflow/history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowProcessId 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProcessNotes(workflowProcessId: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<ProcessNotesResponse>;
    public getProcessNotes(workflowProcessId: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProcessNotesResponse>>;
    public getProcessNotes(workflowProcessId: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProcessNotesResponse>>;
    public getProcessNotes(workflowProcessId: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowProcessId === null || workflowProcessId === undefined) {
            throw new Error('Required parameter workflowProcessId was null or undefined when calling getProcessNotes.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowProcessId !== undefined && workflowProcessId !== null) {
            queryParameters = queryParameters.set('workflowProcessId', <any>workflowProcessId);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ProcessNotesResponse>(`${this.basePath}/workflow/process-notes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get count of Tasks for the logged in user
     * 
     * @param processId 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProcessNotesCount(processId: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public getProcessNotesCount(processId: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public getProcessNotesCount(processId: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public getProcessNotesCount(processId: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (processId === null || processId === undefined) {
            throw new Error('Required parameter processId was null or undefined when calling getProcessNotesCount.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (processId !== undefined && processId !== null) {
            queryParameters = queryParameters.set('processId', <any>processId);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<number>(`${this.basePath}/workflow/process-notes/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get count of Tasks for the logged in user
     * 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTaskCount(showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public getTaskCount(showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public getTaskCount(showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public getTaskCount(showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<number>(`${this.basePath}/workflow/tasks/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param portfolioId 
     * @param taskCategory 
     * @param taskType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTaskPreferences(portfolioId: string, taskCategory: string, taskType: string, observe?: 'body', reportProgress?: boolean): Observable<TaskPreferencesResponse>;
    public getTaskPreferences(portfolioId: string, taskCategory: string, taskType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TaskPreferencesResponse>>;
    public getTaskPreferences(portfolioId: string, taskCategory: string, taskType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TaskPreferencesResponse>>;
    public getTaskPreferences(portfolioId: string, taskCategory: string, taskType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (portfolioId === null || portfolioId === undefined) {
            throw new Error('Required parameter portfolioId was null or undefined when calling getTaskPreferences.');
        }

        if (taskCategory === null || taskCategory === undefined) {
            throw new Error('Required parameter taskCategory was null or undefined when calling getTaskPreferences.');
        }

        if (taskType === null || taskType === undefined) {
            throw new Error('Required parameter taskType was null or undefined when calling getTaskPreferences.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (portfolioId !== undefined && portfolioId !== null) {
            queryParameters = queryParameters.set('portfolioId', <any>portfolioId);
        }
        if (taskCategory !== undefined && taskCategory !== null) {
            queryParameters = queryParameters.set('taskCategory', <any>taskCategory);
        }
        if (taskType !== undefined && taskType !== null) {
            queryParameters = queryParameters.set('taskType', <any>taskType);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TaskPreferencesResponse>(`${this.basePath}/workflow/task-preferences`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Tasks for specified criteria
     * 
     * @param taskFilter 
     * @param taskFilterId 
     * @param groups 
     * @param showActivityIndicator 
     * @param includeInvoiceTasks 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTasks(taskFilter: string, taskFilterId: string, groups?: Array<string>, showActivityIndicator?: boolean, includeInvoiceTasks?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<Task>>;
    public getTasks(taskFilter: string, taskFilterId: string, groups?: Array<string>, showActivityIndicator?: boolean, includeInvoiceTasks?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Task>>>;
    public getTasks(taskFilter: string, taskFilterId: string, groups?: Array<string>, showActivityIndicator?: boolean, includeInvoiceTasks?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Task>>>;
    public getTasks(taskFilter: string, taskFilterId: string, groups?: Array<string>, showActivityIndicator?: boolean, includeInvoiceTasks?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (taskFilter === null || taskFilter === undefined) {
            throw new Error('Required parameter taskFilter was null or undefined when calling getTasks.');
        }

        if (taskFilterId === null || taskFilterId === undefined) {
            throw new Error('Required parameter taskFilterId was null or undefined when calling getTasks.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (taskFilter !== undefined && taskFilter !== null) {
            queryParameters = queryParameters.set('taskFilter', <any>taskFilter);
        }
        if (taskFilterId !== undefined && taskFilterId !== null) {
            queryParameters = queryParameters.set('taskFilterId', <any>taskFilterId);
        }
        if (groups) {
            queryParameters = queryParameters.set('groups', groups.join(COLLECTION_FORMATS['csv']));
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }
        if (includeInvoiceTasks !== undefined && includeInvoiceTasks !== null) {
            queryParameters = queryParameters.set('includeInvoiceTasks', <any>includeInvoiceTasks);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Task>>(`${this.basePath}/workflow/tasks`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkflowCategories(observe?: 'body', reportProgress?: boolean): Observable<Array<TaskCategoryMap>>;
    public getWorkflowCategories(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TaskCategoryMap>>>;
    public getWorkflowCategories(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TaskCategoryMap>>>;
    public getWorkflowCategories(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TaskCategoryMap>>(`${this.basePath}/workflow/categories`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param portfolioId 
     * @param taskCategory 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkflowPreferences(portfolioId: string, taskCategory: string, observe?: 'body', reportProgress?: boolean): Observable<WorkflowPreferencesResponse>;
    public getWorkflowPreferences(portfolioId: string, taskCategory: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WorkflowPreferencesResponse>>;
    public getWorkflowPreferences(portfolioId: string, taskCategory: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WorkflowPreferencesResponse>>;
    public getWorkflowPreferences(portfolioId: string, taskCategory: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (portfolioId === null || portfolioId === undefined) {
            throw new Error('Required parameter portfolioId was null or undefined when calling getWorkflowPreferences.');
        }

        if (taskCategory === null || taskCategory === undefined) {
            throw new Error('Required parameter taskCategory was null or undefined when calling getWorkflowPreferences.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (portfolioId !== undefined && portfolioId !== null) {
            queryParameters = queryParameters.set('portfolioId', <any>portfolioId);
        }
        if (taskCategory !== undefined && taskCategory !== null) {
            queryParameters = queryParameters.set('taskCategory', <any>taskCategory);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WorkflowPreferencesResponse>(`${this.basePath}/workflow/workflow-preferences`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param holdTaskParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public holdTask(holdTaskParams: HoldTaskParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public holdTask(holdTaskParams: HoldTaskParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public holdTask(holdTaskParams: HoldTaskParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public holdTask(holdTaskParams: HoldTaskParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (holdTaskParams === null || holdTaskParams === undefined) {
            throw new Error('Required parameter holdTaskParams was null or undefined when calling holdTask.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/workflow/task/hold`,
            holdTaskParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param taskId 
     * @param taskActionParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public taskAction(taskId: string, taskActionParams: TaskActionParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public taskAction(taskId: string, taskActionParams: TaskActionParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public taskAction(taskId: string, taskActionParams: TaskActionParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public taskAction(taskId: string, taskActionParams: TaskActionParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (taskId === null || taskId === undefined) {
            throw new Error('Required parameter taskId was null or undefined when calling taskAction.');
        }

        if (taskActionParams === null || taskActionParams === undefined) {
            throw new Error('Required parameter taskActionParams was null or undefined when calling taskAction.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/workflow/runtime/tasks/${encodeURIComponent(String(taskId))}`,
            taskActionParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param updateProcessNotesParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProcessNotes(updateProcessNotesParams: UpdateProcessNotesParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateProcessNotes(updateProcessNotesParams: UpdateProcessNotesParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateProcessNotes(updateProcessNotesParams: UpdateProcessNotesParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateProcessNotes(updateProcessNotesParams: UpdateProcessNotesParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (updateProcessNotesParams === null || updateProcessNotesParams === undefined) {
            throw new Error('Required parameter updateProcessNotesParams was null or undefined when calling updateProcessNotes.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/workflow/process-notes`,
            updateProcessNotesParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param updateTaskPreferencesParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTaskPreferences(updateTaskPreferencesParams: UpdateTaskPreferencesParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateTaskPreferences(updateTaskPreferencesParams: UpdateTaskPreferencesParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateTaskPreferences(updateTaskPreferencesParams: UpdateTaskPreferencesParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateTaskPreferences(updateTaskPreferencesParams: UpdateTaskPreferencesParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (updateTaskPreferencesParams === null || updateTaskPreferencesParams === undefined) {
            throw new Error('Required parameter updateTaskPreferencesParams was null or undefined when calling updateTaskPreferences.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/workflow/task-preferences`,
            updateTaskPreferencesParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
