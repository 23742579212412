/**
 * Whittles API
 * Whittles API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateJournalAdviceParams } from '../model/createJournalAdviceParams';
import { Document } from '../model/document';
import { FileData } from '../model/fileData';
import { FinancialYear } from '../model/financialYear';
import { JournalAdvice } from '../model/journalAdvice';
import { NameValuePair } from '../model/nameValuePair';
import { ProcessJournalsParams } from '../model/processJournalsParams';
import { ReconcilePaidInvoiceParams } from '../model/reconcilePaidInvoiceParams';
import { UpdatePaidInvoicesFinancialYearSummaryParams } from '../model/updatePaidInvoicesFinancialYearSummaryParams';
import { UploadDocumentParams } from '../model/uploadDocumentParams';
import { UploadPaidInvoiceDocumentParams } from '../model/uploadPaidInvoiceDocumentParams';
import { WhiteFolderBrowserSummary } from '../model/whiteFolderBrowserSummary';
import { WhiteFolderFinancialYearSummary } from '../model/whiteFolderFinancialYearSummary';
import { WhiteFolderUploadPaidInvoiceResponse } from '../model/whiteFolderUploadPaidInvoiceResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class WhiteFolderService {

    protected basePath = 'https://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param batchId 
     * @param reason 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelJournalWorkflow(batchId: string, reason?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public cancelJournalWorkflow(batchId: string, reason?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public cancelJournalWorkflow(batchId: string, reason?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public cancelJournalWorkflow(batchId: string, reason?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (batchId === null || batchId === undefined) {
            throw new Error('Required parameter batchId was null or undefined when calling cancelJournalWorkflow.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (batchId !== undefined && batchId !== null) {
            queryParameters = queryParameters.set('batchId', <any>batchId);
        }
        if (reason !== undefined && reason !== null) {
            queryParameters = queryParameters.set('reason', <any>reason);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/white-folder/workflow/journal/cancel`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param createJournalAdviceParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createJournalAdvice(createJournalAdviceParams: CreateJournalAdviceParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createJournalAdvice(createJournalAdviceParams: CreateJournalAdviceParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createJournalAdvice(createJournalAdviceParams: CreateJournalAdviceParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createJournalAdvice(createJournalAdviceParams: CreateJournalAdviceParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (createJournalAdviceParams === null || createJournalAdviceParams === undefined) {
            throw new Error('Required parameter createJournalAdviceParams was null or undefined when calling createJournalAdvice.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/white-folder/journal-advice`,
            createJournalAdviceParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDocument(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteDocument(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteDocument(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteDocument(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteDocument.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/white-folder/document/delete/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteJournalAdvice(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteJournalAdvice(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteJournalAdvice(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteJournalAdvice(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteJournalAdvice.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/white-folder/journal-advice`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param financialYearId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteJournalsForFinancialYear(financialYearId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteJournalsForFinancialYear(financialYearId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteJournalsForFinancialYear(financialYearId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteJournalsForFinancialYear(financialYearId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (financialYearId === null || financialYearId === undefined) {
            throw new Error('Required parameter financialYearId was null or undefined when calling deleteJournalsForFinancialYear.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (financialYearId !== undefined && financialYearId !== null) {
            queryParameters = queryParameters.set('financialYearId', <any>financialYearId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/white-folder/process-journals`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param financialYearId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteJournalsForFinancialYear2(financialYearId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteJournalsForFinancialYear2(financialYearId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteJournalsForFinancialYear2(financialYearId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteJournalsForFinancialYear2(financialYearId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (financialYearId === null || financialYearId === undefined) {
            throw new Error('Required parameter financialYearId was null or undefined when calling deleteJournalsForFinancialYear2.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (financialYearId !== undefined && financialYearId !== null) {
            queryParameters = queryParameters.set('financialYearId', <any>financialYearId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/white-folder/delete-journals`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param transactionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePaidInvoiceDocument(transactionId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deletePaidInvoiceDocument(transactionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deletePaidInvoiceDocument(transactionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deletePaidInvoiceDocument(transactionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (transactionId === null || transactionId === undefined) {
            throw new Error('Required parameter transactionId was null or undefined when calling deletePaidInvoiceDocument.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (transactionId !== undefined && transactionId !== null) {
            queryParameters = queryParameters.set('transactionId', <any>transactionId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/white-folder/paid-invoices/delete`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param portalType 
     * @param strataId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBrowserCategories(portalType: string, strataId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<NameValuePair>>;
    public getBrowserCategories(portalType: string, strataId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NameValuePair>>>;
    public getBrowserCategories(portalType: string, strataId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NameValuePair>>>;
    public getBrowserCategories(portalType: string, strataId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (portalType === null || portalType === undefined) {
            throw new Error('Required parameter portalType was null or undefined when calling getBrowserCategories.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (portalType !== undefined && portalType !== null) {
            queryParameters = queryParameters.set('portalType', <any>portalType);
        }
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<NameValuePair>>(`${this.basePath}/white-folder/browser-categories`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param financialYear 
     * @param portalType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBrowserDocuments(financialYear: string, portalType: string, observe?: 'body', reportProgress?: boolean): Observable<Array<WhiteFolderBrowserSummary>>;
    public getBrowserDocuments(financialYear: string, portalType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WhiteFolderBrowserSummary>>>;
    public getBrowserDocuments(financialYear: string, portalType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WhiteFolderBrowserSummary>>>;
    public getBrowserDocuments(financialYear: string, portalType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (financialYear === null || financialYear === undefined) {
            throw new Error('Required parameter financialYear was null or undefined when calling getBrowserDocuments.');
        }

        if (portalType === null || portalType === undefined) {
            throw new Error('Required parameter portalType was null or undefined when calling getBrowserDocuments.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (financialYear !== undefined && financialYear !== null) {
            queryParameters = queryParameters.set('financialYear', <any>financialYear);
        }
        if (portalType !== undefined && portalType !== null) {
            queryParameters = queryParameters.set('portalType', <any>portalType);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WhiteFolderBrowserSummary>>(`${this.basePath}/white-folder/browser-documents`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBrowserFinancialYears(strataId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FinancialYear>>;
    public getBrowserFinancialYears(strataId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FinancialYear>>>;
    public getBrowserFinancialYears(strataId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FinancialYear>>>;
    public getBrowserFinancialYears(strataId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataId === null || strataId === undefined) {
            throw new Error('Required parameter strataId was null or undefined when calling getBrowserFinancialYears.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<FinancialYear>>(`${this.basePath}/white-folder/browser-financial-years`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param portalType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentFileData(id: string, portalType: string, observe?: 'body', reportProgress?: boolean): Observable<FileData>;
    public getDocumentFileData(id: string, portalType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FileData>>;
    public getDocumentFileData(id: string, portalType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FileData>>;
    public getDocumentFileData(id: string, portalType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDocumentFileData.');
        }

        if (portalType === null || portalType === undefined) {
            throw new Error('Required parameter portalType was null or undefined when calling getDocumentFileData.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (portalType !== undefined && portalType !== null) {
            queryParameters = queryParameters.set('portalType', <any>portalType);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<FileData>(`${this.basePath}/white-folder/document/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFinancialYears(strataId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FinancialYear>>;
    public getFinancialYears(strataId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FinancialYear>>>;
    public getFinancialYears(strataId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FinancialYear>>>;
    public getFinancialYears(strataId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataId === null || strataId === undefined) {
            throw new Error('Required parameter strataId was null or undefined when calling getFinancialYears.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<FinancialYear>>(`${this.basePath}/white-folder/financial-years`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param financialYearId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getJournalAdvice(financialYearId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<JournalAdvice>>;
    public getJournalAdvice(financialYearId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<JournalAdvice>>>;
    public getJournalAdvice(financialYearId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<JournalAdvice>>>;
    public getJournalAdvice(financialYearId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (financialYearId === null || financialYearId === undefined) {
            throw new Error('Required parameter financialYearId was null or undefined when calling getJournalAdvice.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (financialYearId !== undefined && financialYearId !== null) {
            queryParameters = queryParameters.set('financialYearId', <any>financialYearId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<JournalAdvice>>(`${this.basePath}/white-folder/journal-advice`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param financialYearId 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getJournalAdviceSupportingDocuments(financialYearId: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<Document>>;
    public getJournalAdviceSupportingDocuments(financialYearId: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Document>>>;
    public getJournalAdviceSupportingDocuments(financialYearId: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Document>>>;
    public getJournalAdviceSupportingDocuments(financialYearId: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (financialYearId === null || financialYearId === undefined) {
            throw new Error('Required parameter financialYearId was null or undefined when calling getJournalAdviceSupportingDocuments.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (financialYearId !== undefined && financialYearId !== null) {
            queryParameters = queryParameters.set('financialYearId', <any>financialYearId);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Document>>(`${this.basePath}/white-folder/journal-advice-supporting-documents`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param financialYear 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPaidInvoicesFinancialYearSummary(financialYear: string, observe?: 'body', reportProgress?: boolean): Observable<WhiteFolderFinancialYearSummary>;
    public getPaidInvoicesFinancialYearSummary(financialYear: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WhiteFolderFinancialYearSummary>>;
    public getPaidInvoicesFinancialYearSummary(financialYear: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WhiteFolderFinancialYearSummary>>;
    public getPaidInvoicesFinancialYearSummary(financialYear: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (financialYear === null || financialYear === undefined) {
            throw new Error('Required parameter financialYear was null or undefined when calling getPaidInvoicesFinancialYearSummary.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (financialYear !== undefined && financialYear !== null) {
            queryParameters = queryParameters.set('financialYear', <any>financialYear);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WhiteFolderFinancialYearSummary>(`${this.basePath}/white-folder/paid-invoices/financial-year-summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param filenames optional filenames to import
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importMeetingFiles(filenames?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public importMeetingFiles(filenames?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public importMeetingFiles(filenames?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public importMeetingFiles(filenames?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/white-folder/import-meeting-files`,
            filenames,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param directory directory to import
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importMeetingFilesDirectory(directory?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public importMeetingFilesDirectory(directory?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public importMeetingFilesDirectory(directory?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public importMeetingFilesDirectory(directory?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (directory !== undefined && directory !== null) {
            queryParameters = queryParameters.set('directory', <any>directory);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/white-folder/import-meeting-files-directory`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importNabCreditorPayments(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public importNabCreditorPayments(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public importNabCreditorPayments(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public importNabCreditorPayments(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/white-folder/import-nab-creditor-payments`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param processJournalsParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public processJournals(processJournalsParams: ProcessJournalsParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public processJournals(processJournalsParams: ProcessJournalsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public processJournals(processJournalsParams: ProcessJournalsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public processJournals(processJournalsParams: ProcessJournalsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (processJournalsParams === null || processJournalsParams === undefined) {
            throw new Error('Required parameter processJournalsParams was null or undefined when calling processJournals.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/white-folder/process-journals`,
            processJournalsParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param reconcilePaidInvoiceParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reconcilePaidInvoice(reconcilePaidInvoiceParams: ReconcilePaidInvoiceParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public reconcilePaidInvoice(reconcilePaidInvoiceParams: ReconcilePaidInvoiceParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public reconcilePaidInvoice(reconcilePaidInvoiceParams: ReconcilePaidInvoiceParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public reconcilePaidInvoice(reconcilePaidInvoiceParams: ReconcilePaidInvoiceParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (reconcilePaidInvoiceParams === null || reconcilePaidInvoiceParams === undefined) {
            throw new Error('Required parameter reconcilePaidInvoiceParams was null or undefined when calling reconcilePaidInvoice.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/white-folder/paid-invoice/reconcile`,
            reconcilePaidInvoiceParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataFinancialYearId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public startJournalWorkflow(strataFinancialYearId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public startJournalWorkflow(strataFinancialYearId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public startJournalWorkflow(strataFinancialYearId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public startJournalWorkflow(strataFinancialYearId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataFinancialYearId === null || strataFinancialYearId === undefined) {
            throw new Error('Required parameter strataFinancialYearId was null or undefined when calling startJournalWorkflow.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataFinancialYearId !== undefined && strataFinancialYearId !== null) {
            queryParameters = queryParameters.set('strataFinancialYearId', <any>strataFinancialYearId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/white-folder/workflow/journal/start`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param updatePaidInvoicesFinancialYearSummaryParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePaidInvoicesFinancialYearSummary(updatePaidInvoicesFinancialYearSummaryParams: UpdatePaidInvoicesFinancialYearSummaryParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updatePaidInvoicesFinancialYearSummary(updatePaidInvoicesFinancialYearSummaryParams: UpdatePaidInvoicesFinancialYearSummaryParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updatePaidInvoicesFinancialYearSummary(updatePaidInvoicesFinancialYearSummaryParams: UpdatePaidInvoicesFinancialYearSummaryParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updatePaidInvoicesFinancialYearSummary(updatePaidInvoicesFinancialYearSummaryParams: UpdatePaidInvoicesFinancialYearSummaryParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (updatePaidInvoicesFinancialYearSummaryParams === null || updatePaidInvoicesFinancialYearSummaryParams === undefined) {
            throw new Error('Required parameter updatePaidInvoicesFinancialYearSummaryParams was null or undefined when calling updatePaidInvoicesFinancialYearSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/white-folder/paid-invoices/financial-year-summary`,
            updatePaidInvoicesFinancialYearSummaryParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uploadDocumentParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadDocument(uploadDocumentParams: UploadDocumentParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public uploadDocument(uploadDocumentParams: UploadDocumentParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public uploadDocument(uploadDocumentParams: UploadDocumentParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public uploadDocument(uploadDocumentParams: UploadDocumentParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uploadDocumentParams === null || uploadDocumentParams === undefined) {
            throw new Error('Required parameter uploadDocumentParams was null or undefined when calling uploadDocument.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/white-folder/document/upload`,
            uploadDocumentParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uploadPaidInvoiceDocumentParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadPaidInvoiceDocument(uploadPaidInvoiceDocumentParams: UploadPaidInvoiceDocumentParams, observe?: 'body', reportProgress?: boolean): Observable<WhiteFolderUploadPaidInvoiceResponse>;
    public uploadPaidInvoiceDocument(uploadPaidInvoiceDocumentParams: UploadPaidInvoiceDocumentParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WhiteFolderUploadPaidInvoiceResponse>>;
    public uploadPaidInvoiceDocument(uploadPaidInvoiceDocumentParams: UploadPaidInvoiceDocumentParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WhiteFolderUploadPaidInvoiceResponse>>;
    public uploadPaidInvoiceDocument(uploadPaidInvoiceDocumentParams: UploadPaidInvoiceDocumentParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uploadPaidInvoiceDocumentParams === null || uploadPaidInvoiceDocumentParams === undefined) {
            throw new Error('Required parameter uploadPaidInvoiceDocumentParams was null or undefined when calling uploadPaidInvoiceDocument.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<WhiteFolderUploadPaidInvoiceResponse>(`${this.basePath}/white-folder/paid-invoices/upload`,
            uploadPaidInvoiceDocumentParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
