import { Injectable } from '@angular/core';

// Wrapper around the native browser window object that can be injected.
@Injectable({
  providedIn: 'root'
})
export class WindowRef {

  get nativeWindow(): Window {

    return window;
  }
}
