import Cookies from 'js-cookie';
import { action, computed, observable } from 'mobx';

import { BaseAppProperties } from '../base-app-properties';
import { Environment } from '../environment';
import { Authentication } from '../models/authentication';
import { Authority } from '../models/authority';
import { Logger } from '../services/logger.service';
import { WindowRef } from '../services/window-ref.service';
import { asEnum } from '../utils/enum.utils';

import { BaseAppStore } from './base-app.store';
import { Subject } from 'rxjs';
import { LoginType } from '../generated';

export abstract class BaseAuthenticationStore {

  private readonly PERMISSIONS_COOKIE_SUFFIX = '.permissions';
  readonly setPermissions$ = new Subject<void>();

  @observable
  private _loginType?: LoginType;

  @observable
  private _authentication?: Authentication;

  constructor(
    protected readonly authenticationCookieName: string,
    protected readonly logger: Logger,
    protected readonly windowRef: WindowRef,
    protected readonly appStore: BaseAppStore,
    protected readonly environment: Environment,
    protected readonly appProperties: BaseAppProperties) {

    this.refreshStoreFromCookie();
  }

  private static parseAuthority(s: string): Authority | undefined {

    try {

      return asEnum(Authority, s);

    } catch (e) {

      return undefined;
    }
  }

  private static mapAuthorities(authorities: string[]): Authority[] {

    const mappedAuthorities = authorities
      .map(authority => BaseAuthenticationStore.parseAuthority(authority))
      .filter(authority => authority != null);

    return mappedAuthorities as Authority[];
  }

  @action
  logout() {

    this.appStore.flagLogoutInvoked();

    this.setAuthentication(undefined);

    // This will force a reload thus ensuring an easy way of clearing out all local state
    this.windowRef.nativeWindow.location.href = '/';
  }

  @action
  addAuthority(authority: Authority) {

    if (this.authentication && !this.authorities.find(a => authority.valueOf() === a)) {

      this.authentication.authorities.push(authority);

      this.setAuthentication({
        // tslint:disable-next-line:no-non-null-assertion
        ...this.authentication!
      });
    }
  }

  @action
  removeAuthority(authority: Authority) {

    if (this.authentication) {

      const index = this.authentication.authorities.indexOf(authority.valueOf());

      if (index > -1) {
        this.authentication.authorities.splice(index, 1);
      }

      this.setAuthentication({
        // tslint:disable-next-line:no-non-null-assertion
        ...this.authentication!
      });
    }
  }

  hasAnyAuthority(...args: Authority[]): boolean {

    if (!args) {

      return false;
    }

    const hasAuthority = args.some(authority => this.authorities.includes(authority));

    return hasAuthority;
  }

  hasAllAuthorities(...args: Authority[]): boolean {

    if (!args) {

      return true;
    }

    const hasAuthority = args.every(authority => this.authorities.includes(authority));

    return hasAuthority;
  }

  @action
  setAuthentication(authentication: Authentication | undefined) {

    this._authentication = authentication;

    if (authentication) {

      this.setAuthenticationCookie();

    } else {

      this.removeAuthenticationCookie();
    }

    this.setDocumentDomain();
  }

  @action
  updateAuthentication(authentication: Authentication | undefined) {

    this._authentication = authentication;

  }

  @action
  setLoginType(loginType: LoginType | undefined) {

    this._loginType = loginType;
  }

  private setDocumentDomain() {

    if (!this.environment.production || !this.authentication || !this.authentication.sharedDocumentDomain) {

      return;
    }

    this.windowRef.nativeWindow.document.domain = this.authentication.sharedDocumentDomain;
  }

  setPassword(password: string) {

    this.setAuthentication({
      // tslint:disable-next-line:no-non-null-assertion
      ...this.authentication!,
      password
    });
  }

  private refreshStoreFromCookie() {

    const authentication = this.getAuthenticationCookie();

    if (!authentication) {

      return;
    }

    this.setAuthentication(authentication);
  }

  @computed
  get isAuthenticated(): boolean {

    return this.authentication != null;
  }

  @computed
  get basicAuth(): string {

    if (!this.authentication) {

      return '';
    }

    // Since the identity is an object, base64 encode it
    const base64EncodedIdentity = btoa(JSON.stringify(this.authentication.identity));

    // HTTP Basic Auth format Base64(username:password)
    return btoa(`${base64EncodedIdentity}:${this.authentication.password}`);
  }

  private removeAuthenticationCookie() {

    Cookies.remove(this.authenticationCookieName);
    localStorage.removeItem(this.authenticationCookieName + this.PERMISSIONS_COOKIE_SUFFIX);
  }

  protected getAuthenticationCookie(): Authentication | undefined {

    const base64EncodedValue = Cookies.get(this.authenticationCookieName)
    const base64Permissions = localStorage.getItem(this.authenticationCookieName + this.PERMISSIONS_COOKIE_SUFFIX);

    if (!base64EncodedValue) {

      return undefined;
    }

    try {

      const json = atob(base64EncodedValue);
      const authentication = JSON.parse(json);

      if (!base64Permissions) {

        return authentication;
      }

      const permissionObj = atob(base64Permissions!);
      const permissionJson = JSON.parse(permissionObj);
      authentication.editables = permissionJson.editables;
      authentication.visibles = permissionJson.visibles;

      return authentication;

    } catch (e) {

      // Cookie could not be decoded from base64 string, maybe it has been fiddled with
      this.logger.warn('Could not parse existing authentication cookie, logging out ...');

      this.logout();

      return undefined;
    }
  }

  private setAuthenticationCookie() {
    // filter this.authentication to remove editable and visible properties
    const auth = this.authentication;
    if (!auth) {
      return;
    }
    const authentication = {
      ...auth,
      editables: undefined,
      visibles: undefined
    };

    const authenticationBase64 = btoa(JSON.stringify(authentication));

    Cookies.set(this.authenticationCookieName, authenticationBase64);

    if (this.authentication!.editables?.length || this.authentication!.visibles?.length) {

      const authenticationPermissions = {
        editables: this.authentication!.editables,
        visibles: this.authentication!.visibles
      }
      const permissionsBase64 = btoa(JSON.stringify(authenticationPermissions));
      localStorage.setItem(this.authenticationCookieName + this.PERMISSIONS_COOKIE_SUFFIX, permissionsBase64)
    }

    this.setPermissions$.next();
  }

  setEmail(email: string) {

    if (!this.isEmailLogin) {

      return;
    }

    this.setAuthentication({
      // tslint:disable-next-line: no-non-null-assertion
      ...this.authentication!,
      identity: {
        // tslint:disable-next-line: no-non-null-assertion
        ...this.authentication!.identity,
        email
      }
    });
  }

  setMobile(mobile: string) {

    if (!this.isMobileLogin) {

      return;
    }

    this.setAuthentication({
      // tslint:disable-next-line: no-non-null-assertion
      ...this.authentication!,
      identity: {
        // tslint:disable-next-line: no-non-null-assertion
        ...this.authentication!.identity,
        mobile
      }
    });
  }

  @computed
  get userId(): string | undefined {

    return this.authentication && this.authentication.identity.userId;
  }

  @computed
  get fullName(): string {

    if (!this.authentication) {

      return '';
    }

    return `${this.authentication.firstName} ${this.authentication.lastName}`;
  }

  @computed
  get identity() {

    if (!this.authentication) {

      return undefined;
    }

    return this.authentication.identity;
  }

  @computed
  get authorities(): Authority[] {

    if (!this.authentication) {

      return [];
    }

    return BaseAuthenticationStore.mapAuthorities(this.authentication.authorities);
  }

  @computed
  get documentDomainMatchesSharedDocumentDomain(): boolean {

    return this.authentication != null && this.authentication.sharedDocumentDomain != null &&
      (document.domain === this.authentication.sharedDocumentDomain);
  }

  @computed
  get loginType(): LoginType {

    return this._loginType || (this.authentication && this.authentication.identity.loginType) || LoginType.OWNER;
  }

  get authentication() {

    return this._authentication;
  }

  @computed
  get isEmailLogin(): boolean {

    return !!this.authentication && !!this.authentication.identity.email;
  }

  @computed
  get isMobileLogin(): boolean {

    return !!this.authentication && !!this.authentication.identity.mobile;
  }

  @computed
  get isAdmin(): boolean {

    return this.hasAnyAuthority(Authority.Administrator);
  }
}
