import { SelectItem, TreeNode } from 'primeng/api';

import { TableFilterEvent } from '../models/table';
import {NameValuePair} from "../generated";
import {toPretty} from "./string.utils";

interface Data<T> {
  [key: number]: T;
  length: number;
}

interface Options<T> {
  childrenKey?: (node: T) => string;
}

const DEFAULT_CHILDREN_KEY = 'children';

const defaultOptions: Options<any> = {
  childrenKey: () => DEFAULT_CHILDREN_KEY
};

//  Iterate recursivley over JSON stucture
function* processData<T>(data: Data<T>, options: Options<T> = defaultOptions): IterableIterator<T> {

  if (!data) {

    return;
  }

  // tslint:disable-next-line: prefer-for-of
  for (let i = 0; i < data.length; i++) {

    const val = data[i];

    yield val;

    const childrenKey = options.childrenKey ? options.childrenKey(val) : DEFAULT_CHILDREN_KEY;

    const children = (val as any)[childrenKey];

    if (children) {

      yield* processData(children, options);
    }
  }
}

export function toSelectItemArray(
  o: { [key: string]: any },
  options?: {
    labelFromValue?: boolean;
    valueFromKey?: boolean;
  }
): SelectItem[] {

  return Object.keys(o).map(key => ({
    label: options && options.labelFromValue ? o[key] : key,
    value: options && options.valueFromKey ? key : o[key]
  }));
}

export function clone<T>(obj: T): T {

  return JSON.parse(JSON.stringify(obj));
}

export function property<T>(prop: keyof T) {

  return prop;
}

function filterNodes<T extends TreeNode>(args: {
  nodes: T[];
  tableFilter: TableFilterEvent;
  filterProperties: string[];
}): T[] {

  let filteredNodes: T[] = args.nodes;

  args.filterProperties.forEach(fp => {

    if (args.tableFilter.filters[fp]) {

      const value = args.tableFilter.filters[fp].value as string;

      if (value && value.trim()) {

        filteredNodes = filteredNodes.filter(n => {

          const dataProperty = n.data[fp] as string;

          return dataProperty && dataProperty.toLowerCase().includes(value.toLowerCase());
        });
      }
    }
  });

  return filteredNodes;
}

export function filterTreeNodes<T extends TreeNode>(args: {
  nodes: T[];
  tableFilter: TableFilterEvent;
  filterProperties: string[];
  createNode(parent: T, children: T[]): T
}) {

  const filteredNodes: T[] = [];

  args.nodes.forEach(n => {

    if (n.children) {

      const filteredChildren = filterNodes({
        nodes: n.children,
        tableFilter: args.tableFilter,
        filterProperties: args.filterProperties
      });

      if (filteredChildren.length > 0) {

        filteredNodes.push(args.createNode(n, filteredChildren as T[]));

      } else if (filterNodes({
        nodes: [n],
        tableFilter: args.tableFilter,
        filterProperties: args.filterProperties
      }).length > 0) {

        filteredNodes.push(n);
      }

    } else if (filterNodes({
      nodes: [n],
      tableFilter: args.tableFilter,
      filterProperties: args.filterProperties
    }).length > 0) {

      filteredNodes.push(n);
    }
  });

  return filteredNodes;
}

export function findTreeNode<T extends TreeNode>(args: {
  nodes: T[];
  predicate: (node: T) => boolean;
}): T | undefined {

  const it = processData(args.nodes);

  let res = it.next();

  while (!res.done) {

    const node = res.value;

    if (args.predicate(node)) {

      return node;
    }

    res = it.next();
  }

  return undefined;
}

export function toOptions(object: Object, pretty?: boolean): NameValuePair[] {
  const VALUE = 1;

  return Object.entries(object).map(
    e =>  {
      return {name: pretty ? toPretty(e[VALUE] as string) : e[VALUE], value: e[VALUE]}
    });
}
