import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Action } from '../models/action';

@Injectable({
  providedIn: 'root'
})
export class AppEventBus {

  private readonly _action$ = new Subject<Action>();

  get action$() {

    return this._action$.asObservable();
  }

  emit(action: Action) {

    this._action$.next(action);
  }
}
