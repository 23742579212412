import { AfterViewInit, ChangeDetectorRef, NgZone, OnInit, Renderer2, ViewChild, Directive } from '@angular/core';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { Dialog } from 'primeng/dialog';
import { tap } from 'rxjs/operators';

import { Logger } from '../../services/logger.service';
import { BaseAppStore } from '../../store/base-app.store';
import { BaseAuthenticationStore } from '../../store/base-authentication.store';
import { ConfirmationStore } from '../../store/confirmation.store';
import { DialogStore } from '../../store/dialog.store';
import { MessageStore } from '../../store/message.store';

import { BaseComponent } from './base.component';
import { PrimeNGAppComponent } from './primeng-app.component';

@Directive()
export abstract class BaseAppComponent extends PrimeNGAppComponent implements OnInit, AfterViewInit {

  private static readonly backgroundSplashCssSelector = 'wht-background-splash';

  @ViewChild(Dialog) readonly dialog?: Dialog;
  @ViewChild(ConfirmDialog) readonly confirmDialog: ConfirmDialog;

  constructor(
    readonly renderer: Renderer2,
    readonly zone: NgZone,
    readonly cdr: ChangeDetectorRef,
    readonly logger: Logger,
    readonly appStore: BaseAppStore,
    readonly dialogStore: DialogStore,
    readonly confirmationStore: ConfirmationStore,
    readonly messageStore: MessageStore,
    readonly authenticationStore: BaseAuthenticationStore) {

    super(renderer, zone);
  }

  ngOnInit(): void {

    super.ngOnInit();

    this.subscribe(this.dialogStore.positionOverlay$.pipe(
      tap(() => {

        if (!this.dialog || !this.dialog.container) {

          return;
        }
        this.dialog.modal = true;
        this.dialog.visible = true;
      })
    ));

    this.mobxReaction('BaseAppComponent: appStore.displayBackgroundSplash',
      () => this.appStore.displayBackgroundSplash,
      displayBackgroundSplash => {

        if (displayBackgroundSplash) {

          document.body.classList.add(BaseAppComponent.backgroundSplashCssSelector);

        } else {

          document.body.classList.remove(BaseAppComponent.backgroundSplashCssSelector);
        }
      });
  }

  ngAfterViewInit() {

    super.ngAfterViewInit();

    this.monkeyPatchConfirmDialog();
  }

  onActivate(component: BaseComponent) {
  }

  onDeactivate(component: BaseComponent) {

    this.appStore.setDisplayBackgroundSplash(false);
  }

  private monkeyPatchConfirmDialog() {

    const close = this.confirmDialog.close.bind(this.confirmDialog);

    this.confirmDialog.close = (evt, ...args) => {

      if (this.confirmationStore.rejectOnClose) {

        close(evt, ...args);

      } else {

        this.confirmDialog.hide();

        evt.preventDefault();
      }
    };
  }
}
