import { ChangeDetectorRef, Component, NgZone, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  arePhoneNumbersEqual,
  BaseAppComponent,
  BrowserUtils,
  ConfirmationStore,
  DialogStore,
  isProductionSubdomain,
  LocalisationStore,
  Logger,
  LoginType,
  MessageStore,
  OwnerService,
  switchMapCatch,
  User,
  UserService,
  VersionDetectorStore
} from 'common-lib';
import { EMPTY, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';

import { environment } from '../environments/environment';

import {
  ContactDetailsComponent,
  ContactDetailsUpdateEmailParams,
  ContactDetailsUpdateMobileParams
} from './components/contact-details/contact-details.component';
import { OwnerSelectorComponent } from './components/owner-selector/owner-selector.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { UnitSelectorComponent } from './components/unit-selector/unit-selector.component';
import { RoutePath } from './route-path';
import { AppStore } from './stores/app.store';
import { AuthenticationStore } from './stores/authentication.store';
import { DomainStore } from './stores/domain.store';

enum ContactDetailsToUpdate {
  Email,
  Mobile
}

// tslint:disable-next-line: prefer-on-push-component-change-detection
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseAppComponent implements OnInit {

  private readonly contactDetailsStatus$ = new Subject();
  private readonly contactDetailsUpdate$ = new Subject<{ contactDetails: string }>();
  private readonly ownersSelected$ = new Subject<User[]>();
  private readonly termsAndConditionsStatus$ = new Subject();

  private readonly activityEvents$ = merge(
    fromEvent(document, 'keydown'),
    fromEvent(document, 'click')
  );

  @ViewChild('contactDetailsTpl') private readonly contactDetailsTpl: TemplateRef<any>;
  @ViewChild('selectOwnerTpl') private readonly selectOwnerTpl: TemplateRef<any>;
  @ViewChild('termsAndConditionsTpl') private readonly termsAndConditionsTpl: TemplateRef<any>;

  private contactDetailsToUpdate: ContactDetailsToUpdate;

  ownerEmail?: string;
  ownerMobile?: string;
  ownerEmailUpdated = false;
  ownerMobileUpdated = false;
  ownerSelectorNewEmail?: string;
  ownerSelectorNewMobile?: string;
  ownerSelectorOwners: User[] = [];

  loginMessageVisible = false;

  constructor(
    renderer: Renderer2,
    zone: NgZone,
    cdr: ChangeDetectorRef,
    logger: Logger,
    appStore: AppStore,
    dialogStore: DialogStore,
    confirmationStore: ConfirmationStore,
    messageStore: MessageStore,
    readonly authenticationStore: AuthenticationStore,
    readonly localisationStore: LocalisationStore,
    readonly domainStore: DomainStore,
    private readonly ownerService: OwnerService,
    private readonly userService: UserService,
    private readonly versionDetectorStore: VersionDetectorStore,
    private readonly title: Title,
    private readonly router: Router) {

    super(
      renderer,
      zone,
      cdr,
      logger,
      appStore,
      dialogStore,
      confirmationStore,
      messageStore,
      authenticationStore);
  }

  ngOnInit() {

    super.ngOnInit();

    if (environment.production) {

      this.subscribe(this.activityEvents$.pipe(
        debounceTime(15 * 60 * 1000), // 15 minutes inactivity
        tap(e => {

          this.logger.warn('Logging out after inactivity period ...');

          this.authenticationStore.logout();
        })
      ));
    }

    this.subscribe(this.contactDetailsStatus$.pipe(
      switchMapCatch(() => this.ownerService.getContactDetailsStatus().pipe(
        tap(ownerContactDetailsStatus => {

          if (!ownerContactDetailsStatus.emailAndMobileVerified || !ownerContactDetailsStatus.passwordMeetsMinimumRequirements) {

            this.ownerEmail = ownerContactDetailsStatus.email;
            this.ownerMobile = ownerContactDetailsStatus.mobile;

            this.showContactDetailsDialog();
          }
        })
      ))
    ));

    this.subscribe(this.contactDetailsUpdate$.pipe(
      switchMapCatch(args => {

        if (this.areExistingContactDetailsEmpty || !this.haveContactDetailsChangedFromExisting(args.contactDetails)) {

          return this.updateContactDetails({
            contactDetails: args.contactDetails,
            // tslint:disable-next-line: no-non-null-assertion
            userIds: [this.authenticationStore.userId!]
          });
        }

        return this.ownerService.findOwners({
          email: this.contactDetailsToUpdate === ContactDetailsToUpdate.Email ? this.ownerEmail : undefined,
          mobile: this.contactDetailsToUpdate === ContactDetailsToUpdate.Mobile ? this.ownerMobile : undefined,
        }).pipe(
          switchMap(owners => {

            if (owners.length > 1) {

              this.showOwnerSelectorDialog({
                newContactDetails: args.contactDetails,
                owners
              });

              return EMPTY;

            } else {

              return this.updateContactDetails({
                contactDetails: args.contactDetails,
                // tslint:disable-next-line: no-non-null-assertion
                userIds: [this.authenticationStore.userId!]
              });
            }
          })
        );

      })
    ));

    this.subscribe(this.ownersSelected$.pipe(
      switchMapCatch(selectedOwners => {

        return this.updateContactDetails({
          contactDetails: this.getNewContactDetails(),
          userIds: selectedOwners.map(owners => owners.id),
          showContactDetailsDialog: true
        });
      })
    ));

    this.subscribe(this.termsAndConditionsStatus$.pipe(
      switchMapCatch(() => {

        return this.userService.getTermsAndConditionsStatus().pipe(
          tap(status => {

            if (status.accepted) {

              return;
            }

            TermsAndConditionsComponent.showInDialog(this.dialogStore, this.termsAndConditionsTpl);
          })
        );
      })
    ));

    this.mobxReaction('AppComponent: versionDetectorStore.updateAvailable',
      () => this.versionDetectorStore.updateAvailable,
      updateAvailable => {

        if (updateAvailable) {

          this.logger.warn('UI update available, logging out ...');

          this.authenticationStore.logout();
        }
      });

    this.mobxReaction('AppComponent: authenticationStore.loginType, authenticationStore.isAuthenticated',
      () => this.authenticationStore.loginType,
      loginType => this.title.setTitle(`Whittles: ${loginType.toLowerCase().toTitleCase()} Portal`));

    this.mobxWhen('AppComponent: Check once after authenticated',
      () => this.authenticationStore.isAuthenticated && !this.authenticationStore.isImpersonationLogin,
      () => {

        switch (this.authenticationStore.loginType) {

          case LoginType.BUILDING:

            if (!isProductionSubdomain) {

              this.termsAndConditionsStatus$.next();
            }

            break;

          case LoginType.OWNER:

            this.contactDetailsStatus$.next();

            this.maybeDisplayLoginMessage();

            break;
        }
      });
  }

  private maybeDisplayLoginMessage() {

    if (!this.domainStore.getLoginMessageDisplayed()) {

      this.loginMessageVisible = true;

      this.domainStore.setLoginMessageDisplayed(true);
    }
  }

  private showOwnerSelectorDialog(args: {
    newContactDetails: string;
    owners: User[];
  }) {

    switch (this.contactDetailsToUpdate) {

      case ContactDetailsToUpdate.Email:

        this.ownerSelectorNewEmail = args.newContactDetails;
        this.ownerSelectorNewMobile = undefined;

        break;

      case ContactDetailsToUpdate.Mobile:

        this.ownerSelectorNewMobile = args.newContactDetails;
        this.ownerSelectorNewEmail = undefined;

        break;

      default:

        throw new Error('showOwnerSelectorDialog - Unkown update method: ' + this.contactDetailsToUpdate);
    }

    this.ownerSelectorOwners = args.owners;

    OwnerSelectorComponent.showInDialog(this.dialogStore, this.selectOwnerTpl);
  }

  private get areExistingContactDetailsEmpty(): boolean {

    switch (this.contactDetailsToUpdate) {

      case ContactDetailsToUpdate.Email:

        return this.isExistingOwnerEmailEmpty;

      case ContactDetailsToUpdate.Mobile:

        return this.isExistingOwnerMobileEmpty;

      default:

        throw new Error('areExistingContactDetailsEmpty - Unkown update method: ' + this.contactDetailsToUpdate);
    }
  }

  private haveContactDetailsChangedFromExisting(contactDetails: string): boolean {

    switch (this.contactDetailsToUpdate) {

      case ContactDetailsToUpdate.Email:

        return this.isExistingOwnerEmailEmpty || this.ownerEmail !== contactDetails;

      case ContactDetailsToUpdate.Mobile:

        return this.isExistingOwnerMobileEmpty || !arePhoneNumbersEqual(this.ownerMobile || '', contactDetails);

      default:

        throw new Error('haveContactDetailsChangedFromExisting - Unkown update method: ' + this.contactDetailsToUpdate);
    }
  }

  private getNewContactDetails(): string {

    switch (this.contactDetailsToUpdate) {

      case ContactDetailsToUpdate.Email:

        // tslint:disable-next-line: no-non-null-assertion
        return this.ownerSelectorNewEmail!;

      case ContactDetailsToUpdate.Mobile:

        // tslint:disable-next-line: no-non-null-assertion
        return this.ownerSelectorNewMobile!;

      default:

        throw new Error('getNewContactDetails - Unkown update method: ' + this.contactDetailsToUpdate);
    }
  }

  private getContactDetailsUpdateMethod(args: {
    contactDetails: string;
    userIds: string[]
  }): Observable<any> {

    switch (this.contactDetailsToUpdate) {

      case ContactDetailsToUpdate.Email:

        return this.ownerService.updateEmail({
          email: args.contactDetails,
          userIds: args.userIds
        });

      case ContactDetailsToUpdate.Mobile:

        return this.ownerService.updateMobile({
          mobile: args.contactDetails,
          userIds: args.userIds
        });

      default:

        throw new Error('getContactDetailsUpdateMethod - Unkown update method: ' + this.contactDetailsToUpdate);
    }
  }

  private isLoggedInUserContactDetailsUpdated(updatedUserIds: string[]): boolean {

    return !!this.authenticationStore.userId && updatedUserIds.includes(this.authenticationStore.userId);
  }

  private updateContactDetails(args: {
    contactDetails: string;
    userIds: string[],
    showContactDetailsDialog?: boolean;
  }) {

    return this.getContactDetailsUpdateMethod(args).pipe(
      tap(() => {

        switch (this.contactDetailsToUpdate) {

          case ContactDetailsToUpdate.Email:

            this.ownerEmail = args.contactDetails;

            this.ownerEmailUpdated = true;

            if (this.isLoggedInUserContactDetailsUpdated(args.userIds)) {

              this.authenticationStore.setEmail(args.contactDetails);
            }

            break;

          case ContactDetailsToUpdate.Mobile:

            this.ownerMobile = args.contactDetails;

            this.ownerMobileUpdated = true;

            if (this.isLoggedInUserContactDetailsUpdated(args.userIds)) {

              this.authenticationStore.setMobile(args.contactDetails);
            }

            break;

          default:

            throw new Error('updateContactDetails - Unkown update method: ' + this.contactDetailsToUpdate);
        }

        if (args.showContactDetailsDialog) {

          this.showContactDetailsDialog();
        }
      })
    );
  }

  onSelectUnit(evt: Event) {

    evt.preventDefault();

    if (BrowserUtils.Tablet || BrowserUtils.Mobile) {

      this.router.navigate(['/', this.authenticationStore.loginType === LoginType.BUILDING ?
        RoutePath.SelectBuilding : RoutePath.SelectUnit]);

    } else {

      UnitSelectorComponent.showInDialog(this.dialogStore);
    }
  }

  onContactDetailsUpdateEmailClick(args: ContactDetailsUpdateEmailParams) {

    this.contactDetailsToUpdate = ContactDetailsToUpdate.Email;

    this.ownerEmailUpdated = false;

    this.contactDetailsUpdate$.next({
      contactDetails: args.email
    });
  }

  onContactDetailsUpdateMobileClick(args: ContactDetailsUpdateMobileParams) {

    this.contactDetailsToUpdate = ContactDetailsToUpdate.Mobile;

    this.ownerMobileUpdated = false;

    this.contactDetailsUpdate$.next({
      contactDetails: args.mobile
    });
  }

  onSelectedOwners(selectedOwners: User[]) {

    if (selectedOwners.length > 0) {

      this.ownersSelected$.next(selectedOwners);

    } else {

      this.ownerSelectorNewEmail = undefined;
      this.ownerSelectorNewMobile = undefined;

      this.showContactDetailsDialog();
    }
  }

  private showContactDetailsDialog() {

    ContactDetailsComponent.showInDialog(this.dialogStore, this.contactDetailsTpl);
  }

  private get isExistingOwnerEmailEmpty(): boolean {

    return !this.ownerEmail || !this.ownerEmail.trim();
  }

  private get isExistingOwnerMobileEmpty(): boolean {

    return !this.ownerMobile || !this.ownerMobile.trim();
  }
}
