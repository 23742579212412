import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from '../base/base.component';

import { BaseAppStore } from '../../store/base-app.store';

@Component({
  selector: 'app-empty',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyComponent extends BaseComponent implements OnInit, OnDestroy {

  constructor(
    private readonly router: Router,
    private readonly appStore: BaseAppStore) {

    super();
  }

  ngOnInit(): void {

    this.appStore.showActivityIndicator({ flag: true });

    this.router.navigateByUrl(this.appStore.refreshUrl, {
      replaceUrl: true
    });
  }

  ngOnDestroy() {

    this.appStore.showActivityIndicator({ flag: false });
  }
}
