import { OnDestroy, Directive } from '@angular/core';
import { autorun, IReactionDisposer, IReactionOptions, IReactionPublic, IWhenOptions, Lambda, reaction, when } from 'mobx';
import { Observable, PartialObserver, Subscription } from 'rxjs';

import { handleError } from '../../utils/error.utils';
import { IAutorunOptions } from '../../utils/mobx.utils';

@Directive()
export abstract class BaseComponent implements OnDestroy {

  private readonly subscriptions: Subscription[] = [];

  private readonly reactionDisposers: IReactionDisposer[] = [];

  constructor() {

    const _ngOnDestroy = this.ngOnDestroy.bind(this);

    this.ngOnDestroy = () => {

      // This will call the superclass ngOnDestroy
      _ngOnDestroy();

      this.cleanupSubscriptions();

      this.cleanupMobxReactions();
    };
  }

  // Placeholder of ngOnDestroy - no need to do super() call on extended class.
  ngOnDestroy() {
  }

  protected mobxAutorun(
    name: string,
    view: (r: IReactionPublic) => any,
    opts?: IAutorunOptions) {

    // create a new MobX autorun
    const reactionDisposer = autorun(view, {
      name,
      onError: handleError,
      ...opts
    });

    // add the autorun disposer function to the list
    this.reactionDisposers.push(reactionDisposer);
  }

  protected mobxReaction<T>(
    name: string,
    expression: (r: IReactionPublic) => T,
    effect: (arg: T, r: IReactionPublic) => void,
    opts?: IReactionOptions | boolean) {

    let defaultOpts: IReactionOptions = {
      name,
      fireImmediately: true,
      onError: handleError
    };

    if (opts != null) {

      if (typeof opts === 'boolean') {

        defaultOpts.fireImmediately = opts;

      } else {

        defaultOpts = {
          ...defaultOpts,
          ...opts,
        };
      }
    }

    // create a new MobX reaction
    const reactionDisposer = reaction(expression, effect, defaultOpts);

    // add the reaction disposer function to the list
    this.reactionDisposers.push(reactionDisposer);
  }

  protected mobxWhen(
    name: string,
    predicate: () => boolean,
    effect: Lambda,
    opts?: IWhenOptions) {

    // create a new MobX when
    const reactionDisposer = when(predicate, effect, {
      name,
      onError: handleError,
      ...opts
    });

    // add the when disposer function to the list
    this.reactionDisposers.push(reactionDisposer);
  }

  protected subscribe<T>(o: Observable<T>, observer?: PartialObserver<T>): void;

  protected subscribe<T>(o: Observable<T>, next?: (value: T) => void, error?: (error: any) => void, complete?: () => void): void;

  protected subscribe<T>(o: Observable<T>, ...params: any[]) {

    this.subscriptions.push(o.subscribe(...params));
  }

  protected cleanupSubscriptions() {

    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  protected cleanupMobxReactions() {

    this.reactionDisposers.forEach(reactionDisposer => reactionDisposer());
  }
}
