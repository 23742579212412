import { AnimationEvent } from '@angular/animations';
import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { fadeInOutTrigger } from '../../utils/animation.utils';
import { BaseComponent } from '../base/base.component';

export enum SpinnerSize {
  Default = 100,
  Small = 30
}

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOutTrigger]
})
export class SpinnerComponent extends BaseComponent implements OnInit {

  @Input() size = SpinnerSize.Small;
  @Input() show = false;
  @Input() containerStyle: NgStyle['ngStyle'];
  @Input() containerClass: NgClass['ngClass'];
  @Input() absoluteFill = true;
  @Input() alphaBackground = true;

  @Output() readonly animationBegin = new EventEmitter();
  @Output() readonly animationEnd = new EventEmitter();

  ngOnInit() {
  }

  get style() {

    return {
      width: `${this.size}px`,
      height: `${this.size}px`
    };
  }

  onFadeInOutStart(evt: AnimationEvent) {

    if (evt.fromState === 'void' && evt.phaseName === 'start' && evt.toState == null) {

      this.animationBegin.next();
    }
  }

  onFadeInOutDone(evt: AnimationEvent) {

    if (evt.fromState == null && evt.phaseName === 'done' && evt.toState === 'void') {

      this.animationEnd.next();
    }
  }
}
