import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[appRouteContainer]'
})
export class RouteContainerDirective {

  @HostBinding('class.p-grid') readonly uiGrid = true;

  @HostBinding('class.p-fluid') readonly uiFluid = true;
}
