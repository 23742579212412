import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BuildingSummaryComponent } from '../components/building-summary/building-summary.component';
import { ButtonContainerComponent } from '../components/button-container/button-container.component';
import { CardHeaderComponent } from '../components/card-header/card-header.component';
import { CardComponent } from '../components/card/card.component';
import { CovidComponent } from '../components/covid/covid-component';
import { CommitteeMembersComponent } from '../components/committee-members/committee-members.component';
import { ContentContainerComponent } from '../components/content-container/content-container.component';
import { CurrencyInputComponent } from '../components/currency-input/currency-input.component';
import { DocTreeComponent } from '../components/doc-tree/doc-tree.component';
import { DropdownFilterComponent } from '../components/dropdown-filter/dropdown-filter.component';
import { DropdownManageableFilter } from '../components/dropdown-manageable-filter/dropdown-manageable-filter.component';
import { EmptyComponent } from '../components/empty/empty.component';
import { FinancialInformationComponent } from '../components/financial-information/financial-information.component';
import { FormErrorComponent } from '../components/form-error/form-error.component';
import { GlobalFilterComponent } from '../components/global-filter/global-filter.component';
import { GoogleMapComponent } from '../components/goggle-map/google-map.component';
import { HelpIconComponent } from '../components/help-icon/help-icon.component';
import { HelpSelectorComponent } from '../components/help-selector/help-selector.component';
import { IconButtonComponent } from '../components/icon-button/icon-button.component';
import { InfoBoxComponent } from '../components/info-box/info-box.component';
import { InfoMessageComponent } from '../components/info-message/info-message.component';
import { InputFilterComponent } from '../components/input-filter/input-filter.component';
import { HeaderInputFilterComponent } from '../components/header-input-filter/header-input-filter.component';
import { JSReportToolbarComponent } from '../components/jsreport/jsreport-toolbar.component';
import { JSReportTypeSelectorComponent } from '../components/jsreport/jsreport-type-selector.component';
import { JSReportComponent } from '../components/jsreport/jsreport.component';
import { MoreIndicatorComponent } from '../components/more-indicator/more-indicator.component';
import { OwnerAccountHistoryComponent } from '../components/owner-account-history/owner-account-history.component';
import { OwnerBalanceComponent } from '../components/owner-balance/owner-balance.component';
import { OwnerBrowserComponent } from '../components/owner-browser/owner-browser.component';
import { OwnerProfileComponent } from '../components/owner-profile/owner-profile.component';
import { OwnerUnitDetailsComponent } from '../components/owner-unit-details/owner-unit-details.component';
import { PasswordStrengthComponent } from '../components/password-strength/password-strength.component';
import { PasswordUpdateComponent } from '../components/password-update/password-update.component';
import { PdfComponent } from '../components/pdf-viewer/pdf-viewer.component';
import { PlanDetailsComponent } from '../components/plan-details/plan-details.component';
import { ProcessNotesIndicatorComponent } from '../components/process-notes-indicator/process-notes-indicator.component';
import { ProcessNotesComponent } from '../components/process-notes/process-notes.component';
import { ReconciliationBrowserComponent } from '../components/reconciliation-browser/reconciliation-browser.component';
import { RouteContainerComponent } from '../components/route-container/route-container.component';
import { SpinnerComponent } from '../components/spinner/spinner.component';
import { StrataAccountsSummaryComponent } from '../components/strata-accounts-summary/strata-accounts-summary.component';
import { TableDataComponent } from '../components/table-data/table-data.component';
import { TableEmptyMessageComponent } from '../components/table-empty-message/table-empty-message.component';
import { TableHeaderComponent } from '../components/table-header/table-header.component';
import { TableRowComponent } from '../components/table-row/table-row.component';
import { TableComponent } from '../components/table/table.component';
import { TaskDoneButtonComponent } from '../components/task-done-button/task-done-button.component';
import { TenantBrowserComponent } from '../components/tenant-browser/tenant-browser.component';
import { TextBlockComponent } from '../components/text-block/text-block.component';
import { TimelineComponent } from '../components/timeline/timeline.component';
import { TreeTableComponent } from '../components/tree-table/tree-table.component';
import { WebTwainComponent } from '../components/web-twain/web-twain.component';
import { WhiteFolderBrowserComponent } from '../components/white-folder-browser/white-folder-browser.component';
import { AnchorDirective } from '../directives/anchor.directive';
import { ButtonDirective } from '../directives/button.directive';
import { CalendarDirective } from '../directives/calendar.directive';
import { CheckboxDirective } from '../directives/checkbox.directive';
import { CurrencyFilterDirective } from '../directives/currency-filter.directive';
import { DataTableDirective } from '../directives/data-table.directive';
import { DataViewDirective } from '../directives/data-view.directive';
import { DialogFooterDirective } from '../directives/dialog-footer.directive';
import { DialogDirective } from '../directives/dialog.directive';
import { DropdownDirective } from '../directives/dropdown.directive';
import { GlobalSpinnerDirective } from '../directives/global-spinner.directive';
import { GlobalToastDirective } from '../directives/global-toast.directive';
import { HideDirective } from '../directives/hide.directive';
import { InputMaskDirective } from '../directives/input-mask.directive';
import { KeyFilterDirective } from '../directives/key-filter.directive';
import { MobxAutorunDirective } from '../directives/mobx.directive';
import { OutletHeightDirective } from '../directives/outlet-height.directive';
import { OverlayPanelDirective } from '../directives/overlay-panel.directive';
import { RadioButtonDirective } from '../directives/radio-button.directive';
import { RouteContainerDirective } from '../directives/route-container.directive';
import { SpinnerContainerDirective } from '../directives/spinner-container.directive';
import { TabPanelDirective } from '../directives/tab-panel.directive';
import { TabViewDirective } from '../directives/tab-view.directive';
import { TableCheckboxDirective } from '../directives/table-checkbox.directive';
import { TableDirective } from '../directives/table.directive';
import { TreeTableDirective } from '../directives/tree-table.directive';
import { CreditDebitPipe } from '../pipes/credit-debit.pipe';
import { CurrencyFormatPipe } from '../pipes/currency-format.pipe';
import { DateFormatPipe } from '../pipes/date-format.pipe';
import { FileSizePipe } from '../pipes/file-size.pipe';
import { PhoneFormatPipe } from '../pipes/phone-format.pipe';
import { SafePipe } from '../pipes/safe.pipe';
import { MblReferencePipe } from '../pipes/mbl-reference.pipe';
import { ErrorMessage } from '../components/error-message/error-message.component';
import { ErrorAlertComponent } from '../components/error-alert/error-alert.component';

import { TitleCasePipe } from '../pipes/title-case.pipe';
import { PrettyTextPipe} from '../pipes/pretty-text.pipe';
import { PrimeNGSharedModule } from './primeng-shared.module';
import { OwnerNotificationComponent } from "../components/owner-notification/owner-notification.component";
import { EoyPipe } from "../pipes/eoy.pipe";
import { ElementObserveDirective } from '../directives/element-observe.directive';
import {IndexedTabPanelDirective} from "../directives/IndexedTabPanelDirective";


@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        PrimeNGSharedModule,
    ],
    declarations: [
        SafePipe,
        PhoneFormatPipe,
        CurrencyFormatPipe,
        TitleCasePipe,
        PrettyTextPipe,
        CreditDebitPipe,
        DateFormatPipe,
        FileSizePipe,
        EoyPipe,
        MblReferencePipe,
        OutletHeightDirective,
        DataTableDirective,
        TabViewDirective,
        AnchorDirective,
        OverlayPanelDirective,
        FormErrorComponent,
        SpinnerComponent,
        SpinnerContainerDirective,
        RouteContainerDirective,
        KeyFilterDirective,
        CurrencyFilterDirective,
        JSReportComponent,
        JSReportToolbarComponent,
        JSReportTypeSelectorComponent,
        TextBlockComponent,
        PdfComponent,
        InfoMessageComponent,
        CardHeaderComponent,
        PasswordUpdateComponent,
        MoreIndicatorComponent,
        CurrencyInputComponent,
        WebTwainComponent,
        ButtonContainerComponent,
        EmptyComponent,
        DropdownDirective,
        PlanDetailsComponent,
        WhiteFolderBrowserComponent,
        DocTreeComponent,
        CardComponent,
        CovidComponent,
        OwnerNotificationComponent,
        RouteContainerComponent,
        GoogleMapComponent,
        IconButtonComponent,
        HelpIconComponent,
        HelpSelectorComponent,
        ReconciliationBrowserComponent,
        ButtonDirective,
        ContentContainerComponent,
        GlobalSpinnerDirective,
        GlobalToastDirective,
        DataViewDirective,
        TableDirective,
        InputFilterComponent,
        GlobalFilterComponent,
        DropdownFilterComponent,
        DropdownManageableFilter,
        TableEmptyMessageComponent,
        TableHeaderComponent,
        TableRowComponent,
        TableDataComponent,
        TableComponent,
        InputMaskDirective,
        DialogFooterDirective,
        TabPanelDirective,
        RadioButtonDirective,
        StrataAccountsSummaryComponent,
        PasswordStrengthComponent,
        OwnerBrowserComponent,
        TenantBrowserComponent,
        CalendarDirective,
        TimelineComponent,
        BuildingSummaryComponent,
        FinancialInformationComponent,
        CommitteeMembersComponent,
        InfoBoxComponent,
        TaskDoneButtonComponent,
        MobxAutorunDirective,
        HideDirective,
        IndexedTabPanelDirective,
        OwnerProfileComponent,
        OwnerAccountHistoryComponent,
        OwnerBalanceComponent,
        TreeTableDirective,
        DialogDirective,
        TreeTableComponent,
        ProcessNotesComponent,
        ProcessNotesIndicatorComponent,
        OwnerUnitDetailsComponent,
        CheckboxDirective,
        TableCheckboxDirective,
        ElementObserveDirective,
        HeaderInputFilterComponent,
        ErrorMessage,
        ErrorAlertComponent
    ],
    exports: [
        CommonModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        PrimeNGSharedModule,
        SafePipe,
        PhoneFormatPipe,
        TitleCasePipe,
        PrettyTextPipe,
        CurrencyFormatPipe,
        CreditDebitPipe,
        DateFormatPipe,
        FileSizePipe,
        EoyPipe,
        MblReferencePipe,
        OutletHeightDirective,
        DataTableDirective,
        TabViewDirective,
        AnchorDirective,
        OverlayPanelDirective,
        KeyFilterDirective,
        CurrencyFilterDirective,
        FormErrorComponent,
        SpinnerComponent,
        SpinnerContainerDirective,
        RouteContainerDirective,
        JSReportComponent,
        JSReportToolbarComponent,
        JSReportTypeSelectorComponent,
        TextBlockComponent,
        PdfComponent,
        InfoMessageComponent,
        CardHeaderComponent,
        PasswordUpdateComponent,
        MoreIndicatorComponent,
        CurrencyInputComponent,
        WebTwainComponent,
        ButtonContainerComponent,
        EmptyComponent,
        DropdownDirective,
        PlanDetailsComponent,
        WhiteFolderBrowserComponent,
        DocTreeComponent,
        CardComponent,
        CovidComponent,
        OwnerNotificationComponent,
        RouteContainerComponent,
        GoogleMapComponent,
        IconButtonComponent,
        HelpIconComponent,
        HelpSelectorComponent,
        ReconciliationBrowserComponent,
        ButtonDirective,
        ContentContainerComponent,
        GlobalSpinnerDirective,
        GlobalToastDirective,
        DataViewDirective,
        TableDirective,
        InputFilterComponent,
        GlobalFilterComponent,
        DropdownFilterComponent,
        DropdownManageableFilter,
        TableEmptyMessageComponent,
        TableHeaderComponent,
        TableRowComponent,
        TableDataComponent,
        TableComponent,
        InputMaskDirective,
        DialogFooterDirective,
        TabPanelDirective,
        RadioButtonDirective,
        StrataAccountsSummaryComponent,
        PasswordStrengthComponent,
        OwnerBrowserComponent,
        TenantBrowserComponent,
        CalendarDirective,
        TimelineComponent,
        BuildingSummaryComponent,
        FinancialInformationComponent,
        CommitteeMembersComponent,
        InfoBoxComponent,
        TaskDoneButtonComponent,
        MobxAutorunDirective,
        HideDirective,
        IndexedTabPanelDirective,
        OwnerProfileComponent,
        OwnerAccountHistoryComponent,
        TreeTableDirective,
        DialogDirective,
        TreeTableComponent,
        ProcessNotesComponent,
        ProcessNotesIndicatorComponent,
        CheckboxDirective,
        TableCheckboxDirective,
        OwnerNotificationComponent,
        ElementObserveDirective,
        HeaderInputFilterComponent,
        ErrorMessage,
        ErrorAlertComponent
    ],
    providers: []
})
export class SharedCommonModule {
}
