import { DatePipe } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';

import { DateTimeFormat } from '../models/date-time-format';

@Pipe({ name: 'dateFormat' })
export class DateFormatPipe extends DatePipe implements PipeTransform {

  // UTC offset
  private static readonly UTC_PLUS_8 = '+0800'; // AWST
  private static readonly UTC_PLUS_9_5 = '+0930'; // ACST
  private static readonly UTC_PLUS_10_5 = '+1030'; // ACST
  private static readonly UTC_PLUS_10 = '+1000'; // AEST
  private static readonly UTC_PLUS_11 = '+1100'; // AEST

  private static readonly SHORT_DATE_FORMAT = 'd/M/yy';
  private static readonly SHORT_TIME_FORMAT = 'h:mm a';
  private static readonly SHORT_DATE_TIME_FORMAT = `${DateFormatPipe.SHORT_DATE_FORMAT} ${DateFormatPipe.SHORT_TIME_FORMAT}`;
  private static readonly LONG_DATE_FORMAT = 'EEEE, MMMM d, y';
  private static readonly LONG_DATE_TIME_FORMAT = `${DateFormatPipe.LONG_DATE_FORMAT} 'at' ${DateFormatPipe.SHORT_TIME_FORMAT}`;


  transform(value: any, format?: string, timezone?: string, locale?: string): any {

    const timezoneOffset = timezone || this.getTimezoneOffset(value);

    const formattedValue = super.transform(value, this.getFormat(format), timezoneOffset, locale) || '';

    if (format === DateTimeFormat.ShortDateTime || format === DateTimeFormat.LongDateTime) {

      return `${formattedValue} ${this.getTimezoneAbbreviation(value)}`.trim();

    } else {

      return formattedValue;
    }
  }

  private getFormat(format: string | undefined): string {

    if (!format) {

      return DateFormatPipe.SHORT_DATE_FORMAT;
    }

    switch (format) {

      case DateTimeFormat.ShortDate:

        return DateFormatPipe.SHORT_DATE_FORMAT;

      case DateTimeFormat.ShortDateTime:

        return DateFormatPipe.SHORT_DATE_TIME_FORMAT;

      case DateTimeFormat.LongDate:

        return DateFormatPipe.LONG_DATE_FORMAT;

      case DateTimeFormat.LongDateTime:

        return DateFormatPipe.LONG_DATE_TIME_FORMAT;

      default:

        return format;
    }
  }

  // Try to extract the timezone offset e.g (+1030)
  private getTimezoneOffset(date: Date | string | number | undefined): string | undefined {

    if (!date) {

      return undefined;
    }

    const matches = new Date(date).toString().match(/([-\+][0-9]+)\s/);

    if (!matches) {

      return undefined;
    }

    return matches[1];
  }

  // Try to extract the timezone abbreviation e.g (ACST, ACDT)
  private getTimezoneAbbreviation(date: Date | string | number | undefined): string {

    switch (this.getTimezoneOffset(date)) {

      case DateFormatPipe.UTC_PLUS_8:

        return 'AWST';

      case DateFormatPipe.UTC_PLUS_9_5:
      case DateFormatPipe.UTC_PLUS_10_5:

        return 'ACST';

      case DateFormatPipe.UTC_PLUS_10:
      case DateFormatPipe.UTC_PLUS_11:

        return 'AEST';

      default:

        return '';
    }
  }
}
