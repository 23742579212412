export enum PlanType {
  CTS = 'CTS', // Queensland (Nambour) - stands for 'Community Title Scheme'
  UnitsPlan = 'UNITS_PLAN', // Darwin
  StrataPlan = 'STRATA_PLAN', // Adelaide and NSW.
  CommunityCorporation = 'COMMUNITY_CORPORATION', // Adelaide
  CompanyTitle = 'COMPANY_TITLE',
  Company = 'COMPANY', // NSW.
  NeighbourhoodAssociation = 'NEIGHBOURHOOD_ASSOCIATION', // NSW.
  OwnersCorporation_act = 'OWNERS_CORPORATION_ACT', // NSW.
  BCSP = 'BCSP' // NSW.
}
