import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { FontAwesomeStyle } from '../../models/font-awsome';
import { HelpTag } from '../../models/help-tag';
import { HelpVideo } from '../../models/help-video';
import { BaseHelpStore } from '../../store/base-help.store';
import { DialogStore } from '../../store/dialog.store';
import { property } from '../../utils/object.utils';
import { BaseComponent } from '../base/base.component';
import { HelpSelectorContext } from '../help-selector/help-selector.context';
import { IconKind } from '../icon-button/icon-button.component';
import { TableHeaderFilterType } from '../table-header/table-header.component';

@Component({
  selector: 'app-help-selector',
  templateUrl: './help-selector.component.html',
  styleUrls: ['./help-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpSelectorComponent extends BaseComponent implements OnInit, AfterViewInit {

  private _videoUrl = '';

  @ViewChild('videoTpl') videoTpl: TemplateRef<any>;

  readonly helpVideoPropertyTitle = property<HelpVideo>('title');
  readonly helpVideoPropertyTags = property<HelpVideo>('tags');

  readonly IconKind = IconKind;
  readonly FontAwesomeStyle = FontAwesomeStyle;
  readonly TableHeaderFilterType = TableHeaderFilterType;

  videos: HelpVideo[] = [];

  constructor(
    private readonly helpStore: BaseHelpStore,
    private readonly dialogStore: DialogStore) {

    super();
  }

  static showInDialog(dialogStore: DialogStore, context: HelpSelectorContext) {

    dialogStore.showComponent(HelpSelectorComponent, {
      context,
      dismissableMask: true,
      width: 'auto'
    });
  }

  ngOnInit(): void {

    if (this.showHelpTable) {

      this.setVideos();
    }
  }

  ngAfterViewInit(): void {

    this.maybeAutoShowVideo();
  }

  private maybeAutoShowVideo() {

    if (!this.helpSelectorContext.params.autoShow) {

      return;
    }

    const helpTag = this.helpSelectorContext.params.tags as HelpTag;

    const helpVideoToAutoShow = this.helpStore.videos.find(video => {

      return video.tags.includes(helpTag);
    });

    if (!helpVideoToAutoShow) {

      return;
    }

    this.showVideoDialog(helpVideoToAutoShow, this.videoTpl);
  }

  private showVideoDialog(helpVideo: HelpVideo, templateRef: TemplateRef<any>) {

    this._videoUrl = helpVideo.url;

    this.dialogStore.showTemplate(templateRef, {
      dismissableMask: true,
      style: {
        width: '80%',
        height: '80%'
      },
      styleClass: 'wht-dialog-content-fill'
    });
  }

  onRowSelected(helpVideo: HelpVideo, templateRef: TemplateRef<any>) {

    this.showVideoDialog(helpVideo, templateRef);
  }

  onRowUnselected(helpVideo: HelpVideo, templateRef: TemplateRef<any>) {

    this.showVideoDialog(helpVideo, templateRef);
  }

  onCloseIconClick() {

    this.dialogStore.hide();
  }

  getTitle(helpVideo: HelpVideo) {

    return helpVideo.title;
  }

  getTags(helpVideo: HelpVideo) {

    return helpVideo.tags.join(', ');
  }

  private setVideos() {

    if (this.helpSelectorContext.params.showAll != null && this.helpSelectorContext.params.showAll) {

      this.videos = this.helpStore.videos;

    } else {

      const inputTags: string[] = [];

      if (typeof this.helpSelectorContext.params.tags === 'string') {

        inputTags.push(this.helpSelectorContext.params.tags);

      } else {

        inputTags.push(...this.helpSelectorContext.params.tags);
      }

      // find all the items in the helpService.videos array that match the "tags"
      this.videos = this.helpStore.videos.filter(video => {

        const anyInputTagsMatchesVideoTags =
          inputTags.some(inputTag => video.tags.some(videoTag => videoTag.toLowerCase() === inputTag.toLowerCase()));

        return anyInputTagsMatchesVideoTags;
      });
    }

    this.videos.sort((a, b) => a.title.localeCompare(b.title));
  }

  get videoUrl() {

    return this._videoUrl;
  }

  private get helpSelectorContext() {

    return this.dialogStore.context as HelpSelectorContext;
  }

  get showHelpTable(): boolean {

    return !this.helpSelectorContext.params.autoShow;
  }
}
