import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseAppProperties } from '../base-app-properties';
import {
  CompanyService as CompanyServiceGenerated,
  Configuration
} from '../generated';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private readonly companyServiceGenerated: CompanyServiceGenerated;

  constructor(httpClient: HttpClient, appProperties: BaseAppProperties) {

    this.companyServiceGenerated = new CompanyServiceGenerated(httpClient, appProperties.irisServerBasePath, new Configuration());
  }

  getBranches() {

    return this.companyServiceGenerated.getBranches();
  }

  getCompanyStrataParameters(companyId: number) {

    return this.companyServiceGenerated.getStrataParameterByCompanyId(companyId);
  }
}
