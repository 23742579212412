import { Component } from "@angular/core";
import { tap } from "rxjs/operators";

// note if you import common-lib -> you will get circular dependencies.
import { MediaType } from '../../models/media-type';
import { FileService } from '../../services/file.service';
import { OwnerService } from '../../services/owner.service';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-owner-notification',
  templateUrl: './owner-notification.component.html',
  styleUrls: ['./owner-notification.component.scss']
})
export class OwnerNotificationComponent extends BaseComponent {

  message = 'Under Agreed Services section 5 Fees item 5.2.5 ' +
    'the standard fees have been reviewed and ' +
    'some services have increased. ' +
    'Further details will be provided at your upcoming AGM or a ' +
    'full fee schedule is available upon ' +
    'request form your manager.';

  constructor(
    private ownerService: OwnerService,
    private fileService: FileService
  ) {
    super();
  }

  onClick() {
    return this.ownerService.getCovidFile().pipe(
      tap(fileData => this.fileService.save({
        fileData,
        contentType: MediaType.Pdf
      }))).subscribe();
  }
}
