import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { BaseAppProperties } from '../base-app-properties';
import { Configuration, UserService as UserServiceGenerated } from '../generated';
import { appendNonUniqueUsersLoginNameToFullName } from '../utils/type-mapping.utils';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly userServiceGenerated: UserServiceGenerated;

  constructor(httpClient: HttpClient, appProperties: BaseAppProperties) {

    this.userServiceGenerated = new UserServiceGenerated(httpClient, appProperties.irisServerBasePath, new Configuration());
  }

  getCompanies() {

    return this.userServiceGenerated.getCompanies();
  }

  getBranches() {

    return this.userServiceGenerated.getBranches();
  }

  getManagers(params: {
    companyId: string;
  }) {

    return this.userServiceGenerated.getManagers(params.companyId);
  }

  updateSelectedPlan(params: {
    strataId: string;
    showActivityIndicator?: boolean;
  }) {

    const showActivityIndicator = params.showActivityIndicator == null || params.showActivityIndicator;

    return this.userServiceGenerated.updateSelectedPlan(params.strataId, showActivityIndicator);
  }

  getStaff(params: {
    companyId: string;
  }) {

    return this.userServiceGenerated.getStaff(params.companyId).pipe(
      map(staff => appendNonUniqueUsersLoginNameToFullName(staff))
    );
  }

  getUsersInGroup(params: {
    group: string
  }) {

    return this.userServiceGenerated.getUsersInGroup(params.group);
  }

  getTermsAndConditionsStatus(params?: {
    showActivityIndicator?: boolean
  }) {

    const showActivityIndicator = !!params && !!params.showActivityIndicator;

    return this.userServiceGenerated.getTermsAndConditionsStatus(showActivityIndicator);
  }

  acceptTermsAndConditions() {

    return this.userServiceGenerated.acceptTermsAndConditions();
  }
}
