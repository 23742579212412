import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { isObservableArray } from 'mobx';
import { SortEvent, SortMeta } from 'primeng/api';
import { PrimeTemplate } from 'primeng/api';
import { Table, TableService } from 'primeng/table';

import { BaseAppProperties } from '../../base-app-properties';
import { PortalType } from '../../models/portal-type';
import { TableFilterEvent, TableSelectionMode, TableSortMode, TableStateStorage } from '../../models/table';
import { DialogStore } from '../../store/dialog.store';
import { BaseComponent } from '../base/base.component';
import { GlobalFilterComponent } from '../global-filter/global-filter.component';
import { TableHeaderComponent } from '../table-header/table-header.component';

export function tableFactory(tableComponent: TableComponent) {

  return tableComponent.table;
}

export function tableServiceFactory(tableComponent: TableComponent) {

  return tableComponent.table.tableService;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // The following providers are defined such that the TableHeaderComponent/TableDataComponent can find the injected Table.
  providers: [
    {
      provide: Table,
      useFactory: tableFactory,
      deps: [TableComponent]
    },
    {
      provide: TableService,
      useFactory: tableServiceFactory,
      deps: [TableComponent]
    }]
})
export class TableComponent extends BaseComponent implements OnInit, AfterViewInit, AfterContentInit {

  private _value?: unknown[];
  private _selection?: unknown;

  @ViewChild('defaultEmptyMessageTpl', { static: true }) private readonly defaultEmptyMessageTpl: TemplateRef<any>;
  @ViewChild(Table, { static: true }) readonly table: Table;

  @ContentChild(GlobalFilterComponent) private readonly globalFilter?: GlobalFilterComponent;
  @ContentChildren(PrimeTemplate) private readonly templates: QueryList<PrimeTemplate>;
  @ContentChildren(TableHeaderComponent, { descendants: true }) private readonly headers: QueryList<TableHeaderComponent>;

  @Input() dataKey?: string;
  @Input() autoLayout = true;
  @Input() paginator = true;
  @Input() rows = 10;
  @Input() alwaysShowPaginator = false;
  @Input() resetPageOnSort = false;
  @Input() resetPageOnValueChange = true;
  @Input() responsive = this.appProperties.portalType === PortalType.Owner;
  @Input() first = 0;
  @Input() preventUnselection = true;
  @Input() selectionMode?: TableSelectionMode;
  @Input() globalFilterFields?: string[];
  @Input() showCaption = true;
  @Input() stateKey?: string;
  @Input() stateStorage = TableStateStorage.Session;
  @Input() sortMode = TableSortMode.Single;
  @Input() sortField?: string;
  @Input() sortOrder = 1;
  @Input() multiSortMeta?: SortMeta[];
  @Input() customSort = false;

  @Input() set value(val: unknown[] | undefined) {

    // Need to convert to normal array to get "dataKey" to work
    this._value = isObservableArray(val) ? val.slice() : val;

    if (this.resetPageOnValueChange) {

      this.resetPage();
    }
  }

  get value() {

    return this._value;
  }

  @Input() set selection(val: unknown) {

    // Need to convert to normal array to get "dataKey" to work
    this._selection = isObservableArray(val) ? val.slice() : val;
  }

  get selection() {

    return this._selection;
  }

  @Output() readonly editInit = new EventEmitter();
  @Output() readonly editComplete = new EventEmitter();
  @Output() readonly editCancel = new EventEmitter();
  @Output() readonly filter = new EventEmitter();
  @Output() readonly page = new EventEmitter();
  @Output() readonly selectionChange = new EventEmitter();
  @Output() readonly rowSelect = new EventEmitter();
  @Output() readonly rowUnselect = new EventEmitter();
  @Output() readonly firstChange = new EventEmitter<number>();
  @Output() readonly sortFunction = new EventEmitter<SortEvent>();
  @Output() readonly sort = new EventEmitter();

  captionTemplate?: TemplateRef<any>;
  headerTemplate?: TemplateRef<any>;
  bodyTemplate?: TemplateRef<any>;
  footerTemplate?: TemplateRef<any>;
  emptyMessageTemplate?: TemplateRef<any>;

  constructor(
    private readonly dialogStore: DialogStore,
    private readonly appProperties: BaseAppProperties,
    private readonly cdr: ChangeDetectorRef) {

    super();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    this.monkeyPatch();
  }

  ngAfterContentInit(): void {

    const primeCaptionTemplate = this.getPrimeTemplateByType('caption');
    this.captionTemplate = primeCaptionTemplate && primeCaptionTemplate.template;

    const primeHeaderTemplate = this.getPrimeTemplateByType('header');
    this.headerTemplate = primeHeaderTemplate && primeHeaderTemplate.template;

    const primeBodyTemplate = this.getPrimeTemplateByType('body');
    this.bodyTemplate = primeBodyTemplate && primeBodyTemplate.template;

    const primeFooterTemplate = this.getPrimeTemplateByType('footer');
    this.footerTemplate = primeFooterTemplate && primeFooterTemplate.template;

    const primeEmptyMessageTemplate = this.getPrimeTemplateByType('emptymessage');
    this.emptyMessageTemplate = primeEmptyMessageTemplate && primeEmptyMessageTemplate.template || this.defaultEmptyMessageTpl;
  }

  // Just the presence of an onFilter handler will make the p-table filtering work with CD OnPush ...
  onFilter(evt: TableFilterEvent) {

    this.headers.forEach(h => h.tableFilterEvent = evt);

    this.filter.emit(evt);
  }

  onPage(evt: {
    first: number;
    rows: number;
  }) {

    this.page.emit(evt);

    if (this.dialogStore.visible) {

      this.dialogStore.positionOverlay({ nextTick: true });
    }
  }

  onRowSelect(evt: unknown) {

    this.rowSelect.emit(evt);

    this.cdr.markForCheck();
  }

  onRowUnselect(evt: unknown) {

    this.rowUnselect.emit(evt);

    this.cdr.markForCheck();
  }

  onSort(evt: unknown) {

    this.sort.emit(evt);

    this.refreshHeaders({ nextTick: true });
  }

  onFirstChange(first: number) {

    this.firstChange.emit(first);
  }

  resetPage() {

    setTimeout(() => {

      this.table.first = 0;

      this.cdr.detectChanges();

      this.onFirstChange(this.table.first);
    });
  }

  reset() {

    this.table.reset();

    this.table.clearState();

    this.refreshHeaders();
  }

  clearFilters() {

    this.table.filteredValue = null as any;
    this.table.filters = {};

    this.table.clearState();

    this.refreshHeaders();

    if (this.globalFilter) {

      this.globalFilter.refresh();
    }
  }

  doFilter(args: {
    value: unknown[];
    field: string;
  }) {

    this.clearFilters();

    this.table.filter(args.value, args.field, 'in');

    this.refreshHeaders();
  }

  private doRefreshHeaders() {

    this.headers.forEach(header => header.refresh());
  }

  refreshHeaders(args?: {
    nextTick?: boolean;
  }) {

    if (args && args.nextTick) {

      setTimeout(() => this.doRefreshHeaders());

    } else {

      this.doRefreshHeaders();
    }
  }

  private getPrimeTemplateByType(type: string): PrimeTemplate | undefined {

    return this.templates.find(template => template.getType() === type);
  }

  private monkeyPatch() {

    const handleRowClick = this.table.handleRowClick.bind(this.table);

    this.table.handleRowClick = (evt: { rowData: unknown }, ...args) => {

      if (this.preventUnselection && this.table.selectionMode === TableSelectionMode.Single && this.table.isSelected(evt.rowData)) {

        return;
      }

      handleRowClick(evt, ...args);
    };
  }
}
