import { NgStyle } from '@angular/common';
import { Injectable, TemplateRef, Type } from '@angular/core';
import { action, computed, observable } from 'mobx';
import { Dialog } from 'primeng/dialog';
import { Subject } from 'rxjs';

import { Logger } from '../services/logger.service';
import { BaseAppStore } from '../store/base-app.store';
import { calculateDialogWidth } from '../utils/display.utils';

export interface DialogOptions {
  readonly context?: any;
  readonly header?: string;
  readonly titleCaseHeader?: boolean;
  readonly showHeader?: boolean;
  readonly responsive?: boolean;
  readonly resizable?: boolean;
  readonly draggable?: boolean;
  readonly modal?: boolean;
  readonly dismissableMask?: boolean;
  readonly breakpoint?: number;
  readonly style?: NgStyle['ngStyle'];
  readonly styleClass?: string;
  readonly contentStyle?: NgStyle['ngStyle'];
  readonly width?: string;
  readonly height?: string;
  readonly maxWidth?: string;
  readonly maxHeight?: string;
  readonly closable?: boolean;
  readonly closeOnEscape?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DialogStore {

  public static readonly DEFAULTS = {
    header: '',
    showHeader: false,
    responsive: true,
    resizable: false,
    draggable: false,
    modal: true,
    dismissableMask: false,
    // Make sure breakpoint never matches (resize behaviour is buggy)
    breakpoint: -1,
    style: {
      'max-width': '90%',
      'max-height': '90%',
      width: '80%',
      overflow: 'auto'
    },
    styleClass: '',
    contentStyle: undefined,
    closable: true,
    closeOnEscape: true
  };

  private readonly _positionOverlay$ = new Subject();

  private _context: any;

  private hideAnimationEndTimeout?: ReturnType<typeof setTimeout>;

  @observable
  private _visible = false;

  @observable
  private _dialog?: Dialog;

  @observable
  private _isShowing = false;

  @observable
  private _component?: Type<any>;

  @observable
  private _template?: TemplateRef<any>;

  @observable
  private _header = DialogStore.DEFAULTS.header;

  @observable
  private _showHeader = DialogStore.DEFAULTS.showHeader;

  @observable
  private _responsive = DialogStore.DEFAULTS.responsive;

  @observable
  private _resizable = DialogStore.DEFAULTS.responsive;

  @observable
  private _draggable = DialogStore.DEFAULTS.draggable;

  @observable
  private _modal = DialogStore.DEFAULTS.modal;

  @observable
  private _dismissableMask = DialogStore.DEFAULTS.dismissableMask;

  @observable
  private _breakpoint = DialogStore.DEFAULTS.breakpoint;

  @observable
  private _style: NgStyle['ngStyle'] = DialogStore.DEFAULTS.style;

  @observable
  private _contentStyle?: NgStyle['ngStyle'] = DialogStore.DEFAULTS.contentStyle;

  @observable
  private _styleClass = DialogStore.DEFAULTS.styleClass;

  @observable
  private _closable = DialogStore.DEFAULTS.closable;

  @observable
  private _closeOnEscape = DialogStore.DEFAULTS.closeOnEscape;

  constructor(
    private readonly appStore: BaseAppStore,
    private readonly logger: Logger) {
  }

  @action
  private show(dialogOptions: DialogOptions) {

    if (this.appStore.logoutInvoked) {

      return;

    } else if (!this._visible && this._isShowing) {

      this.cancelHideAnimationTimeout();
    }

    this._context = dialogOptions.context;

    if (dialogOptions.header == null) {

      this._header = DialogStore.DEFAULTS.header;

    } else {

      this._header = dialogOptions.titleCaseHeader == null || dialogOptions.titleCaseHeader ?
        // @ts-ignore
        dialogOptions.header.toTitleCase() : dialogOptions.header;
    }

    this._showHeader = dialogOptions.showHeader == null ? DialogStore.DEFAULTS.showHeader : dialogOptions.showHeader;
    this._responsive = dialogOptions.responsive == null ? DialogStore.DEFAULTS.responsive : dialogOptions.responsive;
    this._resizable = dialogOptions.resizable == null ? DialogStore.DEFAULTS.resizable : dialogOptions.resizable;
    this._draggable = dialogOptions.draggable == null ? DialogStore.DEFAULTS.draggable : dialogOptions.draggable;
    this._modal = dialogOptions.modal == null ? DialogStore.DEFAULTS.modal : dialogOptions.modal;
    this._dismissableMask = dialogOptions.dismissableMask == null ? DialogStore.DEFAULTS.dismissableMask : dialogOptions.dismissableMask;
    this._breakpoint = dialogOptions.breakpoint == null ? DialogStore.DEFAULTS.breakpoint : dialogOptions.breakpoint;
    this._style = dialogOptions.style == null ? DialogStore.DEFAULTS.style : dialogOptions.style;
    this._contentStyle = dialogOptions.contentStyle == null ? DialogStore.DEFAULTS.contentStyle : dialogOptions.contentStyle;
    this._closable = dialogOptions.closable == null ? DialogStore.DEFAULTS.closable : dialogOptions.closable;
    this._closeOnEscape = dialogOptions.closeOnEscape == null ? DialogStore.DEFAULTS.closeOnEscape : dialogOptions.closeOnEscape;
    this._styleClass = dialogOptions.styleClass == null ? DialogStore.DEFAULTS.styleClass : dialogOptions.styleClass;

    if (dialogOptions.width) {

      this._style.width = calculateDialogWidth(dialogOptions.width);
    }

    if (dialogOptions.height) {

      this._style.height = dialogOptions.height;
    }

    if (dialogOptions.maxWidth) {

      this._style['max-width'] = dialogOptions.maxWidth;
    }

    if (dialogOptions.maxHeight) {

      this._style['max-height'] = dialogOptions.maxHeight;
    }

    this._visible = true;
  }

  @action
  showComponent(component: Type<any>, dialogOptions?: DialogOptions) {

    this.clearContentIfVisible();

    this._component = component;

    this.show(dialogOptions || {});
  }

  @action
  showTemplate(template: TemplateRef<any>, dialogOptions?: DialogOptions) {

    this.clearContentIfVisible();

    this._template = template;

    this.show(dialogOptions || {});
  }

  @action
  hide() {

    this._visible = false;
  }

  @action
  onHide() {

    // The default hide animatation for p-dialog is 150ms.
    this.hideAnimationEndTimeout = setTimeout(() => this.onHideAnimationEnd(), 150);
  }

  @action
  private onHideAnimationEnd() {

    this.hideAnimationEndTimeout = undefined;

    this._isShowing = false;

    // By clearing out the dialog contents AFTER the animation finishes, we get a nice hide transition.
    this.clearContent();
  }

  private cancelHideAnimationTimeout() {

    if (!this.hideAnimationEndTimeout) {

      return;
    }

    clearTimeout(this.hideAnimationEndTimeout);

    this.hideAnimationEndTimeout = undefined;
  }

  @action
  private clearContent() {

    this._context = undefined;
    this._component = undefined;
    this._template = undefined;
    this._dialog = undefined;
  }

  @action
  private clearContentIfVisible() {

    if (!this._visible) {

      return;
    }

    this.clearContent();

    // Since the dialog is not transitioning from hidden -> visible, need to invoke positionOverlay() on next tick.
    setTimeout(() => this.positionOverlay());
  }

  @action
  onShow(dialog: Dialog) {

    this._isShowing = true;
    this._dialog = dialog;
  }

  positionOverlay(args?: {
    nextTick: boolean;
  }) {

    const nextTick = args && args.nextTick;

    if (nextTick) {

      setTimeout(() => this._positionOverlay$.next());

    } else {

      this._positionOverlay$.next();
    }
  }

  @action
  setVisible(val: boolean) {

    this._visible = val;
  }

  get positionOverlay$() {

    return this._positionOverlay$.asObservable();
  }

  get visible() {

    return this._visible;
  }

  get context() {

    return this._context;
  }

  get dialog() {

    return this._dialog;
  }

  get isShowing() {

    return this._isShowing;
  }

  get component() {

    return this._component;
  }

  get template() {

    return this._template;
  }

  get header() {

    return this._header;
  }

  get showHeader() {

    return this._showHeader;
  }

  get responsive() {

    return this._responsive;
  }

  get resizable() {

    return this._resizable;
  }

  get draggable() {

    return this._draggable;
  }

  get modal() {

    return this._modal;
  }

  get dismissableMask() {

    return this._dismissableMask;
  }

  get breakpoint() {

    return this._breakpoint;
  }

  get style() {

    return this._style;
  }

  get contentStyle() {

    return this._contentStyle;
  }

  get styleClass() {

    return this._styleClass;
  }

  get closable() {

    return this._closable;
  }

  get closeOnEscape() {

    return this._closeOnEscape;
  }
}
