<div *mobxAutorun fxLayoutAlign="center center" class="wht-fullscreen" [ngClass]="store.backgroundCSS"
  style="overflow: auto">
  <div class="card ui-fluid login-panel wht-login-panel">
    <form [formGroup]="loginForm" class="p-grid" (ngSubmit)="onLoginFormSubmit()">
      <div class="p-col-12" [class.mobile-spacer]="BrowserUtils.Mobile">
        <div fxLayoutAlign="center center">
          <img src="/assets/images/whittles-logo.svg">
        </div>
      </div>
      <ng-container *ngIf="store.isLoginMethodVisible">
        <div class="p-col-12 rb-padding">
          <p-radioButton [name]="loginTypeGroupName" label="Login using Account Details"
            [value]="LoginMethod.AccountDetails" [ngModel]="store.loginMethod"
            (ngModelChange)="store.setLoginMethod($event)" [ngModelOptions]="{standalone: true}"></p-radioButton>
        </div>
        <div class="p-col-12 rb-padding">
          <p-radioButton [name]="loginTypeGroupName" label="Login using Email" [value]="LoginMethod.Email"
            [ngModel]="store.loginMethod" (ngModelChange)="store.setLoginMethod($event)"
            [ngModelOptions]="{standalone: true}"></p-radioButton>
        </div>
        <div *ngIf="!store.forgottenPassword" class="p-col-12 rb-padding">
          <p-radioButton [name]="loginTypeGroupName" label="Login using Mobile" [value]="LoginMethod.Mobile"
            [ngModel]="store.loginMethod" (ngModelChange)="store.setLoginMethod($event)"
            [ngModelOptions]="{standalone: true}"></p-radioButton>
        </div>
      </ng-container>
      <ng-container *ngIf="store.isImpersonationLogin">
        <div class="p-col-12">
          <span class="p-float-label">
            <input pInputText [formControl]="impersonatorUsername" class="p-w-100">
            <label class="p-ml-1 p-grid p-jc-start p-label">Admin Username</label>
            <app-form-error [form]="loginForm" [control]="impersonatorUsername" [touched]="false"></app-form-error>
          </span>
        </div>
        <div class="p-col-12">
          <span class="p-float-label">
            <input type="password" pInputText [formControl]="impersonatorPassword" class="p-w-100">
            <label class="p-ml-1 p-grid p-jc-start p-label">Admin Password</label>
            <app-form-error [form]="loginForm" [control]="impersonatorPassword" [touched]="false"></app-form-error>
          </span>
        </div>
      </ng-container>
      <div *ngIf="store.isEmailVisible" class="p-col-12">
        <span class="p-float-label">
          <input pInputText [formControl]="email" class="p-w-100">
          <label  class="p-ml-1 p-grid p-jc-start p-label">Email</label>
          <app-form-error [form]="loginForm" [control]="email" [touched]="false"></app-form-error>
        </span>
      </div>
      <div *ngIf="store.isMobileVisible" class="p-col-12">
        <span class="p-float-label">
          <input pInputText [formControl]="mobile" class="p-w-100">
          <label  class="p-ml-1 p-grid p-jc-start p-label">Mobile</label>
          <app-form-error [form]="loginForm" [control]="mobile" [touched]="false"></app-form-error>
        </span>
      </div>
      <ng-container *ngIf="store.loginMethod === LoginMethod.AccountDetails">
        <div class="p-col-12">
          <span class="p-float-label">
            <input type="text" pInputText [formControl]="accountNumber" class="p-w-100">
            <label for="account-number" class="p-ml-1 p-grid p-jc-start p-label">Account No.</label>
            <app-form-error [form]="loginForm" [control]="accountNumber" [touched]="false"></app-form-error>
          </span>
        </div>
        <div class="p-col-12">
          <span class="p-float-label">
            <input pInputText [formControl]="plan" class="p-w-100">
            <label class="p-ml-1 p-grid p-jc-start p-label">Plan</label>
            <app-form-error [form]="loginForm" [control]="plan" [touched]="false"></app-form-error>
          </span>
        </div>
        <div class="p-col-12">
          <span class="p-float-label">
            <input class="p-w-100" pInputText [formControl]="unitNumber">
            <label  class="p-ml-1 p-grid p-jc-start p-label">Unit No.</label>
            <app-form-error [form]="loginForm" [control]="unitNumber" [touched]="false"></app-form-error>
          </span>
        </div>
      </ng-container>
      <div *ngIf="store.isPasswordVisible" class="p-col-12">
        <span class="p-float-label">
          <input class="p-w-100" type="password" pInputText [formControl]="password">
          <label class="p-ml-1 p-grid p-jc-start p-label">Password</label>
          <app-form-error [form]="loginForm" [control]="password" [touched]="false"></app-form-error>
        </span>
      </div>
      <div class="p-col-12 login-button-container" style="text-align: center">
        <button pButton type="submit" [label]="store.forgottenPassword ? 'reset password' : 'login'"
          [icon]="store.forgottenPassword ? 'ui-icon-lock' : 'ui-icon-person'" [disabled]="!loginForm.valid"></button>
        <button *ngIf="store.oneTimePinButtonVisible" pButton type="button" label="Send me a login code"
          [icon]="store.oneTimePinButtonIcon" [disabled]="!oneTimePinButtonEnabled"
          (click)="onSendLoginCodeClick(loginCodeTpl)"></button>
        <span class="md-inputfield">
          <app-form-error [visible]="store.loginErrorMessage"
            [fontSize]="FormErrorFontSize.Normal">{{store.loginErrorMessage}}</app-form-error>
        </span>
      </div>
      <div class="ui-g-12">
        <div *ngIf="store.isPasswordDescriptionVisible" class="wht-placeholder-label-colour">
          <b>Hint:</b>If you don't have a password you can log in using either your EMAIL or MOBILE registered with Whittles and a Login Code.<br><br>
          You can set your password using the <b>Your Profile</b> screen after logging in.
        </div>
      </div>
      <ng-container *ngIf="!store.isImpersonationLogin">
        <div *ngIf="store.isDontKnowEmailVisible" class="p-col-12" style="text-align: center">
          <a appAnchor (click)="onDontKnowEmailLink($event)">Don't know email?</a>
        </div>
<!--        <div *ngIf="store.isForgotYouPasswordVisible" class="ui-g-12" style="text-align: center">-->
<!--          <a appAnchor-->
<!--            (click)="onForgottenPasswordClick()">{{store.forgottenPassword ? 'Login' : 'Forgot your password ? '}}</a>-->
<!--        </div>-->
      </ng-container>
    </form>
    <div class="p-col-12" fxLayoutAlign="center center">
      <div #inlineRecaptcha></div>
    </div>
  </div>
</div>

<ng-template #passwordUpdateTpl>
  <app-password-update [passwordUpdateFailed]="store.passwordUpdateFailed"
    (passwordUpdateFailedChange)="store.setPasswordUpdateFailed($event)" (passwordUpdate)="onPasswordUpdate($event)">
  </app-password-update>
</ng-template>

<ng-template #loginCodeTpl>
  <form [formGroup]="loginCodeForm" (ngSubmit)="onLoginCodeFormSubmit()">
    <div class="p-grid ui-fluid form-group">
      <div class="p-col-12">
        <app-info-message [message]="loginCodeMessage"></app-info-message>
      </div>
      <div class="p-col-12">
        <span class="md-inputfield">
          <input pInputText [formControl]="loginCode" [maxlength]="loginCodeMaxLength">
          <label>Login Code</label>
          <app-form-error [form]="loginCodeForm" [control]="loginCode"></app-form-error>
        </span>
      </div>
      <div class="p-col-12 login-button-container" style="text-align: center">
        <button pButton type="submit" label="login using code" icon="ui-icon-apps"
          [disabled]="!loginCodeForm.valid"></button>
        <span class="md-inputfield">
          <app-form-error [positionStatic]="true" [visible]="store.loginCodeInvalid"
            [fontSize]="FormErrorFontSize.Normal">Invalid login code</app-form-error>
        </span>
      </div>
    </div>
  </form>
</ng-template>
