import { Injectable } from '@angular/core';
import { CustomMenuItem, Heading as CommonHeading, LocalisationStore } from 'common-lib';
import { computed } from 'mobx';

import { Heading } from '../models/heading';
import { RoutePath } from '../route-path';
import { ChildAuthGuard } from '../services/auth-guard.service';
import { AuthenticationStore } from '../stores/authentication.store';

@Injectable({
  providedIn: 'root'
})
export class LeftSideMenuStore {

  constructor(
    private readonly authenticationStore: AuthenticationStore,
    private readonly childApprovalGuard: ChildAuthGuard,
    private readonly localisationStore: LocalisationStore) {
  }

  @computed
  get menuModel(): CustomMenuItem[] {

    return [
      {
        label: Heading.Home,
        fawIcon: 'fa-bars',
        routerLink: [`/${RoutePath.Home}`],
        visible: this.childApprovalGuard.hasAccess(RoutePath.Home)
      },
      {
        label: `select ${this.localisationStore.unitLabel}`,
        fawIcon: 'fa-home',
        routerLink: [`/${RoutePath.SelectUnit}`],
        visible: this.childApprovalGuard.hasAccess(RoutePath.SelectUnit)
      },
      {
        label: Heading.SelectBuidling,
        fawIcon: 'fa-building',
        routerLink: [`/${RoutePath.SelectBuilding}`],
        visible: this.childApprovalGuard.hasAccess(RoutePath.SelectBuilding)
      },
      {
        label: Heading.YourProfile,
        fawIcon: 'fa-user',
        routerLink: [`/${RoutePath.Profile}`],
        visible: this.childApprovalGuard.hasAccess(RoutePath.Profile)
      },
      {
        label: Heading.BuildingInfo,
        fawIcon: 'fa-info',
        routerLink: [`/${RoutePath.PlanInfo}`],
        visible: this.childApprovalGuard.hasAccess(RoutePath.PlanInfo)
      },
      {
        label: Heading.YourBuilding,
        fawIcon: 'fa-info',
        routerLink: [`/${RoutePath.PlanSummary}`],
        visible: this.childApprovalGuard.hasAccess(RoutePath.PlanSummary)
      },
      {
        label: Heading.PayMyBills,
        fawIcon: 'fa-credit-card',
        routerLink: [`/${RoutePath.MakePayment}`],
        visible: this.childApprovalGuard.hasAccess(RoutePath.MakePayment)
      },
      {
        label: Heading.AccountHistory,
        fawIcon: 'fa-history',
        routerLink: [`/${RoutePath.AccountHistory}`],
        visible: this.childApprovalGuard.hasAccess(RoutePath.AccountHistory)
      },
      {
        label: Heading.Invoices,
        fawIcon: 'fa-file-pdf',
        routerLink: [`/${RoutePath.Invoices}`],
        visible: this.childApprovalGuard.hasAccess(RoutePath.Invoices)
      },
      {
        label: Heading.Documents,
        fawIcon: 'fa-book',
        routerLink: [`/${RoutePath.Documents}`],
        visible: this.childApprovalGuard.hasAccess(RoutePath.Documents)
      },
      {
        label: Heading.FinancialRecords,
        fawIcon: 'fa-file-invoice-dollar',
        routerLink: [`/${RoutePath.FinancialRecords}`],
        visible: this.childApprovalGuard.hasAccess(RoutePath.FinancialRecords)
      },
      {
        label: Heading.FinancialTransactions,
        fawIcon: 'fa-usd-circle',
        routerLink: [`/${RoutePath.FinancialTransactions}`],
        visible: this.childApprovalGuard.hasAccess(RoutePath.FinancialTransactions)
      },
      {
        label: CommonHeading.InvoiceApproval,
        fawIcon: 'fa-thumbs-up',
        routerLink: [`/${RoutePath.CommitteeApproval}`],
        visible: this.childApprovalGuard.hasAccess(RoutePath.CommitteeApproval)
      },
      {
        label: Heading.ContactUs,
        fawIcon: 'fa-phone',
        routerLink: [`/${RoutePath.Contact}`],
        visible: this.childApprovalGuard.hasAccess(RoutePath.Contact)
      },
      {
        label: Heading.Logout,
        fawIcon: 'fa-sign-out',
        command: () => this.authenticationStore.logout()
      }
    ];
  }
}
