<div class="exception-body notfound-page wht-fullscreen">
  <div class="exception-type">
    <img src="/assets/layout/images/404.svg" />
  </div>

  <div class="card exception-panel">
    <i class="material-icons">error_outline</i>
    <h1>Page Not Found</h1>
    <div class="exception-detail">The resource you are looking for does not exist.</div>
    <a href="/" class="p-button secondary">
      <span class="p-button-text">Homepage</span>
    </a>
  </div>
</div>
