export class Environment {

  constructor(private readonly args: {
    production: boolean;
    gmapsApiKey: string;
    logrocketRelease: boolean;
  }) {
  }

  get production() {

    return this.args.production;
  }

  get gmapsApiKey() {

    return this.args.gmapsApiKey;
  }

  get logrocketRelease() {

    return this.args.logrocketRelease;
  }
}
