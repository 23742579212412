import { action, computed, observable } from 'mobx';
import { TreeNode } from 'primeng/api';

import { toFileSizeDisplayFormat } from '../utils/file.utils';

export interface Data {
  readonly id: string;
  readonly name: string;
  readonly type?: string;
  size?: number;
  createTime?: Date;
}

export class DocNode implements TreeNode {

  @observable
  private _expanded = true;

  @observable
  label: string;

  @observable
  data: Data;

  @observable
  children?: DocNode[];

  @observable
  leaf = true;

  @observable
  selectable = true;

  @observable
  style?: string;

  constructor(id: string, name: string, type: string) {
    this.label = name;
    this.data = { id, name, type };
  }

  @action
  public addChild(child: DocNode) {
    this.leaf = false;
    this.selectable = false;
    if (!this.children) {
      this.children = [];
    }
    this.children.push(child);
  }

  @action
  public setSize(size: number) {
    this.data.size = size;
  }

  @action
  public setCreateTime(time: Date) {
    this.data.createTime = time;
  }

  @computed
  get displaySize(): string {

    const size = this.data.size;

    if (this.leaf && size && size > 0) {
      return toFileSizeDisplayFormat(size);
    } else {
      return (size == null || size <= 0) ? '' : `${this.data.size}`;
    }
  }

  @computed
  get fileIcon(): string {
    if (this.children && this.children.length > 0) {
      return 'fa ui-icon-folder';
    }
    if (this.leaf) {
      const filenameArray = this.data.name.split('.');
      const len = filenameArray.length;
      if (len === 1 || this.data.type === 'DOCUMENT') {
        return 'far fa-file-pdf';
      } else if (filenameArray.length > 1) {
        const ext = filenameArray[len - 1].toLowerCase();
        switch (ext) {
          case 'pdf': return 'far fa-file-pdf';
          case 'jpg': return 'far fa-file-image';
          case 'jpeg': return 'far fa-file-image';
          default: return 'far fa-file-alt';
        }
      } else {
        return 'far fa-file-alt';
      }
    } else {
      return 'fa ui-icon-folder';
    }
  }

  @computed
  get iconRightPadding(): string {
    if (this.leaf) {
      return '10px';
    } else {
      return '30px';
    }
  }

  @computed
  get expanded() {

    return this._expanded;
  }

  set expanded(value: boolean) {

    this._expanded = value;
  }
}
