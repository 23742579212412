import { Directive, Input } from '@angular/core';
import { RadioButton } from 'primeng/radiobutton';

// tslint:disable-next-line:directive-selector
@Directive({ selector: 'p-radioButton' })
export class RadioButtonDirective {

  @Input() set label(val: string | undefined) {

    if (val) {

      // @ts-ignore
      this.radioButton.label = val.toTitleCase();
    }
  }

  constructor(private readonly radioButton: RadioButton) {
  }
}
