import { Injectable, NgZone } from '@angular/core';
import { MessageService } from 'primeng/api';

import { Message, MessageSeverity } from '../models/message';

@Injectable({
  providedIn: 'root'
})
export class MessageStore {

  constructor(
    private readonly messageService: MessageService,
    private readonly zone: NgZone) {
  }

  pushMessage(message: Message | string) {

    let xmessage: Message = {
      severity: MessageSeverity.Success
    };

    if (typeof message === 'string') {

      xmessage = {
        ...xmessage,
        // @ts-ignore
        summary: message.toTitleCase()
      };

    } else {

      xmessage = {
        ...message,
        // @ts-ignore
        summary: message.summary ? message.summary.toTitleCase() : message.summary
      };
    }

    xmessage = {
      ...xmessage,
      // @ts-ignore
      key: 'app' as any
    };

    this.zone.run(() => this.messageService.add(xmessage));
  }

  clearMessages() {

    this.zone.run(() => this.messageService.clear());
  }

  pushTaskCompletedMessage() {

    this.pushMessage('task completed');
  }
}
