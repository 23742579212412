import { Directive, Input } from '@angular/core';

import { DataView } from 'primeng/dataview';

// tslint:disable-next-line:directive-selector
@Directive({ selector: 'p-dataView' })
export class DataViewDirective {

  @Input() resetPaginatorOnValueChange = true;

  constructor(private readonly dataView: DataView) {

    dataView.paginator = true;
    dataView.alwaysShowPaginator = false;
  }
}
