import {AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import { parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js';
import { of, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { NameValuePair } from '../generated';
import { CreditCardType } from '../models/credit-card';
import { RegExpUtils } from './regexp.utils';

const AustralianNationalCode = '61';

export type FormModel<T> = { [P in keyof T]: any };

export enum ValidationErrorCodes {
  Custom = 'custom',
  Required = 'required',
  NotEmpty = 'notEmpty',
  MinLength = 'minlength',
  MaxLength = 'maxlength',
  Min = 'min',
  Max = 'max',
  Pattern = 'pattern',
  Email = 'email',
  Phone = 'phone',
  Name = 'name',
  Filename = 'filename',
  CreditCardNumber = 'creditCardNumber',
  CreditCardVerificationValue = 'creditCardVerificationValue',
  Password = 'password'
}

export function validationErrorMessage(formControl: AbstractControl, validationMessage: string ='' ): string {

  if (!formControl.errors) {

    return '';

  } else if (formControl.hasError(ValidationErrorCodes.Required) || formControl.hasError(ValidationErrorCodes.NotEmpty)) {
    if(validationMessage!=='') return validationMessage;
    return 'Field is required';

  } else if (formControl.hasError(ValidationErrorCodes.MinLength)) {

    return `Min characters (${formControl.errors && formControl.errors.minlength.requiredLength})`;

  } else if (formControl.hasError(ValidationErrorCodes.MaxLength)) {

    return `Max characters (${formControl.errors && formControl.errors.maxlength.requiredLength}) exceeded`;

  } else if (formControl.hasError(ValidationErrorCodes.Min)) {

    return `Value must be > ${formControl.errors && formControl.errors.min.min}`;

  } else if (formControl.hasError(ValidationErrorCodes.Max)) {

    return `Value must be < ${formControl.errors && formControl.errors.max.max}`;

  } else if (formControl.hasError(ValidationErrorCodes.Pattern)) {

    switch (formControl.errors.pattern.requiredPattern) {

      case `/${RegExpUtils.PositiveInt.source}/`:

        return 'Only numbers allowed';

      default:

        return 'Invalid characters';

    }

  } else if (formControl.hasError(ValidationErrorCodes.Email)) {

    return 'Email is invalid';

  } else if (formControl.hasError(ValidationErrorCodes.Phone)) {

    return formControl.getError(ValidationErrorCodes.Phone);

  } else if (formControl.hasError(ValidationErrorCodes.Name)) {

    return formControl.getError(ValidationErrorCodes.Name);

  } else if (formControl.hasError(ValidationErrorCodes.CreditCardNumber)) {

    return formControl.getError(ValidationErrorCodes.CreditCardNumber);

  } else if (formControl.hasError(ValidationErrorCodes.CreditCardVerificationValue)) {

    return formControl.getError(ValidationErrorCodes.CreditCardVerificationValue);

  } else if (formControl.hasError(ValidationErrorCodes.Filename)) {

    return formControl.getError(ValidationErrorCodes.Filename);

  } else if (formControl.hasError(ValidationErrorCodes.Password)) {

    return formControl.getError(ValidationErrorCodes.Password);
  }

  return '';
}

export const notEmptyValidator: ValidatorFn = control => {

  if (!control.value ||
    (typeof control.value === 'string' && !control.value.trim()) ||
    (Array.isArray(control.value) && control.value.length === 0)) {

    return {
      [ValidationErrorCodes.NotEmpty]: true
    };
  }

  return null;
};

// validator for Id, if value is empty, null, undefined or negative value, it will return ValidationErrorCodes.NotEmpty
// handling for bad data
export const idValidator: ValidatorFn = control => {

  if (!control.value || control.value <= 0) {

    return {
      [ValidationErrorCodes.NotEmpty]: true
    };
  }

  return null;
}


// validator for number, if value is empty, null or undefined, it will return ValidationErrorCodes.NotEmpty
// if value is zero return null
export const numberValidator: ValidatorFn = control => {
    if (control.value === null || control.value === undefined || control.value === '') {

      return {
        [ValidationErrorCodes.NotEmpty]: true
      };

    } else if (control.value === 0) {

      return null;

    } else if (!isNaN(control.value)) {

      return null;

    } else {

      return {
        [ValidationErrorCodes.Custom]: 'Invalid number'
      };
    }
}

export function asyncValidator(validator: ValidatorFn, options?: {
  debounceMS?: number;
}): AsyncValidatorFn {

  const debounceMS = options && options.debounceMS || 500;

  return (control: AbstractControl) => {

    return timer(debounceMS).pipe(
      switchMap(() => of(validator(control)))
    );
  };
}

export const asyncEmailValidator = asyncValidator(Validators.email);

export function parsePhoneNumber(phoneNumber: string): PhoneNumber | undefined {

  return parsePhoneNumberFromString(phoneNumber, 'AU');
}

export function formatPhoneNumberForDisplay(phoneNumber: string | undefined, args?: {
  removeWhiteSpace?: boolean;
}, showNonAustralianNationalCode?: boolean): string {

  if (!phoneNumber) {

    return '';
  }

  const parsedPhoneNumber = parsePhoneNumber(phoneNumber);

  if (!parsedPhoneNumber) {

    return phoneNumber;
  }

  if (!showNonAustralianNationalCode) {

    let formattedPhoneNumber = parsedPhoneNumber.formatNational();
    if (args) {

      if (args.removeWhiteSpace) {

        formattedPhoneNumber = formattedPhoneNumber.replace(/ /g, '');
      }
    }

    return formattedPhoneNumber;
  }
  else {

    let formattedPhoneNumber = parsedPhoneNumber.countryCallingCode == AustralianNationalCode
      ? parsedPhoneNumber.formatNational()
      : parsedPhoneNumber.formatInternational();

    if (args) {

      if (args.removeWhiteSpace) {

        formattedPhoneNumber = formattedPhoneNumber.replace(/ /g, '');
      }
    }

    return formattedPhoneNumber;
  }
}

export function formatPhoneNumberForPersistance<T extends string | undefined>(phoneNumber: T): T {

  if (!phoneNumber) {

    return phoneNumber;
  }

  const parsedPhoneNumber = parsePhoneNumber(phoneNumber as string);

  if (!parsedPhoneNumber) {

    return phoneNumber;
  }

  return parsedPhoneNumber.format('E.164') as T;
}

export function arePhoneNumbersEqual(a: string, b: string): boolean {

  return formatPhoneNumberForPersistance(a) === formatPhoneNumberForPersistance(b);
}

export const phoneValidator: ValidatorFn = control => {

  if (!control.value) {

    // Phone number is null
    return null;
  }

  const parsedPhoneNumber = typeof control.value === 'string' && parsePhoneNumber(control.value);

  if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {

    // Phone number is valid
    return null;
  }

  return {
    [ValidationErrorCodes.Phone]: 'Phone number is invalid'
  };
};

export const nameValidator: ValidatorFn = control => {

  const validationErrors = Validators.pattern('[a-zA-Z ]+')(control);

  if (validationErrors) {

    return {
      [ValidationErrorCodes.Name]: 'Only letters and spaces allowed'
    };
  }

  return null;
};

export const filenameValidator: ValidatorFn = control => {

  const validationErrors = Validators.pattern('[0-9a-zA-Z-_]+')(control);

  if (validationErrors) {

    return {
      [ValidationErrorCodes.Filename]: 'Only letters, numbers, hyphens or underscores allowed'
    };
  }

  return null;
};

export const passwordMinLength = 6;

export const passwordValidator: ValidatorFn = control => {

  const passwordValue = control.value as string;

  if (!passwordValue) {

    return null;
  }

  return Validators.minLength(passwordMinLength)(control);
};

export function validatePasswords(args: {
  password: string;
  repeatPassword: string;
}): ValidationErrors | null {

  if (args.password !== args.repeatPassword) {

    return validationError(`Passwords don't match`);
  }

  return null;
}

// https://en.wikipedia.org/wiki/Payment_card_number
export function getCreditCardType(ccNumber: string): CreditCardType | undefined {

  if (!ccNumber) {

    return undefined;
  }

  const ccNumFirst4Digits = parseInt(ccNumber.trim().substring(0, 4), 10);

  if (ccNumber.startsWith('34') || ccNumber.startsWith('37')) {

    return CreditCardType.AmericanExpress;

  } else if (ccNumber.startsWith('4')) {

    return CreditCardType.Visa;

  } else if (
    ccNumber.startsWith('51') ||
    ccNumber.startsWith('52') ||
    ccNumber.startsWith('53') ||
    ccNumber.startsWith('54') ||
    ccNumber.startsWith('55') ||
    (ccNumFirst4Digits >= 2221 && ccNumFirst4Digits <= 2720)) {

    return CreditCardType.MasterCard;

  } else if (
    ccNumber.startsWith('300') ||
    ccNumber.startsWith('301') ||
    ccNumber.startsWith('302') ||
    ccNumber.startsWith('303') ||
    ccNumber.startsWith('304') ||
    ccNumber.startsWith('305') ||
    ccNumber.startsWith('36') ||
    ccNumber.startsWith('38') ||
    ccNumber.startsWith('39') ||
    ccNumber.startsWith('3095')) {

    return CreditCardType.DinersInternational;

  } else if (
    ccNumber.startsWith('2014') ||
    ccNumber.startsWith('2149')) {

    return CreditCardType.DinersEnRoute;
  }

  return undefined;
}

export interface CreditCardDetails {
  readonly number: {
    readonly maxLength: number;
  };
  readonly cvv: {
    readonly maxLength: number;
  };
}

abstract class CreditCards {

  static readonly Visa: CreditCardDetails = {
    number: {
      maxLength: 19
    },
    cvv: {
      maxLength: 3
    }
  };

  static readonly MasterCard: CreditCardDetails = {
    number: {
      maxLength: 16
    },
    cvv: {
      maxLength: 3
    }
  };

  static readonly AmericanExpress: CreditCardDetails = {
    number: {
      maxLength: 15
    },
    cvv: {
      maxLength: 4
    }
  };

  static readonly DinersInternational: CreditCardDetails = {
    number: {
      maxLength: 19
    },
    cvv: {
      maxLength: 3
    }
  };

  static readonly DinersEnRoute: CreditCardDetails = {
    number: {
      maxLength: 15
    },
    cvv: {
      maxLength: 3
    }
  };
}

export const creditCardNumberValidator: ValidatorFn = control => {

  const controlValue = control.value as string;

  const creditCardType = getCreditCardType(controlValue);

  if (!creditCardType) {

    return {
      [ValidationErrorCodes.CreditCardNumber]: 'Only Visa, MasterCard, AmEx or Diners allowed'
    };

  } else if (creditCardType === CreditCardType.Visa) {

    const ccNumberLength13 = 13;
    const ccNumberLength16 = 16;
    const ccNumberLength19 = CreditCards.Visa.number.maxLength;

    const ccNumberLength = controlValue.trim().length;

    if (ccNumberLength !== ccNumberLength13 && ccNumberLength !== ccNumberLength16 && ccNumberLength !== ccNumberLength19) {

      return {
        [ValidationErrorCodes.CreditCardNumber]:
          `Visa number length should be ${ccNumberLength13}, ${ccNumberLength16} or ${ccNumberLength19}`
      };
    }

  } else if (creditCardType === CreditCardType.MasterCard) {

    const ccNumberLength = CreditCards.MasterCard.number.maxLength;

    if (controlValue.trim().length < ccNumberLength) {

      return {
        [ValidationErrorCodes.CreditCardNumber]: `MasterCard number length should be ${ccNumberLength}`
      };
    }

  } else if (creditCardType === CreditCardType.AmericanExpress) {

    const ccNumberLength = CreditCards.AmericanExpress.number.maxLength;

    if (controlValue.trim().length < ccNumberLength) {

      return {
        [ValidationErrorCodes.CreditCardNumber]: `AmEx number length should be ${ccNumberLength}`
      };
    }

  } else if (creditCardType === CreditCardType.DinersEnRoute) {

    const ccNumberLength = CreditCards.DinersEnRoute.number.maxLength;

    if (controlValue.trim().length < ccNumberLength) {

      return {
        [ValidationErrorCodes.CreditCardNumber]: `Diners enRoute number length should be ${ccNumberLength}`
      };
    }

  } else if (creditCardType === CreditCardType.DinersInternational) {

    // Diners International starting with 36 have min legth 14, else 16 - https://en.wikipedia.org/wiki/Payment_card_number
    const ccNumberMinLength = controlValue.startsWith('36') ? 14 : 16;
    const ccNumberMaxLength = CreditCards.DinersInternational.number.maxLength;

    if (controlValue.trim().length < ccNumberMinLength) {

      return {
        [ValidationErrorCodes.CreditCardNumber]:
          `Diners International number length should be between ${ccNumberMinLength}-${ccNumberMaxLength}`
      };
    }
  }

  return null;
};

export function isCreditCardVerificationValueValid(params: {
  ccNumber: string;
  cvv: string;
}): ValidationErrors | null {

  if (!params.ccNumber || !params.cvv) {

    return null;
  }

  if (params.ccNumber.trim() !== '' && params.cvv.trim() !== '') {

    const ccDetails = getCreditCardDetails(params.ccNumber);

    if (params.cvv.length !== ccDetails.cvv.maxLength) {

      return {
        [ValidationErrorCodes.CreditCardVerificationValue]: `CVV length should be ${ccDetails.cvv.maxLength}`
      };
    }
  }

  return null;
}

export function getCreditCardDetails(cardNumber: string): CreditCardDetails {

  const creditCardType = getCreditCardType(cardNumber);

  if (creditCardType === CreditCardType.Visa) {

    return CreditCards.Visa;

  } else if (creditCardType === CreditCardType.MasterCard) {

    return CreditCards.MasterCard;

  } else if (creditCardType === CreditCardType.AmericanExpress) {

    return CreditCards.AmericanExpress;

  } else if (creditCardType === CreditCardType.DinersInternational) {

    return CreditCards.DinersInternational;

  } else if (creditCardType === CreditCardType.DinersEnRoute) {

    return CreditCards.DinersEnRoute;

  } else {

    return {
      number: {
        maxLength: 19
      },
      cvv: {
        maxLength: 4
      }
    };
  }
}

export function emailValidator(control: AbstractControl, maxLength?: number): ValidationErrors | null {

  const emailValidators = Validators.compose([Validators.email, maxLength ? Validators.maxLength(maxLength) : null]);
  const emailControl = new FormControl();
  emailControl?.setValue(control.value?.trim());

  control.setErrors(emailValidators!(emailControl));
  
  if (control.errors) 
    return { [ValidationErrorCodes.Email]: 'Invalid Email' };
  else
    return null;
}

export function validationError(message: string): { [key: string]: string; } {

  return {
    [ValidationErrorCodes.Custom]: message
  };
}

export function findNameValuePairByValue(nameValuePairs: NameValuePair[], value: NameValuePair['value']): NameValuePair | undefined {

  return nameValuePairs.find(nv => nv.value === value);
}

/* debug method to show which fields have validation errors
useful when using conditional validation
 */
export function getFormValidationErrors(form: FormGroup) {

  console.log('%c ==>> Validation Errors: ', 'color: red; font-weight: bold; font-size:25px;');

  let totalErrors = 0;

  Object.keys(form.controls).forEach(key => {
    const controlErrors = form.get(key)?.errors;
    if (controlErrors) {
      totalErrors++;
      Object.keys(controlErrors).forEach(keyError => {
        console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
      });
    }
  });

  console.log('Number of errors: ' ,totalErrors);
}

export class FormControlWithPermission extends FormControl {
    isVisible: boolean = false;
    isEditable: boolean = false;
    preserveEditable: boolean = false;
}

