/**
 * Whittles API
 * Whittles API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type AccountsPayableInvoiceApproverType = 'MANAGER' | 'COMMITTEE';

export const AccountsPayableInvoiceApproverType = {
    MANAGER: 'MANAGER' as AccountsPayableInvoiceApproverType,
    COMMITTEE: 'COMMITTEE' as AccountsPayableInvoiceApproverType
};
