import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from 'common-lib';
import { action, observable } from 'mobx';
import { tap } from 'rxjs/operators';

@Injectable()
export class Store {

  @observable
  private _termsAndConditions = '';

  @observable
  private _accept = false;

  constructor(
    private readonly userService: UserService,
    private readonly httpClient: HttpClient) {
  }

  getTermsAndConditions() {

    return this.httpClient.get('/assets/terms-and-conditions.html', {
      responseType: 'text'
    }).pipe(
      tap(response => this.setTermsAndConditions(response))
    );
  }

  acceptTermsAndConditions() {

    return this.userService.acceptTermsAndConditions();
  }

  @action
  setAccept(accept: boolean) {

    this._accept = accept;
  }

  @action
  private setTermsAndConditions(termsAndConditions: string) {

    this._termsAndConditions = termsAndConditions;
  }

  get termsAndConditions() {

    return this._termsAndConditions;
  }

  get accept() {

    return this._accept;
  }
}
