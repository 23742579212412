import { Injectable } from '@angular/core';

import { BaseAppProperties, PortalType } from 'common-lib';

@Injectable({
  providedIn: 'root'
})
export class AppProperties extends BaseAppProperties {

  protected getPortalType(): PortalType {

    return PortalType.Owner;
  }
}
