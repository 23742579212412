import { Message as PrimeNGMessage } from 'primeng/api';

export enum MessageSeverity {
  Info = 'info',
  Success = 'success',
  Warn = 'warn',
  Error = 'error'
}

export interface Message extends Readonly<PrimeNGMessage> {
  readonly severity: MessageSeverity;
  // @ts-ignore
  readonly key?: never;
}
