<div *mobxAutorun>
  <div class="p-grid">
    <div class="p-col-12">
      <app-info-message message="Select the relevant property."></app-info-message>
    </div>
    <div class="p-col-12">
      <app-table selectionMode="single" [dataKey]="ownerUnitSummaryPropertyUnitId" [showCaption]="showHeader"
        [value]="store.units" [selection]="store.selectedUnit" (selectionChange)="store.setSelectedUnit($event)"
        (rowSelect)="onUnitSelected()" (rowUnselect)="onUnitUnselected()">
        <ng-template pTemplate="caption">Selected {{localisationStore.unitLabel}} - {{store.selectedUnitTitle}}
        </ng-template>
        <ng-template pTemplate="header">
          <tr appTableRow>
            <th [appTableHeader]="ownerUnitSummaryPropertyState" title="state" [filter]="TableHeaderFilterType.Input"
              pSortableColumn></th>
            <th [appTableHeader]="ownerUnitSummaryPropertyStrataName" title="plan"
              [filter]="TableHeaderFilterType.Input" pSortableColumn></th>
            <th [appTableHeader]="ownerUnitSummaryPropertyBuilding" title="building"
              [filter]="TableHeaderFilterType.Input" pSortableColumn></th>
            <th [appTableHeader]="ownerUnitSummaryPropertyUnitNumber" title="{{localisationStore.unitLabel}} #"
              [filter]="TableHeaderFilterType.Input" pSortableColumn></th>
            <th [appTableHeader]="ownerUnitSummaryPropertyFinancialsBalanceOwingValue" title="balance" pSortableColumn>
            </th>
            <th [appTableHeader]="ownerUnitSummaryPropertyFinancialsReminders" title="reminders"
              [filter]="TableHeaderFilterType.Input" pSortableColumn>
            </th>
            <th appTableHeader></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-unit>
          <tr appTableRow [pSelectableRow]="unit">
            <td appTableData>{{getUnitState(unit)}}</td>
            <td appTableData>{{getUnitStrataName(unit)}}</td>
            <td appTableData>{{getUnitBuilding(unit)}}</td>
            <td appTableData>{{getUnitNumber(unit)}}</td>
            <td appTableData>
              <div appSpinnerContainer>
                <span>{{getUnitFinancials(unit)?.balanceOwing | currencyFormat}}</span>
                <app-spinner [show]="store.showUnitFinancialsDataSpinner(unit)" [alphaBackground]="false"></app-spinner>
              </div>
            </td>
            <td appTableData>
              <div appSpinnerContainer>
                <span>{{getUnitFinancials(unit)?.reminders}}</span>
                <app-spinner [show]="store.showUnitFinancialsDataSpinner(unit)" [alphaBackground]="false"></app-spinner>
              </div>
            </td>
            <td appTableData>
              <app-button-container [containerBottomMargin]="false">
                <button pButton type="button" label="home" icon="fas fa-bars" (click)="onHome(unit)"></button>
                <button *ngIf="store.showPayBill(unit)" pButton type="button" label="pay bill" icon="fas fa-credit-card"
                  (click)="onPayBill(unit)"></button>
                <button pButton type="button" label="invoices" icon="fas fa-file-pdf"
                  (click)="onInvoices(unit)"></button>
                <button pButton type="button" label="contact" icon="fas fa-phone" (click)="onContact(unit)"></button>
              </app-button-container>
            </td>
          </tr>
        </ng-template>
      </app-table>
    </div>
  </div>
</div>
