/**
 * Whittles API
 * Whittles API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type TaskCategory = 'AGM_PROCESS' | 'APPROVE_INVOICE' | 'JOURNAL_PROCESS';

export const TaskCategory = {
    AGMPROCESS: 'AGM_PROCESS' as TaskCategory,
    APPROVEINVOICE: 'APPROVE_INVOICE' as TaskCategory,
    JOURNALPROCESS: 'JOURNAL_PROCESS' as TaskCategory
};
