<div *mobxAutorun appSpinnerContainer class="ui-fluid">
  <form class="p-grid form-group" [formGroup]="processNotesForm" (ngSubmit)="onProcessNotesFormSubmit()">
    <div class="p-col-12 p-md-4">
      <label>Target</label>
    </div>
    <div class="p-col-12 p-md-8">
      <span class="md-inputfield">
        <p-dropdown placeholder="select target" [dataKey]="nameValuePairPropertyValue"
          [optionLabel]="nameValuePairPropertyName" [options]="store.processNotesTargets" [appendTo]="appendTo"
          [formControl]="processNoteTarget" [readonly]="!isEditable">
        </p-dropdown>
        <app-form-error *ngIf="processNoteTarget.touched" [form]="processNotesForm" [control]="processNoteTarget"></app-form-error>
      </span>
    </div>
    <div class="p-col-12">
      <span class="md-inputfield">
        <textarea pInputTextarea placeholder="Enter Process Notes" [rows]="10" [formControl]="processNoteText"
          [readOnly]="!isEditable"></textarea>
        <app-form-error *ngIf="processNoteText.touched" [form]="processNotesForm" [control]="processNoteText"></app-form-error>
      </span>
    </div>
    <div *ngIf="editable" class="p-col-12" style="text-align: center">
      <app-content-container layoutAlign="center center">
        <button pButton type="submit" [kind]="ButtonKind.Save" [disabled]="!isSubmitButtonEnabled"></button>
        <button pButton type="button" [kind]="ButtonKind.Clear" (click)="onNewClick()"></button>
      </app-content-container>
    </div>
  </form>
  <div class="p-grid form-group">
    <div class="p-col-12">
      <app-table selectionMode="single" [value]="store.processNotes" [selection]="store.selectedProcessNote" [rows]="5"
        (selectionChange)="store.setSelectedProcessNote($event)" [paginator]="paginator">
        <ng-template pTemplate="caption">Process Notes</ng-template>
        <ng-template pTemplate="header">
          <tr appTableRow>
            <th [appTableHeader]="processNotePropertyCreatedDate" title="date created"
              [filter]="TableHeaderFilterType.Input" pSortableColumn>
            <th [appTableHeader]="processNotePropertyText" title="note" [filter]="TableHeaderFilterType.Input"
              pSortableColumn>
            <th [appTableHeader]="processNotePropertyCreatedByFullName" title="created by"
              [filter]="TableHeaderFilterType.Input" pSortableColumn>
            <th [appTableHeader]="processNotePropertyTargetName" title="target" [filter]="TableHeaderFilterType.Input"
              pSortableColumn>
            <th *ngIf="editable" appTableHeader></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-note>
          <tr appTableRow [pSelectableRow]="note">
            <td appTableData>{{getCreatedDate(note) | dateFormat}}</td>
            <td appTableData>{{getText(note)}}</td>
            <td appTableData>{{getCreatedBy(note)}}</td>
            <td appTableData>{{getTarget(note)}}</td>
            <td *ngIf="editable">
              <app-content-container layoutAlign="center center">
                <button pButton type="button" [kind]="ButtonKind.Delete" [forTableColumn]="true" class="clear-round-button"
                  (click)="onDeleteClick(note)" [class.wht-visibility-hidden]="!store.isNoteEditable(note)"
                  [disabled]="!store.isNoteEditable(note)"></button>
              </app-content-container>
            </td>
          </tr>
        </ng-template>
      </app-table>
    </div>
  </div>
  <app-spinner [alphaBackground]="spinnerAlphaBackground" [show]="store.spinnerVisible" [size]="SpinnerSize.Default">
  </app-spinner>
</div>

<ng-template #crudTpl>
  <div class="ui-fluid p-grid form-group">
    <div class="p-col-12 p-md-4">
      <label>Note</label>
    </div>
    <div class="p-col-12 p-md-8">
      {{crudDialogNoteText}}
    </div>
    <div class="p-col-12 p-md-4">
      <label>Target</label>
    </div>
    <div class="p-col-12 p-md-8">
      {{crudDialogNoteTarget}}
    </div>
  </div>
  <div appDialogFooter>
    <button pButton [kind]="ButtonKind.Ok" type="button" (click)="onDialokOk()"></button>
    <button pButton [kind]="ButtonKind.Cancel" type="button" (click)="onDialogCancel()"></button>
  </div>
</ng-template>
