import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  EmptyComponent,
  LoginAuthGuard,
  RoutePath as CommonRoutePath
} from 'common-lib';

import { InitComponent } from './components/init/init.component';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RoutePath } from './route-path';
import { ChildAuthGuard } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [ChildAuthGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: RoutePath.CommitteeApproval,
        loadChildren: () => import('./modules/committee-approval/committee-approval.module').then(m => m.CommitteeApprovalModule)
      },
      {
        path: RoutePath.Home,
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
      },
      {
        path: RoutePath.SelectUnit,
        loadChildren: () => import('./modules/select-unit/select-unit.module').then(m => m.SelectUnitModule)
      },
      {
        path: RoutePath.SelectBuilding,
        loadChildren: () => import('./modules/select-building/select-building.module').then(m => m.SelectBuildingModule)
      },
      {
        path: RoutePath.Profile,
        loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: RoutePath.Contact,
        loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule)
      },
      {
        path: RoutePath.PlanInfo,
        loadChildren: () => import('./modules/plan-info/plan-info.module').then(m => m.PlanInfoModule)
      },
      {
        path: RoutePath.PlanSummary,
        loadChildren: () => import('./modules/plan-summary/plan-summary.module').then(m => m.PlanSummaryModule)
      },
      {
        path: RoutePath.MakePayment,
        loadChildren: () => import('./modules/makepayment/makepayment.module').then(m => m.MakepaymentModule)
      },
      {
        path: RoutePath.AccountHistory,
        loadChildren: () => import('./modules/makepayment/makepayment.module').then(m => m.MakepaymentModule)
      },
      {
        path: RoutePath.Invoices,
        loadChildren: () => import('./modules/invoices/invoices.module').then(m => m.InvoicesModule)
      },
      {
        path: RoutePath.Documents,
        loadChildren: () => import('./modules/documents/documents.module').then(m => m.DocumentsModule)
      },
      {
        path: RoutePath.FinancialRecords,
        loadChildren: () => import('./modules/financial-records/financial-records.module').then(m => m.FinancialRecordsModule)
      },
      {
        path: RoutePath.FinancialTransactions,
        loadChildren: () => import('./modules/financial-transactions/financial-transactions.module').then(m => m.FinancialTransactionsModule)
      },
      {
        path: '',
        component: InitComponent,
        pathMatch: 'full'
      }
    ]
  },
  {
    path: CommonRoutePath.Login,
    component: LoginComponent,
    canActivate: [LoginAuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: CommonRoutePath.Refresh,
    component: EmptyComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'top',
    relativeLinkResolution: 'corrected'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
