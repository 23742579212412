<div *mobxAutorun class="ui-fluid login-panel wht-login-panel container">
  <app-info-message message="Please set your password."></app-info-message>
  <form [formGroup]="passwordForm" class="p-grid" (ngSubmit)="onSubmit()">
    <div class="p-col-12 p-mt-3">
      <span class="p-float-label">
        <input class="p-w-100" type="password" pInputText [formControl]="newPassword">
        <label class="p-ml-1 p-grid p-jc-start p-label">Password</label>
        <app-form-error [form]="passwordForm" [control]="newPassword" [untouched]="true"></app-form-error>
      </span>
    </div>
    <div class="p-col-12">
      <span class="p-float-label">
        <input class="p-w-100" type="password" pInputText [formControl]="repeatNewPassword">
        <label class="p-ml-1 p-grid p-jc-start p-label">Repeat password</label>
        <app-form-error [form]="passwordForm" [control]="repeatNewPassword" [untouched]="true"></app-form-error>
      </span>
    </div>
    <div class="p-col-12">
      <app-password-strength [password]="store.passwordFormModel.newPassword"></app-password-strength>
    </div>
    <div class="p-col-12" style="text-align: center">
      <button pButton type="submit" label="set password" icon="ui-icon-check" [disabled]="!passwordForm.valid"></button>
      <span class="p-float-label">
        <app-form-error [positionStatic]="true" [form]="passwordForm" [control]="passwordForm"
          [fontSize]="FormErrorFontSize.Normal">
        </app-form-error>
      </span>
    </div>
  </form>
</div>
