import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { centsToInputDollars, inputDollarsToCents } from '../../utils/currency.utils';
import { BaseComponent } from '../base/base.component';
type ReadonlyInput = true | undefined;
@Component({
  selector: 'app-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: CurrencyInputComponent,
    multi: true
  }]
})
export class CurrencyInputComponent extends BaseComponent implements ControlValueAccessor, OnInit {

  private onChange?: (_: any) => void;
  private onTouched?: () => void;

  private _dollars = '';

  @Input() inputStyle?: { [key: string]: string; };
  @Input() readOnly?: ReadonlyInput;
  @Input() allowNegative = false;

  @Output() readonly focus = new EventEmitter<FocusEvent>();
  @Output() readonly blur = new EventEmitter<FocusEvent>();

  disabled = false;

  constructor(private readonly cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
  }

  writeValue(obj: any): void {

    this._dollars = centsToInputDollars(obj);

    this.cdr.markForCheck();
  }

  registerOnChange(fn: (_: any) => void): void {

    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {

    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {

    this.disabled = disabled;

    this.cdr.markForCheck();
  }

  onBlur(evt: FocusEvent) {

    // Converting from dollars -> cents -> dollars should clean up any leading zeros, trailing decimals etc.
    let cents = inputDollarsToCents(this._dollars);

    if (Number.isNaN(cents)) {

      cents = 0;
    }

    this._dollars = centsToInputDollars(cents);

    if (this.onChange) {

      this.onChange(cents);
    }

    this.blur.emit(evt);

    if (this.onTouched) {

      this.onTouched();
    }
  }

  onFocus(evt: FocusEvent) {

    this.focus.emit(evt);
  }

  get dollars() {

    return this._dollars;
  }

  set dollars(value: string) {

    this._dollars = value;

    if (!this.onChange) {

      return;
    }

    const cents = inputDollarsToCents(this._dollars);

    this.onChange(cents);
  }
}
