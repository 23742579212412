/**
 * Whittles API
 * Whittles API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type NoteType = 'CALL_LOG' | 'COMPLIANCE' | 'GENERAL' | 'IN_DISPUTE' | 'LEGAL_HANDS' | 'PAYMENT_PLAN' | 'PAYMENT_PLAN_WITH_FEE_NOTICE' | 'SERVICE_CONTRACT' | 'ST' | 'SYSTEM';

export const NoteType = {
    CALLLOG: 'CALL_LOG' as NoteType,
    COMPLIANCE: 'COMPLIANCE' as NoteType,
    GENERAL: 'GENERAL' as NoteType,
    INDISPUTE: 'IN_DISPUTE' as NoteType,
    LEGALHANDS: 'LEGAL_HANDS' as NoteType,
    PAYMENTPLAN: 'PAYMENT_PLAN' as NoteType,
    PAYMENTPLANWITHFEENOTICE: 'PAYMENT_PLAN_WITH_FEE_NOTICE' as NoteType,
    SERVICECONTRACT: 'SERVICE_CONTRACT' as NoteType,
    ST: 'ST' as NoteType,
    SYSTEM: 'SYSTEM' as NoteType
};
