import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
    } from '@angular/core';
import { DropdownManageableItem } from '../../models/dropdown-manageable-filter-item';
import { Table } from 'primeng/table';
import { TreeTable } from 'primeng/treetable';
import { BaseComponent } from '../base/base.component';
import { FilterMetadata } from 'primeng/api';
import { Subject } from 'rxjs';
import { tap, debounceTime } from 'rxjs/operators';
import { Dropdown } from 'primeng/dropdown';

@Component({
    selector: 'app-dropdown-manageable-filter',
    templateUrl: './dropdown-manageable-filter.component.html',
    styleUrls: ['./dropdown-manageable-filter.component.scss'],
})
export class DropdownManageableFilter extends BaseComponent implements OnInit, OnChanges {

    @Input() table?: Table;
    @Input() treeTable?: TreeTable;
    @Input() field: string;

    @Input() totalItems?: number;
    @Input() items: DropdownManageableItem[] = [];
    @Input() item?: DropdownManageableItem;
    @Input() placeholder: string = "Select";
    @Input() selectedKey: string = "value";
    @Input() virtualScroll: boolean = true;
    @Input() isContinuous: boolean = false;
    @Input() filterString?: string;
    @Input() filter = false;
    @Input() dynamicLoading: boolean = true;
    @Input() showButtons: boolean = true;
    @Input() disabled: boolean = false;

    @Output() readonly selected = new EventEmitter<DropdownManageableItem>();
    @Output() readonly clear = new EventEmitter();
    @Output() readonly onHide = new EventEmitter();
    @Output() readonly customFilterValueChange = new EventEmitter();
    @Output() readonly addItem = new EventEmitter();
    @Output() readonly updateItem = new EventEmitter();
    @Output() readonly loadNext = new EventEmitter();

    selectedItem?: DropdownManageableItem;

    filterString$: Subject<string> = new Subject<string>();

    @ViewChild('manageableDropdown') dropdown: Dropdown;

    readonly pagenationLoadNumber = 30;
    readonly intersectionVisibleNumber = 40;

    ngOnInit(): void {
        if (this.item) {
            this.selectedItem = this.item
        }
        this.subscribe(this.filterString$
            .pipe(
                debounceTime(500),
                tap(() => this.loadNext.emit(this.filterString))
            ))
        
    }

    showOptionsPanel() {
        if (this.dropdown) {
            // @ts-ignore
            this.dropdown.el.nativeElement.firstChild.click()
        }
    }

    ngOnChanges(): void {
        if (this.item) {
            this.selectedItem = this.item;
        }
    }

    ngAfterViewInit(): void {

        this.restoreFilterState();
    }

    refresh() {

        //this.restoreFilterState();
    }

    addNewItem() {
        this.addItem.emit();
    }

    editItem(item: DropdownManageableItem) {
        this.updateItem.emit(item);
    }

    selectedChange(item?: any) {

        // this.selectedItem = item;
        this.item = item.value;

        this.selected.emit(item.value);
        if (this.table) {
            if (item.value) {
                // @ts-ignore
                const value = item.value[this.selectedKey];
                this.table.filter(value, this.field, 'contains');
            } else {
                this.table.filter("", this.field, 'contains');
            }
        }

    }

    clicked(e: Event) {

        e.stopPropagation();
    }

    onClear(e: Event) {
        this.item = undefined;
        this.selectedItem = undefined;

        this.clear.emit();
        e.stopPropagation();
    }

    private getFilterMetadata(): FilterMetadata | FilterMetadata[] {

        if (this.table) {
            return this.table.filters[this.field];
        }

        if (this.treeTable) {

            return this.treeTable.filters[this.field];
        }
        return {};
    }

    private restoreFilterState() {

        const filterMetadata = this.getFilterMetadata();

        let filterValue: any;
        if (!Array.isArray(filterMetadata)) {
            filterValue = filterMetadata && filterMetadata.value;
        } else {
            filterValue = filterMetadata.map(f => f.value!);
        }

        // this.cdr.detectChanges();
    }

    get appendTo() {

        return this.table || this.treeTable || 'body';
    }

    onFilter(e: any) {

        let ulElement = null;
        if (e.originalEvent != null) {
            // original filter input box option list index is 4
            ulElement = e.originalEvent.target.parentElement.parentElement.parentElement.childNodes[4];
        } else {
            // custom filter input box childNodes index 3 is the scrollable container
            ulElement = e.target.parentElement.parentElement.parentElement.parentElement.parentElement.childNodes[3];
        }
        if (ulElement) {
            ulElement.scrollTop = 0;
        }

        if (this.virtualScroll) return;
        this.filterString = e.originalEvent?.target.value ?? e.target.value;

        this.filterString$.next(this.filterString);
    }

    shouldEmit(item: DropdownManageableItem) {
        if (!this.dynamicLoading) return false;
        if (this.totalItems && this.totalItems <= this.pagenationLoadNumber) {
            
            return false;
        } else {
            const index = this.items.indexOf(item);
            const totalItems = this.items.length;
    
            return index + this.intersectionVisibleNumber > totalItems;
        }
    }

    isIntersecting(status: boolean) {
        if (!status) return;
        if (status) { this.loadNext.emit(this.filterString); }
    }

    onHidden() {
        this.onHide.emit();
    }
}
