import { Logger } from '../services/logger.service';

import { ServiceLocator } from './service-locator.utils';

enum StorageType {
  Local,
  Session
}

export interface StorageParams {
  base64Encoded?: boolean;
}

function isBase64Encoded(params?: StorageParams): boolean {

  return !params || params.base64Encoded == null || params.base64Encoded;
}

function getStorage(storageType: StorageType): Storage | undefined {

  const logger = ServiceLocator.injector.get<Logger>(Logger);

  try {

    switch (storageType) {

      case StorageType.Local:

        return localStorage;

      case StorageType.Session:

        return sessionStorage;

      default:

        logger.warn(`getStorage: Unknown storage type "${storageType}"`);

        return undefined;
    }

  } catch (e) {

    logger.warn(`getStorage: Could not get "${storageType}"`, e);

    return undefined;
  }
}

function getFromStorage<T>(key: string, storageType: StorageType, params?: StorageParams): T | undefined {

  const logger = ServiceLocator.injector.get<Logger>(Logger);

  try {

    const storage = getStorage(storageType);

    const item = storage && storage.getItem(key);

    if (item == null) {

      return undefined;
    }

    if (isBase64Encoded(params)) {

      return JSON.parse(atob(item));
    }

    return JSON.parse(item);

  } catch (e) {

    logger.warn(`getFromStorage: Could not get "${key}" from ${storageType} storage`, e);

    const storage = getStorage(storageType);

    if (storage) {

      storage.removeItem(key);
    }

    return undefined;
  }
}

function setInStorage<T>(key: string, data: T, storageType: StorageType, params?: StorageParams) {

  const storage = getStorage(storageType);

  if (!storage) {

    return;
  }

  if (isBase64Encoded(params)) {

    storage.setItem(key, btoa(JSON.stringify(data)));

  } else {

    storage.setItem(key, JSON.stringify(data));
  }
}

export function getFromLocalStorage<T>(key: string, params?: StorageParams): T | undefined {

  return getFromStorage(key, StorageType.Local, params);
}

export function setInLocalStorage<T>(key: string, data: T, params?: StorageParams) {

  setInStorage(key, data, StorageType.Local, params);
}

export function getFromSessionStorage<T>(key: string, params?: StorageParams): T | undefined {

  return getFromStorage(key, StorageType.Session, params);
}

export function setInSessionStorage<T>(key: string, data: T, params?: StorageParams) {

  setInStorage(key, data, StorageType.Session, params);
}

export function dropFromLocalStorage(key: string) {

  const storage = getStorage(StorageType.Local);

  if (storage) {

    storage.removeItem(key);
  }
}
