import { outputAst } from '@angular/compiler';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import { SortableColumn, Table } from 'primeng/table';
import { TreeTable, TTSortableColumn } from 'primeng/treetable';
import { Subject } from 'rxjs';

import { BaseAppProperties } from '../../base-app-properties';
import { PortalType } from '../../models/portal-type';
import { CustomTableFilterValue, TableFilterEvent } from '../../models/table';
import { BaseComponent } from '../base/base.component';
import { DropdownFilterComponent } from '../dropdown-filter/dropdown-filter.component';
import { DropdownManageableFilter } from '../dropdown-manageable-filter/dropdown-manageable-filter.component';
import { InputFilterComponent } from '../input-filter/input-filter.component';
import { HeaderInputFilterComponent } from '../header-input-filter/header-input-filter.component';

export enum TableHeaderFilterType {
  None,
  Input,
  Dropdown,
  MultiSelect,
  ManageableDropdown,
  DebounceInput,
  PopupSelect,
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'th[appTableHeader]',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableHeaderComponent extends BaseComponent implements OnInit {

  private _field = '';

  readonly TableHeaderFilterType = TableHeaderFilterType;

  @ViewChild(InputFilterComponent) readonly inputFilterComponent?: InputFilterComponent;
  @ViewChild(DropdownFilterComponent) readonly dropdownFilterComponent?: DropdownFilterComponent;
  @ViewChild(DropdownManageableFilter) readonly dropdownManageableFilter?: DropdownManageableFilter;
  @ViewChild(HeaderInputFilterComponent) readonly headerInputFilterComponent?: HeaderInputFilterComponent;

  constructor(
    private readonly appProperties: BaseAppProperties,
    private readonly cdr: ChangeDetectorRef,
    @Optional() readonly table?: Table,
    @Optional() private readonly sortableColumn?: SortableColumn,
    @Optional() readonly treeTable?: TreeTable,
    @Optional() private readonly ttSortableColumn?: TTSortableColumn) {

    super();
  }

  @Input()
  @HostBinding('class.wht-table-button-header') buttonHeader = false;

  @Input()
  @HostBinding('class.wht-table-checkbox-header') checkboxHeader = false;

  // tslint:disable-next-line: no-input-rename
  @Input('appTableHeader') set field(val: string) {

    this._field = val;

    if (this.sortableColumn) {

      this.sortableColumn.field = this._field;
    }

    if (this.ttSortableColumn) {

      this.ttSortableColumn.field = this._field;
    }
  }

  get field() {

    return this._field;
  }

  @Input() title = '';
  @Input() titleNoWrap = this.appProperties.portalType === PortalType.Owner;
  @Input() filter = TableHeaderFilterType.None;
  @Input() filterOptions: any[] = [];
  @Input() selectSingleFilteredTableRowOnEnter = false;
  @Input() tableFilterEvent?: TableFilterEvent;
  @Input() customFilter = false;
  @Input() customFilterValueChange?: Subject<CustomTableFilterValue>;
  @Input() filterValueChange?: Subject<string>;
  @Input() appendFilterTo: unknown;
  @Input() selectedKey = "value";
  @Input() virtualScroll = true;
  @Input() isContinuous = false;
  @Input() filterString? : string;
  @Input() filterDropDown = false;
  @Input() placeholder: string = "Select";
  @Input() defaultLabel: string = "All";
  @Input() showManageButtons = true;
  @Input() readonly = false;
  @Input() showInputButton = false;
  @Input() inputButtonIcon = '';
  @Input() inputButtonTooltip = '';
  @Input() inputButtonLabel = '';
  @Output() loadNext = new EventEmitter();
  @Output() addItem = new EventEmitter();
  @Output() updateItem = new EventEmitter();
  @Output() clear = new EventEmitter();
  @Output() selected = new EventEmitter();
  @Output() popupSelectClick = new EventEmitter();
  @Output() inputButtonClicked = new EventEmitter<string | undefined>();

  ngOnInit(): void {
  }

  refresh() {

    this.cdr.detectChanges();

    if (this.inputFilterComponent) {

      this.inputFilterComponent.refresh();
    }

    if (this.dropdownFilterComponent) {

      this.dropdownFilterComponent.refresh();
    }

    if (this.dropdownManageableFilter) {
      this.dropdownManageableFilter.refresh();
    }
  }

  onCustomFilterValueChange(e: string | string[]) {
    if (this.customFilterValueChange) {

      this.customFilterValueChange.next({
        value: e,
        field: this._field
      });
    }
  }

  onInputValueChanged(e: string | undefined) {
    this.filterValueChange?.next(e);
  }

  addNewItem() {
    this.addItem.emit();
  }

  editItem(item: any) {
    this.updateItem.emit(item);
  }

  loadMore(filterString: string) {
    this.loadNext.emit(filterString);
  }

  clearFilterString() {
    this.clear.emit();
  }

  selectedItem(item: any) {
    this.selected.emit(item);
  }

  get isTableSort(): boolean {

    return this.sortableColumn != null && this.sortableColumn.isEnabled();
  }

  get isTreeTableSort(): boolean {

    return this.ttSortableColumn != null && this.ttSortableColumn.isEnabled();
  }

  get isInputFilterVisible(): boolean {

    return this.filter === TableHeaderFilterType.Input;
  }

  get isDropDownFilterVisible(): boolean {

    return this.filter === TableHeaderFilterType.Dropdown || this.multiSelect;
  }

  get multiSelect(): boolean {

    return this.filter === TableHeaderFilterType.MultiSelect;
  }

  get isManageableDropdownVisable(): boolean {

    return this.filter === TableHeaderFilterType.ManageableDropdown;
  }

  get isHeaderInputFilterVisible(): boolean {

    return this.filter === TableHeaderFilterType.DebounceInput;
  }

  get isPopupSelectVisible(): boolean {

    return this.filter === TableHeaderFilterType.PopupSelect;
  }
}
