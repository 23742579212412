/**
 * Whittles API
 * Whittles API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddPlanAccessParams } from '../model/addPlanAccessParams';
import { AdditionalCharge } from '../model/additionalCharge';
import { BatchCreateContributionParams } from '../model/batchCreateContributionParams';
import { CreateAdditionalChargeParams } from '../model/createAdditionalChargeParams';
import { DeletePlanAccessParams } from '../model/deletePlanAccessParams';
import { Fund } from '../model/fund';
import { Meeting } from '../model/meeting';
import { MeetingSummary } from '../model/meetingSummary';
import { MeetingSummaryAction } from '../model/meetingSummaryAction';
import { MeetingSummaryContribution } from '../model/meetingSummaryContribution';
import { MeetingSummaryContributionParams } from '../model/meetingSummaryContributionParams';
import { MeetingSummaryParams } from '../model/meetingSummaryParams';
import { MeetingSummaryStatusParams } from '../model/meetingSummaryStatusParams';
import { NameValuePair } from '../model/nameValuePair';
import { Owner } from '../model/owner';
import { OwnerPlanSummaryDetails } from '../model/ownerPlanSummaryDetails';
import { PlanEvent } from '../model/planEvent';
import { PortalApprover } from '../model/portalApprover';
import { SmsDetails } from '../model/smsDetails';
import { SmsHistory } from '../model/smsHistory';
import { SmsOwnerParams } from '../model/smsOwnerParams';
import { SmsOwnerResult } from '../model/smsOwnerResult';
import { StrataCommitteeApproverDetails } from '../model/strataCommitteeApproverDetails';
import { StrataPlanDetails } from '../model/strataPlanDetails';
import { SubmitAdditionalChargeParams } from '../model/submitAdditionalChargeParams';
import { SubmitCommitteeDetailsParams } from '../model/submitCommitteeDetailsParams';
import { Sundry } from '../model/sundry';
import { Tenant } from '../model/tenant';
import { Unit } from '../model/unit';
import { UnitEPaymentDetails } from '../model/unitEPaymentDetails';
import { UnitPaymentRecord } from '../model/unitPaymentRecord';
import { UnitSummary } from '../model/unitSummary';
import { UpdateAdditionalChargeParams } from '../model/updateAdditionalChargeParams';
import { UpdatePlanAccessUserParams } from '../model/updatePlanAccessUserParams';
import { User } from '../model/user';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class StrataService {

    protected basePath = 'https://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param addPlanAccessParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addPlanAccess(addPlanAccessParams: AddPlanAccessParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addPlanAccess(addPlanAccessParams: AddPlanAccessParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addPlanAccess(addPlanAccessParams: AddPlanAccessParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addPlanAccess(addPlanAccessParams: AddPlanAccessParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (addPlanAccessParams === null || addPlanAccessParams === undefined) {
            throw new Error('Required parameter addPlanAccessParams was null or undefined when calling addPlanAccess.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/strata/add-plan-access`,
            addPlanAccessParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param batchCreateContributionParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public batchMeetingSummaryContributions(batchCreateContributionParams: BatchCreateContributionParams, observe?: 'body', reportProgress?: boolean): Observable<Array<MeetingSummaryContribution>>;
    public batchMeetingSummaryContributions(batchCreateContributionParams: BatchCreateContributionParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MeetingSummaryContribution>>>;
    public batchMeetingSummaryContributions(batchCreateContributionParams: BatchCreateContributionParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MeetingSummaryContribution>>>;
    public batchMeetingSummaryContributions(batchCreateContributionParams: BatchCreateContributionParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (batchCreateContributionParams === null || batchCreateContributionParams === undefined) {
            throw new Error('Required parameter batchCreateContributionParams was null or undefined when calling batchMeetingSummaryContributions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<MeetingSummaryContribution>>(`${this.basePath}/strata/meeting-summary/contribution/batchCreate`,
            batchCreateContributionParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param createAdditionalChargeParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAdditionalCharge(createAdditionalChargeParams: CreateAdditionalChargeParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createAdditionalCharge(createAdditionalChargeParams: CreateAdditionalChargeParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createAdditionalCharge(createAdditionalChargeParams: CreateAdditionalChargeParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createAdditionalCharge(createAdditionalChargeParams: CreateAdditionalChargeParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (createAdditionalChargeParams === null || createAdditionalChargeParams === undefined) {
            throw new Error('Required parameter createAdditionalChargeParams was null or undefined when calling createAdditionalCharge.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/strata/additional-charge`,
            createAdditionalChargeParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param additionalChargeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAdditionalCharge(additionalChargeId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteAdditionalCharge(additionalChargeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteAdditionalCharge(additionalChargeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteAdditionalCharge(additionalChargeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (additionalChargeId === null || additionalChargeId === undefined) {
            throw new Error('Required parameter additionalChargeId was null or undefined when calling deleteAdditionalCharge.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (additionalChargeId !== undefined && additionalChargeId !== null) {
            queryParameters = queryParameters.set('additionalChargeId', <any>additionalChargeId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/strata/additional-charge`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMeetingSummary(id: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteMeetingSummary(id: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteMeetingSummary(id: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteMeetingSummary(id: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteMeetingSummary.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/strata/meeting-summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMeetingSummaryContribution(id: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteMeetingSummaryContribution(id: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteMeetingSummaryContribution(id: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteMeetingSummaryContribution(id: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteMeetingSummaryContribution.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/strata/meeting-summary/contribution`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deletePlanAccessParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePlanAccess(deletePlanAccessParams: DeletePlanAccessParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deletePlanAccess(deletePlanAccessParams: DeletePlanAccessParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deletePlanAccess(deletePlanAccessParams: DeletePlanAccessParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deletePlanAccess(deletePlanAccessParams: DeletePlanAccessParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deletePlanAccessParams === null || deletePlanAccessParams === undefined) {
            throw new Error('Required parameter deletePlanAccessParams was null or undefined when calling deletePlanAccess.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/strata/delete-plan-access`,
            deletePlanAccessParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataId 
     * @param financialYear 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAdditionalCharges(strataId: string, financialYear: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<AdditionalCharge>>;
    public getAdditionalCharges(strataId: string, financialYear: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AdditionalCharge>>>;
    public getAdditionalCharges(strataId: string, financialYear: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AdditionalCharge>>>;
    public getAdditionalCharges(strataId: string, financialYear: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataId === null || strataId === undefined) {
            throw new Error('Required parameter strataId was null or undefined when calling getAdditionalCharges.');
        }

        if (financialYear === null || financialYear === undefined) {
            throw new Error('Required parameter financialYear was null or undefined when calling getAdditionalCharges.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }
        if (financialYear !== undefined && financialYear !== null) {
            queryParameters = queryParameters.set('financialYear', <any>financialYear);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<AdditionalCharge>>(`${this.basePath}/strata/additional-charge`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgreementTypes(showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<Sundry>>;
    public getAgreementTypes(showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Sundry>>>;
    public getAgreementTypes(showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Sundry>>>;
    public getAgreementTypes(showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Sundry>>(`${this.basePath}/strata/meeting-summary/agreementTypes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBuildingSummaries(observe?: 'body', reportProgress?: boolean): Observable<Array<StrataPlanDetails>>;
    public getBuildingSummaries(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StrataPlanDetails>>>;
    public getBuildingSummaries(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StrataPlanDetails>>>;
    public getBuildingSummaries(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<StrataPlanDetails>>(`${this.basePath}/strata/building/summaries`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param managerId 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFutureMeetings(managerId: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<Meeting>>;
    public getFutureMeetings(managerId: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Meeting>>>;
    public getFutureMeetings(managerId: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Meeting>>>;
    public getFutureMeetings(managerId: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (managerId === null || managerId === undefined) {
            throw new Error('Required parameter managerId was null or undefined when calling getFutureMeetings.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (managerId !== undefined && managerId !== null) {
            queryParameters = queryParameters.set('managerId', <any>managerId);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Meeting>>(`${this.basePath}/strata/future-meetings`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataId 
     * @param filter 
     * @param status 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMeetingSummaries(strataId: string, filter: string, status: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<MeetingSummary>>;
    public getMeetingSummaries(strataId: string, filter: string, status: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MeetingSummary>>>;
    public getMeetingSummaries(strataId: string, filter: string, status: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MeetingSummary>>>;
    public getMeetingSummaries(strataId: string, filter: string, status: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataId === null || strataId === undefined) {
            throw new Error('Required parameter strataId was null or undefined when calling getMeetingSummaries.');
        }

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling getMeetingSummaries.');
        }

        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling getMeetingSummaries.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MeetingSummary>>(`${this.basePath}/strata/meeting-summary/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMeetingSummary(id: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<MeetingSummary>;
    public getMeetingSummary(id: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeetingSummary>>;
    public getMeetingSummary(id: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeetingSummary>>;
    public getMeetingSummary(id: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getMeetingSummary.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MeetingSummary>(`${this.basePath}/strata/meeting-summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMeetingSummaryActions(id: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<MeetingSummaryAction>>;
    public getMeetingSummaryActions(id: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MeetingSummaryAction>>>;
    public getMeetingSummaryActions(id: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MeetingSummaryAction>>>;
    public getMeetingSummaryActions(id: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getMeetingSummaryActions.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MeetingSummaryAction>>(`${this.basePath}/strata/meeting-summary/actions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param meetingSummaryId 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMeetingSummaryContributions(meetingSummaryId: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<MeetingSummaryContribution>>;
    public getMeetingSummaryContributions(meetingSummaryId: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MeetingSummaryContribution>>>;
    public getMeetingSummaryContributions(meetingSummaryId: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MeetingSummaryContribution>>>;
    public getMeetingSummaryContributions(meetingSummaryId: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meetingSummaryId === null || meetingSummaryId === undefined) {
            throw new Error('Required parameter meetingSummaryId was null or undefined when calling getMeetingSummaryContributions.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (meetingSummaryId !== undefined && meetingSummaryId !== null) {
            queryParameters = queryParameters.set('meetingSummaryId', <any>meetingSummaryId);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MeetingSummaryContribution>>(`${this.basePath}/strata/meeting-summary/contributions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMeetingSummaryCreatedStatus(strataId?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getMeetingSummaryCreatedStatus(strataId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getMeetingSummaryCreatedStatus(strataId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getMeetingSummaryCreatedStatus(strataId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/strata/meeting-summary/status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataId 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMeetingSummaryDefaults(strataId: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<MeetingSummary>;
    public getMeetingSummaryDefaults(strataId: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeetingSummary>>;
    public getMeetingSummaryDefaults(strataId: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeetingSummary>>;
    public getMeetingSummaryDefaults(strataId: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataId === null || strataId === undefined) {
            throw new Error('Required parameter strataId was null or undefined when calling getMeetingSummaryDefaults.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MeetingSummary>(`${this.basePath}/strata/meeting-summary/defaults`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataId 
     * @param noteType 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNotes(strataId: string, noteType: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getNotes(strataId: string, noteType: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getNotes(strataId: string, noteType: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getNotes(strataId: string, noteType: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataId === null || strataId === undefined) {
            throw new Error('Required parameter strataId was null or undefined when calling getNotes.');
        }

        if (noteType === null || noteType === undefined) {
            throw new Error('Required parameter noteType was null or undefined when calling getNotes.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }
        if (noteType !== undefined && noteType !== null) {
            queryParameters = queryParameters.set('noteType', <any>noteType);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/strata/notes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataId 
     * @param committeeOnly 
     * @param includeExternalCommittee 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOwners(strataId: string, committeeOnly?: boolean, includeExternalCommittee?: boolean, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<Owner>>;
    public getOwners(strataId: string, committeeOnly?: boolean, includeExternalCommittee?: boolean, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Owner>>>;
    public getOwners(strataId: string, committeeOnly?: boolean, includeExternalCommittee?: boolean, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Owner>>>;
    public getOwners(strataId: string, committeeOnly?: boolean, includeExternalCommittee?: boolean, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataId === null || strataId === undefined) {
            throw new Error('Required parameter strataId was null or undefined when calling getOwners.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }
        if (committeeOnly !== undefined && committeeOnly !== null) {
            queryParameters = queryParameters.set('committeeOnly', <any>committeeOnly);
        }
        if (includeExternalCommittee !== undefined && includeExternalCommittee !== null) {
            queryParameters = queryParameters.set('includeExternalCommittee', <any>includeExternalCommittee);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Owner>>(`${this.basePath}/strata/owners`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param portfolioId 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPendingPortfolioCharges(portfolioId: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<AdditionalCharge>>;
    public getPendingPortfolioCharges(portfolioId: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AdditionalCharge>>>;
    public getPendingPortfolioCharges(portfolioId: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AdditionalCharge>>>;
    public getPendingPortfolioCharges(portfolioId: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (portfolioId === null || portfolioId === undefined) {
            throw new Error('Required parameter portfolioId was null or undefined when calling getPendingPortfolioCharges.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (portfolioId !== undefined && portfolioId !== null) {
            queryParameters = queryParameters.set('portfolioId', <any>portfolioId);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<AdditionalCharge>>(`${this.basePath}/strata/pending-portfolio-charge`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPlanAccessUsers(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<User>>;
    public getPlanAccessUsers(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<User>>>;
    public getPlanAccessUsers(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<User>>>;
    public getPlanAccessUsers(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getPlanAccessUsers.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<User>>(`${this.basePath}/strata/${encodeURIComponent(String(id))}/plan-access`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPlanDetails(id: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<StrataPlanDetails>;
    public getPlanDetails(id: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StrataPlanDetails>>;
    public getPlanDetails(id: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StrataPlanDetails>>;
    public getPlanDetails(id: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getPlanDetails.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<StrataPlanDetails>(`${this.basePath}/strata/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataId 
     * @param financialYear 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPlanEvents(strataId: string, financialYear?: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<PlanEvent>>;
    public getPlanEvents(strataId: string, financialYear?: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PlanEvent>>>;
    public getPlanEvents(strataId: string, financialYear?: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PlanEvent>>>;
    public getPlanEvents(strataId: string, financialYear?: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataId === null || strataId === undefined) {
            throw new Error('Required parameter strataId was null or undefined when calling getPlanEvents.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }
        if (financialYear !== undefined && financialYear !== null) {
            queryParameters = queryParameters.set('financialYear', <any>financialYear);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<PlanEvent>>(`${this.basePath}/strata/events`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId 
     * @param managerId 
     * @param strataIds 
     * @param controlAccounts 
     * @param newBusiness 
     * @param bypassCache 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPlanSummaries(companyId?: string, managerId?: string, strataIds?: Array<string>, controlAccounts?: boolean, newBusiness?: boolean, bypassCache?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<StrataPlanDetails>>;
    public getPlanSummaries(companyId?: string, managerId?: string, strataIds?: Array<string>, controlAccounts?: boolean, newBusiness?: boolean, bypassCache?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StrataPlanDetails>>>;
    public getPlanSummaries(companyId?: string, managerId?: string, strataIds?: Array<string>, controlAccounts?: boolean, newBusiness?: boolean, bypassCache?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StrataPlanDetails>>>;
    public getPlanSummaries(companyId?: string, managerId?: string, strataIds?: Array<string>, controlAccounts?: boolean, newBusiness?: boolean, bypassCache?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (companyId !== undefined && companyId !== null) {
            queryParameters = queryParameters.set('companyId', <any>companyId);
        }
        if (managerId !== undefined && managerId !== null) {
            queryParameters = queryParameters.set('managerId', <any>managerId);
        }
        if (strataIds) {
            queryParameters = queryParameters.set('strataIds', strataIds.join(COLLECTION_FORMATS['csv']));
        }
        if (controlAccounts !== undefined && controlAccounts !== null) {
            queryParameters = queryParameters.set('controlAccounts', <any>controlAccounts);
        }
        if (newBusiness !== undefined && newBusiness !== null) {
            queryParameters = queryParameters.set('newBusiness', <any>newBusiness);
        }
        if (bypassCache !== undefined && bypassCache !== null) {
            queryParameters = queryParameters.set('bypassCache', <any>bypassCache);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<StrataPlanDetails>>(`${this.basePath}/strata/plansummaries`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataId 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPlanSummaryDetails(strataId: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<OwnerPlanSummaryDetails>;
    public getPlanSummaryDetails(strataId: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OwnerPlanSummaryDetails>>;
    public getPlanSummaryDetails(strataId: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OwnerPlanSummaryDetails>>;
    public getPlanSummaryDetails(strataId: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataId === null || strataId === undefined) {
            throw new Error('Required parameter strataId was null or undefined when calling getPlanSummaryDetails.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OwnerPlanSummaryDetails>(`${this.basePath}/strata/plansummary/details`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataId 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPlanSummarySummary(strataId: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<OwnerPlanSummaryDetails>;
    public getPlanSummarySummary(strataId: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OwnerPlanSummaryDetails>>;
    public getPlanSummarySummary(strataId: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OwnerPlanSummaryDetails>>;
    public getPlanSummarySummary(strataId: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataId === null || strataId === undefined) {
            throw new Error('Required parameter strataId was null or undefined when calling getPlanSummarySummary.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OwnerPlanSummaryDetails>(`${this.basePath}/strata/plansummary/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSmsDetails(strataId?: string, observe?: 'body', reportProgress?: boolean): Observable<SmsDetails>;
    public getSmsDetails(strataId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmsDetails>>;
    public getSmsDetails(strataId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmsDetails>>;
    public getSmsDetails(strataId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SmsDetails>(`${this.basePath}/strata/sms/details`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSmsDetails_1(observe?: 'body', reportProgress?: boolean): Observable<SmsDetails>;
    public getSmsDetails_1(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmsDetails>>;
    public getSmsDetails_1(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmsDetails>>;
    public getSmsDetails_1(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SmsDetails>(`${this.basePath}/sms/details`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataId 
     * @param financialYearId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSmsHistory(strataId: string, financialYearId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SmsHistory>>;
    public getSmsHistory(strataId: string, financialYearId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SmsHistory>>>;
    public getSmsHistory(strataId: string, financialYearId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SmsHistory>>>;
    public getSmsHistory(strataId: string, financialYearId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataId === null || strataId === undefined) {
            throw new Error('Required parameter strataId was null or undefined when calling getSmsHistory.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }
        if (financialYearId !== undefined && financialYearId !== null) {
            queryParameters = queryParameters.set('financialYearId', <any>financialYearId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SmsHistory>>(`${this.basePath}/strata/sms/history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSmsTemplates(strataId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<NameValuePair>>;
    public getSmsTemplates(strataId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NameValuePair>>>;
    public getSmsTemplates(strataId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NameValuePair>>>;
    public getSmsTemplates(strataId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataId === null || strataId === undefined) {
            throw new Error('Required parameter strataId was null or undefined when calling getSmsTemplates.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<NameValuePair>>(`${this.basePath}/strata/sms/templates`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrataCommitteeApproverDetails(strataId: string, observe?: 'body', reportProgress?: boolean): Observable<StrataCommitteeApproverDetails>;
    public getStrataCommitteeApproverDetails(strataId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StrataCommitteeApproverDetails>>;
    public getStrataCommitteeApproverDetails(strataId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StrataCommitteeApproverDetails>>;
    public getStrataCommitteeApproverDetails(strataId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataId === null || strataId === undefined) {
            throw new Error('Required parameter strataId was null or undefined when calling getStrataCommitteeApproverDetails.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<StrataCommitteeApproverDetails>(`${this.basePath}/strata/committee/approvers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrataFunds(strataId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Fund>>;
    public getStrataFunds(strataId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Fund>>>;
    public getStrataFunds(strataId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Fund>>>;
    public getStrataFunds(strataId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataId === null || strataId === undefined) {
            throw new Error('Required parameter strataId was null or undefined when calling getStrataFunds.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Fund>>(`${this.basePath}/strata/funds`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataId 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTenants(strataId: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<Tenant>>;
    public getTenants(strataId: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Tenant>>>;
    public getTenants(strataId: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Tenant>>>;
    public getTenants(strataId: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataId === null || strataId === undefined) {
            throw new Error('Required parameter strataId was null or undefined when calling getTenants.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Tenant>>(`${this.basePath}/strata/tenants`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param historyDays 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUnitAccountHistory(historyDays: number, observe?: 'body', reportProgress?: boolean): Observable<Array<UnitPaymentRecord>>;
    public getUnitAccountHistory(historyDays: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UnitPaymentRecord>>>;
    public getUnitAccountHistory(historyDays: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UnitPaymentRecord>>>;
    public getUnitAccountHistory(historyDays: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (historyDays === null || historyDays === undefined) {
            throw new Error('Required parameter historyDays was null or undefined when calling getUnitAccountHistory.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (historyDays !== undefined && historyDays !== null) {
            queryParameters = queryParameters.set('historyDays', <any>historyDays);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<UnitPaymentRecord>>(`${this.basePath}/strata/unit/account-history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUnitEPaymentDetails(observe?: 'body', reportProgress?: boolean): Observable<UnitEPaymentDetails>;
    public getUnitEPaymentDetails(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UnitEPaymentDetails>>;
    public getUnitEPaymentDetails(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UnitEPaymentDetails>>;
    public getUnitEPaymentDetails(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<UnitEPaymentDetails>(`${this.basePath}/strata/unit/epayment/details`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param unitId 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUnitSummary(unitId: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<UnitSummary>;
    public getUnitSummary(unitId: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UnitSummary>>;
    public getUnitSummary(unitId: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UnitSummary>>;
    public getUnitSummary(unitId: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (unitId === null || unitId === undefined) {
            throw new Error('Required parameter unitId was null or undefined when calling getUnitSummary.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (unitId !== undefined && unitId !== null) {
            queryParameters = queryParameters.set('unitId', <any>unitId);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<UnitSummary>(`${this.basePath}/strata/unit/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataId 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUnits(strataId: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<Unit>>;
    public getUnits(strataId: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Unit>>>;
    public getUnits(strataId: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Unit>>>;
    public getUnits(strataId: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataId === null || strataId === undefined) {
            throw new Error('Required parameter strataId was null or undefined when calling getUnits.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Unit>>(`${this.basePath}/strata/units`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataId 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isApprover(strataId: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<PortalApprover>;
    public isApprover(strataId: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PortalApprover>>;
    public isApprover(strataId: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PortalApprover>>;
    public isApprover(strataId: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataId === null || strataId === undefined) {
            throw new Error('Required parameter strataId was null or undefined when calling isApprover.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PortalApprover>(`${this.basePath}/strata/approver`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param smsOwnerParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendSms(smsOwnerParams: SmsOwnerParams, observe?: 'body', reportProgress?: boolean): Observable<Array<SmsOwnerResult>>;
    public sendSms(smsOwnerParams: SmsOwnerParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SmsOwnerResult>>>;
    public sendSms(smsOwnerParams: SmsOwnerParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SmsOwnerResult>>>;
    public sendSms(smsOwnerParams: SmsOwnerParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (smsOwnerParams === null || smsOwnerParams === undefined) {
            throw new Error('Required parameter smsOwnerParams was null or undefined when calling sendSms.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<SmsOwnerResult>>(`${this.basePath}/strata/sms/send`,
            smsOwnerParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check if a address is one of the strata addresses
     * 
     * @param addressID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public strataAddressCheck(addressID: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public strataAddressCheck(addressID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public strataAddressCheck(addressID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public strataAddressCheck(addressID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (addressID === null || addressID === undefined) {
            throw new Error('Required parameter addressID was null or undefined when calling strataAddressCheck.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (addressID !== undefined && addressID !== null) {
            queryParameters = queryParameters.set('addressID', <any>addressID);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<boolean>(`${this.basePath}/strata/strata-address-check`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param submitAdditionalChargeParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public submitAdditionalCharge(submitAdditionalChargeParams: SubmitAdditionalChargeParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public submitAdditionalCharge(submitAdditionalChargeParams: SubmitAdditionalChargeParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public submitAdditionalCharge(submitAdditionalChargeParams: SubmitAdditionalChargeParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public submitAdditionalCharge(submitAdditionalChargeParams: SubmitAdditionalChargeParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (submitAdditionalChargeParams === null || submitAdditionalChargeParams === undefined) {
            throw new Error('Required parameter submitAdditionalChargeParams was null or undefined when calling submitAdditionalCharge.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/strata/submit-additional-charge`,
            submitAdditionalChargeParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param submitCommitteeDetailsParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public submitCommitteeDetails(submitCommitteeDetailsParams: SubmitCommitteeDetailsParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public submitCommitteeDetails(submitCommitteeDetailsParams: SubmitCommitteeDetailsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public submitCommitteeDetails(submitCommitteeDetailsParams: SubmitCommitteeDetailsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public submitCommitteeDetails(submitCommitteeDetailsParams: SubmitCommitteeDetailsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (submitCommitteeDetailsParams === null || submitCommitteeDetailsParams === undefined) {
            throw new Error('Required parameter submitCommitteeDetailsParams was null or undefined when calling submitCommitteeDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/strata/committee/update`,
            submitCommitteeDetailsParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param updateAdditionalChargeParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAdditionalCharge(updateAdditionalChargeParams: UpdateAdditionalChargeParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateAdditionalCharge(updateAdditionalChargeParams: UpdateAdditionalChargeParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateAdditionalCharge(updateAdditionalChargeParams: UpdateAdditionalChargeParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateAdditionalCharge(updateAdditionalChargeParams: UpdateAdditionalChargeParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (updateAdditionalChargeParams === null || updateAdditionalChargeParams === undefined) {
            throw new Error('Required parameter updateAdditionalChargeParams was null or undefined when calling updateAdditionalCharge.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/strata/update-additional-charge`,
            updateAdditionalChargeParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param meetingSummaryParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMeetingSummary(meetingSummaryParams: MeetingSummaryParams, observe?: 'body', reportProgress?: boolean): Observable<MeetingSummary>;
    public updateMeetingSummary(meetingSummaryParams: MeetingSummaryParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeetingSummary>>;
    public updateMeetingSummary(meetingSummaryParams: MeetingSummaryParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeetingSummary>>;
    public updateMeetingSummary(meetingSummaryParams: MeetingSummaryParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meetingSummaryParams === null || meetingSummaryParams === undefined) {
            throw new Error('Required parameter meetingSummaryParams was null or undefined when calling updateMeetingSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MeetingSummary>(`${this.basePath}/strata/meeting-summary`,
            meetingSummaryParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param meetingSummaryContributionParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMeetingSummaryContribution(meetingSummaryContributionParams: MeetingSummaryContributionParams, observe?: 'body', reportProgress?: boolean): Observable<Array<MeetingSummaryContribution>>;
    public updateMeetingSummaryContribution(meetingSummaryContributionParams: MeetingSummaryContributionParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MeetingSummaryContribution>>>;
    public updateMeetingSummaryContribution(meetingSummaryContributionParams: MeetingSummaryContributionParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MeetingSummaryContribution>>>;
    public updateMeetingSummaryContribution(meetingSummaryContributionParams: MeetingSummaryContributionParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meetingSummaryContributionParams === null || meetingSummaryContributionParams === undefined) {
            throw new Error('Required parameter meetingSummaryContributionParams was null or undefined when calling updateMeetingSummaryContribution.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<MeetingSummaryContribution>>(`${this.basePath}/strata/meeting-summary/contribution`,
            meetingSummaryContributionParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param meetingSummaryStatusParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMeetingSummaryStatus(meetingSummaryStatusParams: MeetingSummaryStatusParams, observe?: 'body', reportProgress?: boolean): Observable<MeetingSummary>;
    public updateMeetingSummaryStatus(meetingSummaryStatusParams: MeetingSummaryStatusParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeetingSummary>>;
    public updateMeetingSummaryStatus(meetingSummaryStatusParams: MeetingSummaryStatusParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeetingSummary>>;
    public updateMeetingSummaryStatus(meetingSummaryStatusParams: MeetingSummaryStatusParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meetingSummaryStatusParams === null || meetingSummaryStatusParams === undefined) {
            throw new Error('Required parameter meetingSummaryStatusParams was null or undefined when calling updateMeetingSummaryStatus.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MeetingSummary>(`${this.basePath}/strata/meeting-summary/status`,
            meetingSummaryStatusParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param updatePlanAccessUserParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePlanAccessUser(updatePlanAccessUserParams: UpdatePlanAccessUserParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updatePlanAccessUser(updatePlanAccessUserParams: UpdatePlanAccessUserParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updatePlanAccessUser(updatePlanAccessUserParams: UpdatePlanAccessUserParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updatePlanAccessUser(updatePlanAccessUserParams: UpdatePlanAccessUserParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (updatePlanAccessUserParams === null || updatePlanAccessUserParams === undefined) {
            throw new Error('Required parameter updatePlanAccessUserParams was null or undefined when calling updatePlanAccessUser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/strata/update-plan-access-user`,
            updatePlanAccessUserParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
