import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  BaseComponent,
  BrowserUtils,
  ButtonKind,
  ConfirmationStore,
  DialogOptions,
  DialogStore,
  DialogWidth,
  FormErrorFontSize,
  FormModel,
  LegacyOwnerCredentials,
  Logger,
  LoginType,
  notEmptyValidator,
  parseLoginType,
  PasswordUpdateComponent,
  phoneValidator,
  property,
  switchMapCatch,
  validationError
} from 'common-lib';
import Cookies from 'js-cookie';
import { EMPTY, Subject } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { AppStore } from '../../stores/app.store';
import { AuthenticationStore } from '../../stores/authentication.store';
import { GoogleRecaptchaStore } from '../../stores/google-recaptcha.store';

import { isTempPassword, isValidPassword, LoginMethod, Store } from './login.store';
import {DomainStore} from "../../stores/domain.store";

interface LoginFormModel extends LegacyOwnerCredentials {
  readonly impersonatorUsername: string;
  readonly impersonatorPassword: string;
  readonly mobile: string;
  readonly email: string;
  readonly password: string;
}

interface LoginCodeFormModel {
  code: string;
}

enum QueryParam {
  Impersonate = 'impersonate',
  EmailLogin = 'emailLogin',
  ForgottenPassword = 'forgottenPassword',
  LoginType = 'loginType',
  branch= 'branch'
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Store]
})
export class LoginComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  private static readonly credentialsCookieName = 'whittles.ownerportal.legacy.credentials';

  private readonly submit$ = new Subject();
  private readonly passwordUpdate$ = new Subject<{ password: string }>();
  private readonly getLoginCode$ = new Subject<{ loginCodeTpl: TemplateRef<any> }>();
  private readonly validateLoginCode$ = new Subject();

  @ViewChild('passwordUpdateTpl') private readonly passwordUpdateTpl: TemplateRef<any>;
  @ViewChild('inlineRecaptcha') private readonly inlineRecaptchaRef: ElementRef<HTMLDivElement>;

  private recaptchaWidgetId: RecaptchaWidgetId;

  readonly FormErrorFontSize = FormErrorFontSize;
  readonly LoginType = LoginType;
  readonly LoginMethod = LoginMethod;
  readonly ButtonKind = ButtonKind;
  readonly BrowserUtils = BrowserUtils;

  readonly loginTypeGroupName = 'loginType';

  readonly loginCodeMaxLength = 4;

  loginForm: UntypedFormGroup;

  loginCodeForm: UntypedFormGroup;

  constructor(
    readonly store: Store,
    readonly authenticationStore: AuthenticationStore,
    readonly domainStore: DomainStore,
    private readonly fb: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    private readonly dialogStore: DialogStore,
    private readonly confirmationStore: ConfirmationStore,
    private readonly logger: Logger,
    private readonly googleRecaptchaStore: GoogleRecaptchaStore,
    private readonly appStore: AppStore) {

    super();
  }

  ngOnInit(): void {

    const loginFormModel: FormModel<LoginFormModel> = {
      impersonatorUsername: ['', [notEmptyValidator]],
      impersonatorPassword: ['', [notEmptyValidator]],
      accountNumber: ['', [notEmptyValidator]],
      plan: ['', [notEmptyValidator]],
      unitNumber: ['', [notEmptyValidator]],
      email: ['', [notEmptyValidator, Validators.email]],
      mobile: ['', [notEmptyValidator, phoneValidator]],
      password: ['', [notEmptyValidator, this.passwordValidator]],
    };

    this.loginForm = this.fb.group(loginFormModel, { validators: this.loginFormValidator });

    const loginCodeFormModel: FormModel<LoginCodeFormModel> = {
      code: ['', [notEmptyValidator, Validators.maxLength(this.loginCodeMaxLength)]],
    };

    this.loginCodeForm = this.fb.group(loginCodeFormModel);

    this.subscribe(this.loginForm.valueChanges.pipe(
      tap(() => {

        // Reset loginFailed as we start typing in the form
        this.store.setLoginError(undefined);
      })
    ));

    this.subscribe(this.loginCodeForm.valueChanges.pipe(
      tap(() => {

        // Reset loginCodeInvalid as we start typing in the form
        this.store.setLoginCodeInvalid(false);
      })
    ));

    this.subscribe(this.submit$.pipe(
      switchMap(() => this.googleRecaptchaStore.executeSiteVerify({
        widgetId: this.recaptchaWidgetId,
        action: 'login_FormSubmit'
      })),
      switchMapCatch(args => {

        if (this.store.forgottenPassword) {

          return this.store.passwordResetRequest({
            accountNumber: this.loginFormValue.accountNumber,
            plan: this.loginFormValue.plan,
            unitNumber: this.loginFormValue.unitNumber,
            email: this.loginFormValue.email
          }).pipe(
            catchError(e => {

              this.logger.warn('LoginComponent: store.passwordResetRequest catchError');

              return EMPTY;
            }),
          );

        } else {

          return this.store.validateCredentials({
            identity: {
              impersonatorUsername: this.loginFormValue.impersonatorUsername || undefined,
              accountNumber: this.loginFormValue.accountNumber || undefined,
              plan: this.loginFormValue.plan || undefined,
              unitNumber: this.loginFormValue.unitNumber || undefined,
              email: this.loginFormValue.email || undefined,
              mobile: this.loginFormValue.mobile || undefined
            },
            password: this.loginFormValue.impersonatorPassword || this.loginFormValue.password
          }).pipe(
            tap(response => {

              if (!response.valid || this.store.isImpersonationLogin) {

                return;
              }

              if (isTempPassword(this.loginFormValue.password)) {

                this.store.setLoginError(new Error('Temp Password'));

                PasswordUpdateComponent.showInDialog(this.dialogStore, this.passwordUpdateTpl);

              } else if (!isValidPassword(this.loginFormValue.password)) {

                this.store.setLoginError(new Error('Empty Password'));

                if (this.store.loginMethod === LoginMethod.AccountDetails) {

                  PasswordUpdateComponent.showInDialog(this.dialogStore, this.passwordUpdateTpl);

                } else {

                  this.confirmationStore.confirm({
                    header: 'empty password',
                    message: `Please login using your account details to set your initial password.`
                  });
                }
              }
            }),
            catchError(e => {

              this.logger.warn('LoginComponent: store.validateCredentials catchError');

              return EMPTY;
            }),
          );
        }
      })
    ));

    this.subscribe(this.passwordUpdate$.pipe(
      switchMap(args => {

        return this.googleRecaptchaStore.executeSiteVerify({
          widgetId: this.recaptchaWidgetId,
          action: 'login_PasswordUpdate'
        }).pipe(
          map(recaptchaResponse => ({
            ...args,
            recaptchaResponse
          }))
        );
      }),
      switchMapCatch(args => {

        return this.store.updateCredentialsPassword({
          accountNumber: this.loginFormValue.accountNumber,
          plan: this.loginFormValue.plan,
          unitNumber: this.loginFormValue.unitNumber,
          email: this.loginFormValue.email,
          password: args.password
        }).pipe(
          catchError(e => {

            this.logger.warn('LoginComponent: store.updateCredentialsPassword catchError');

            return EMPTY;
          }),
        );
      })
    ));

    this.subscribe(this.getLoginCode$.pipe(
      switchMap(args => {

        return this.googleRecaptchaStore.executeSiteVerify({
          widgetId: this.recaptchaWidgetId,
          action: 'login_GetLoginCode'
        }).pipe(
          map(recaptchaResponse => ({
            ...args,
            recaptchaResponse
          }))
        );
      }),
      switchMapCatch(args => {

        const dialogOptions: DialogOptions = {
          header: 'enter login code',
          showHeader: true,
          closable: true,
          dismissableMask: false,
          width: DialogWidth.Default
        };

        this.loginCodeFormReset({
          code: ''
        });

        switch (this.store.loginMethod) {

          case LoginMethod.Email:

            return this.store.sendEmailTempPin({
              email: this.loginFormValue.email
            }).pipe(
              tap(() => {

                this.dialogStore.showTemplate(args.loginCodeTpl, dialogOptions);
              }),
              catchError(e => {

                this.logger.warn('LoginComponent: store.sendEmailTempPin catchError');

                return EMPTY;
              })
            );

          case LoginMethod.Mobile:

            return this.store.sendMobileTempPin({
              mobile: this.loginFormValue.mobile
            }).pipe(
              tap(() => {

                this.dialogStore.showTemplate(args.loginCodeTpl, dialogOptions);
              }),
              catchError(e => {

                this.logger.warn('LoginComponent: store.sendMobileTempPin catchError');

                return EMPTY;
              })
            );

          default:

            throw new Error(this.store.invalidLoginMethodMessage('LoginComponent.getLoginCode$'));
        }
      })
    ));

    this.subscribe(this.validateLoginCode$.pipe(
      switchMap(args => {

        return this.googleRecaptchaStore.executeSiteVerify({
          widgetId: this.recaptchaWidgetId,
          action: 'login_ValidateLoginCode'
        }).pipe(
          map(recaptchaResponse => ({
            ...args as any,
            recaptchaResponse
          }))
        );
      }),
      switchMapCatch(args => {

        return this.store.validateLoginCode({
          emailOrMobile: this.emailOrMobileValue,
          pin: this.loginCodeFormValue.code
        }).pipe(
          catchError(e => {

            this.logger.warn('LoginComponent: store.validateLoginCode catchError');

            return EMPTY;
          })
        );
      })
    ));

    this.mobxReaction('LoginComponent: store.isXXXVisible',
      () => ({
        isImpersonationLogin: this.store.isImpersonationLogin,
        loginMethod: this.store.loginMethod,
        isEmailVisible: this.store.isEmailVisible,
        isMobileVisible: this.store.isMobileVisible,
        isPasswordVisible: this.store.isPasswordVisible
      }),
      args => {

        args.isImpersonationLogin ? this.impersonatorUsername.enable() : this.impersonatorUsername.disable();
        args.isImpersonationLogin ? this.impersonatorPassword.enable() : this.impersonatorPassword.disable();

        args.loginMethod === LoginMethod.AccountDetails ? this.accountNumber.enable() : this.accountNumber.disable();
        args.loginMethod === LoginMethod.AccountDetails ? this.plan.enable() : this.plan.disable();
        args.loginMethod === LoginMethod.AccountDetails ? this.unitNumber.enable() : this.unitNumber.disable();

        args.isEmailVisible ? this.email.enable() : this.email.disable();

        args.isMobileVisible ? this.mobile.enable() : this.mobile.disable();

        args.isPasswordVisible ? this.password.enable() : this.password.disable();
      });

    this.initialiseLoginForm();
  }

  ngAfterViewInit(): void {

    this.initialiseRecaptcha();
  }

  ngOnDestroy() {

    this.removeCredentialsFromCookie();

    this.dialogStore.hide();
  }

  onDialogCancel() {

    this.dialogStore.hide();
  }

  private passwordValidator: ValidatorFn = (control) => {

    if (this.authenticationStore.loginType === LoginType.BUILDING) {

      return notEmptyValidator(control);
    }

    return null;
  }

  private loginFormValidator: ValidatorFn = (control) => {

    if (this.store.loginMethod === LoginMethod.AccountDetails ||
      this.store.isImpersonationLogin ||
      this.store.forgottenPassword) {

      return null;
    }

    const formModel = control.value as LoginFormModel;

    return !formModel.password || !formModel.password.trim() ? validationError('Password is required') : null;
  }

  private submitFormIfValid() {

    if (this.loginForm.valid && this.loginFormValue.password) {

      this.onLoginFormSubmit();
    }
  }

  private initialiseRecaptcha() {

    // Wait for Google Recaptcha to initialise
    this.appStore.showActivityIndicator({ flag: true });

    this.mobxReaction('LoginComponent: googleRecaptchaStore.ready',
      () => this.googleRecaptchaStore.initialised,
      initialised => {

        if (!initialised) {

          return;
        }

        this.appStore.showActivityIndicator({ flag: false });

        this.recaptchaWidgetId = this.googleRecaptchaStore.render(this.inlineRecaptchaRef.nativeElement);

        this.submitFormIfValid();
      });
  }

  private initialiseLoginForm() {

    const credentials = this.getLegacyOwnerCredentialsFromCookie();

    if (credentials) {

      // Since the credentials cookie was present, delete current authentication cookie
      this.authenticationStore.setAuthentication(undefined);
    }

    this.loginFormPatchValue({
      // tslint:disable-next-line: max-line-length
      accountNumber: credentials ? credentials.accountNumber : this.route.snapshot.queryParamMap.get(property<LoginFormModel>('accountNumber')) || '',
      plan: credentials ? credentials.plan : this.route.snapshot.queryParamMap.get(property<LoginFormModel>('plan')) || '',
      // tslint:disable-next-line: max-line-length
      unitNumber: credentials ? credentials.unitNumber : this.route.snapshot.queryParamMap.get(property<LoginFormModel>('unitNumber')) || '',
      email: this.route.snapshot.queryParamMap.get(property<LoginFormModel>('email')) || '',
      password: credentials ? credentials.pin : this.route.snapshot.queryParamMap.get('pin') || '',
    });

    this.store.setIsImpersonationLogin(this.route.snapshot.queryParamMap.get(QueryParam.Impersonate) === 'true');
    this.store.setForgottenPassword(this.route.snapshot.queryParamMap.get(QueryParam.ForgottenPassword) === 'true');
    this.domainStore.setBranch(this.route.snapshot.queryParamMap.get(QueryParam.branch));
    this.authenticationStore.setLoginType(parseLoginType(this.route.snapshot.queryParamMap.get(QueryParam.LoginType)));

    const emailLoginParam = this.route.snapshot.queryParamMap.get(QueryParam.EmailLogin);
    this.store.setLoginMethod(emailLoginParam === 'true' ? LoginMethod.Email : LoginMethod.AccountDetails);
  }

  // This method is used to get the Legacy Credentials from the cookie set via the CMS.
  private getLegacyOwnerCredentialsFromCookie(): Partial<LegacyOwnerCredentials & { pin: string }> | undefined {

    const encodedCredentials = Cookies.get(LoginComponent.credentialsCookieName);

    if (!encodedCredentials) {

      return undefined;
    }

    try {

      return JSON.parse(atob(encodedCredentials));

    } catch (e) {

      this.logger.warn('LoginComponent.getLegacyOwnerCredentialsFromCookie: could not construct credentials', e);

      return undefined;
    }
  }

  private removeCredentialsFromCookie() {

    Cookies.remove(LoginComponent.credentialsCookieName, {
      path: '/',
      domain: this.authenticationStore.authentication && this.authenticationStore.authentication.sharedDocumentDomain
    });
  }

  onLoginFormSubmit() {

    this.store.setLoginError(undefined);

    this.submit$.next();
  }

  onForgottenPasswordClick() {

    this.store.toggleForgottenPasswordLink();
  }

  onDontKnowEmailLink(evt: Event) {

    if (this.accountNumber.valid && this.plan.valid && this.unitNumber.valid) {

      // tslint:disable-next-line:max-line-length
      const subject = `Account Number: ${this.loginFormValue.accountNumber}, Plan: ${this.loginFormValue.plan}, Unit Number: ${this.loginFormValue.unitNumber}`;

      const body = `[Add any additional notes here]`;

      window.open(`mailto:operations@whittles.com.au?subject=${subject}&body=${body}`);

    } else {

      this.confirmationStore.confirm({
        header: 'missing information',
        message: `Please enter the following:<br><br>
        * Account No.<br>
        * Plan<br>
        * Unit No.<br>
        `
      });
    }
  }

  onSendLoginCodeClick(loginCodeTpl: TemplateRef<any>) {

    this.getLoginCode$.next({ loginCodeTpl });
  }

  onLoginCodeFormSubmit() {

    this.validateLoginCode$.next();
  }

  onPasswordUpdate(password: string) {

    this.passwordUpdate$.next({ password });
  }

  private loginFormPatchValue(model: Partial<LoginFormModel>) {

    this.loginForm.patchValue(model);
  }

  private get loginFormValue() {

    return this.loginForm.value as LoginFormModel;
  }

  get oneTimePinButtonEnabled(): boolean {

    return (this.email.valid || this.mobile.valid);
  }

  get loginCodeMessage(): string {

    return `A 4 digit one time code has been sent to ${this.store.loginMethod.toLowerCase()}: <b>${this.emailOrMobileValue}</b>`;
  }

  private get emailOrMobileValue(): string {

    switch (this.store.loginMethod) {

      case LoginMethod.Email:

        return this.loginFormValue.email;

      case LoginMethod.Mobile:

        return this.loginFormValue.mobile;

      default:

        throw new Error(this.store.invalidLoginMethodMessage('LoginComponent.emailOrMobileValue'));
    }
  }

  private loginCodeFormReset(value: LoginCodeFormModel) {

    this.loginCodeForm.reset(value);
  }

  private get loginCodeFormValue() {

    return this.loginCodeForm.value as LoginCodeFormModel;
  }

  get impersonatorUsername() {

    return this.loginForm.controls[property<LoginFormModel>('impersonatorUsername')];
  }

  get impersonatorPassword() {

    return this.loginForm.controls[property<LoginFormModel>('impersonatorPassword')];
  }

  get accountNumber() {

    return this.loginForm.controls[property<LoginFormModel>('accountNumber')];
  }

  get plan() {

    return this.loginForm.controls[property<LoginFormModel>('plan')];
  }

  get unitNumber() {

    return this.loginForm.controls[property<LoginFormModel>('unitNumber')];
  }

  get password() {

    return this.loginForm.controls[property<LoginFormModel>('password')];
  }

  get email() {

    return this.loginForm.controls[property<LoginFormModel>('email')];
  }

  get mobile() {

    return this.loginForm.controls[property<LoginFormModel>('mobile')];
  }

  get loginCode() {

    return this.loginCodeForm.controls[property<LoginCodeFormModel>('code')];
  }
}
