import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { BaseAppProperties } from '../../base-app-properties';
import { PortalType } from '../../models/portal-type';
import { BaseComponent } from '../base/base.component';
import { Dropdown } from 'primeng/dropdown';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardComponent extends BaseComponent implements OnInit {

  @Input() title = '';

  @Input() helpTags: any;

  @Input() helpIconVisible: boolean;

  @Input() helpIcon: string = '';

  @Input() showCovid = false;

  @Input() showNotification = false;

  // #region dropdown list
  @Input() showDropdownContainer: boolean = false;

  @Input() selectedDropdownItem?: any;

  @Input() field?: string

  @Input() options?: any[]

  @Output() dropdownChanged = new EventEmitter();
  //#endregion

  //#region show global
  @Input() showGlobalCheckbox = false;

  @Input() showGlobal: boolean = false;

  @Output() checkboxChanged = new EventEmitter();
  //#endregion

  @ViewChild('companyDropdown') companyDropdown: Dropdown;

  companyDisable = false;

  readonly PortalType = PortalType;

  constructor(readonly appProperties: BaseAppProperties) {
    super();
  }

  ngOnInit(): void {
  }

  dropdownOnChange(event: any): void {
    this.dropdownChanged.emit(event);
  }

  checkboxOnChange(event: any): void {
    this.companyDisable = event.checked;
    this.checkboxChanged.emit(event);
  }
}
