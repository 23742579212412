import { ErrorHandler, Injectable } from '@angular/core';

import { handleError } from './utils/error.utils';

@Injectable({
  providedIn: 'root'
})
export class AppErrorHandler implements ErrorHandler {

  handleError(error: any) {

    handleError(error);
  }
}
