import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ButtonKind } from '../../directives/button.directive';
import {
  NameValuePair,
  ProcessNote,
  ProcessNotesResponse,
  User
} from '../../generated';
import { CrudOperation } from '../../models/crud-operation';
import { DialogWidth } from '../../models/dialog-width';
import { switchMapCatch } from '../../rxjs/operators';
import { DialogStore } from '../../store/dialog.store';
import { FormModel, notEmptyValidator } from '../../utils/form.utils';
import { property } from '../../utils/object.utils';
import { BaseComponent } from '../base/base.component';
import { SpinnerSize } from '../spinner/spinner.component';
import { TableHeaderFilterType } from '../table-header/table-header.component';

import { Store } from './process-notes.store';

export interface ProcessNotesFormModel {
  text: string;
  target: NameValuePair;
}

export interface ProcessNotesCrud {
  model: ProcessNotesFormModel;
  operation: CrudOperation;
}

@Component({
  selector: 'app-process-notes',
  templateUrl: './process-notes.component.html',
  styleUrls: ['./process-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Store]
})
export class ProcessNotesComponent extends BaseComponent implements OnInit {

  private readonly getProcessNotes$ = new Subject();
  private readonly updateProcessNotes$ = new Subject();

  @ViewChild('crudTpl') private readonly crudTpl: TemplateRef<any>;

  readonly nameValuePairPropertyName = property<NameValuePair>('name');
  readonly nameValuePairPropertyValue = property<NameValuePair>('value');

  readonly userPropertyFullName = property<User>('fullName');

  readonly processNotePropertyText = property<ProcessNote>('text');
  readonly processNotePropertyCreatedBy = property<ProcessNote>('createdBy');
  readonly processNotePropertyCreatedDate = property<ProcessNote>('createdDate');
  readonly processNotePropertyTarget = property<ProcessNote>('target');

  readonly processNotePropertyCreatedByFullName = `${this.processNotePropertyCreatedBy}.${this.userPropertyFullName}`;
  readonly processNotePropertyTargetName = `${this.processNotePropertyTarget}.${this.nameValuePairPropertyName}`;

  readonly TableHeaderFilterType = TableHeaderFilterType;
  readonly ButtonKind = ButtonKind;
  readonly SpinnerSize = SpinnerSize;

  @Input() editable = false;
  @Input() appendTo: unknown;
  @Input() spinnerAlphaBackground = true;
  @Input() paginator = true;

  @Input() set workflowProcessId(val: string | undefined) {

    this.store.setWorkflowProcessId(val || '');
  }

  get workflowProcessId() {

    return this.store.workflowProcessId;
  }

  @Output() readonly crudSuccess = new EventEmitter<ProcessNotesCrud>();
  @Output() readonly processNotesFetched = new EventEmitter<ProcessNotesResponse>();

  processNotesForm: UntypedFormGroup;

  constructor(
    readonly store: Store,
    private readonly fb: UntypedFormBuilder,
    private readonly dialogStore: DialogStore) {

    super();
  }

  ngOnInit(): void {

    const notesFormModel: FormModel<ProcessNotesFormModel> = {
      text: ['', [notEmptyValidator]],
      target: [undefined, [notEmptyValidator]]
    };

    this.processNotesForm = this.fb.group(notesFormModel);

    this.subscribe(this.getProcessNotes$.pipe(
      switchMapCatch(() => {

        return this.store.getProcessNotes().pipe(
          tap(response => {
            this.processNotesFetched.emit(response)
            this.processNotesForm.markAsPristine();
            this.processNotesForm.markAsUntouched();
          })
        );
      })
    ));

    this.subscribe(this.updateProcessNotes$.pipe(
      switchMapCatch(() => {

        return this.store.updateProcessNotes({
          text: this.processNotesFormModelValue.text,
          target: this.processNotesFormModelValue.target
        }).pipe(
          tap(() => {

            this.crudSuccess.emit({
              model: this.processNotesFormModelValue,
              // tslint:disable-next-line: no-non-null-assertion
              operation: this.store.crudOperation!
            });

            this.getProcessNotes$.next();
          })
        );
      })
    ));

    this.mobxReaction('ProcessNotesComponent: store.taskId',
      () => this.store.workflowProcessId,
      workflowProcessId => {

        this.resetForm();

        if (workflowProcessId) {

          this.getProcessNotes$.next();
        }
      });

    this.mobxReaction('ProcessNotesComponent: store.selectedProcessNote',
      () => this.store.selectedProcessNote,
      selectedProcessNote => {

        this.resetForm();

        if (selectedProcessNote) {

          this.patchProcessNotesForm({
            text: selectedProcessNote.text,
            target: selectedProcessNote.target || Store.GeneralNVPair
          });
        }
      });
  }

  onDialokOk() {

    this.updateProcessNotes$.next();
  }

  private showCrudDialog() {

    this.dialogStore.showTemplate(this.crudTpl, {
      showHeader: true,
      // tslint:disable-next-line: no-non-null-assertion
      // @ts-ignore
      header: `${this.store.crudOperation!.toTitleCase()} Process Note`,
      width: DialogWidth.Screen33
    });
  }

  onDeleteClick(note: ProcessNote) {

    this.store.setSelectedProcessNote(note);

    this.store.setCrudOperation(CrudOperation.Delete);

    this.showCrudDialog();
  }

  onDialogCancel() {

    this.dialogStore.hide();
  }

  onNewClick() {

    this.resetForm();

    this.store.setSelectedProcessNote(undefined);
  }

  private resetForm() {

    this.processNotesForm.reset();

    this.processNotesForm.updateValueAndValidity();
  }

  onProcessNotesFormSubmit() {

    this.store.setCrudOperation(this.store.selectedProcessNote ? CrudOperation.Update : CrudOperation.Create);

    this.updateProcessNotes$.next();
  }

  getCreatedDate(note: ProcessNote) {

    return note.createdDate;
  }

  getText(note: ProcessNote) {

    return note.text;
  }

  getCreatedBy(note: ProcessNote) {

    return note.createdBy.fullName;
  }

  getTarget(note: ProcessNote): string {

    return note.target ? note.target.name : Store.GeneralNVPair.name;
  }

  private patchProcessNotesForm(model: Partial<ProcessNotesFormModel>) {

    this.processNotesForm.patchValue(model);
  }

  get crudDialogNoteText(): string {

    switch (this.store.crudOperation) {

      case CrudOperation.Create:
      case CrudOperation.Update:

        return this.processNotesFormModelValue.text;

      case CrudOperation.Delete:

        return this.store.selectedProcessNote && this.store.selectedProcessNote.text || '';

      default:

        return '';
    }
  }

  get crudDialogNoteTarget(): string {

    switch (this.store.crudOperation) {

      case CrudOperation.Create:
      case CrudOperation.Update:

        return this.processNotesFormModelValue.target &&
          this.processNotesFormModelValue.target.name || '';

      case CrudOperation.Delete:

        return this.store.selectedProcessNote &&
          this.store.selectedProcessNote.target &&
          this.store.selectedProcessNote.target.name || '';

      default:

        return '';
    }
  }

  get isSubmitButtonEnabled(): boolean {

    return this.isEditable && this.processNotesForm.valid;
  }

  get isEditable(): boolean {

    if (!this.editable) {

      return false;
    }

    return this.store.isSelectedNoteEditable;
  }

  get processNotesFormModelValue() {

    return this.processNotesForm.value as ProcessNotesFormModel;
  }

  get processNoteText() {

    return this.processNotesForm.controls[property<ProcessNotesFormModel>('text')];
  }

  get processNoteTarget() {

    return this.processNotesForm.controls[property<ProcessNotesFormModel>('target')];
  }
}
