import { filter, flatten, flow, groupBy } from 'lodash-es';

export function formatArray(arr: any[] | undefined, separator = ', '): string {

  if (!arr) {

    return '';
  }

  return arr.join(separator);
}

export function arrayJoinNewline(arr: any[] | undefined): string {

  return formatArray(arr, '\n');
}

export function findDuplicates<T>(values: T[], property: string): T[] {

  return flow([
    arr => groupBy(arr, property), // group elements by property
    g => filter(g, o => o.length > 1), // remove groups that have less than two members
    flatten // flatten the results to a single array
  ])(values);
}
