import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseAppProperties } from '../base-app-properties';
import {
  AuthenticationService as AuthenticationServiceGenerated,
  Configuration,
  LegacyOwnerCredentialsPasswordResetRequestParams,
  LogoffParams,
  PasswordResetRequestParams,
  UpdateLegacyOwnerCredentialsPasswordOnLoginParams,
  UpdatePasswordOnLoginParams,
  ValidateCredentialsParams,
  ValidateEmailLoginCredentialsParams,
  ValidateLegacyOwnerCredentialsParams,
  ValidateMobileLoginCredentialsParams
} from '../generated';
import { formatPhoneNumberForPersistance } from '../utils/form.utils';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private readonly authenticationServiceGenerated: AuthenticationServiceGenerated;

  constructor(httpClient: HttpClient, appProperties: BaseAppProperties) {

    this.authenticationServiceGenerated = new AuthenticationServiceGenerated(
      httpClient, appProperties.irisServerBasePath, new Configuration());
  }

  validateCredentials(params: ValidateCredentialsParams) {

    return this.authenticationServiceGenerated.validateCredentials(params);
  }

  validateLegacyOwnerCredentials(params: ValidateLegacyOwnerCredentialsParams) {

    return this.authenticationServiceGenerated.validateLegacyOwnerCredentials(params);
  }

  updateLegacyOwnerCredentialsPasswordOnLogin(params: UpdateLegacyOwnerCredentialsPasswordOnLoginParams) {

    return this.authenticationServiceGenerated.updateLegacyOwnerCredentialsPasswordOnLogin(params);
  }

  legacyOwnerCredentialsPasswordResetRequest(params: LegacyOwnerCredentialsPasswordResetRequestParams) {

    return this.authenticationServiceGenerated.legacyOwnerCredentialsPasswordResetRequest(params);
  }

  getCompanies() {

    return this.authenticationServiceGenerated.getCompanies();
  }

  validateEmailLoginCredentials(params: ValidateEmailLoginCredentialsParams) {

    return this.authenticationServiceGenerated.validateEmailLoginCredentials(params);
  }

  validateMobileLoginCredentials(params: ValidateMobileLoginCredentialsParams) {

    return this.authenticationServiceGenerated.validateMobileLoginCredentials(params);
  }

  passwordResetRequest(params: PasswordResetRequestParams) {

    return this.authenticationServiceGenerated.passwordResetRequest(params);
  }

  updatePasswordOnLogin(params: UpdatePasswordOnLoginParams) {

    return this.authenticationServiceGenerated.updatePasswordOnLogin(params);
  }

  sendEmailTempPin(params: {
    email: string;
  }) {

    return this.authenticationServiceGenerated.sendEmailTempPin(params.email);
  }

  sendMobileTempPin(params: {
    mobile: string;
  }) {

    return this.authenticationServiceGenerated.sendMobileTempPin(formatPhoneNumberForPersistance(params.mobile));
  }

  validateEmailTempPinLoginCredentials(params: {
    email: string;
    pin: string;
  }) {

    return this.authenticationServiceGenerated.validateEmailTempPinLoginCredentials(params.email, params.pin);
  }

  validateMobileTempPinLoginCredentials(params: {
    mobile: string;
    pin: string;
  }) {

    return this.authenticationServiceGenerated.validateMobileTempPinLoginCredentials(
      formatPhoneNumberForPersistance(params.mobile), params.pin);
  }

  googleRecaptchaSiteVerify(params: {
    recaptchaToken: string;
  }) {

    return this.authenticationServiceGenerated.googleRecaptchaSiteVerify(params.recaptchaToken);
  }

  logoff(params: LogoffParams) {

    return this.authenticationServiceGenerated.logoff(params);
  }
}
