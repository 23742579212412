/**
 * Whittles API
 * Whittles API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { TaskHistory } from '../model/taskHistory';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class AgmService {

    protected basePath = 'https://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param strataFinancialYearId 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public budgetAdopted(strataFinancialYearId: string, userId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public budgetAdopted(strataFinancialYearId: string, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public budgetAdopted(strataFinancialYearId: string, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public budgetAdopted(strataFinancialYearId: string, userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataFinancialYearId === null || strataFinancialYearId === undefined) {
            throw new Error('Required parameter strataFinancialYearId was null or undefined when calling budgetAdopted.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling budgetAdopted.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataFinancialYearId !== undefined && strataFinancialYearId !== null) {
            queryParameters = queryParameters.set('strataFinancialYearId', <any>strataFinancialYearId);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/agm/workflow/budget-adopted`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataFinancialYearId 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public budgetProposed(strataFinancialYearId: string, userId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public budgetProposed(strataFinancialYearId: string, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public budgetProposed(strataFinancialYearId: string, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public budgetProposed(strataFinancialYearId: string, userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataFinancialYearId === null || strataFinancialYearId === undefined) {
            throw new Error('Required parameter strataFinancialYearId was null or undefined when calling budgetProposed.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling budgetProposed.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataFinancialYearId !== undefined && strataFinancialYearId !== null) {
            queryParameters = queryParameters.set('strataFinancialYearId', <any>strataFinancialYearId);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/agm/workflow/budget-proposed`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataFinancialYearId 
     * @param reason 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelAgmWorkflow(strataFinancialYearId: string, reason?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public cancelAgmWorkflow(strataFinancialYearId: string, reason?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public cancelAgmWorkflow(strataFinancialYearId: string, reason?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public cancelAgmWorkflow(strataFinancialYearId: string, reason?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataFinancialYearId === null || strataFinancialYearId === undefined) {
            throw new Error('Required parameter strataFinancialYearId was null or undefined when calling cancelAgmWorkflow.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataFinancialYearId !== undefined && strataFinancialYearId !== null) {
            queryParameters = queryParameters.set('strataFinancialYearId', <any>strataFinancialYearId);
        }
        if (reason !== undefined && reason !== null) {
            queryParameters = queryParameters.set('reason', <any>reason);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/agm/workflow/cancel`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataFinancialYearId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelInterimResolutionsTimer(strataFinancialYearId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public cancelInterimResolutionsTimer(strataFinancialYearId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public cancelInterimResolutionsTimer(strataFinancialYearId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public cancelInterimResolutionsTimer(strataFinancialYearId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataFinancialYearId === null || strataFinancialYearId === undefined) {
            throw new Error('Required parameter strataFinancialYearId was null or undefined when calling cancelInterimResolutionsTimer.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataFinancialYearId !== undefined && strataFinancialYearId !== null) {
            queryParameters = queryParameters.set('strataFinancialYearId', <any>strataFinancialYearId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/agm/workflow/cancel-interim-resolutions-timer`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param taskId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMeetingDate(taskId: string, observe?: 'body', reportProgress?: boolean): Observable<Date>;
    public getMeetingDate(taskId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Date>>;
    public getMeetingDate(taskId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Date>>;
    public getMeetingDate(taskId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (taskId === null || taskId === undefined) {
            throw new Error('Required parameter taskId was null or undefined when calling getMeetingDate.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (taskId !== undefined && taskId !== null) {
            queryParameters = queryParameters.set('taskId', <any>taskId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Date>(`${this.basePath}/agm/workflow/meeting-date`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param taskId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSmsMeetingReminders(taskId: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public getSmsMeetingReminders(taskId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public getSmsMeetingReminders(taskId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public getSmsMeetingReminders(taskId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (taskId === null || taskId === undefined) {
            throw new Error('Required parameter taskId was null or undefined when calling getSmsMeetingReminders.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (taskId !== undefined && taskId !== null) {
            queryParameters = queryParameters.set('taskId', <any>taskId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<boolean>(`${this.basePath}/agm/workflow/sms-meeting-reminders`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataId 
     * @param financialYearId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkflowHistory(strataId: string, financialYearId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TaskHistory>>;
    public getWorkflowHistory(strataId: string, financialYearId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TaskHistory>>>;
    public getWorkflowHistory(strataId: string, financialYearId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TaskHistory>>>;
    public getWorkflowHistory(strataId: string, financialYearId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataId === null || strataId === undefined) {
            throw new Error('Required parameter strataId was null or undefined when calling getWorkflowHistory.');
        }

        if (financialYearId === null || financialYearId === undefined) {
            throw new Error('Required parameter financialYearId was null or undefined when calling getWorkflowHistory.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }
        if (financialYearId !== undefined && financialYearId !== null) {
            queryParameters = queryParameters.set('financialYearId', <any>financialYearId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TaskHistory>>(`${this.basePath}/agm/workflow/history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param financialYearId 
     * @param context 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkflowProcessId(financialYearId: string, context?: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getWorkflowProcessId(financialYearId: string, context?: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getWorkflowProcessId(financialYearId: string, context?: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getWorkflowProcessId(financialYearId: string, context?: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (financialYearId === null || financialYearId === undefined) {
            throw new Error('Required parameter financialYearId was null or undefined when calling getWorkflowProcessId.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (financialYearId !== undefined && financialYearId !== null) {
            queryParameters = queryParameters.set('financialYearId', <any>financialYearId);
        }
        if (context !== undefined && context !== null) {
            queryParameters = queryParameters.set('context', <any>context);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/agm/workflow/process`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataFinancialYearId 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendMinutes(strataFinancialYearId: string, userId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sendMinutes(strataFinancialYearId: string, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sendMinutes(strataFinancialYearId: string, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sendMinutes(strataFinancialYearId: string, userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataFinancialYearId === null || strataFinancialYearId === undefined) {
            throw new Error('Required parameter strataFinancialYearId was null or undefined when calling sendMinutes.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling sendMinutes.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataFinancialYearId !== undefined && strataFinancialYearId !== null) {
            queryParameters = queryParameters.set('strataFinancialYearId', <any>strataFinancialYearId);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/agm/workflow/send-minutes`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataFinancialYearId 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendNotices(strataFinancialYearId: string, userId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sendNotices(strataFinancialYearId: string, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sendNotices(strataFinancialYearId: string, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sendNotices(strataFinancialYearId: string, userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataFinancialYearId === null || strataFinancialYearId === undefined) {
            throw new Error('Required parameter strataFinancialYearId was null or undefined when calling sendNotices.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling sendNotices.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataFinancialYearId !== undefined && strataFinancialYearId !== null) {
            queryParameters = queryParameters.set('strataFinancialYearId', <any>strataFinancialYearId);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/agm/workflow/send-notices`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataFinancialYearId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public startAgmWorkflow(strataFinancialYearId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public startAgmWorkflow(strataFinancialYearId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public startAgmWorkflow(strataFinancialYearId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public startAgmWorkflow(strataFinancialYearId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataFinancialYearId === null || strataFinancialYearId === undefined) {
            throw new Error('Required parameter strataFinancialYearId was null or undefined when calling startAgmWorkflow.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataFinancialYearId !== undefined && strataFinancialYearId !== null) {
            queryParameters = queryParameters.set('strataFinancialYearId', <any>strataFinancialYearId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/agm/workflow/start`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
