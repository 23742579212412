import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-button-container',
  templateUrl: './button-container.component.html',
  styleUrls: ['./button-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonContainerComponent extends BaseComponent implements OnInit {

  readonly layoutGap = '5px';

  @Input() layout = 'row wrap';

  @Input() layoutAlign = 'start center';

  @Input() layoutSM = 'column';

  @Input() layoutAlignSM = 'start stretch';

  @Input() containerBottomMargin = true;

  ngOnInit(): void {
  }
}
