import { Directive, HostListener, ChangeDetectorRef } from '@angular/core';

import { OverlayPanel } from 'primeng/overlaypanel';

// tslint:disable-next-line:directive-selector
@Directive({ selector: 'p-overlayPanel' })
export class OverlayPanelDirective {

  private overlayPanelVisible = false;

  constructor(
    private readonly overlayPanel: OverlayPanel,
    private readonly cdr: ChangeDetectorRef) {
  }

  @HostListener('onShow')
  onShow() {

    this.overlayPanelVisible = true;
  }

  @HostListener('onHide')
  onHide() {

    this.overlayPanelVisible = false;
  }

  @HostListener('document:click')
  documentClick() {

    if (!this.overlayPanelVisible) {

      return;
    }

    setTimeout(() => this.cdr.markForCheck());
  }
}
