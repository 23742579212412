import { autorun, reaction, IReactionDisposer, IReactionPublic, IReactionOptions } from 'mobx';

import { handleError } from './error.utils';

export interface IAutorunOptions {
  delay?: number;
  name?: string;
  scheduler?: (callback: () => void) => any;
  onError?: (error: any) => void;
}

export function mobxAutorun(
  name: string,
  view: (r: IReactionPublic) => any,
  opts?: IAutorunOptions): IReactionDisposer {

  return autorun(view, {
    name,
    onError: handleError,
    ...opts
  });
}

export function mobxReaction<T>(
  name: string,
  expression: (r: IReactionPublic) => T,
  effect: (arg: T, r: IReactionPublic) => void,
  opts?: IReactionOptions): IReactionDisposer {

  return reaction(expression, effect, {
    name,
    onError: handleError,
    fireImmediately: true,
    ...opts
  });
}
