<div *mobxAutorun class="p-grid ui-fluid form-group">
  <div class="p-col-12">
    <div class="terms-and-conditions-container">
      <div class="wht-terms-and-conditions" [innerHTML]="store.termsAndConditions"></div>
    </div>
  </div>
  <div class="p-col-12">
    <app-content-container layoutGap="10px">
      <p-checkbox [binary]="true" [ngModel]="store.accept" (ngModelChange)="store.setAccept($event)"></p-checkbox>
      <div>I accept the terms and conditions.</div>
    </app-content-container>
  </div>
  <div class="p-col-12" style="text-align: center">
    <button pButton type="button" [label]="acceptDeclineButtonLabel" (click)="onAcceptDeclineClick()"></button>
  </div>
</div>
