import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, Optional } from '@angular/core';
import { Table } from 'primeng/table';

import { BaseComponent } from '../base/base.component';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'td[appTableData]',
  templateUrl: './table-data.component.html',
  styleUrls: ['./table-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableDataComponent extends BaseComponent implements OnInit {

  private _title = '';

  @Input() set title(val: string) {

    this._title = val;
  }

  get title() {

    return this._title;
  }

  constructor(
    private readonly elRef: ElementRef,
    @Optional() readonly table?: Table) {

    super();
  }

  ngOnInit(): void {

    this.setResponsiveTitle();
  }

  private setResponsiveTitle() {

    if (!this.table || !this.table.responsive || this._title) {

      // Title has already been set via title setter
      return;
    }

    const tdIndex = this.findTDIndex();

    if (tdIndex == null) {

      return;
    }

    // tslint:disable-next-line: max-line-length
    const thSpan = (this.table.el.nativeElement as Element).querySelector(`th:nth-child(${tdIndex + 1}) > span`);

    if (thSpan) {

      this._title = thSpan.innerHTML;
    }
  }

  private findTDIndex(): number | undefined {

    // tslint:disable-next-line: no-non-null-assertion
    const tds = (this.elRef.nativeElement as Element).parentNode!.querySelectorAll('td');

    for (let i = 0; i < tds.length; i++) {

      if (tds.item(i) === this.elRef.nativeElement) {

        return i;
      }
    }

    return undefined;
  }

  get responsiveTitle() {

    return this._title;
  }
}
