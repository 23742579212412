export enum HelpTag {
  Approve = 'approve',
  Invoice = 'invoice',
  Reject = 'reject',
  Budget = 'budget',
  Actual = 'actual',
  SecondAuthority = 'second authority',
  New = 'new',
  Swift = 'swift',
  AccountCode = 'account code',
  EmailApprove = 'email approve',
  PlanAccess = 'plan access',
  Expiry = 'expire',
  Timed = 'timed',
  BuildingManager = 'building manager',
  CommitteeAdmin = 'committee admin',
  ExternalCommittee = 'external committee',
  Filter = 'filter',
  Search = 'search',
  OwnerPayments = 'payments',
  OwnerProfile = 'profile',
  OwnerInvoices = 'owner invoices',
  OwnerPortalDocuments = 'documents',
  OwnerFinancials = 'financial records',
  OwnerHome = 'home',
  Sms = 'sms',
  SmsTemplates = 'sms templates',
  Task = 'task',
  Journal = 'journal',
  AdditionalCharges = 'additional charges',
  Diagram = 'diagram',
  History = 'history',
  Notes = 'notes',
  DefaultAssignments = 'default assignments',
  MeetingSummary = 'meeting summary',
  AddressMaintenance = 'address maintenance',
  PlanListing = 'plan listing',
  PlanTypes = 'plan types',
  StateDivisionMaintenance = 'state division maintenance',
  SuburbMaintenance = 'suburb maintenance',
  SundryItems = 'sundry items',
  UnitOwnerMaintenance = 'unit & owner maintenance',
  ChangeOwnershipWizard = 'change of ownership wizard',
  ChangeOwnershipWizardReversalNameChange = 'change of ownership wizard reversal name change'
}
