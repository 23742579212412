import { PortalType } from './models/portal-type';

export abstract class BaseAppProperties {

  readonly irisServerBasePath = '/api/whittles-online-server';
  readonly reportsServerBasePath = '/api/whittles-reports-server';

  get portalType() {

    return this.getPortalType();
  }

  get localStoragePrefix() {

    return `whittles.${this.getLocalStoragePrefix()}`;
  }

  protected getLocalStoragePrefix(): string {

    return this.portalType.toLowerCase();
  }

  protected abstract getPortalType(): PortalType;
}
