import { Injectable } from '@angular/core';
import { computed } from 'mobx';

import { PresidingOfficeTitle } from '../models/committee';
import { CompanyId } from '../models/company';
import { PlanType } from '../models/plan-type';
import { State } from '../models/state';

import { BaseDomainStore } from './base-domain.store';

enum Labels {
  Lot = 'Lot',
  Unit = 'Unit',
  UEV = 'UEV',
  LEV = 'LEV',
  UE = 'UE'
}

@Injectable({
  providedIn: 'root'
})
export class LocalisationStore {

  constructor(
    private readonly baseDomainStore: BaseDomainStore
  ) {
  }

  @computed
  get unitLabel(): string {

    const companyId = this.baseDomainStore.localisationContext.companyId;
    const state = this.baseDomainStore.localisationContext.state;
    const planType = this.baseDomainStore.localisationContext.planType;

    // ported code from Online
    if (companyId === CompanyId.Adelaide) {

      if (planType === PlanType.StrataPlan) {

        return Labels.Unit;
      }

    } else if (state === State.ACT || companyId === CompanyId.Darwin || companyId === CompanyId.Sydney) {

      return Labels.Unit;
    }

    return Labels.Lot;
  }

  @computed
  get levLabel(): string {

    const companyId = this.baseDomainStore.localisationContext.companyId;
    const planType = this.baseDomainStore.localisationContext.planType;

    // ported code from Online
    if (companyId === CompanyId.Adelaide) {

      if (planType === PlanType.StrataPlan) {

        return Labels.UEV;
      }

    } else if (companyId === CompanyId.Darwin) {

      return Labels.UEV;

    } else if (companyId === CompanyId.Canberra) {

      return Labels.UE;
    }

    return Labels.LEV;
  }

  @computed
  get presidingOfficerTitle(): string {

    const companyId = this.baseDomainStore.localisationContext.companyId;

    // ported code from Online
    if (companyId === CompanyId.Adelaide) {

      return PresidingOfficeTitle.PresidingOfficer;
    }

    return PresidingOfficeTitle.Chairperson;
  }
}
