export enum FontAwesomeStyle {
  Solid = 'fas',
  Regular = 'far',
  Light = 'fal',
  Brands = 'fab'
}

export enum FontAwesomeIcon {
  BallotCheck = 'fa-ballot-check',
  FileInvoiceDollar = 'fa-file-invoice-dollar',
  ClipboardList = 'fa-clipboard-list',
  SearchDollar = 'fa-search-dollar',
  Gavel = 'fa-gavel',
  ThumbsUp = 'fa-thumbs-up',
  PiggyBank = 'fa-piggy-bank',
  MailBulk = 'fa-mail-bulk',
  Bullhorn = 'fa-bullhorn',
  AlarmClock = 'fa-alarm-clock'
}
