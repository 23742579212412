// https://docs.logrocket.com/docs/troubleshooting-sessions#section-due-to-logrocket-init-call-being-placed-incorrectly
import LogRocket from 'logrocket';

import { environment } from './environments/environment';

if (environment.logrocketRelease) {

  LogRocket.init('blkbxf/whittles-owner-portal', {
    release: environment.buildTag
  });
}
