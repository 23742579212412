import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { tap } from 'rxjs/operators';

import { DialogWidth } from '../../models/dialog-width';
import { DialogStore } from '../../store/dialog.store';
import { BrowserUtils } from '../../utils/browser.utils';
import {
  FormModel,
  notEmptyValidator,
  passwordMinLength,
  passwordValidator,
  validatePasswords,
  validationError
} from '../../utils/form.utils';
import { property } from '../../utils/object.utils';
import { BaseComponent } from '../base/base.component';
import { FormErrorFontSize } from '../form-error/form-error.component';

import { PasswordFormModel, Store } from './password.store';

@Component({
  selector: 'app-password-update',
  templateUrl: './password-update.component.html',
  styleUrls: ['./password-update.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Store]
})
export class PasswordUpdateComponent extends BaseComponent implements OnInit {

  readonly FormErrorFontSize = FormErrorFontSize;
  readonly passwordMinLength = passwordMinLength;

  @Output() readonly passwordUpdate = new EventEmitter<string>();
  @Output() readonly passwordUpdateFailedChange = new EventEmitter<boolean>();

  @Input() set passwordUpdateFailed(val: boolean) {

    this.store.setPasswordUpdateFailed(val);

    if (this.passwordForm) {

      this.passwordForm.updateValueAndValidity();
    }
  }

  get passwordUpdateFailed() {

    return this.store.passwordUpdateFailed;
  }

  passwordForm: UntypedFormGroup;

  static showInDialog(dialogStore: DialogStore, template: TemplateRef<any>) {

    const width = BrowserUtils.Mobile ? '100%' : BrowserUtils.Tablet ? DialogWidth.Screen50 : DialogWidth.Screen25;

    dialogStore.showTemplate(template, {
      width
    });
  }

  constructor(
    readonly store: Store,
    private readonly fb: UntypedFormBuilder) {

    super();
  }

  ngOnInit() {

    const passwordFormModel: FormModel<PasswordFormModel> = {
      newPassword: ['', [notEmptyValidator, passwordValidator]],
      repeatNewPassword: ['', [notEmptyValidator, passwordValidator]]
    };

    this.passwordForm = this.fb.group(passwordFormModel, { validators: this.passwordFormValidator });

    this.subscribe(this.passwordForm.valueChanges.pipe(
      tap(values => {

        this.store.setPasswordFormModel(values);

        if (this.store.passwordUpdateFailed &&
          this.store.passwordFormModel.newPassword !== this.store.passwordFormModel.repeatNewPassword) {

          this.store.setPasswordUpdateFailed(false);
        }
      })
    ));

    this.mobxReaction('PasswordUpdateComponent: store.passwordUpdateFailed',
      () => this.store.passwordUpdateFailed,
      passwordUpdateFailed => this.passwordUpdateFailedChange.emit(passwordUpdateFailed));
  }

  onSubmit() {

    this.passwordUpdate.emit(this.store.passwordFormModel.newPassword);
  }

  private passwordFormValidator: ValidatorFn = control => {

    const passwordValues = control.value as PasswordFormModel;

    const passwordErrors = validatePasswords({
      password: passwordValues.newPassword,
      repeatPassword: passwordValues.repeatNewPassword
    });

    if (passwordErrors) {

      return passwordErrors;
    }

    if (this.passwordUpdateFailed) {

      return validationError(`Setting a new password failed, please try again`);
    }

    return null;
  }

  get newPassword() {

    return this.passwordForm.controls[property<PasswordFormModel>('newPassword')];
  }

  get repeatNewPassword() {

    return this.passwordForm.controls[property<PasswordFormModel>('repeatNewPassword')];
  }
}
