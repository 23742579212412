import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Dialog } from 'primeng/dialog';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { FinancialYear } from '../../generated';
import { MessageSeverity } from '../../models/message';
import { WorkflowContext } from '../../models/workflow-context';
import { switchMapCatch } from '../../rxjs/operators';
import { BaseDomainStore } from '../../store/base-domain.store';
import { BaseComponent } from '../base/base.component';

import { Store } from './process-notes-indicator.store';

@Component({
  selector: 'app-process-notes-indicator',
  templateUrl: './process-notes-indicator.component.html',
  styleUrls: ['./process-notes-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Store]
})
export class ProcessNotesIndicatorComponent extends BaseComponent implements OnInit {

  private readonly getProcessNotes$ = new Subject();
  private readonly getWorkflowProcessId$ = new Subject();

  @ViewChild('processNotesDialog') private readonly processNotesDialog: Dialog;

  readonly MessageSeverity = MessageSeverity;

  @Input() set financialYear(val: FinancialYear | undefined) {

    this.store.setFinancialYear(val);
  }

  @Input() set workflowContext(val: WorkflowContext | undefined) {

    this.store.setWorkflowContext(val);
  }

  @Output() readonly visibleChange = new EventEmitter<boolean>();

  constructor(
    readonly store: Store,
    private readonly domainStore: BaseDomainStore) {

    super();
  }

  ngOnInit(): void {

    this.subscribe(this.getProcessNotes$.pipe(
      switchMapCatch(() => this.store.getProcessNotes())
    ));

    this.subscribe(this.getWorkflowProcessId$.pipe(
      switchMapCatch(() => {

        return this.store.getWorkflowProcessId().pipe(
          switchMap(() => this.store.getProcessNotes())
        );
      })
    ));

    this.mobxReaction('ProcessNotesIndicatorComponent: store.financialYear, domainStore.isTaskActionable',
      () => ({
        financialYear: this.store.financialYear,
        isTaskActionable: this.domainStore.isTaskActionable
      }),
      effect => {

        if (effect.financialYear && !effect.isTaskActionable) {

          // A financial year has been set and we did not navigate from the task queue - see if notes are available
          this.getWorkflowProcessId$.next();
        }
      });

    this.mobxReaction('ProcessNotesIndicatorComponent: store.isVisible',
      () => this.store.isVisible,
      isVisible => {

        if (isVisible) {

          this.getProcessNotes$.next();
        }

        this.visibleChange.emit(isVisible);
      });
  }

  onClick() {

    this.store.setProcessNotesDialogVisible(true);
  }

  onProcessNotesFetched() {

    if (this.processNotesDialog.visible) {

      this.processNotesDialog.modal = true;
    }

    this.getProcessNotes$.next();
  }
}
