<div *mobxAutorun>
  <div class="p-grid ui-fluid">
    <div class="p-col-12">
      <app-info-message [message]="infoMessage"></app-info-message>
      <app-info-message *ngIf="isUnlinkedOwnersMessageVisible" [message]="unlinkedOwnersMessage"
        [severity]="MessageSeverity.Warn">
      </app-info-message>
    </div>
    <div class="p-col-12">
      <app-table [dataKey]="userIdProp" [value]="owners" [selection]="store.ownersSelected" [rows]="5"
        [showCaption]="false" (selectionChange)="store.setOwnersSelected($event)">
        <ng-template pTemplate="header">
          <tr appTableRow>
            <th appTableHeader>
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th [appTableHeader]="userFirstNameProp" title="name" pSortableColumn></th>
            <th [appTableHeader]="userfullNameProp" title="registered name" pSortableColumn></th>
            <ng-container *ngIf="newEmail">
              <th [appTableHeader]="userEmailProp" title="existing email" pSortableColumn></th>
              <th appTableHeader title="new email"></th>
            </ng-container>
            <ng-container *ngIf="newMobile">
              <th [appTableHeader]="userMobileProp" title="existing mobile" pSortableColumn></th>
              <th appTableHeader title="new mobile"></th>
            </ng-container>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
          <tr appTableRow [pSelectableRow]="user">
            <td appTableData>
              <p-tableCheckbox [value]="user"></p-tableCheckbox>
            </td>
            <td appTableData>{{displayName(user)}}</td>
            <td appTableData>{{user[userfullNameProp]}}</td>
            <ng-container *ngIf="newEmail">
              <td appTableData>{{user[userEmailProp]}}</td>
              <td appTableData>
                <ng-container *ngIf="isNewEmailOrMobileVisible(user)">{{newEmail}}</ng-container>
              </td>
            </ng-container>
            <ng-container *ngIf="newMobile">
              <td appTableData>{{displayMoblile(user)}}</td>
              <td appTableData>
                <ng-container *ngIf="isNewEmailOrMobileVisible(user)">{{newMobile}}</ng-container>
              </td>
            </ng-container>
          </tr>
        </ng-template>
      </app-table>
    </div>
  </div>
  <div *ngIf="showDialogFooter" appDialogFooter>
    <button pButton [kind]="ButtonKind.Ok" type="button" (click)="onOk()" [disabled]="!store.isOKEnabled"></button>
    <button pButton [kind]="ButtonKind.Cancel" type="button" (click)="onCancel()"></button>
  </div>
</div>
