<div *mobxAutorun class="p-grid">
  <div class="p-col-12 p-md-6">
    <form class="p-grid form-group" [formGroup]="paidInvoiceForm" (ngSubmit)="formSubmit.emit()">
      <div class="p-col-12" [class.wht-display-none]="!store.processNotesIndicatorVisibility">
        <app-process-notes-indicator *ngIf="appProperties.portalType === PortalType.Manager"
          [financialYear]="selectedPaidInvoicesFinancialYear" [workflowContext]="WorkflowContext.Accounts"
          (visibleChange)="store.setProcessNotesIndicatorVisibility($event)">
        </app-process-notes-indicator>
      </div>
      <div *ngIf="numberOfRecordsReconciledMessage || financialYearReconciledMessage" class="p-col-12">
        <app-info-message [message]="numberOfRecordsReconciledMessage" [severity]="numberOfRecordsReconciledSevertity">
        </app-info-message>
        <app-info-message [message]="financialYearReconciledMessage" [severity]="MessageSeverity.Warn">
        </app-info-message>
      </div>
      <div class="p-col-12 p-md-4">
        <label>Financial Year</label>
      </div>
      <div class="p-col-12 p-md-8">
        <p-dropdown placeholder="Select Financial Year" optionLabel="displayTitle"
          [options]="paidInvoicesFinancialYears" [ngModel]="selectedPaidInvoicesFinancialYear"
          (ngModelChange)="selectedPaidInvoicesFinancialYearChange.emit($event)" [ngModelOptions]="{standalone: true}">
        </p-dropdown>
      </div>
      <ng-container *ngIf="consolidatedYearVisible">
        <div class="p-col-12 p-md-4">
          <label>Consolidated Accounts</label>
        </div>
        <div class="p-col-12 p-md-2">
          <p-checkbox [binary]="true" [disabled]="!consolidatedYearEnabled" [ngModel]="consolidatedYear"
            (ngModelChange)="consolidatedYearChange.emit($event)" [ngModelOptions]="{standalone: true}"
            (onChange)="consolidatedYearOnChange.emit($event.checked)"></p-checkbox>
        </div>
      </ng-container>
      <ng-container>
        <div class="p-col-12 p-md-4" style="text-align: end">
          <label>Audit Required</label>
        </div>
        <div class="p-col-12 p-md-2" style="text-align: end">
          <p-checkbox [binary]="true" [disabled]="true" [ngModel]="audited" [ngModelOptions]="{standalone: true}">
          </p-checkbox>
        </div>
      </ng-container>
      <ng-container *ngIf="lockPaidInvoicesVisible">
        <div class="p-col-12 p-md-4">
          <label>Lock</label>
        </div>
        <div class="p-col-12 p-md-2">
          <p-checkbox [binary]="true" [disabled]="!lockPaidInvoicesEnabled" [ngModel]="lockPaidInvoices"
            (ngModelChange)="lockPaidInvoicesChange.emit($event)" [ngModelOptions]="{standalone: true}"
            (onChange)="lockPaidInvoicesOnChange.emit($event.checked)"></p-checkbox>
        </div>
        <div class="p-col-12 p-md-4" style="text-align: end">
          <app-task-done-button *ngIf="taskDoneVisible" [enabled]="taskDoneEnabled"
            (taskDoneClick)="taskDoneClick.emit()">
          </app-task-done-button>
        </div>
      </ng-container>
      <div class="p-col-12 p-md-4">
        <label>Hide Reconciled</label>
      </div>
      <div class="p-col-12 p-md-2">
        <p-checkbox [binary]="true" [ngModel]="hideReconciledPaidInvoices"
          (ngModelChange)="hideReconciledPaidInvoicesChange.emit($event)" [ngModelOptions]="{standalone: true}">
        </p-checkbox>
      </div>
      <ng-container *ngIf="sortByAmountVisible">
        <div class="p-col-12 p-md-4" style="text-align: end">
          <label>Sort by Amount</label>
        </div>
        <div class="p-col-12 p-md-2" style="text-align: end">
          <p-checkbox [binary]="true" [ngModel]="store.sortByAmount" (ngModelChange)="store.setSortByAmount($event)"
            [ngModelOptions]="{standalone: true}">
          </p-checkbox>
        </div>
      </ng-container>
      <div class="p-col-12">
        <app-table #transactionsTable selectionMode="single" [dataKey]="whiteFolderInvoiceSummaryPropertyId"
          [value]="paidInvoices" [selection]="selectedPaidInvoice" [sortMode]="store.sortMode" [showCaption]="false"
          [multiSortMeta]="paidInvoicesMultiSortMeta" (selectionChange)="selectedPaidInvoiceChange.emit($event)"
          [customSort]="store.customSort" (sortFunction)="transactionsCustomSort($event)">
          <ng-template pTemplate="header">
            <tr appTableRow>
              <th [appTableHeader]="whiteFolderInvoiceSummaryPropertyReconciled" title="reconciled" pSortableColumn
                [pSortableColumnDisabled]="store.customSort">
              </th>
              <th [appTableHeader]="whiteFolderInvoiceSummaryPropertyDatePaid" title="transaction date" pSortableColumn
                  [pSortableColumnDisabled]="store.customSort"></th>
              <th [appTableHeader]="whiteFolderInvoiceSummaryPropertyAmountValue" title="amount" pSortableColumn></th>
              <th [appTableHeader]="whiteFolderInvoiceSummaryPropertyGstValue" title="GST" pSortableColumn
                [pSortableColumnDisabled]="store.customSort"></th>
              <th [appTableHeader]="whiteFolderInvoiceSummaryPropertyAccountNumber" title="account #" pSortableColumn
                [pSortableColumnDisabled]="store.customSort" [filter]="TableHeaderFilterType.Input"></th>
              <th [appTableHeader]="whiteFolderInvoiceSummaryPropertyReference" title="reference" pSortableColumn
                [pSortableColumnDisabled]="store.customSort" [filter]="TableHeaderFilterType.Input"></th>
              <th [appTableHeader]="whiteFolderInvoiceSummaryPropertyAccountDescription" title="description"
                pSortableColumn [pSortableColumnDisabled]="store.customSort" [filter]="TableHeaderFilterType.Input">
              </th>
              <th [appTableHeader]="whiteFolderInvoiceSummaryPropertyDatePaid" title="date paid" pSortableColumn
                [pSortableColumnDisabled]="store.customSort"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-invoice>
            <tr appTableRow [pSelectableRow]="invoice">
              <td appTableData>
                <div fxLayoutAlign="center center">
                  <p-checkbox [binary]="true" [disabled]="!isPaidInvoiceCheckboxEnabled(invoice)"
                    [ngModel]="invoice.reconciled" [ngModelOptions]="{standalone: true}"
                    (onChange)="selectedPaidInvoiceReconciledOnChange.emit({reconciled: $event.checked, invoice: invoice})">
                  </p-checkbox>
                </div>
              </td>
              <td appTableData>{{getInvoiceTransactionDate(invoice)  | dateFormat}}</td>
              <td appTableData>{{getInvoiceAmount(invoice) | currencyFormat}}</td>
              <td appTableData>{{getInvoiceGst(invoice) | currencyFormat}}</td>
              <td appTableData>{{getInvoiceAccountNumber(invoice)}}</td>
              <td appTableData>{{getInvoiceReference(invoice)}}</td>
              <td appTableData>{{getInvoiceAccountDescription(invoice)}}</td>
              <td appTableData>{{getInvoiceDatePaid(invoice)  | dateFormat}}</td>
            </tr>
          </ng-template>
        </app-table>
      </div>
      <div class="p-col-12">
        <hr>
      </div>
      <ng-container *ngIf="isPaidInvoiceBrowserReconcileMode">
        <div class="p-col-12 p-md-4">
          <label>Reconciled</label>
        </div>
        <div class="p-col-12 p-md-8">
          <p-checkbox [binary]="true" [formControl]="paidInvoiceReconciled"></p-checkbox>
        </div>
      </ng-container>
      <div class="p-col-12 p-md-4">
        <label>Reconciled Comments</label>
      </div>
      <div class="p-col-12 p-md-8">
        <span *ngIf="isPaidInvoiceBrowserReconcileMode; else reconciledComments" class="md-inputfield">
          <input pInputText [formControl]="paidInvoiceReconciledComments">
          <app-form-error [form]="paidInvoiceForm" [control]="paidInvoiceReconciledComments" [untouched]="true">
          </app-form-error>
        </span>
        <ng-template #reconciledComments>{{selectedPaidInvoice?.reconciledComments}}</ng-template>
      </div>
      <div class="p-col-12 p-md-4">
        <label>Transaction Comments</label>
      </div>
      <div class="p-col-12 p-md-8">
        {{selectedPaidInvoice?.comments}}
      </div>
      <ng-container *ngIf="isPaidInvoiceBrowserReconcileMode">
        <div class="p-col-12">
          <hr>
        </div>
        <div class="p-col-12" style="text-align: center">
          <button pButton [kind]="ButtonKind.Save" type="submit"
            [disabled]="!selectedPaidInvoice || !paidInvoiceForm.valid"></button>
        </div>
        <div class="p-col-12">
          <ng-content select="app-scan-upload"></ng-content>
        </div>
      </ng-container>
    </form>
  </div>
  <div class="p-col-12 p-md-6">
    <app-pdf-viewer [src]="selectedPaidInvoiceDocumentFileData?.data"
      [filename]="selectedPaidInvoiceDocumentFileData?.filename"></app-pdf-viewer>
  </div>
</div>
