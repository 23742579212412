import { MediaType } from '../models/media-type';

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

export function toFileSizeDisplayFormat(bytes: number, precision = 1): string {

  if (bytes < 0 || isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) {

    return '-';
  }

  let unit = 0;
  let theBytes = bytes;

  while (theBytes >= 1024) {

    theBytes /= 1024;

    unit++;
  }

  return `${theBytes.toFixed(precision)} ${units[unit]}`;
}

export function base64toBlob(base64Data: string, contentType = '', sliceSize = 512): Blob {

  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {

    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {

      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

export async function base64toBlobAsync(base64Data: string, mediaType: MediaType): Promise<Blob> {

  const url = `data:${mediaType};base64,${base64Data}`;

  const response = await fetch(url);

  return response.blob();
}

export function stripMimeTypeFromBase64DataURL(dataURL: string) {

  const split = dataURL.split(',');

  return split.length > 1 ? split[1] : split[0];
}
