/**
 * Whittles API
 * Whittles API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type LoginType = 'BUILDING' | 'OWNER';

export const LoginType = {
    BUILDING: 'BUILDING' as LoginType,
    OWNER: 'OWNER' as LoginType
};
