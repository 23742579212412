import { Component } from '@angular/core';
import { appSubmenuTrigger, PrimeNGAppMenuComponent, PrimeNGAppSubMenuComponent } from 'common-lib';

import { AppComponent } from './app.component';
import { LeftSideMenuStore } from './stores/left-side-menu.store';

// tslint:disable-next-line: prefer-on-push-component-change-detection
@Component({
  selector: 'app-menu',
  templateUrl: '../../../common-lib/src/lib/templates/app-menu/app-menu.component.html'
})
export class AppMenuComponent extends PrimeNGAppMenuComponent {

  constructor(readonly leftSideMenuStore: LeftSideMenuStore) {

    super();
  }
}

// tslint:disable-next-line: prefer-on-push-component-change-detection
@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-submenu]',
  templateUrl: '../../../common-lib/src/lib/templates/app-menu/app-submenu.component.html',
  animations: [appSubmenuTrigger]
})
export class AppSubMenuComponent extends PrimeNGAppSubMenuComponent {

  constructor(app: AppComponent) {

    super(app);
  }
}
