export enum Heading {
  Home = 'home',
  SelectBuidling = 'select building',
  YourProfile = 'your profile',
  BuildingInfo = 'building info',
  YourBuilding = 'your building',
  PayMyBills = 'pay my bills',
  AccountHistory = 'account history',
  Invoices = 'invoices',
  Documents = 'documents',
  FinancialRecords = 'financial records',
  FinancialTransactions = 'financial transactions',
  ContactUs = 'contact us',
  Logout = 'logout'
}
