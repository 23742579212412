import { action, computed, observable } from 'mobx';
import { EMPTY, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BaseAppProperties } from '../base-app-properties';
import {
  FileData,
  FinancialYear,
  NameValuePair,
  WhiteFolderBrowserSummary,
  WhiteFolderFinancialYearSummary,
  WhiteFolderInvoiceSummary
} from '../generated';
import { Authority } from '../models/authority';
import { MessageSeverity } from '../models/message';
import { WhiteFolderService } from '../services/white-folder.service';
import { BaseAuthenticationStore } from '../store/base-authentication.store';
import { BaseDomainStore } from '../store/base-domain.store';
import {DocumentService} from "../services/document.service";

export abstract class BaseWhiteFolderStore {

  @observable
  protected _selectedBrowserFinancialYear?: FinancialYear;

  @observable
  protected _selectedBrowserCategory?: NameValuePair;

  @observable
  protected _selectedBrowserDocument?: WhiteFolderBrowserSummary;

  @observable
  protected _browserDocuments: WhiteFolderBrowserSummary[] = [];

  @observable
  protected _selectedPaidInvoicesFinancialYear?: FinancialYear;

  @observable
  protected _hideReconciledPaidInvoices = false;

  @observable
  protected _selectedPaidInvoice?: WhiteFolderInvoiceSummary;

  @observable
  private _browserFinancialYears: FinancialYear[] = [];

  @observable
  private _browserCategories: NameValuePair[] = [];

  @observable
  private _selectedBrowserDocumentFileData?: FileData;

  @observable
  private _paidInvoicesFinancialYearSummary?: WhiteFolderFinancialYearSummary;

  @observable
  private _paidInvoicesFinancialYears: FinancialYear[] = [];

  @observable
  protected _selectedPaidInvoiceDocumentFileData?: FileData;

  constructor(
    protected readonly documentService: DocumentService,
    protected readonly whiteFolderService: WhiteFolderService,
    protected readonly domainStore: BaseDomainStore,
    protected readonly authenticationStore: BaseAuthenticationStore,
    protected readonly appProperties: BaseAppProperties) {
  }

  getBrowserFinancialYears() {

    return this.whiteFolderService.getBrowserFinancialYears({
      strataId: this.domainStore.selectedStrataId
    }).pipe(
      tap(financialYears => this.setBrowserFinancialYears(financialYears))
    );
  }

  getBrowserCategories() {

    return this.whiteFolderService.getBrowserCategories({
      portalType: this.appProperties.portalType
    }).pipe(
      tap(categories => {

        this.setCategoriesWithAll(categories);
      }));
  }

  setCategoriesWithAll(categories: NameValuePair[]) {
    const categoriesWithAll = [
      {
        name: 'All',
        value: ''
      },
      ...categories
    ];

    this.setBrowserCategories(categories.length > 1 ? categoriesWithAll : categories);
  }

  getBrowserDocuments() {

    if (!this._selectedBrowserFinancialYear) {

      return of([]);
    }

    return this.whiteFolderService.getBrowserDocuments({
      financialYear: this._selectedBrowserFinancialYear.id,
      portalType: this.appProperties.portalType
    }).pipe(
      tap(documents => this.setBrowserDocuments(documents)),
    );
  }

  getBrowserDocumentData() {

    if (!this._selectedBrowserDocument) {

      return EMPTY;
    }

    return this.whiteFolderService.getDocumentFileData({          // IM-182

      id: this._selectedBrowserDocument.document.id,
      portalType: this.appProperties.portalType
    }).pipe(
      tap(fileData => this.setBrowserDocumentData(fileData))
    );
  }

  getPaidInvoicesFinancialYears() {

    return this.whiteFolderService.getFinancialYears({
      strataId: this.domainStore.selectedStrataId
    }).pipe(
      tap(financialYears => this.setPaidInvoicesFinancialYears(financialYears))
    );
  }

  getPaidInvoicesFinancialYearSummary() {

    if (!this._selectedPaidInvoicesFinancialYear) {

      return EMPTY;
    }

    return this.whiteFolderService.getPaidInvoicesFinancialYearSummary({
      financialYear: this._selectedPaidInvoicesFinancialYear.id
    }).pipe(
      tap(paidInvoiceSummary => {

        this.setPaidInvoicesFinancialYearSummary(paidInvoiceSummary);
      }));
  }

  getPaidInvoiceDocumentData() {

    if (!this._selectedPaidInvoice || !this._selectedPaidInvoice.invoiceId) {

      this.setPaidInvoiceDocumentData(undefined);

      return EMPTY;
    }

    return this.whiteFolderService.getDocumentFileData({          // IM-182

      id: this._selectedPaidInvoice.invoiceId,
      portalType: this.appProperties.portalType
    }).pipe(
      tap(fileData => this.setPaidInvoiceDocumentData(fileData)));
  }

  @action
  private setPaidInvoicesFinancialYears(financialYears: FinancialYear[]) {

    this._paidInvoicesFinancialYears = financialYears;

    this._selectedPaidInvoicesFinancialYear = undefined;
    this.setPaidInvoicesFinancialYearSummary(undefined);
  }

  @action
  private setPaidInvoicesFinancialYearSummary(paidInvoicesFinancialYearSummary: WhiteFolderFinancialYearSummary | undefined) {

    this._paidInvoicesFinancialYearSummary = paidInvoicesFinancialYearSummary;

    if (this.paidInvoicesFinancialYearSummary && this.paidInvoicesFinancialYearSummary.paidInvoices) {

      this.paidInvoicesFinancialYearSummary.paidInvoices.forEach(invoice => {

        invoice.accountNumber = `${invoice.fund}${invoice.accountNumber}`;

        // convert to boolean
        invoice.reconciled = !!invoice.reconciled as any;
      });
    }

    this.clearSelectedPaidInvoice();

    this._hideReconciledPaidInvoices = false;
  }

  @action
  setPaidInvoices(paidInvoices: WhiteFolderInvoiceSummary[]) {

    if (this._paidInvoicesFinancialYearSummary) {

      this._paidInvoicesFinancialYearSummary.paidInvoices = paidInvoices;
    }
  }

  @action
  clearSelectedPaidInvoice() {

    this._selectedPaidInvoice = undefined;
    this._selectedPaidInvoiceDocumentFileData = undefined;
  }

  @action
  clearSelectedPaidInvoiceIfHidden() {

    const selectedPaidInvoiceIsVisible = this.paidInvoices.find(
      item => this._selectedPaidInvoice != null && this._selectedPaidInvoice.id === item.id);

    if (!selectedPaidInvoiceIsVisible) {

      this.clearSelectedPaidInvoice();
    }
  }

  @action
  private setPaidInvoiceDocumentData(fileData: FileData | undefined) {

    this._selectedPaidInvoiceDocumentFileData = fileData;
  }

  @action
  private setBrowserFinancialYears(financialYears: FinancialYear[]) {

    this._browserFinancialYears = financialYears;

    this._selectedBrowserFinancialYear = undefined;

    this.setBrowserDocuments([]);
  }

  @action
  protected setBrowserCategories(categories: NameValuePair[]) {

    this._browserCategories = categories;
  }

  @action
  private setBrowserDocuments(documents: WhiteFolderBrowserSummary[]) {

    this._browserDocuments = documents;

    this.clearSelectedBrowserDocument();
  }

  @action
  private clearSelectedBrowserDocument() {

    this._selectedBrowserDocument = undefined;
    this._selectedBrowserDocumentFileData = undefined;
  }

  @action
  private setBrowserDocumentData(fileData: FileData) {

    this._selectedBrowserDocumentFileData = fileData;
  }

  get selectedBrowserCategory() {

    return this._selectedBrowserCategory;
  }

  @action
  setSelectedBrowserCategory(val: NameValuePair | undefined) {

    this._selectedBrowserCategory = val;

    this.clearSelectedBrowserDocument();
  }

  get selectedBrowserFinancialYear() {

    return this._selectedBrowserFinancialYear;
  }

  @action
  setSelectedBrowserFinancialYear(val: FinancialYear | undefined) {

    this._selectedBrowserFinancialYear = val;
  }

  get selectedBrowserDocument() {

    return this._selectedBrowserDocument;
  }

  @action
  setSelectedBrowserDocument(val: WhiteFolderBrowserSummary | undefined) {

    if (val) {

      this._selectedBrowserDocument = val;
    }
  }

  @computed
  get browserDocuments() {

    const selectedBrowserCategory = this._selectedBrowserCategory;

    if (!selectedBrowserCategory || !selectedBrowserCategory.value) {

      return this._browserDocuments;
    }

    return this._browserDocuments.filter(doc =>
      doc.document.category != null && doc.document.category.value === selectedBrowserCategory.value);
  }

  @computed
  get numberOfInvoicesReconciledCount(): number {

    if (!this.paidInvoicesFinancialYearSummary || !this.paidInvoicesFinancialYearSummary.paidInvoices) {

      return 0;
    }

    return this.paidInvoicesFinancialYearSummary.paidInvoices.filter(invoice => invoice.reconciled).length;
  }

  @computed
  get numberOfRecordsReconciledMessage(): string {

    if (!this.paidInvoicesFinancialYearSummary || !this.paidInvoicesFinancialYearSummary.paidInvoices ||
      this.paidInvoicesFinancialYearSummary.paidInvoices.length === 0) {

      return '';
    }

    return `${this.numberOfInvoicesReconciledCount} of ${this.paidInvoicesFinancialYearSummary.paidInvoices.length} records reconciled.`;
  }

  @computed
  get financialYearReconciledMessage(): string {

    if (!this.paidInvoicesFinancialYearSummary) {

      return '';
    }

    const showMessage = this.paidInvoicesFinancialYearSummary.reconciled && this.authenticationStore.hasAnyAuthority(Authority.Accounts);

    return showMessage ? 'Financial year reconciled, editing unavailable.' : '';
  }

  @computed
  get allPaidInvoiceRecordsReconciled(): boolean {

    return this.paidInvoicesFinancialYearSummary != null && this.paidInvoicesFinancialYearSummary.paidInvoices != null &&
      this.paidInvoicesFinancialYearSummary.paidInvoices.length === this.numberOfInvoicesReconciledCount;
  }

  @computed
  get numberOfRecordsReconciledSevertity(): MessageSeverity {

    return this.allPaidInvoiceRecordsReconciled ? MessageSeverity.Success : MessageSeverity.Info;
  }

  get selectedPaidInvoicesFinancialYear() {

    return this._selectedPaidInvoicesFinancialYear;
  }

  @action
  setSelectedPaidInvoicesFinancialYear(val: FinancialYear | undefined) {

    this._selectedPaidInvoicesFinancialYear = val;
  }

  @computed
  get lockPaidInvoices() {

    return this.paidInvoicesFinancialYearSummary != null && this.paidInvoicesFinancialYearSummary.reconciled != null;
  }

  @action
  setLockPaidInvoices(val: boolean) {

    if (!this.paidInvoicesFinancialYearSummary) {

      return;
    }

    this.paidInvoicesFinancialYearSummary.reconciled = val ? new Date() : undefined;
  }

  @computed
  get consolidatedYear() {

    return this.paidInvoicesFinancialYearSummary != null && this.paidInvoicesFinancialYearSummary.consolidated;
  }

  @action
  setConsolidatedYear(val: boolean) {

    if (!this.paidInvoicesFinancialYearSummary) {

      return;
    }

    this.paidInvoicesFinancialYearSummary.consolidated = val;
  }

  get hideReconciledPaidInvoices() {

    return this._hideReconciledPaidInvoices;
  }

  @action
  setHideReconciledPaidInvoices(val: boolean) {

    this._hideReconciledPaidInvoices = val;
  }

  @computed
  get paidInvoices(): WhiteFolderInvoiceSummary[] {

    if (!this.paidInvoicesFinancialYearSummary || !this.paidInvoicesFinancialYearSummary.paidInvoices) {

      return [];

    } else if (this._hideReconciledPaidInvoices) {

      return this.paidInvoicesFinancialYearSummary.paidInvoices.filter(invoice => !invoice.reconciled);

    } else {

      return this.paidInvoicesFinancialYearSummary.paidInvoices;
    }
  }

  get selectedPaidInvoice() {

    return this._selectedPaidInvoice;
  }

  @action
  setSelectedPaidInvoice(val: WhiteFolderInvoiceSummary | undefined) {

    this._selectedPaidInvoice = val;
  }

  get browserFinancialYears() {

    return this._browserFinancialYears;
  }

  get browserCategories() {

    return this._browserCategories;
  }

  get selectedBrowserDocumentFileData() {

    return this._selectedBrowserDocumentFileData;
  }

  get paidInvoicesFinancialYearSummary() {

    return this._paidInvoicesFinancialYearSummary;
  }

  get paidInvoicesFinancialYears() {

    return this._paidInvoicesFinancialYears;
  }

  get selectedPaidInvoiceDocumentFileData() {

    return this._selectedPaidInvoiceDocumentFileData;
  }

  get audited(): boolean {

    return this._paidInvoicesFinancialYearSummary != null && this._paidInvoicesFinancialYearSummary.audited;
  }
}
