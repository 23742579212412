<!-- <div class="timeline" [class.card]="cardWrapper">
  <div class="p-grid">
    <ng-container *ngFor="let event of events">
      <div class="p-col-3">
        <span class="event-time">{{event.eventTime | dateFormat}}</span>
        <i fxLayoutAlign="center center" [ngClass]="['fas', event.fawIcon, event.cssClass]"></i>
      </div>
      <div class="p-col-9">
        <span class="event-title" [ngClass]="event.cssClass">{{event.title}}</span>
        <span class="event-text">{{event.description}}</span>
      </div>
    </ng-container>
  </div>
</div> -->

<div [class.card]="cardWrapper">
  <p-dataView [value]="events" [rows]="10">
    <ng-template let-event pTemplate="listItem">
      <div fxLayout>
        <div class="time" fxFlex="25" fxFlex.lt-md="50">
          <span class="event-time">{{event.eventTime | dateFormat}}</span>
          <i fxLayoutAlign="center center" [ngClass]="['fas', event.fawIcon, event.cssClass]"></i>
        </div>
        <div class="event" fxFlex>
          <span class="event-title" [ngClass]="event.cssClass">{{event.title}}</span>
          <span class="event-text">{{event.description}}</span>
        </div>
      </div>
    </ng-template>
  </p-dataView>
</div>
