import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BaseComponent } from '../base/base.component';

export interface TimelineEvent {
  eventTime: Date;
  title: string;
  description: string;
  fawIcon: string;
  cssClass: string;
}

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineComponent extends BaseComponent {

  @Input() cardWrapper = true;

  @Input() events: TimelineEvent[] = [];
}
