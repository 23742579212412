import { DialogWidth } from '../models/dialog-width';

import { BrowserUtils } from './browser.utils';

export function routeOutletHeight(): number {

  return window.innerHeight - 120;
}

export function calculateDialogWidth(width: string): string {

  switch (width) {

    case DialogWidth.Screen25:

      return `${screen.width * (1 / 4)}px`;

    case DialogWidth.Screen33:

      return `${screen.width * (1 / 3)}px`;

    case DialogWidth.Screen50:

      return `${screen.width * (1 / 2)}px`;

    case DialogWidth.Screen66:

      return `${screen.width * (2 / 3)}px`;

    case DialogWidth.Screen75:

      return `${screen.width * (3 / 4)}px`;

    case DialogWidth.Default:

      if (BrowserUtils.Mobile) {

        return '100%';
      }

      if (BrowserUtils.Tablet) {

        return calculateDialogWidth(DialogWidth.Screen66);
      }

      return calculateDialogWidth(DialogWidth.Screen33);

    default:

      return width;
  }
}
