<form *mobxAutorun [formGroup]="profileForm" class="ui-fluid p-grid form-group" (ngSubmit)="onSubmit()">

  <!-- left column -->
  <div class="p-col-12 p-md-6 p-grid form-group" style="padding: 0">
    <div class="p-col-12 p-md-4">
      <label>Registered Name</label>
    </div>
    <div class="p-col-12 p-md-8">
      {{store.ownerProfileDetails?.owner.registeredOwner}}
    </div>
    <div class="p-col-12 p-md-4">
      <label>Email</label>
    </div>
    <div class="p-col-12 p-md-8">
      <span class="md-inputfield">
        <input pInputText type="text" [formControl]="email" />
        <app-form-error [form]="profileForm" [control]="[email, emails, profileForm]" [untouched]="true">
        </app-form-error>
      </span>
    </div>
    <div class="p-col-12 p-md-4">
      <label>Confirm Email</label>
    </div>
    <div class="p-col-12 p-md-8">
      <span class="md-inputfield">
        <input pInputText type="text" [formControl]="confirmEmail" (paste)="onConfirmEmailPaste($event)" />
        <app-form-error [form]="profileForm" [control]="[confirmEmail, emails, profileForm]" [untouched]="true">
        </app-form-error>
      </span>
    </div>
    <div class="p-col-12 p-md-4">
      <label>Email (cc)</label>
    </div>
    <div class="p-col-12 p-md-8">
      <span class="md-inputfield">
        <input pInputText [formControl]="emailCc" />
        <app-form-error [form]="profileForm" [control]="emailCc" [untouched]="true"></app-form-error>
      </span>
    </div>
    <div class="p-col-12 p-md-4">
      <label>Password</label>
    </div>
    <div class="p-col-12 p-md-8">
      <span class="md-inputfield">
        <input pInputText [type]="store.passwordInputType" [formControl]="loginPassword" />
        <ng-container *ngTemplateOutlet="passwordVisibleIconTpl"></ng-container>
        <app-form-error [form]="profileForm" [control]="[loginPassword, passwords]" [untouched]="true">
        </app-form-error>
      </span>
    </div>
    <div class="p-col-12 p-md-4">
      <label>Confirm Password</label>
    </div>
    <div class="p-col-12 p-md-8">
      <span class="md-inputfield">
        <input pInputText [type]="store.passwordInputType" [formControl]="confirmLoginPassword" />
        <ng-container *ngTemplateOutlet="passwordVisibleIconTpl"></ng-container>
        <app-form-error [form]="profileForm" [control]="[confirmLoginPassword, passwords]" [untouched]="true">
        </app-form-error>
      </span>
    </div>
    <div [appHide]="appStore.isMqAliasLtMd" class="p-col-12 p-md-4"></div>
    <div class="p-col-12 p-md-8">
      <app-password-strength [password]="profileFormModelValue.passwords.loginPassword"></app-password-strength>
    </div>
  </div>

  <!-- right column -->
  <div class="p-col-12 p-md-6 p-grid form-group" style="padding: 0">
    <div class="p-col-12 p-md-4">
      <label>Account Notices Method</label>
    </div>
    <div class="p-col-12 p-md-8">
      {{store.accountsNoticesMethod}}
    </div>
    <div class="p-col-12 p-md-4">
      <label>Correspondence Method</label>
    </div>
    <div class="p-col-12 p-md-8">
      <p-dropdown [options]="correspondenceMethods" [formControl]="correspondenceMethod"></p-dropdown>
      <span class="md-inputfield">
        <app-form-error [form]="profileForm" [control]="[correspondenceMethod]" [untouched]="true">
        </app-form-error>
      </span>
    </div>
    <div class="p-col-12 p-md-4">
      <label>Mobile</label>
    </div>
    <div class="p-col-12 p-md-8">
      <span class="md-inputfield">
        <input pInputText [formControl]="mobile">
        <app-form-error [form]="profileForm" [control]="mobile" [untouched]="true"></app-form-error>
      </span>
    </div>
    <div class="p-col-12 p-md-4">
      <label>Work Phone</label>
    </div>
    <div class="p-col-12 p-md-8">
      <span class="md-inputfield">
        <input pInputText [formControl]="workPhone">
        <app-form-error [form]="profileForm" [control]="workPhone" [untouched]="true"></app-form-error>
      </span>
    </div>
    <div class="p-col-12 p-md-4">
      <label>Home Phone</label>
    </div>
    <div class="p-col-12 p-md-8">
      <span class="md-inputfield">
        <input pInputText [formControl]="homePhone">
        <app-form-error [form]="profileForm" [control]="homePhone" [untouched]="true"></app-form-error>
      </span>
    </div>
  </div>

  <div class="p-col-12">
    <hr>
  </div>
  <div class="p-col-12 p-md-2">
    <label>Accounts Address</label>
  </div>
  <div class="p-col-12 p-md-4">
    <app-text-block [lines]="store.ownerProfileDetails?.accountsAddress"></app-text-block>
  </div>
  <div class="p-col-12 p-md-2">
    <label>Correspondence Address</label>
  </div>
  <div class="p-col-12 p-md-4">
    <app-text-block [lines]="store.ownerProfileDetails?.correspondenceAddress"></app-text-block>
  </div>
  <div class="p-col-12">
    <hr>
  </div>
  <ng-container *ngIf="showComments">
    <div class="p-col-12">
      <app-info-message [message]="updateProfileDetailsMessage"></app-info-message>
    </div>
    <div class="p-col-12">
      <textarea pInputTextarea [rows]="10"
        placeholder="Please click here to describe the changes you require us to make."
        [formControl]="comments"></textarea>
    </div>
  </ng-container>
  <div class="p-col-12" style="text-align: center">
    <div fxLayoutAlign="center center" fxLayoutGap="5px">
      <button pButton type="submit" label="save changes" [disabled]="!profileForm.valid" icon="ui-icon-check"></button>
      <button pButton type="button" label="cancel changes" (click)="resetForm()" icon="ui-icon-clear"></button>
    </div>
    <span class="md-inputfield">
      <app-form-error [positionStatic]="true" [visible]="!profileForm.valid" [fontSize]="FormErrorFontSize.Normal">
        Please correct any invalid information as indicated above</app-form-error>
    </span>
  </div>
</form>

<ng-template #passwordVisibleIconTpl>
  <i class="wht-inputfield-icon-right" [ngClass]="{
    'ui-icon-visibility-off': store.passwordVisible,
    'ui-icon-visibility': !store.passwordVisible
  }" (click)="store.togglePasswordVisible()"></i>
</ng-template>
