import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { autorun, IReactionDisposer } from 'mobx';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[mobxAutorun]'
})
export class MobxAutorunDirective implements OnInit, OnDestroy {

  private reactionDisposer: IReactionDisposer;

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainerRef: ViewContainerRef) {
  }

  ngOnInit(): void {

    this.autorunEmbeddedView();
  }

  ngOnDestroy(): void {

    this.reactionDisposer();
  }

  private autorunEmbeddedView() {

    const view = this.viewContainerRef.createEmbeddedView(this.templateRef);

    this.reactionDisposer = autorun(
      () => view.detectChanges(),
      {
        name: `${(view as any)._lView[1].template.name}.detectChanges()`
      });
  }
}
