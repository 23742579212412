import { NgModule } from '@angular/core';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';

@NgModule({
  exports: [
    InputTextareaModule,
    InputTextModule,
    ButtonModule,
    ToastModule,
    DropdownModule,
    MenuModule,
    PanelModule,
    ConfirmDialogModule,
    InputMaskModule,
    DialogModule,
    CheckboxModule,
    TreeModule,
    TreeTableModule,
    MessagesModule,
    MessageModule,
    TabViewModule,
    ScrollPanelModule,
    RadioButtonModule,
    TabMenuModule
  ]
})
export class PrimeNGModule {
}
