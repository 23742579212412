import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseAppProperties } from '../base-app-properties';
import {
  AddPlanAccessParams,
  Configuration,
  CreateAdditionalChargeParams,
  DeletePlanAccessParams,
  MeetingSummary,
  MeetingSummaryContributionParams,
  MeetingSummaryParams,
  MeetingSummaryStatusParams,
  SmsOwnerParams,
  StrataService as StrataServiceGenerated,
  SubmitAdditionalChargeParams,
  SubmitCommitteeDetailsParams,
  UpdateAdditionalChargeParams,
  UpdatePlanAccessUserParams
} from '../generated';
import { PlanNoteType } from '../models/plan-note-type';
import {Observable} from "rxjs/Observable";

@Injectable({
  providedIn: 'root'
})
export class StrataService {

  private readonly strataServiceGenerated: StrataServiceGenerated;

  constructor(httpClient: HttpClient, appProperties: BaseAppProperties) {

    this.strataServiceGenerated = new StrataServiceGenerated(httpClient, appProperties.irisServerBasePath, new Configuration());
  }

  getOwnerPlanSummaryDetails(params: {
    strataId: string;
    showActivityIndicator?: boolean;
  }) {

    const showActivityIndicator = params.showActivityIndicator == null || params.showActivityIndicator;

    return this.strataServiceGenerated.getPlanSummaryDetails(params.strataId, showActivityIndicator);
  }

  getPlanSummary(params: {
    strataId: string,
    showActivityIndicator?: boolean;
  }) {

    return this.strataServiceGenerated.getPlanSummarySummary(params.strataId, !!params.showActivityIndicator);
  }

  submitCommitteeDetails(submitCommitteeDetailsParams: SubmitCommitteeDetailsParams) {

    return this.strataServiceGenerated.submitCommitteeDetails(submitCommitteeDetailsParams);
  }

  getStrataCommitteeApproverDetails(params: {
    strataId: string
  }) {

    return this.strataServiceGenerated.getStrataCommitteeApproverDetails(params.strataId);
  }

  getUnitEPaymentDetails() {

    return this.strataServiceGenerated.getUnitEPaymentDetails();
  }

  getUnitAccountHistory(historyDays: number) {

    return this.strataServiceGenerated.getUnitAccountHistory(historyDays);
  }


  isApprover(params: {
    id: string;
    showActivityIndicator?: boolean;
  }) {

    const showActivityIndicator = params.showActivityIndicator == null ? true : params.showActivityIndicator;

    return this.strataServiceGenerated.isApprover(params.id, showActivityIndicator);
  }


  getPlanDetails(params: {
    id: string;
    showActivityIndicator?: boolean;
  }) {

    const showActivityIndicator = params.showActivityIndicator == null ? true : params.showActivityIndicator;

    return this.strataServiceGenerated.getPlanDetails(params.id, showActivityIndicator);
  }

  getPlanSummaries(params: {
    companyId?: string;
    managerId?: string;
    strataIds?: string[];
    controlAccounts?: boolean;
    newBusiness?: boolean;
    byPassCache?: boolean;
  }) {

    return this.strataServiceGenerated.getPlanSummaries(
      params.companyId,
      params.managerId,
      params.strataIds,
      params.controlAccounts,
      params.newBusiness,
      params.byPassCache);
  }

  getBuildingSummaries() {

    return this.strataServiceGenerated.getBuildingSummaries();
  }

  getPlanAccessUsers(params: {
    strataId: string
  }) {

    return this.strataServiceGenerated.getPlanAccessUsers(params.strataId);
  }

  addPlanAccess(params: AddPlanAccessParams) {

    return this.strataServiceGenerated.addPlanAccess(params);
  }

  deletePlanAccess(params: DeletePlanAccessParams) {

    return this.strataServiceGenerated.deletePlanAccess(params);
  }

  updatePlanAccessUser(params: UpdatePlanAccessUserParams) {

    return this.strataServiceGenerated.updatePlanAccessUser(params);
  }

  getOwners(params: {
    strataId: string;
    committeeOnly?: boolean;
    includeExternalCommittee?: boolean;
    showActivityIndicator?: boolean;
  }) {

    // const showActivityIndicator = params.showActivityIndicator == null ? true : params.showActivityIndicator;

    return this.strataServiceGenerated.getOwners(params.strataId, params.committeeOnly, params.includeExternalCommittee, params.showActivityIndicator);
  }

  getTenants(params: {
    strataId: string;
    showActivityIndicator?: boolean;
  }) {

    const showActivityIndicator = params.showActivityIndicator == null ? true : params.showActivityIndicator;

    return this.strataServiceGenerated.getTenants(params.strataId, showActivityIndicator);
  }

  getNotes(params: {
    strataId: string;
    noteType: PlanNoteType;
    showActivityIndicator?: boolean;
  }) {

    return this.strataServiceGenerated.getNotes(params.strataId, params.noteType, !!params.showActivityIndicator);
  }

  getSmsDetails(params: {
    strataId: string;
  }) {

    return this.strataServiceGenerated.getSmsDetails(params.strataId);
  }

  getSmsHistory(params: {
    strataId: string;
    financialYearId: string;
  }) {

    return this.strataServiceGenerated.getSmsHistory(params.strataId, params.financialYearId);
  }

  sendSms(params: SmsOwnerParams) {

    return this.strataServiceGenerated.sendSms(params);
  }

  getSmsTemplates(params: {
    strataId: string;
  }) {

    return this.strataServiceGenerated.getSmsTemplates(params.strataId);
  }

  getAdditionalCharges(params: {
    strataId: string;
    financialYearId: string;
  }) {

    return this.strataServiceGenerated.getAdditionalCharges(params.strataId, params.financialYearId);
  }

  getPendingPortfolioCharges(params: {
    portfolioId: string;
  }) {

    return this.strataServiceGenerated.getPendingPortfolioCharges(params.portfolioId);
  }

  createAdditionalCharge(params: CreateAdditionalChargeParams) {

    return this.strataServiceGenerated.createAdditionalCharge(params);
  }

  deleteAdditionalCharge(params: {
    additionalChargeId: string;
  }) {

    return this.strataServiceGenerated.deleteAdditionalCharge(params.additionalChargeId);
  }

  updateAdditionalCharge(params: UpdateAdditionalChargeParams) {

    return this.strataServiceGenerated.updateAdditionalCharge(params);
  }

  getPlanEvents(params: {
    strataId: string;
    financialYearId?: string;
    showActivityIndicator?: boolean;
  }) {

    const showActivityIndicator = params.showActivityIndicator == null || params.showActivityIndicator;

    return this.strataServiceGenerated.getPlanEvents(params.strataId, params.financialYearId, showActivityIndicator);
  }

  submitAdditionalCharge(params: SubmitAdditionalChargeParams) {

    return this.strataServiceGenerated.submitAdditionalCharge(params);
  }

  getUnitSummary(args: {
    unitId: string;
    showActivityIndicator?: boolean;
  }) {

    return this.strataServiceGenerated.getUnitSummary(args.unitId, args.showActivityIndicator);
  }

  getUnits(args: {
    strataId: string;
    showActivityIndicator?: boolean;
  }) {

    return this.strataServiceGenerated.getUnits(args.strataId, args.showActivityIndicator);
  }

  getFutureMeetings(args: {
    managerId: string;
  }) {

    return this.strataServiceGenerated.getFutureMeetings(args.managerId);
  }

  getMeetingSummaries(args: {
    strataId: string;
    filter: string;
    status: string;
  }) {

    return this.strataServiceGenerated.getMeetingSummaries(args.strataId, args.filter, args.status);
  }

  getMeetingSummaryCreatedStatus(args: {
    strataId: string;
  }) {

    return this.strataServiceGenerated.getMeetingSummaryCreatedStatus(args.strataId);
  }

  getMeetingSummary(args: { id: string; }) {

    return this.strataServiceGenerated.getMeetingSummary(args.id);
  }

  getMeetingSummaryDefaults(strataId: string): Observable<MeetingSummary> {

    return this.strataServiceGenerated.getMeetingSummaryDefaults(strataId);
  }

  getMeetingSummaryActions(meetingSummaryId: string) {

    return this.strataServiceGenerated.getMeetingSummaryActions(meetingSummaryId);
  }

  getMeetingSummaryContributions(meetingSummaryId: string) {

    return this.strataServiceGenerated.getMeetingSummaryContributions(meetingSummaryId);
  }

  deleteMeetingSummaryContribution(id: string) {

    return this.strataServiceGenerated.deleteMeetingSummaryContribution(id);
  }

  updateMeetingSummary(params: {
    meetingSummaryParams: MeetingSummaryParams
  }) {
    return this.strataServiceGenerated.updateMeetingSummary(params.meetingSummaryParams);
  }

  updateMeetingSummaryStatus(params: {
    id: string,
    status: MeetingSummaryStatusParams.StatusEnum
  }) {
    return this.strataServiceGenerated.updateMeetingSummaryStatus({meetingSummaryId: params.id, status: params.status});
  }

  updateMeetingSummaryContribution(params: {
    meetingSummaryParams: MeetingSummaryContributionParams
  }) {
    return this.strataServiceGenerated.updateMeetingSummaryContribution(params.meetingSummaryParams);
  }

  getAgreementTypes() {
    return this.strataServiceGenerated.getAgreementTypes();
  }

  deleteMeetingSummary(id: string) {
    return this.strataServiceGenerated.deleteMeetingSummary(id);
  }

  getStrataFunds(params: {
    strataId: string
  }) {
    return this.strataServiceGenerated.getStrataFunds(params.strataId);
  }

  strataAddressCheck(addressID: number) {
    return this.strataServiceGenerated.strataAddressCheck(addressID);
  }
}
