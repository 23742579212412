
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  Configuration,
  DocumentService as DocumentServiceGenerated,
  GeneratePdfParams, NameValuePair
} from '../generated';

import { BaseAppProperties } from '../base-app-properties';
import {PortalType} from "../models/portal-type";

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  private readonly documentServiceGenerated: DocumentServiceGenerated;

  constructor(httpClient: HttpClient, appProperties: BaseAppProperties) {

    this.documentServiceGenerated = new DocumentServiceGenerated(httpClient, appProperties.irisServerBasePath, new Configuration());
  }

  generatePdf(args: GeneratePdfParams & {
    showActivityIndicator?: boolean;
  }) {

    return this.documentServiceGenerated.generatePdf(args, args.showActivityIndicator);
  }

  getFragments(args: {
    documentId: string;
  }) {

    return this.documentServiceGenerated.getFragments(args.documentId);
  }

  // returns the result of SaveAsPdf in Online
  // i.e. a PDF report of the form being displayed.
  legacySaveAsPdf(params: {
    path: string; // includes any query params
    body: NameValuePair[]
  }) {
    return this.documentServiceGenerated.legacySaveAsPdf(params, true);
  }

  getDocumentFileData(params: {
    id: string,
    portalType: PortalType
  }) {

    return this.documentServiceGenerated.getDocumentFileData(params.id, params.portalType);
  }
}
