import { Injectable } from '@angular/core';

import { action, observable } from 'mobx';

import { ServerEvent, ServerEventKind } from '../models/server-event';
import { Logger } from '../services/logger.service';
import { BaseAuthenticationStore } from '../store/base-authentication.store';
import { mobxReaction } from '../utils/mobx.utils';

declare const SharedWorker: any;

@Injectable({
  providedIn: 'root'
})
export class ServerEventStore {

  private sharedWorker: any;

  @observable
  private _data?: ServerEvent;

  constructor(
    private readonly logger: Logger,
    private readonly authenticationStore: BaseAuthenticationStore) {

    mobxReaction('ServerEventStore: authenticationStore.isAuthenticated',
      () => this.authenticationStore.isAuthenticated,
      isAuthenticated => isAuthenticated ? this.initEventSource() : this.destroyEventSource());
  }

  private initEventSource() {

    if (!(window as any).SharedWorker) {

      this.logger.warn('ServerEventStore.initEventSource: SharedWorker not available!');

      return;
    }

    this.sharedWorker = new SharedWorker('/assets/workers/event-source.worker.js');

    this.sharedWorker.onerror = (e: any) => {

      throw new Error(`Error creating SharedWorker for EventSource: ${e.message}`);
    };

    this.sharedWorker.port.onmessage = (e: any) => {

      switch (e.data.messageType) {

        case 'DATA':

          this.setData(e.data.payload);

          break;
      }
    };

    this.sharedWorker.port.onmessageerror = (e: any) => {

      this.logger.error(`Error receiving message from SharedWorker for EventSource: ${e.message}`);
    };

    this.sharedWorker.port.postMessage({
      messageType: 'INIT',
      path: `/stream/${this.authenticationStore.userId}`,
      authentication: this.authenticationStore.basicAuth
    });
  }

  private destroyEventSource() {

    if (this.sharedWorker) {

      this.sharedWorker.port.postMessage({
        messageType: 'DESTROY'
      });
    }
  }

  @action
  private setData(data: ServerEvent) {

    this._data = data;

    if (this._data.kind === ServerEventKind.AuthenticationFailed) {

      this.authenticationStore.logout();
    }
  }

  get data() {

    return this._data;
  }
}
