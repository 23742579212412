import { Directive } from '@angular/core';
import { Toast } from 'primeng/toast';

import { globalSpinnerContainerZindex } from './global-spinner.directive';

@Directive({ selector: '[appGlobalToast]' })
export class GlobalToastDirective {

  constructor(toast: Toast) {

    toast.key = 'app';
    toast.autoZIndex = false;
    toast.style = {
      marginTop: '70px',
      zIndex: `${globalSpinnerContainerZindex + 1}`
    };
  }
}
