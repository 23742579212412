import { ChangeDetectionStrategy, Component, OnInit, TemplateRef } from '@angular/core';
import { BaseComponent, BrowserUtils, DialogStore, DialogWidth, switchMapCatch } from 'common-lib';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthenticationStore } from '../../stores/authentication.store';

import { Store } from './terms-and-conditions.store';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Store]
})
export class TermsAndConditionsComponent extends BaseComponent implements OnInit {

  private readonly acceptTermsAndConditions$ = new Subject();

  static showInDialog(dialogStore: DialogStore, template: TemplateRef<any>) {

    dialogStore.showTemplate(template, {
      closable: false,
      width: BrowserUtils.Mobile ? '100%' : DialogWidth.Screen66
    });
  }

  constructor(
    readonly store: Store,
    private readonly dialogStore: DialogStore,
    private readonly authenticationStore: AuthenticationStore) {

    super();
  }

  ngOnInit(): void {

    this.subscribe(this.store.getTermsAndConditions().pipe(
      tap(() => this.dialogStore.positionOverlay())
    ));

    this.subscribe(this.acceptTermsAndConditions$.pipe(
      switchMapCatch(() => {

        return this.store.acceptTermsAndConditions().pipe(
          tap(() => this.dialogStore.hide())
        );
      })
    ));
  }

  onAcceptDeclineClick() {

    if (this.store.accept) {

      this.acceptTermsAndConditions$.next();

    } else {

      this.authenticationStore.logout();
    }
  }

  get acceptDeclineButtonLabel(): string {

    return this.store.accept ? 'proceed' : 'decline';
  }
}
