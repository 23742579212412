import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-content-container',
  templateUrl: './content-container.component.html',
  styleUrls: ['./content-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentContainerComponent extends BaseComponent implements OnInit {

  @Input() layout = 'row';

  @Input() layoutAlign = 'start center';

  @Input() layoutGap = '5px';

  @Input() containerClass?: NgClass['ngClass'];

  @Input() containerStyle?: NgStyle['ngStyle'];

  ngOnInit(): void {
  }
}
