export enum CommitteeCode {
  Y = 'Y', // Yes.
  N = 'N', // No.
  P = 'P', // Presiding officer / Chairperson.
  S = 'S', // Secretary.
  T = 'T', // Treasurer.
  U = 'U', // Public officer.
}

export enum PresidingOfficeTitle {
  PresidingOfficer = 'Presiding Officer',
  Chairperson = 'Chairperson'
}
