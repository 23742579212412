import { NgStyle } from '@angular/common';
import { Directive, Input, OnInit } from '@angular/core';
import { Dialog } from 'primeng/dialog';

import { DialogStore } from '../store/dialog.store';
import { calculateDialogWidth } from '../utils/display.utils';

@Directive({
  selector: '[appDialog]'
})
export class DialogDirective implements OnInit {

  public static readonly DEFAULTS = {
    style: {
      ...DialogStore.DEFAULTS.style,
      'max-width': '90vw',
      'max-height': '90vh',
      width: '80vw'
    }
  };

  @Input() titleCaseHeader = true;
  @Input() defaultStyle = true;

  @Input() set header(val: string) {

    this.dialog.header = val;

    if (val && this.titleCaseHeader) {

      // @ts-ignore
      this.dialog.header = val.toTitleCase();
    }
  }

  @Input() set style(val: NgStyle['ngStyle']) {

    if (this.defaultStyle) {

      this.dialog.style = {
        ...DialogDirective.DEFAULTS.style,
        ...val,
        width: val && val.width ? calculateDialogWidth(val.width) : DialogDirective.DEFAULTS.style.width
      };

    } else {

      this.dialog.style = val;
    }
  }

  constructor(private readonly dialog: Dialog) {

    dialog.header = DialogStore.DEFAULTS.header;
    dialog.showHeader = DialogStore.DEFAULTS.showHeader;
    dialog.responsive = DialogStore.DEFAULTS.responsive;
    dialog.resizable = DialogStore.DEFAULTS.resizable;
    dialog.draggable = DialogStore.DEFAULTS.draggable;
    dialog.modal = DialogStore.DEFAULTS.modal;
    dialog.dismissableMask = DialogStore.DEFAULTS.dismissableMask;
    dialog.breakpoint = DialogStore.DEFAULTS.breakpoint;
    dialog.styleClass = DialogStore.DEFAULTS.styleClass;
    dialog.closable = DialogStore.DEFAULTS.closable;
    dialog.closeOnEscape = DialogStore.DEFAULTS.closeOnEscape;

    dialog.style = DialogDirective.DEFAULTS.style;

    dialog.blockScroll = true;
  }

  ngOnInit(): void {
  }
}
