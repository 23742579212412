export class HelpSelectorContext {

  constructor(readonly params: {
    readonly tags: string | string[];
    readonly showAll?: boolean;
    readonly autoShow?: boolean;
  }) {
  }
}

