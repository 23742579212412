<div *mobxAutorun appSpinnerContainer>
  <app-table [showCaption]="false"
             [selectionMode]="rowSelectionMode" [value]="store.owners" [selection]="store.selectedOwners" (selectionChange)="store.setSelectedOwners($event)">
    <ng-template pTemplate="header">
      <tr appTableRow>
        <th [appTableHeader]="ownerUserLastNameProp" title="name" pSortableColumn
          [filter]="TableHeaderFilterType.Input"></th>
        <th [appTableHeader]="ownerUserFullNameProp" title="registered name" pSortableColumn
          [filter]="TableHeaderFilterType.Input"></th>
        <th [appTableHeader]="ownerUnitsProp" title="unit(s) #" [filter]="TableHeaderFilterType.Input"></th>
        <th [appTableHeader]="ownerCommitteeCodesProp" title="Position" [filter]="TableHeaderFilterType.Input"></th>
        <th [appTableHeader]="ownerUserMobileProp" title="mobile" [filter]="TableHeaderFilterType.Input"></th>
        <th [appTableHeader]="ownerUserEmailProp" title="email" [filter]="TableHeaderFilterType.Input"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-owner>
      <tr appTableRow [pSelectableRow]="owner">
        <td appTableData>{{getName(owner)}}</td>
        <td appTableData>{{getRegisteredName(owner)}}</td>
        <td appTableData>
          <div fxLayoutAlign="start center">
            <ng-container *ngFor="let unit of getUnits(owner); index as i;">
              <span *ngIf="i > 0">,&nbsp;</span>{{unit.unitNumber}}
<!--              <a appAnchor (click)="onUnitLinkClick({owner: owner, unit: unit})">{{unit.unitNumber}}</a>-->
            </ng-container>
          </div>
        </td>
        <td appTableData>{{getCommitteeCodes(owner)}}</td>
        <td appTableData>{{getMobile(owner)}}</td>
        <td appTableData>{{getEmail(owner)}}</td>
      </tr>
    </ng-template>
  </app-table>
  <app-spinner [show]="store.spinnerVisible"></app-spinner>
</div>

<ng-template #ownerDetailsTpl>
  <p-tabView [activeIndex]="store.selectedTabPanel" (activeIndexChange)="store.setSelectedTabPanel($event)">
    <p-tabPanel header="Profile" leftIcon="ui-icon-person">
      <div class="p-grid form-group">
        <div class="p-col-12">
          <app-owner-profile [userId]="store.selectedOwner?.user.id" [unitId]="store.selectedUnit?.id"
            [showComments]="false">
          </app-owner-profile>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Unit" leftIcon="ui-icon-home">
      <div class="p-grid form-group">
        <div class="p-col-12">
          <app-owner-unit-details [owner]="store.selectedOwner" [unitId]="store.selectedUnit?.id">
          </app-owner-unit-details>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Account History" leftIcon="ui-icon-history">
      <div class="p-grid form-group">
        <div class="p-col-12">
          <app-owner-account-history></app-owner-account-history>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</ng-template>
