Owner Account History
<!--
<div *mobxAutorun class="ui-fluid p-grid form-group">
  <div class="p-col-12 p-md-2">
    <label>Account Balance</label>
  </div>
  <div class="p-col-12 p-md-10">
    <app-owner-balance [balance]="store.balance" [showCreditDebit]="true"></app-owner-balance>
  </div>
  <div class="p-col-12 p-md-2">
    <label>History</label>
  </div>
  <div class="p-col-12 p-md-4">
    <p-dropdown optionLabel="name" [options]="store.accountHistoryOptions"
      [ngModel]="store.selectedAccountHistoryOption" (ngModelChange)="store.setSelectedAccountHistoryOption($event)">
    </p-dropdown>
  </div>
  <div class="p-col-12">
    <p-dataTable [value]="store.unitPaymentRecords">
      <p-column field="date" header="Date" [sortable]="true">
        <ng-template pTemplate="body" let-col let-payment="rowData">
          {{payment[col.field] | dateFormat}}
        </ng-template>
      </p-column>
      <p-column field="referenceNumber" header="Reference" [sortable]="true"></p-column>
      <p-column field="description" header="Description" [sortable]="true"></p-column>
      <p-column field="amount.value" header="Billed" [sortable]="true">
        <ng-template pTemplate="body" let-col let-payment="rowData">
          <ng-container *ngIf="payment.amount.value > 0">{{payment.amount | currencyFormat}}</ng-container>
        </ng-template>
      </p-column>
      <p-column field="amount.value" header="Received" [sortable]="true">
        <ng-template pTemplate="body" let-col let-payment="rowData">
          <ng-container *ngIf="payment.amount.value < 0">{{abs(payment.amount.value) | currencyFormat}}
          </ng-container>
        </ng-template>
      </p-column>
    </p-dataTable>
  </div>
</div>
-->
