function toCents(dollars: number): number {

  return dollars * 100;
}

function toDollars(cents: number): number {

  return cents / 100;
}

export function formatCents(cents: number): string {

  return '$' + toDollars(cents).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
}

function parseCents(cents: string): number {

  if (!cents) {

    return 0;
  }

  // .1 means 10 cents
  const twoDigitCents = cents.length === 1 ? `${cents}0` : cents;

  return parseInt(twoDigitCents, 10);
}

export function inputDollarsToCents(dollarsString: string | undefined): number {

  if (dollarsString == null || dollarsString.trim() === '') {

    return Number.NaN;
  }

  const dollarsAndCents = dollarsString.split('.');

  const dollars = parseInt(dollarsAndCents[0], 10);

  const cents = dollarsAndCents.length > 1 ? parseCents(dollarsAndCents[1]) : 0;

  return toCents(dollars) + (dollars < 0 ? -1 * cents : cents);
}

export function centsToInputDollars(cents: number | undefined): string {

  if (cents == null || Number.isNaN(cents)) {

    return '';
  }

  return toDollars(cents).toFixed(2);
}
