import { HttpErrorResponse } from '@angular/common/http';
import LogRocket from 'logrocket';

import { BaseAppProperties } from '../base-app-properties';
import { Environment } from '../environment';
import { PortalType } from '../models/portal-type';
import { Logger } from '../services/logger.service';
import { WindowRef } from '../services/window-ref.service';
import { BaseAppStore } from '../store/base-app.store';
import { ConfirmationStore } from '../store/confirmation.store';

import { ServiceLocator } from './service-locator.utils';

const irisException = 'au.com.whittles.common.IrisException';

export function getIrisErrorMessage(httpErrorResponse: HttpErrorResponse): string {

  const innerError = httpErrorResponse.error;
  const irisExceptionPrefix = 'IrisException ';

//if (!innerError || innerError.exception !== irisException || innerError.status !== 500) {                                    // HttpErrorResponse.exception is unresolved
  if (!innerError || !innerError.detail || !innerError.detail.startsWith(irisExceptionPrefix) || innerError.status !== 500) {  // IM-288

    return '';
  }

  const innerErrorMessage = innerError.detail.replace(irisExceptionPrefix, '') as string;                               // IM-288 innerError.message as string;

  return innerErrorMessage.replace(`Request processing failed; nested exception is ${irisException}:`, '').trim();
}

function getErrorMessage(httpErrorResponse: HttpErrorResponse): string {

  const irisErrorMessage = getIrisErrorMessage(httpErrorResponse);

  if (irisErrorMessage) {

    return irisErrorMessage;
  }

  const serverErrorMessage = httpErrorResponse.error && httpErrorResponse.error.message;

  return getAppProperties().portalType === PortalType.Manager && serverErrorMessage ||
    'An error has occurred trying to perform the previous operation';
}

export function showHttpErrorDialog(params: {
  error: HttpErrorResponse;
  remoteLog: boolean;
}) {

  if (isLoggingOutOfOwnerPortal()) {

    return;
  }

  if (params.remoteLog) {

    maybeRemoteLog(params.error);
  }

  getLogger().error('error.utils: showHttpErrorDialog - ', params.error);

  // An error response from the server
  getConfirmationStore().confirm({
    header: 'server error',
    icon: 'ui-icon-error',
    message: `${getErrorMessage(params.error)}.<br><br>Press OK to close this dialogue and try again or try refreshing the browser.`
  });
}

export function handleError(error: any) {

  if (isLoggingOutOfOwnerPortal()) {

    return;
  }

  maybeRemoteLog(error);

  getLogger().error('error.utils: handleError - ', error);

  if (error instanceof HttpErrorResponse) {

    showHttpErrorDialog({
      error,
      remoteLog: false
    });

  } else {

    // If we got here, this is an unhandled exception in the client side code.
    getAppStore().showActivityIndicator({
      flag: false,
      force: true
    });

    getConfirmationStore().confirm({
      closable: false,
      header: 'application error',
      icon: 'ui-icon-error',
      message: 'An error has occurred and your session needs be refreshed.<br><br>' +
        'Press OK to reload the application.',
      accept: () => reload(),
      reject: () => reload()
    });
  }
}

export function handleExceptionMessage(err: any): string[] {
  const regex = /'([^']*)'/; // Regular expression to match text inside single quotes

  const errorMessages = [];
  let hasViolations = false;
  err?.error?.violations?.forEach((v: any) => {
      const match = v.message.match(regex);
      hasViolations = true;

      errorMessages.push(v.message);
      if (match) {
          const textInsideQuotes = match[1];
          errorMessages.push(`${textInsideQuotes} has error`);
      }
  });

  if (!hasViolations) {
      errorMessages.push(err?.error?.detail ?? "An error has occurred");
  }

  return errorMessages;
}

function maybeRemoteLog(error: any) {

  if (!getEnvironment().logrocketRelease) {

    return;
  }

  // https://docs.logrocket.com/docs/troubleshooting-error-reporting
  LogRocket.captureException(error);
}

function reload() {

  // This will force a reload thus ensuring an easy way of clearing out all local state
  getWindowRef().nativeWindow.location.href = '/';
}

function getLogger() {

  return ServiceLocator.injector.get<Logger>(Logger);
}

function getConfirmationStore() {

  return ServiceLocator.injector.get<ConfirmationStore>(ConfirmationStore);
}

function getAppProperties() {

  return ServiceLocator.injector.get<BaseAppProperties>(BaseAppProperties as any);
}

function getAppStore() {

  return ServiceLocator.injector.get<BaseAppStore>(BaseAppStore as any);
}

function getWindowRef() {

  return ServiceLocator.injector.get<WindowRef>(WindowRef);
}

function getEnvironment() {

  return ServiceLocator.injector.get<Environment>(Environment);
}

// Don't bother logging error when logging out of Portal, will just fill up the remote logging service with false positives.
function isLoggingOutOfOwnerPortal() {

  return getAppProperties().portalType === PortalType.Owner && getAppStore().logoutInvoked;
}

