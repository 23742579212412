import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BREAKPOINTS, DEFAULT_BREAKPOINTS } from '@angular/flex-layout';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ActivityIndicatorIntercpetor,
  AppErrorHandler,
  BaseAppModule,
  BaseAppProperties,
  BaseAppStore,
  BaseAuthenticationStore,
  BaseDomainStore,
  BaseHelpStore,
  BasicAuthHeaderInterceptor,
  Environment,
  HttpErrorIntercpetor,
  Logger,
  mapFlexLayoutBreakPointToPrimeNG,
  mobxReaction
} from 'common-lib';
import LogRocket from 'logrocket';
import { ConfirmationService, MessageService } from 'primeng/api';

import { environment } from '../environments/environment';

import { AppProperties } from './app-properties';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppMenuComponent, AppSubMenuComponent } from './app.menu.component';
import { AppTopBarComponent } from './app.topbar.component';
import { ContactDetailsComponent } from './components/contact-details/contact-details.component';
import { InitComponent } from './components/init/init.component';
import { LoginMessageComponent } from './components/login-message/login-message.component';
import { LoginComponent } from './components/login/login.component';
import { OwnerSelectorComponent } from './components/owner-selector/owner-selector.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { AppSharedModule } from './modules/shared.module';
import { ClientContextHeaderInterceptor } from './services/http-interceptors.service';
import { AppStore } from './stores/app.store';
import { AuthenticationStore } from './stores/authentication.store';
import { DomainStore } from './stores/domain.store';
import { HelpStore } from './stores/help-store';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientXsrfModule,
    LayoutModule,
    AppSharedModule,
    AppRoutingModule,
    ClipboardModule
  ],
  declarations: [
    AppComponent,
    AppTopBarComponent,
    AppMenuComponent,
    AppSubMenuComponent,
    PageNotFoundComponent,
    LoginComponent,
    ContactDetailsComponent,
    InitComponent,
    OwnerSelectorComponent,
    TermsAndConditionsComponent,
    LoginMessageComponent
  ],
  providers: [
    Title,
    ConfirmationService,
    MessageService,
    {
      provide: LOCALE_ID,
      useValue: 'en-AU'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ActivityIndicatorIntercpetor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BasicAuthHeaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientContextHeaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorIntercpetor,
      multi: true
    },
    {
      provide: BaseAppProperties,
      useExisting: AppProperties
    },
    {
      provide: ErrorHandler,
      useExisting: AppErrorHandler
    },
    {
      provide: BaseAuthenticationStore,
      useExisting: AuthenticationStore
    },
    {
      provide: BaseAppStore,
      useExisting: AppStore
    },
    {
      provide: BaseDomainStore,
      useExisting: DomainStore
    },
    {
      provide: BaseHelpStore,
      useExisting: HelpStore
    },
    {
      provide: Environment,
      useValue: new Environment(environment)
    },
    {
      provide: BREAKPOINTS,
      useValue: DEFAULT_BREAKPOINTS.map(mapFlexLayoutBreakPointToPrimeNG)
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule extends BaseAppModule {

  constructor(
    injector: Injector,
    private readonly authenticationStore: AuthenticationStore,
    private readonly env: Environment) {

    super(injector);

    this.identifyUserForLogging();
  }

  private identifyUserForLogging() {

    mobxReaction('AppModule: authenticationStore.authentication',
      () => this.authenticationStore.authentication,
      authentication => {

        if (authentication && this.env.logrocketRelease) {

          LogRocket.identify(authentication.identity.userId || '', {
            accountNumber: authentication.identity.accountNumber || '',
            plan: authentication.identity.plan || '',
            unitNumber: authentication.identity.unitNumber || '',
            email: authentication.identity.email || '',
            loginType: this.authenticationStore.loginType
          });

          if (typeof FS !== 'undefined') {

            FS.identify(authentication.identity.userId || '', {
              accountNumber: authentication.identity.accountNumber || '',
              plan: authentication.identity.plan || '',
              unitNumber: authentication.identity.unitNumber || '',
              email: authentication.identity.email || '',
              loginType: this.authenticationStore.loginType
            });
          }
        }
      });
  }
}
