import {Directive, Input} from "@angular/core";

@Directive({
  selector: 'p-tabPanel'
})
export class IndexedTabPanelDirective {
  @Input() index: number;

  constructor() {}
}
