/**
 * Whittles API
 * Whittles API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Company } from '../model/company';
import { GoogleRecaptchaSiteVerifyResponse } from '../model/googleRecaptchaSiteVerifyResponse';
import { LegacyOwnerCredentialsPasswordResetRequestParams } from '../model/legacyOwnerCredentialsPasswordResetRequestParams';
import { LogoffParams } from '../model/logoffParams';
import { PasswordResetRequestParams } from '../model/passwordResetRequestParams';
import { UpdateLegacyOwnerCredentialsPasswordOnLoginParams } from '../model/updateLegacyOwnerCredentialsPasswordOnLoginParams';
import { UpdatePasswordOnLoginParams } from '../model/updatePasswordOnLoginParams';
import { ValidateCredentialsParams } from '../model/validateCredentialsParams';
import { ValidateCredentialsResponse } from '../model/validateCredentialsResponse';
import { ValidateEmailLoginCredentialsParams } from '../model/validateEmailLoginCredentialsParams';
import { ValidateEmailLoginCredentialsResponse } from '../model/validateEmailLoginCredentialsResponse';
import { ValidateLegacyOwnerCredentialsParams } from '../model/validateLegacyOwnerCredentialsParams';
import { ValidateLegacyOwnerCredentialsResponse } from '../model/validateLegacyOwnerCredentialsResponse';
import { ValidateMobileLoginCredentialsParams } from '../model/validateMobileLoginCredentialsParams';
import { ValidateMobileOwnerCredentialsResponse } from '../model/validateMobileOwnerCredentialsResponse';
import { ValidateTemporaryPinLoginCredentialsResponse } from '../model/validateTemporaryPinLoginCredentialsResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

    protected basePath = 'https://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanies(observe?: 'body', reportProgress?: boolean): Observable<Array<Company>>;
    public getCompanies(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Company>>>;
    public getCompanies(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Company>>>;
    public getCompanies(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Company>>(`${this.basePath}/authentication/companies`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param recaptchaToken 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public googleRecaptchaSiteVerify(recaptchaToken: string, observe?: 'body', reportProgress?: boolean): Observable<GoogleRecaptchaSiteVerifyResponse>;
    public googleRecaptchaSiteVerify(recaptchaToken: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GoogleRecaptchaSiteVerifyResponse>>;
    public googleRecaptchaSiteVerify(recaptchaToken: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GoogleRecaptchaSiteVerifyResponse>>;
    public googleRecaptchaSiteVerify(recaptchaToken: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (recaptchaToken === null || recaptchaToken === undefined) {
            throw new Error('Required parameter recaptchaToken was null or undefined when calling googleRecaptchaSiteVerify.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (recaptchaToken !== undefined && recaptchaToken !== null) {
            queryParameters = queryParameters.set('recaptchaToken', <any>recaptchaToken);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GoogleRecaptchaSiteVerifyResponse>(`${this.basePath}/authentication/google-recaptcha/site-verify`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param legacyOwnerCredentialsPasswordResetRequestParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public legacyOwnerCredentialsPasswordResetRequest(legacyOwnerCredentialsPasswordResetRequestParams: LegacyOwnerCredentialsPasswordResetRequestParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public legacyOwnerCredentialsPasswordResetRequest(legacyOwnerCredentialsPasswordResetRequestParams: LegacyOwnerCredentialsPasswordResetRequestParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public legacyOwnerCredentialsPasswordResetRequest(legacyOwnerCredentialsPasswordResetRequestParams: LegacyOwnerCredentialsPasswordResetRequestParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public legacyOwnerCredentialsPasswordResetRequest(legacyOwnerCredentialsPasswordResetRequestParams: LegacyOwnerCredentialsPasswordResetRequestParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (legacyOwnerCredentialsPasswordResetRequestParams === null || legacyOwnerCredentialsPasswordResetRequestParams === undefined) {
            throw new Error('Required parameter legacyOwnerCredentialsPasswordResetRequestParams was null or undefined when calling legacyOwnerCredentialsPasswordResetRequest.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/authentication/legacy-owner-credentials/password-reset-request`,
            legacyOwnerCredentialsPasswordResetRequestParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param logoffParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logoff(logoffParams: LogoffParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public logoff(logoffParams: LogoffParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public logoff(logoffParams: LogoffParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public logoff(logoffParams: LogoffParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (logoffParams === null || logoffParams === undefined) {
            throw new Error('Required parameter logoffParams was null or undefined when calling logoff.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/authentication/logoff`,
            logoffParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param passwordResetRequestParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public passwordResetRequest(passwordResetRequestParams: PasswordResetRequestParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public passwordResetRequest(passwordResetRequestParams: PasswordResetRequestParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public passwordResetRequest(passwordResetRequestParams: PasswordResetRequestParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public passwordResetRequest(passwordResetRequestParams: PasswordResetRequestParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (passwordResetRequestParams === null || passwordResetRequestParams === undefined) {
            throw new Error('Required parameter passwordResetRequestParams was null or undefined when calling passwordResetRequest.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/authentication/password-reset-request`,
            passwordResetRequestParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendEmailTempPin(email: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sendEmailTempPin(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sendEmailTempPin(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sendEmailTempPin(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling sendEmailTempPin.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/authentication/send-email-temp-pin`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param mobile 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendMobileTempPin(mobile: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sendMobileTempPin(mobile: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sendMobileTempPin(mobile: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sendMobileTempPin(mobile: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mobile === null || mobile === undefined) {
            throw new Error('Required parameter mobile was null or undefined when calling sendMobileTempPin.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mobile !== undefined && mobile !== null) {
            queryParameters = queryParameters.set('mobile', <any>mobile);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/authentication/send-mobile-temp-pin`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param updateLegacyOwnerCredentialsPasswordOnLoginParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateLegacyOwnerCredentialsPasswordOnLogin(updateLegacyOwnerCredentialsPasswordOnLoginParams: UpdateLegacyOwnerCredentialsPasswordOnLoginParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateLegacyOwnerCredentialsPasswordOnLogin(updateLegacyOwnerCredentialsPasswordOnLoginParams: UpdateLegacyOwnerCredentialsPasswordOnLoginParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateLegacyOwnerCredentialsPasswordOnLogin(updateLegacyOwnerCredentialsPasswordOnLoginParams: UpdateLegacyOwnerCredentialsPasswordOnLoginParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateLegacyOwnerCredentialsPasswordOnLogin(updateLegacyOwnerCredentialsPasswordOnLoginParams: UpdateLegacyOwnerCredentialsPasswordOnLoginParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (updateLegacyOwnerCredentialsPasswordOnLoginParams === null || updateLegacyOwnerCredentialsPasswordOnLoginParams === undefined) {
            throw new Error('Required parameter updateLegacyOwnerCredentialsPasswordOnLoginParams was null or undefined when calling updateLegacyOwnerCredentialsPasswordOnLogin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/authentication/legacy-owner-credentials/update-password-on-login`,
            updateLegacyOwnerCredentialsPasswordOnLoginParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param updatePasswordOnLoginParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePasswordOnLogin(updatePasswordOnLoginParams: UpdatePasswordOnLoginParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updatePasswordOnLogin(updatePasswordOnLoginParams: UpdatePasswordOnLoginParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updatePasswordOnLogin(updatePasswordOnLoginParams: UpdatePasswordOnLoginParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updatePasswordOnLogin(updatePasswordOnLoginParams: UpdatePasswordOnLoginParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (updatePasswordOnLoginParams === null || updatePasswordOnLoginParams === undefined) {
            throw new Error('Required parameter updatePasswordOnLoginParams was null or undefined when calling updatePasswordOnLogin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/authentication/update-password-on-login`,
            updatePasswordOnLoginParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param validateCredentialsParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateCredentials(validateCredentialsParams: ValidateCredentialsParams, observe?: 'body', reportProgress?: boolean): Observable<ValidateCredentialsResponse>;
    public validateCredentials(validateCredentialsParams: ValidateCredentialsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ValidateCredentialsResponse>>;
    public validateCredentials(validateCredentialsParams: ValidateCredentialsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ValidateCredentialsResponse>>;
    public validateCredentials(validateCredentialsParams: ValidateCredentialsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (validateCredentialsParams === null || validateCredentialsParams === undefined) {
            throw new Error('Required parameter validateCredentialsParams was null or undefined when calling validateCredentials.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ValidateCredentialsResponse>(`${this.basePath}/authentication/validate-credentials`,
            validateCredentialsParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param validateEmailLoginCredentialsParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateEmailLoginCredentials(validateEmailLoginCredentialsParams: ValidateEmailLoginCredentialsParams, observe?: 'body', reportProgress?: boolean): Observable<ValidateEmailLoginCredentialsResponse>;
    public validateEmailLoginCredentials(validateEmailLoginCredentialsParams: ValidateEmailLoginCredentialsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ValidateEmailLoginCredentialsResponse>>;
    public validateEmailLoginCredentials(validateEmailLoginCredentialsParams: ValidateEmailLoginCredentialsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ValidateEmailLoginCredentialsResponse>>;
    public validateEmailLoginCredentials(validateEmailLoginCredentialsParams: ValidateEmailLoginCredentialsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (validateEmailLoginCredentialsParams === null || validateEmailLoginCredentialsParams === undefined) {
            throw new Error('Required parameter validateEmailLoginCredentialsParams was null or undefined when calling validateEmailLoginCredentials.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ValidateEmailLoginCredentialsResponse>(`${this.basePath}/authentication/validate-email-login-credentials`,
            validateEmailLoginCredentialsParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param email 
     * @param pin 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateEmailTempPinLoginCredentials(email: string, pin: string, observe?: 'body', reportProgress?: boolean): Observable<ValidateTemporaryPinLoginCredentialsResponse>;
    public validateEmailTempPinLoginCredentials(email: string, pin: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ValidateTemporaryPinLoginCredentialsResponse>>;
    public validateEmailTempPinLoginCredentials(email: string, pin: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ValidateTemporaryPinLoginCredentialsResponse>>;
    public validateEmailTempPinLoginCredentials(email: string, pin: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling validateEmailTempPinLoginCredentials.');
        }

        if (pin === null || pin === undefined) {
            throw new Error('Required parameter pin was null or undefined when calling validateEmailTempPinLoginCredentials.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (pin !== undefined && pin !== null) {
            queryParameters = queryParameters.set('pin', <any>pin);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ValidateTemporaryPinLoginCredentialsResponse>(`${this.basePath}/authentication/validate-email-temp-pin-login-credentials`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param validateLegacyOwnerCredentialsParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateLegacyOwnerCredentials(validateLegacyOwnerCredentialsParams: ValidateLegacyOwnerCredentialsParams, observe?: 'body', reportProgress?: boolean): Observable<ValidateLegacyOwnerCredentialsResponse>;
    public validateLegacyOwnerCredentials(validateLegacyOwnerCredentialsParams: ValidateLegacyOwnerCredentialsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ValidateLegacyOwnerCredentialsResponse>>;
    public validateLegacyOwnerCredentials(validateLegacyOwnerCredentialsParams: ValidateLegacyOwnerCredentialsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ValidateLegacyOwnerCredentialsResponse>>;
    public validateLegacyOwnerCredentials(validateLegacyOwnerCredentialsParams: ValidateLegacyOwnerCredentialsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (validateLegacyOwnerCredentialsParams === null || validateLegacyOwnerCredentialsParams === undefined) {
            throw new Error('Required parameter validateLegacyOwnerCredentialsParams was null or undefined when calling validateLegacyOwnerCredentials.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ValidateLegacyOwnerCredentialsResponse>(`${this.basePath}/authentication/validate-legacy-owner-credentials`,
            validateLegacyOwnerCredentialsParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param validateMobileLoginCredentialsParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateMobileLoginCredentials(validateMobileLoginCredentialsParams: ValidateMobileLoginCredentialsParams, observe?: 'body', reportProgress?: boolean): Observable<ValidateMobileOwnerCredentialsResponse>;
    public validateMobileLoginCredentials(validateMobileLoginCredentialsParams: ValidateMobileLoginCredentialsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ValidateMobileOwnerCredentialsResponse>>;
    public validateMobileLoginCredentials(validateMobileLoginCredentialsParams: ValidateMobileLoginCredentialsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ValidateMobileOwnerCredentialsResponse>>;
    public validateMobileLoginCredentials(validateMobileLoginCredentialsParams: ValidateMobileLoginCredentialsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (validateMobileLoginCredentialsParams === null || validateMobileLoginCredentialsParams === undefined) {
            throw new Error('Required parameter validateMobileLoginCredentialsParams was null or undefined when calling validateMobileLoginCredentials.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ValidateMobileOwnerCredentialsResponse>(`${this.basePath}/authentication/validate-mobile-login-credentials`,
            validateMobileLoginCredentialsParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param mobile 
     * @param pin 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateMobileTempPinLoginCredentials(mobile: string, pin: string, observe?: 'body', reportProgress?: boolean): Observable<ValidateTemporaryPinLoginCredentialsResponse>;
    public validateMobileTempPinLoginCredentials(mobile: string, pin: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ValidateTemporaryPinLoginCredentialsResponse>>;
    public validateMobileTempPinLoginCredentials(mobile: string, pin: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ValidateTemporaryPinLoginCredentialsResponse>>;
    public validateMobileTempPinLoginCredentials(mobile: string, pin: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mobile === null || mobile === undefined) {
            throw new Error('Required parameter mobile was null or undefined when calling validateMobileTempPinLoginCredentials.');
        }

        if (pin === null || pin === undefined) {
            throw new Error('Required parameter pin was null or undefined when calling validateMobileTempPinLoginCredentials.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mobile !== undefined && mobile !== null) {
            queryParameters = queryParameters.set('mobile', <any>mobile);
        }
        if (pin !== undefined && pin !== null) {
            queryParameters = queryParameters.set('pin', <any>pin);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ValidateTemporaryPinLoginCredentialsResponse>(`${this.basePath}/authentication/validate-mobile-temp-pin-login-credentials`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
