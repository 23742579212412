import { Injectable } from '@angular/core';
import { Authorities, Authority, BaseAuthGuardService, isProductionSubdomain, LoginType } from 'common-lib';

import { RoutePath } from '../route-path';
import { AuthenticationStore } from '../stores/authentication.store';
import { DomainStore } from '../stores/domain.store';

@Injectable({
  providedIn: 'root'
})
export class ChildAuthGuard extends BaseAuthGuardService {

  constructor(
    private readonly childAuthenticationStore: AuthenticationStore,
    private readonly domainStore: DomainStore
  ) {

    super(childAuthenticationStore);
  }

  protected canAccessChild(path: string): boolean {

    if (!path) {

      return true;
    }

    if (this.childAuthenticationStore.loginType === LoginType.OWNER) {

      switch (path) {

        case RoutePath.Home:
        case RoutePath.SelectUnit:
        case RoutePath.Profile:
        case RoutePath.PlanSummary:
        case RoutePath.MakePayment:
        case RoutePath.AccountHistory:
        case RoutePath.Invoices:
        case RoutePath.Documents:
        case RoutePath.FinancialRecords:
        case RoutePath.Contact:

          return true;

        case RoutePath.CommitteeApproval:

          return this.domainStore.isApproverForSelectedUnit || this.domainStore.isApproverForSelectedBuilding;

        default:

          return false;
      }

    } else if (this.childAuthenticationStore.loginType === LoginType.BUILDING) {

      switch (path) {

        case RoutePath.SelectBuilding:

          return true;

        case RoutePath.CommitteeApproval:

          return this.domainStore.isApproverForSelectedBuilding;

        case RoutePath.FinancialRecords:

          return this.childAuthenticationStore.hasAnyAuthority(
            ...Authorities.SearchAgent,
            Authority.Auditor,
            Authority.DebtCollectionAgencies,                                   // IM-170
            Authority.ExternalCommitteeMember,
            Authority.BuildingManager);

        case RoutePath.FinancialTransactions:

          return this.childAuthenticationStore.hasAnyAuthority(
            ...Authorities.SearchAgent,
            Authority.DebtCollectionAgencies,                                   // IM-170
            Authority.Auditor);

        case RoutePath.Documents:

          return this.childAuthenticationStore.hasAnyAuthority(
            ...Authorities.SearchAgent,
            Authority.BuildingManager,
            Authority.CaretakerQld,
            Authority.ExternalCommitteeMember,
            Authority.DebtCollectionAgencies,                                   // IM-170
            Authority.Auditor);

        case RoutePath.PlanSummary:

            return false;

        case RoutePath.PlanInfo:

            return this.childAuthenticationStore.hasAnyAuthority(
              ...Authorities.SearchAgent,
              Authority.BuildingManager,
              Authority.CaretakerQld,
              Authority.ExternalCommitteeMember);

        default:

          return false;
      }
    }

    return false;
  }
}
