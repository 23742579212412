import { EMPTY, of, throwError } from 'rxjs';
import { concatMap, delay, retryWhen } from 'rxjs/operators';

export function retryDelay(args?: {
  delayMillis?: number;
  retryCount?: number;
  errorOnComplete?: boolean;
}) {

  return retryWhen(errors => errors.pipe(
    concatMap((e, i) => {

      const retryCount = args && args.retryCount;

      if (retryCount != null && i >= retryCount) {

        const errorOnComplete = args == null || args.errorOnComplete == null || args.errorOnComplete;

        return errorOnComplete ? throwError(e) : EMPTY;
      }

      const delayMillis = args && args.delayMillis || 5000;

      return of(e).pipe(delay(delayMillis));
    })
  ));
}
