import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ButtonKind } from '../../directives/button.directive';
import { NameValuePair, Owner, Unit } from '../../generated';
import { FinancialStanding } from '../../models/financial-standing';
import { switchMapCatch } from '../../rxjs/operators';
import { FormModel } from '../../utils/form.utils';
import { property } from '../../utils/object.utils';
import { financialStandings } from '../../utils/type-mapping.utils';
import { BaseComponent } from '../base/base.component';

import { Store } from './owner-unit-details.store';

interface UnitFormModel {
  addressType: NameValuePair;
  financialStanding: FinancialStanding;
  additionalInfo: string;
}

@Component({
  selector: 'app-owner-unit-details',
  templateUrl: './owner-unit-details.component.html',
  styleUrls: ['./owner-unit-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Store]
})
export class OwnerUnitDetailsComponent extends BaseComponent implements OnInit {

  private readonly getUnitSummary$ = new Subject();

  readonly unitPropertyId = property<Unit>('id');
  readonly unitPropertyUnitNumber = property<Unit>('unitNumber');
  readonly unitPropertyAddress = property<Unit>('address');

  readonly nameValuePairPropertyName = property<NameValuePair>('name');
  readonly nameValuePairPropertyValue = property<NameValuePair>('value');

  readonly ButtonKind = ButtonKind;
  readonly financialStandings = financialStandings;

  unitForm: UntypedFormGroup;

  @Input() set owner(val: Owner) {

    this.store.setOwner(val);
  }

  get owner() {

    return this.store.owner;
  }

  @Input() set unitId(val: string | undefined) {

    if (!val) {

      return;
    }

    const unit = this.store.units.find(u => u.id === val);

    this.store.setSelectedUnit(unit);
  }

  constructor(
    readonly store: Store,
    private readonly fb: UntypedFormBuilder) {

    super();
  }

  ngOnInit(): void {

    const unitFormModel: FormModel<UnitFormModel> = {
      addressType: [undefined],
      financialStanding: [undefined],
      additionalInfo: [undefined]
    };

    this.unitForm = this.fb.group(unitFormModel);

    this.subscribe(this.getUnitSummary$.pipe(
      switchMapCatch(() => {

        return this.store.getUnitSummary().pipe(
          tap(unitSummary => {

            this.unitForm.reset();

            this.patchUnitForm({
              addressType: this.store.unitSummary?.unitTypeOptions.find(ut => ut.value === unitSummary.unit.unitTypeId),
              financialStanding: this.store.unitSummary?.unit.inLegalHands as FinancialStanding,
              additionalInfo: this.store.unitSummary?.unit.additionalInfor
            });
          })
        );
      })
    ));

    this.mobxReaction('OwnerUnitDetailsComponent: store.selectedUnit',
      () => this.store.selectedUnit,
      selectedUnit => {

        if (selectedUnit) {

          this.getUnitSummary$.next();
        }
      }
    );
  }

  private patchUnitForm(model: Partial<UnitFormModel>) {

    this.unitForm.patchValue(model);
  }

  get unitFormModelValue() {

    return this.unitForm.value as UnitFormModel;
  }

  onUnitFormSubmit() {

    console.log('onUnitFormSubmit', this.unitFormModelValue);
  }

  get unitAddressType() {

    return this.unitForm.controls[property<UnitFormModel>('addressType')];
  }

  get unitFinancialStanding() {

    return this.unitForm.controls[property<UnitFormModel>('financialStanding')];
  }

  get unitAdditionalInfo() {

    return this.unitForm.controls[property<UnitFormModel>('additionalInfo')];
  }
}
