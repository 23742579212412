<div appRouteContainer>
  <div class="p-col-12">
    <app-card *ngIf="contentContainer; else noContainer" [title]="title" [helpTags]="helpTags" [helpIconVisible]="helpIconVisible" [helpIcon]="helpIcon"
    [showDropdownContainer]="dropdownContainer" [field]="field" [options]="options" [showGlobal]="showGlobal" [showGlobalCheckbox]="showGlobalCheckbox"
    (checkboxChanged)="checkboxChanged($event)" (dropdownChanged)="dropdownChanged($event)" [selectedDropdownItem]="selectedDropdownItem">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-card>
    <ng-template #noContainer>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </ng-template>
  </div>
</div>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
