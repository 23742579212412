<span *ngIf="title" [ngClass]="{'wht-white-space-nowrap': titleNoWrap}">{{title | titleCase}}</span>
<p-sortIcon *ngIf="isTableSort" [field]="field"></p-sortIcon>
<p-treeTableSortIcon *ngIf="isTreeTableSort" [field]="field"></p-treeTableSortIcon>
<app-input-filter *ngIf="isInputFilterVisible" [field]="field" [table]="table" [treeTable]="treeTable"
  [tableFilterEvent]="tableFilterEvent" [selectSingleFilteredTableRowOnEnter]="selectSingleFilteredTableRowOnEnter"
  [customFilter]="customFilter" [readonly]="readonly" [showInputButton]="showInputButton"
  [inputButtonLabel]="inputButtonLabel" [inputButtonIcon]="inputButtonIcon" [inputButtonTooltip]="inputButtonTooltip"
  (customFilterValueChange)="onCustomFilterValueChange($event)" 
  (inputButtonClicked)="inputButtonClicked.emit($event)"
  (inputValueChanged)="onInputValueChanged($event)">
</app-input-filter>
<app-dropdown-filter *ngIf="isDropDownFilterVisible" [multiSelect]="multiSelect" [field]="field" [table]="table"
  [treeTable]="treeTable" [options]="filterOptions" [customFilter]="customFilter" [defaultLabel]="defaultLabel"
  (customFilterValueChange)="onCustomFilterValueChange($event)" [appendFilterTo]="appendFilterTo">
</app-dropdown-filter>
<app-dropdown-manageable-filter *ngIf="isManageableDropdownVisable"
  [field]="field"
  (customFilterValueChange)="onCustomFilterValueChange($event)"
  [placeholder]="placeholder"
  [items]="filterOptions"
  (addItem)="addNewItem()"
  (updateItem)="editItem($event)"
  (clear)="clearFilterString()"
  [selectedKey]="selectedKey"
  (selected)="selectedItem($event)"
  [table]="table"
  [virtualScroll]="virtualScroll"
  (loadNext)="loadMore($event)"
  [isContinuous]="isContinuous"
  [filterString]="filterString"
  [filter] ="filterDropDown"
  [showButtons]="showManageButtons"
  [treeTable]="treeTable">
</app-dropdown-manageable-filter>
<!-- [filterString]="filterString"  -->
<app-header-input-filter *ngIf="isHeaderInputFilterVisible"
[field]="field" [table]="table" [treeTable]="treeTable"
[tableFilterEvent]="tableFilterEvent"
[selectSingleFilteredTableRowOnEnter]="selectSingleFilteredTableRowOnEnter"
[customFilter]="customFilter"
(customFilterValueChange)="onCustomFilterValueChange($event)"></app-header-input-filter>
<app-input-filter *ngIf="isPopupSelectVisible" [field]="field" [table]="table" [treeTable]="treeTable" 
  [tableFilterEvent]="tableFilterEvent" [selectSingleFilteredTableRowOnEnter]="selectSingleFilteredTableRowOnEnter"
  [customFilter]="customFilter" (customFilterValueChange)="onCustomFilterValueChange($event)" (inputFilterClick)="popupSelectClick.emit()">
</app-input-filter>
<ng-content></ng-content>
