/**
 * Whittles API
 * Whittles API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Address } from './address';
import { DomainObjectType } from './domainObjectType';


export interface User { 
    id: string;
    type?: DomainObjectType;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    loginName?: string;
    phone?: string;
    homePhone?: string;
    busPhone?: string;
    fax?: string;
    mobile?: string;
    email?: string;
    emailCc?: string;
    dateUpdated?: Date;
    company?: string;
    mailMethod?: User.MailMethodEnum;
    password?: string;
    isToReceiveNewsLetter?: number;
    registeredOwner?: string;
    sendAccountNoticesByEmail?: number;
    expiryDate?: Date;
    address?: Address;
    correspondenceAddress?: Address;
    secondContactCorrespondenceAddress?: Address;
    combinedName?: string;
    title?: string;
    secondContactTitle?: string;
    secondContactFirstName?: string;
    secondContactLastName?: string;
    secondContactCombinedName?: string;
    secondContactEmail?: string;
    secondContactEmailCc?: string;
    secondContactMobile?: string;
    secondContactBusPhone?: string;
    secondContactHomePhone?: string;
    isArchived?: number;
    companyId?: number;
}
export namespace User {
    export type MailMethodEnum = 'MAIL' | 'EMAIL' | 'AGENT';
    export const MailMethodEnum = {
        MAIL: 'MAIL' as MailMethodEnum,
        EMAIL: 'EMAIL' as MailMethodEnum,
        AGENT: 'AGENT' as MailMethodEnum
    };
}
