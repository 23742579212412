import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseAppProperties } from '../base-app-properties';
import {
  AgmService as AgmServiceGenerated,
  Configuration
} from '../generated';
import { WorkflowContext } from '../models/workflow-context';

@Injectable({
  providedIn: 'root'
})
export class AgmService {

  private readonly agmServiceGenerated: AgmServiceGenerated;

  constructor(httpClient: HttpClient, appProperties: BaseAppProperties) {

    this.agmServiceGenerated = new AgmServiceGenerated(httpClient, appProperties.irisServerBasePath, new Configuration());
  }

  getMeetingDate(args: {
    taskId: string;
  }) {

    return this.agmServiceGenerated.getMeetingDate(args.taskId);
  }

  getSmsMeetingReminders(args: {
    taskId: string;
  }) {

    return this.agmServiceGenerated.getSmsMeetingReminders(args.taskId);
  }

  getWorkflowHistory(args: {
    strataId: string;
    financialYearId: string;
  }) {

    return this.agmServiceGenerated.getWorkflowHistory(args.strataId, args.financialYearId);
  }

  getWorkflowProcessId(args: {
    financialYearId: string;
    context?: WorkflowContext;
    showActivityIndicator?: boolean;
  }) {

    const showActivityIndicator = args.showActivityIndicator == null || args.showActivityIndicator;

    return this.agmServiceGenerated.getWorkflowProcessId(args.financialYearId, args.context, showActivityIndicator);
  }
}
