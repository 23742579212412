import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { FontAwesomeStyle } from '../../models/font-awsome';
import { BaseComponent } from '../base/base.component';

export enum IconKind {
  Add = 'add',
  Close = 'close',
  Delete = 'delete',
  Help = 'help'
}

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconButtonComponent extends BaseComponent {

  @Input() type = '';
  @Input() name = '';
  @Input() kind?: IconKind;
  @Input() iconStyle: NgStyle['ngStyle'] = {};
  @Input() iconClass: NgClass['ngClass'] = '';
  @Input() fontAwesomeStyle = FontAwesomeStyle.Regular;

  @Output() readonly iconClick = new EventEmitter<Event>();

  get fawMaterialIconClass() {

    switch (this.kind) {

      case IconKind.Add:

        return `${this.fontAwesomeStyle} fa-plus`;

      case IconKind.Close:

        return `${this.fontAwesomeStyle} fa-times`;

      case IconKind.Delete:

        return `${this.fontAwesomeStyle} fa-trash-alt`;

      case IconKind.Help:

        return `${this.fontAwesomeStyle} fa-question-circle`;

      default:

        return this.type === 'material-icons' ? this.type : `${this.type} ${this.name}`;
    }
  }

  get iconName() {

    return this.type === 'material-icons' ? this.name : '';
  }
}
