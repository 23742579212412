<div #container *ngIf="canRenderPdfInline; else cannotRenderPdfInline" appSpinnerContainer class="container"
  fxLayoutAlign="center center">
  <iframe *ngIf="pdfIframeVisible" (load)="iframeOnLoad($event)"
    [src]="objectURL | safe:SafePipeBypassType.ResourceUrl"></iframe>
  <i *ngIf="!pdfIframeVisible" class="far fa-file-pdf fa-10x"></i>
  <app-spinner [show]="spinnerVisible"></app-spinner>
</div>
<ng-template #cannotRenderPdfInline>
  <div fxLayoutAlign="center center" class="container" style="height: 100%">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
      <i class="far fa-file-pdf fa-5x"></i>
      <a appAnchor (click)="onAnchorClick()">{{filename}}</a>
    </div>
  </div>
</ng-template>
