import { Directive, OnInit } from '@angular/core';
import { TableCheckbox } from 'primeng/table';

import { BaseAppProperties } from '../base-app-properties';
import { PortalType } from '../models/portal-type';
import { isMouseClickWithinCheckbox } from '../utils/html.utils';

// tslint:disable-next-line:directive-selector
@Directive({ selector: 'p-tableCheckbox' })
export class TableCheckboxDirective implements OnInit {

  constructor(
    private readonly tableCheckbox: TableCheckbox,
    private readonly appProperties: BaseAppProperties) {
  }

  ngOnInit(): void {

    this.monkeyPatch();
  }

  private monkeyPatch() {

    this.monkeyPatchClick();
  }

  private monkeyPatchClick() {

    if (this.appProperties.portalType !== PortalType.Manager) {

      return;
    }

    const onClick = this.tableCheckbox.onClick.bind(this.tableCheckbox);

    this.tableCheckbox.onClick = (e: MouseEvent, ...args) => {

      if (!this.tableCheckbox.checked || isMouseClickWithinCheckbox(e)) {

        onClick(e, ...args);
      }
    };
  }
}
