import { Component, ChangeDetectionStrategy, Input, OnInit, ChangeDetectorRef, Output, EventEmitter, ViewChild } from '@angular/core';

import { BaseComponent } from '../../components/base/base.component';
import { CardComponent } from '../card/card.component';

@Component({
  selector: 'app-route-container',
  templateUrl: './route-container.component.html',
  styleUrls: ['./route-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RouteContainerComponent extends BaseComponent implements OnInit {

  private _contentContainer?: boolean;

  @Input() get contentContainer(): boolean {

    return this._contentContainer == null ? true : this._contentContainer;
  }

  set contentContainer(val: boolean) {

    this._contentContainer = val;
  }

  @Input() title = '';

  @Input() helpTags: any;

  @Input() helpIcon: string = 'video';

  @Input() helpIconVisible: any;

  constructor(private readonly cdr: ChangeDetectorRef) {

    super();
  }

  // #region dropdown list
  @Input() dropdownContainer?: boolean;

  @Input() selectedDropdownItem?: any;

  @Input() field?: string

  @Input() options?: any[]

  @Output() dropdownOnChange = new EventEmitter();
  // #endregion

  //#region show global
  @Input() showGlobalCheckbox = false;

  @Input() showGlobal = false;

  @Output() checkboxOnChange = new EventEmitter();
  //#endregion

  @ViewChild(CardComponent) cardComponent?: CardComponent;

  ngOnInit(): void {

    // Need setTimeout as need to interrogate the DOM
    setTimeout(() => {

      if (document.getElementById('legacyIframeContainer') && this._contentContainer == null) {

        this._contentContainer = false;

        this.cdr.markForCheck();
      }
    });
  }

  dropdownChanged(event: any) {
    this.dropdownOnChange.emit(event);
  }

  checkboxChanged(event: any) {
    this.checkboxOnChange.emit(event);
  }
}
