import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  Authority,
  BaseDomainStore, CompanyId,
  LocalisationContext,
  LoginType,
  mobxReaction,
  OwnerUnitSummary, PortalType, retryDelay, StrataPlanDetails,
  StrataService, switchMapCatch,
  UserService
} from 'common-lib';
import Cookies from 'js-cookie';
import {action, computed, observable} from 'mobx';

import { AppProperties } from '../app-properties';
import { RoutePath } from '../route-path';
import { AppStore } from '../stores/app.store';
import { AuthenticationStore } from '../stores/authentication.store';
import {catchError, tap} from "rxjs/operators";
import {Subject, throwError} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DomainStore extends BaseDomainStore {

  private static readonly loginMessageDisplayedCookieName = 'whittles.ownerportal.login-message-displayed';

  private readonly selectedPlanDetailsPortal$ = new Subject<string>();

  @observable
  private _isApprover?: boolean;

  @observable
  private _branch?: string;

  @observable
  private _changeAccountCode?: boolean;

  constructor(
    appProperties: AppProperties,
    userService: UserService,
    strataService: StrataService,
    readonly authenticationStore: AuthenticationStore,
    readonly appStore: AppStore,
    private readonly router: Router) {

    super(appProperties, userService, strataService, authenticationStore, appStore);

    this.selectedPlanDetailsPortal$.pipe(
      switchMapCatch(selectedStrataId => {

        return this.strataService.isApprover({
          id: selectedStrataId,
          showActivityIndicator: false
        }).pipe(
          tap(portalApprover => {
            this.setIsApprover(portalApprover.invoice);
            this.setCanChangeAccountCode(portalApprover.accountCode);
          }),
          catchError(e => {

            this.setIsApprover(false);
            this.setCanChangeAccountCode(false);

            return throwError(e);
          }),
          retryDelay(),
        );
        // tslint:disable-next-line: rxjs-no-ignored-subscription
      })).subscribe();

    mobxReaction('BaseDomainStore: selectedStrataId',
      () => this.selectedStrataId,
      selectedStrataId => selectedStrataId && this.selectedPlanDetailsPortal$.next(selectedStrataId));

    mobxReaction('DomainStore: isApproverForSelectedBuilding',
      () => this.isApproverForSelectedBuilding,
      isApproverForSelectedBuilding => {

        if (!this.authenticationStore.isAuthenticated || !this.selectedPlanDetails ||
          this.authenticationStore.loginType !== LoginType.BUILDING
        ) {

          return;
        }

        const selectBuildingUrl = `/${RoutePath.SelectBuilding}`;

        if (!isApproverForSelectedBuilding && this.appStore.url !== selectBuildingUrl) {

          this.router.navigateByUrl(selectBuildingUrl, {
            replaceUrl: true
          });
        }
      });

    mobxReaction( 'DomainStore: isCanChangeAccountCodeForSelectedBuilding',
      () => this.isCanChangeAccountCodeForSelectedBuilding,
      canChangeAccountCode => {
        if (canChangeAccountCode) {

          this.authenticationStore.addAuthority(Authority.ChangeAccountCode);

        } else {

          this.authenticationStore.removeAuthority(Authority.ChangeAccountCode);
        }
      });
  }

  setLoginMessageDisplayed(val: boolean) {

    // Cookies.set(DomainStore.loginMessageDisplayedCookieName, JSON.stringify(val));
  }

  getLoginMessageDisplayed(): boolean {

    return true;

    // const infoMessageDisplayed = Cookies.get(DomainStore.loginMessageDisplayedCookieName);
    //
    // return infoMessageDisplayed === 'true';
  }

  setSelectedUnit(selectedUnit: OwnerUnitSummary) {

    // if (selectedUnit.canChangeAccountCode) {
    //
    //   this.authenticationStore.addAuthority(Authority.ChangeAccountCode);
    //
    // } else {
    //
    //   this.authenticationStore.removeAuthority(Authority.ChangeAccountCode);
    // }

    this.authenticationStore.setAuthentication({
      // tslint:disable-next-line:no-non-null-assertion
      ...this.authenticationStore.getAuthentication()!,
      selectedUnit
    });
  }

  @action
  setBranch(branch: string | undefined | null) {
    this._branch = branch ? branch : undefined;
  }

  get branch() {
    return this._branch;
  }

  @computed
  get isActStrataBranch(): boolean {

    return this.selectedUnit?.companyId === CompanyId.Canberra || this._branch === CompanyId.Canberra ;
  }

  @computed
  get selectedUnit(): OwnerUnitSummary | undefined {

    const authentication = this.authenticationStore.getAuthentication();

    if (!authentication) {

      return undefined;
    }

    return authentication.selectedUnit;
  }

  @computed
  get selectedUnitBuildingTitle(): string {

    if (this.selectedUnit) {

      return `${this.selectedUnit.unitNumber}, ${this.selectedUnit.building}`;

    } else if (this.selectedPlanDetails && this.selectedPlanDetails.displayTitle) {

      return this.selectedPlanDetails.displayTitle;
    }

    return '';
  }

  @computed
  get selectedUnitId(): string {

    if (!this.selectedUnit) {

      return '';
    }

    return this.selectedUnit.unitId;
  }

  protected getSelectedStrataId(): string {

    if (this.authenticationStore.loginType === LoginType.BUILDING) {

      return super.getSelectedStrataId();

    } else if (this.selectedUnit) {

      return this.selectedUnit.strataId;
    }

    return '';
  }

  @computed
  get isApproverForSelectedUnit(): boolean {

    const authentication = this.authenticationStore.getAuthentication();

    if (!authentication || !authentication.selectedUnit) {

      return false;
    }

    return authentication.selectedUnit.approver === true;
  }

  @action
  setIsApprover(approver: boolean) {

    this._isApprover = approver;
  }

  @action
  setCanChangeAccountCode(changeAccountCode: boolean) {

    this._changeAccountCode = changeAccountCode;
  }

  @computed
  get isApproverForSelectedBuilding(): boolean {

    return this._isApprover === true;
  }

  @computed
  get isCanChangeAccountCodeForSelectedBuilding(): boolean {

    return this._changeAccountCode === true;
  }

  @computed
  get canChangeAccountCodeForSelectedUnit(): boolean {

    const authentication = this.authenticationStore.getAuthentication();

    if (!authentication || !authentication.selectedUnit) {

      return false;
    }

    return authentication.selectedUnit.canChangeAccountCode === true;
  }

  @computed
  get isSelectedUnitStrataPartner(): boolean {

    const authentication = this.authenticationStore.getAuthentication();

    if (!authentication || !authentication.selectedUnit) {

      return false;
    }

    return authentication.selectedUnit.strataPartner === true;
  }

  getLocalisationContext(): LocalisationContext {

    if (this.selectedUnit) {

      return {
        state: this.selectedUnit.state,
        planType: this.selectedUnit.planType,
        companyId: this.selectedUnit.companyId
      };

    } else {

      return super.getLocalisationContext();
    }
  }
}
