/**
 * Whittles API
 * Whittles API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface OwnerPaymentDetailsPriority { 
    index: number;
    reason: OwnerPaymentDetailsPriority.ReasonEnum;
}
export namespace OwnerPaymentDetailsPriority {
    export type ReasonEnum = 'Interest' | 'Overdue' | 'Contribution' | 'Recovery';
    export const ReasonEnum = {
        Interest: 'Interest' as ReasonEnum,
        Overdue: 'Overdue' as ReasonEnum,
        Contribution: 'Contribution' as ReasonEnum,
        Recovery: 'Recovery' as ReasonEnum
    };
}
