// https://docs.logrocket.com/docs/troubleshooting-sessions#section-due-to-logrocket-init-call-being-placed-incorrectly
import './logrocket';
// tslint:disable-next-line: ordered-imports
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// Init the Anguar app
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
