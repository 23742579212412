import { Injectable } from '@angular/core';
import { StrataPlanDetails, StrataService } from 'common-lib';
import { action, computed, observable } from 'mobx';
import { tap } from 'rxjs/operators';

import { DomainStore } from '../../stores/domain.store';

@Injectable()
export class Store {

  @observable
  private _buildings: StrataPlanDetails[] = [];

  constructor(
    private readonly domainStore: DomainStore,
    private readonly strataService: StrataService) {
  }

  getBuildingSummaries() {

    return this.strataService.getBuildingSummaries().pipe(
      tap(buildings => this.setBuildings(buildings))
    );
  }

  @action
  private setBuildings(buildings: StrataPlanDetails[]) {

    this._buildings = buildings;
  }

  @action
  setSelectedBuilding(building: StrataPlanDetails | undefined) {

    if (building) {

      this.domainStore.setSelectedStrataId(building.id);
    }
  }

  @computed
  get selectedBuildingTitle() {

    return this.selectedBuilding && this.selectedBuilding.displayTitle || '';
  }

  @computed
  get selectedBuilding() {

    return this.buildings.find(building => building.id === this.domainStore.selectedStrataId);
  }

  get buildings() {

    return this._buildings;
  }
}
