/* A module to provide shared declarations and exports:
 * https://angular.io/guide/ngmodule-faq#what-kinds-of-modules-should-i-have-and-how-should-i-use-them
 */
import { NgModule } from '@angular/core';
import { SharedCommonModule } from 'common-lib';

import { BuildingSelectorComponent } from '../components/building-selector/building-selector.component';
import { UnitInDebtMessageComponent } from '../components/unit-in-debt-message/unit-in-debt-message.component';
import { UnitSelectorComponent } from '../components/unit-selector/unit-selector.component';

import { PrimeNGModule } from './primeng.module';

@NgModule({
    imports: [
        SharedCommonModule,
        PrimeNGModule,
    ],
    declarations: [
        UnitSelectorComponent,
        BuildingSelectorComponent,
        UnitInDebtMessageComponent
    ],
    exports: [
        SharedCommonModule,
        PrimeNGModule,
        UnitSelectorComponent,
        BuildingSelectorComponent,
        UnitInDebtMessageComponent
    ]
})
export class AppSharedModule {
}
