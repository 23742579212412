import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { BaseAppProperties } from '../base-app-properties';
import {
  AssignTaskParams,
  AssignTasksParams,
  CancelWorkflowParams,
  ClaimTaskParams,
  CompleteTaskParams,
  Configuration,
  HoldTaskParams,
  TaskActionParams,
  TaskType,
  UpdateProcessNotesParams,
  WorkflowService as WorkflowServiceGenerated
} from '../generated';
import { UpdateTaskPreferencesParams } from '../generated/model/updateTaskPreferencesParams';
import { Authority } from '../models/authority';
import { appendNonUniqueUsersLoginNameToFullName } from '../utils/type-mapping.utils';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {

  private readonly workflowServiceGenerated: WorkflowServiceGenerated;

  constructor(httpClient: HttpClient, appProperties: BaseAppProperties) {

    this.workflowServiceGenerated = new WorkflowServiceGenerated(httpClient, appProperties.irisServerBasePath, new Configuration());
  }

  getTasks(params: {
    taskFilter: string;
    taskFilterId: string;
    groups?: Authority[];
    includeInvoiceTasks?: boolean;
    showActivityIndicator?: boolean;
  }) {

    const showActivityIndicator = params.showActivityIndicator == null || params.showActivityIndicator;

    return this.workflowServiceGenerated.getTasks(
      params.taskFilter,
      params.taskFilterId,
      params.groups,
      showActivityIndicator,
      params.includeInvoiceTasks);
  }

  taskAction(taskId: string) {

    return this.workflowServiceGenerated.taskAction(taskId, {
      action: TaskActionParams.ActionEnum.COMPLETE
    });
  }

  getCandidateAssigneesByTaskType(params: {
    strataId: string;
    taskType: TaskType;
  }) {

    return this.workflowServiceGenerated.getCandidateAssigneesByTaskType(params.strataId, params.taskType).pipe(
      map(staff => appendNonUniqueUsersLoginNameToFullName(staff))
    );
  }

  assignTask(assignTaskParams: AssignTaskParams) {

    return this.workflowServiceGenerated.assignTask(assignTaskParams);
  }

  assignTasks(assignTasksParams: AssignTasksParams) {

    return this.workflowServiceGenerated.assignTasks(assignTasksParams);
  }

  completeTask(completeTaskParams: CompleteTaskParams) {

    return this.workflowServiceGenerated.completeTask(completeTaskParams);
  }

  claimTask(claimTaskParams: ClaimTaskParams) {

    return this.workflowServiceGenerated.claimTask(claimTaskParams);
  }

  holdTask(holdTaskParams: HoldTaskParams) {

    return this.workflowServiceGenerated.holdTask(holdTaskParams);
  }

  getProcessInstanceDiagramForTask(params: {
    taskId: string;
    showActivityIndicator?: boolean;
  }) {

    return this.workflowServiceGenerated.getProcessInstanceDiagramForTask(params.taskId, params.showActivityIndicator);
  }

  getTaskCount() {

    const showActivityIndicator = false;

    return this.workflowServiceGenerated.getTaskCount(showActivityIndicator);
  }

  getProcessNotes(args: {
    workflowProcessId: string;
    showActivityIndicator?: boolean;
  }) {

    return this.workflowServiceGenerated.getProcessNotes(args.workflowProcessId, args.showActivityIndicator);
  }

  updateProcessNotes(params: UpdateProcessNotesParams) {

    return this.workflowServiceGenerated.updateProcessNotes(params);
  }

  getProcessInstanceHistory(params: {
    taskId: string;
    showActivityIndicator?: boolean;
  }) {

    return this.workflowServiceGenerated.getProcessInstanceHistory(params.taskId, params.showActivityIndicator);
  }

  cancelWorkflow(cancelWorkflowParams: CancelWorkflowParams) {

    return this.workflowServiceGenerated.cancelWorkflow(cancelWorkflowParams);
  }

  getWorkflowCategories() {

    return this.workflowServiceGenerated.getWorkflowCategories();
  }

  getProcessNotesCount(params: {
    processId: string;
    showActivityIndicator?: boolean;
  }) {

    const showActivityIndicator = !!params.showActivityIndicator;

    return this.workflowServiceGenerated.getProcessNotesCount(params.processId, showActivityIndicator);
  }

  getWorkflowPreferences(args: {
    portfolioId: string;
    taskCategory: string;
  }) {

    return this.workflowServiceGenerated.getWorkflowPreferences(args.portfolioId, args.taskCategory);
  }

  getTaskPreferences(args: {
    portfolioId: string;
    taskCategory: string;
    taskType: string;
  }) {

    return this.workflowServiceGenerated.getTaskPreferences(args.portfolioId, args.taskCategory, args.taskType);
  }

  updateTaskPreferences(params: UpdateTaskPreferencesParams) {

    return this.workflowServiceGenerated.updateTaskPreferences(params);
  }
}
