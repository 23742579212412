/**
 * Whittles API
 * Whittles API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ActivatedCreditor } from '../model/activatedCreditor';
import { CompanyCreditor } from '../model/companyCreditor';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class CreditorService {

    protected basePath = 'https://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param creditorId 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCreditorStatus(creditorId: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getCreditorStatus(creditorId: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getCreditorStatus(creditorId: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getCreditorStatus(creditorId: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (creditorId === null || creditorId === undefined) {
            throw new Error('Required parameter creditorId was null or undefined when calling getCreditorStatus.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (creditorId !== undefined && creditorId !== null) {
            queryParameters = queryParameters.set('creditorId', <any>creditorId);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/creditor/status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets list of creditor by companyId and creditorCode
     * 
     * @param companyId 
     * @param creditorCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCreditors(companyId: number, creditorCode: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CompanyCreditor>>;
    public getCreditors(companyId: number, creditorCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CompanyCreditor>>>;
    public getCreditors(companyId: number, creditorCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CompanyCreditor>>>;
    public getCreditors(companyId: number, creditorCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCreditors.');
        }

        if (creditorCode === null || creditorCode === undefined) {
            throw new Error('Required parameter creditorCode was null or undefined when calling getCreditors.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (companyId !== undefined && companyId !== null) {
            queryParameters = queryParameters.set('companyId', <any>companyId);
        }
        if (creditorCode !== undefined && creditorCode !== null) {
            queryParameters = queryParameters.set('creditorCode', <any>creditorCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CompanyCreditor>>(`${this.basePath}/creditor/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataId 
     * @param showActivityIndicator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrataCreditors(strataId: string, showActivityIndicator?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<ActivatedCreditor>>;
    public getStrataCreditors(strataId: string, showActivityIndicator?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ActivatedCreditor>>>;
    public getStrataCreditors(strataId: string, showActivityIndicator?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ActivatedCreditor>>>;
    public getStrataCreditors(strataId: string, showActivityIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataId === null || strataId === undefined) {
            throw new Error('Required parameter strataId was null or undefined when calling getStrataCreditors.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }
        if (showActivityIndicator !== undefined && showActivityIndicator !== null) {
            queryParameters = queryParameters.set('showActivityIndicator', <any>showActivityIndicator);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ActivatedCreditor>>(`${this.basePath}/creditor/strata/creditors`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
