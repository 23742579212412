import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';

import { BaseComponent } from 'common-lib';

import { AppStore } from '../../stores/app.store';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent extends BaseComponent implements OnInit, OnDestroy {

  constructor(private readonly appStore: AppStore) {

    super();
  }

  ngOnInit() {

    this.appStore.setPageNotFound(true);
  }

  ngOnDestroy() {

    this.appStore.setPageNotFound(false);
  }
}

