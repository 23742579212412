import { NgModule } from '@angular/core';

import { SharedCommonModule } from '../shared-common.module';
import { CommitteeapprovalRoutingModule } from './committee-approval-routing.module';

import { CommitteeapprovalComponent } from './committee-approval.component';

@NgModule({
  imports: [
    SharedCommonModule,
    CommitteeapprovalRoutingModule
  ],
  declarations: [
    CommitteeapprovalComponent
  ]
})
export class CommitteeapprovalModule {
}
