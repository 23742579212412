import { ChangeDetectionStrategy, Component, OnInit, TemplateRef } from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn} from '@angular/forms';
import { OverlayPanel } from 'primeng/overlaypanel';
import { EMPTY, from, Subject } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { BaseAppProperties } from '../../base-app-properties';
import { BaseComponent } from '../../components/base/base.component';
import { FormErrorFontSize } from '../../components/form-error/form-error.component';
import { IconKind } from '../../components/icon-button/icon-button.component';
import { TableHeaderFilterType } from '../../components/table-header/table-header.component';
import { ButtonKind } from '../../directives/button.directive';
import { DropdownKind } from '../../directives/dropdown.directive';
import {
  AccountsPayableInvoiceDetailsApprovers,
  AccountsPayableInvoiceSummary,
  CreditorInvoiceEntryPosting,
  Currency,
  NameValuePair,
  StrataPlanDetails,
  TaskCategory,
  WorkOrderSummary
} from '../../generated';
import { ActionKind } from '../../models/action';
import { HelpTag } from '../../models/help-tag';
import { MessageSeverity } from '../../models/message';
import { PortalType } from '../../models/portal-type';
import { switchMapCatch } from '../../rxjs/operators';
import { AppEventBus } from '../../services/app-event-bus.service';
import { BaseDomainStore } from '../../store/base-domain.store';
import { ConfirmationStore } from '../../store/confirmation.store';
import { DialogStore } from '../../store/dialog.store';
import { asyncEmailValidator, notEmptyValidator, validationError } from '../../utils/form.utils';
import { property } from '../../utils/object.utils';

import { InvoiceSelector, Store } from './committee-approval.store';
import {CompanyId} from "../../models/company"


enum ActionInvoiceForm {
  ApprovalNotes = 'approvalNotes'
}

enum RejectForm {
  InvoiceIncorrect = 'invoiceIncorrect',
  RejectionReason = 'rejectionReason'
}

enum PostingsForm {
  Amount = 'amount',
  Account = 'account',
  FundCode = 'fundCode',
  Comment = 'comment'
}

enum InvoiceOnHoldForm {
  Reason = 'reason'
}

enum OverrideForm {
  Reason = 'reason'
}

enum EmailApprovalForm {
  EmailAddress = 'emailAddress',
  EmailBody = 'emailBody'
}

enum ReprocessRejectedForm {
  Reason = 'reason'
}

enum Action {
  Approve,
  ProcessRejected
}

@Component({
  selector: 'app-committee-approval',
  templateUrl: './committee-approval.component.html',
  styleUrls: ['./committee-approval.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Store]
})
export class CommitteeapprovalComponent extends BaseComponent implements OnInit {

  private readonly invoiceSummaries$ = new Subject();
  private readonly invoiceAlerts$ = new Subject();
  private readonly selectedInvoiceDetails$ = new Subject();
  private readonly approve$ = new Subject();
  private readonly reject$ = new Subject();
  private readonly accountCodes$ = new Subject<TemplateRef<any>>();
  private readonly onHold$ = new Subject();
  private readonly updatePostingComments$ = new Subject<string>();
  private readonly override$ = new Subject();
  private readonly processRejected$ = new Subject();
  private readonly changePlan$ = new Subject<StrataPlanDetails>();
  private readonly emailApprovalRequest$ = new Subject();
  private readonly emailApprovalDelete$ = new Subject<AccountsPayableInvoiceDetailsApprovers>();
  private readonly secondAuthorityFlag$ = new Subject<boolean>();
  private readonly postingsBudget$ = new Subject<CreditorInvoiceEntryPosting[]>();
  private readonly reprocessRejectedInvoice$ = new Subject();

  readonly FormErrorFontSize = FormErrorFontSize;
  readonly ConfirmationStore = ConfirmationStore;
  readonly PortalType = PortalType;
  readonly Action = Action;
  readonly MessageSeverity = MessageSeverity;
  readonly IconKind = IconKind;
  readonly HelpTag = HelpTag;
  readonly ButtonKind = ButtonKind;
  readonly DropdownKind = DropdownKind;
  readonly TableHeaderFilterType = TableHeaderFilterType;

  readonly approversPropertyName = property<AccountsPayableInvoiceDetailsApprovers>('name');
  readonly approversPropertyPosition = property<AccountsPayableInvoiceDetailsApprovers>('position');
  readonly approversPropertyStatus = property<AccountsPayableInvoiceDetailsApprovers>('status');
  readonly approversPropertyDate = property<AccountsPayableInvoiceDetailsApprovers>('date');

  readonly currencyPropertyValue = property<Currency>('value');

  readonly invoiceSummaryPropertyCreditorInvoiceEntryHeaderId = property<AccountsPayableInvoiceSummary>('creditorInvoiceEntryHeaderId');
  readonly invoiceSummaryPropertyDateEntered = property<AccountsPayableInvoiceSummary>('dateEntered');
  readonly invoiceSummaryPropertyPlanNumber = property<AccountsPayableInvoiceSummary>('planNumber');
  readonly invoiceSummaryPropertyCreditor = property<AccountsPayableInvoiceSummary>('creditor');
  readonly invoiceSummaryPropertyDescription = property<AccountsPayableInvoiceSummary>('description');
  readonly invoiceSummaryPropertyAmount = property<AccountsPayableInvoiceSummary>('amount');
  readonly invoiceSummaryPropertyStatus = property<AccountsPayableInvoiceSummary>('status');

  readonly invoiceSummaryAmountPropertyValue = `${this.invoiceSummaryPropertyAmount}.${this.currencyPropertyValue}`;

  readonly workOrderPropertyId = property<WorkOrderSummary>('id');
  readonly workOrderPropertyOrderNumber = property<WorkOrderSummary>('orderNumber');
  readonly workOrderPropertyDescriptionOfWork = property<WorkOrderSummary>('descriptionOfWork');
  readonly workOrderPropertyDateIssued = property<WorkOrderSummary>('dateIssued');

  readonly postingPropertyAccountNumber = property<CreditorInvoiceEntryPosting>('accountNumber');
  readonly postingPropertyFundCode = property<CreditorInvoiceEntryPosting>('fundCode');
  readonly postingPropertyAccountDescription = property<CreditorInvoiceEntryPosting>('accountDescription');
  readonly postingPropertyAmount = property<CreditorInvoiceEntryPosting>('amount');

  readonly postingAmountPropertyValue = `${this.postingPropertyAmount}.${this.currencyPropertyValue}`;

  actionInvoiceForm: UntypedFormGroup;
  rejectForm: UntypedFormGroup;
  postingsForm: UntypedFormGroup;
  invoiceOnHoldForm: UntypedFormGroup;
  overrideForm: UntypedFormGroup;
  emailApprovalForm: UntypedFormGroup;
  reprocessRejectedForm: UntypedFormGroup;

  constructor(
    readonly store: Store,
    readonly appProperties: BaseAppProperties,
    readonly domainStore: BaseDomainStore,
    private readonly fb: UntypedFormBuilder,
    private readonly confirmationStore: ConfirmationStore,
    private readonly dialogStore: DialogStore,
    private readonly appEventBus: AppEventBus) {

    super();
  }

  ngOnInit(): void {

    this.actionInvoiceForm = this.fb.group({
      [ActionInvoiceForm.ApprovalNotes]: ['', this.approvalNotesValidator],
    });

    this.rejectForm = this.fb.group({
      [RejectForm.InvoiceIncorrect]: [],
      [RejectForm.RejectionReason]: ['', notEmptyValidator],
    });

    this.postingsForm = this.fb.group({
      [PostingsForm.Amount]: [0, this.postingsAmountValidator],
      [PostingsForm.Account]: [null, this.postingsAccountValidator],
      [PostingsForm.FundCode]: [null, notEmptyValidator],
      [PostingsForm.Comment]: []
    });

    this.invoiceOnHoldForm = this.fb.group({
      [InvoiceOnHoldForm.Reason]: ['', notEmptyValidator]
    });

    this.overrideForm = this.fb.group({
      [OverrideForm.Reason]: ['', notEmptyValidator]
    });

    this.emailApprovalForm = this.fb.group({
      [EmailApprovalForm.EmailAddress]: ['', [notEmptyValidator, this.emailAddressValidator], [asyncEmailValidator]],
      [EmailApprovalForm.EmailBody]: ['', notEmptyValidator],
    }, {
      validator: this.emailApprovalFormValidator
    });

    this.reprocessRejectedForm = this.fb.group({
      [ReprocessRejectedForm.Reason]: ['', notEmptyValidator]
    });

    this.subscribe(this.postingsAccount.valueChanges.pipe(
      tap(val => {

        this.store.setSelectedPostingsAccount(val);
      })
    ));

    this.subscribe(this.postingsAmount.valueChanges.pipe(
      tap(val => {

        this.store.setSelectedPostingsAmount(val);
      })
    ));

    this.subscribe(this.postingsFundCode.valueChanges.pipe(
      tap(val => {

        this.store.setPostingsFundCode(val);
      })
    ));

    this.subscribe(this.postingsComment.valueChanges.pipe(
      tap(val => {
        this.store.setPostingsComment(val);
      })
    ));

    this.subscribe(this.emailApprovalAddress.valueChanges.pipe(
      tap(val => {

        if (val.trim() !== val) {

          this.emailApprovalAddress.setValue(val.trim());
        }
      })
    ));

    if (this.store.isBranchDropdownVisible) {

      this.subscribe(this.domainStore.getBranchesForUser());
    }

    this.subscribe(this.invoiceSummaries$.pipe(
      switchMapCatch(() => this.store.getInvoiceSummaries())
    ));

    this.subscribe(this.invoiceAlerts$.pipe(
      switchMapCatch(() => this.store.getInvoiceAlerts())
    ));

    this.subscribe(this.selectedInvoiceDetails$.pipe(
      switchMapCatch(() => this.store.getInvoiceDetails())
    ));

    this.subscribe(this.approve$.pipe(
      switchMapCatch(() => {

        const approvalNotes = this.approvalNotes.value && this.approvalNotes.value.trim() ? this.approvalNotes.value : undefined;

        return this.store.approveInvoice({
          approvalNotes
        }).pipe(
          tap(() => {

            this.dialogStore.hide();
          }));
      })
    ));

    this.subscribe(this.reject$.pipe(
      switchMapCatch(() => {

        return this.store.rejectInvoice({
          invoiceIncorrect: this.invoiceIncorrect.value,
          rejectionReason: this.rejectionReason.value
        }).pipe(
          tap(() => {

            this.dialogStore.hide();
          }));
      })
    ));

    this.subscribe(this.accountCodes$.pipe(
      switchMapCatch(templateRef => {

        if (!this.store.filterByBudgetCodesFlag) {
          return this.store.getChartOfAccounts(!templateRef).pipe(
            tap(() => {

              if (templateRef) {
                this.showAccountCodesDialog(templateRef);
              }
            }),
            tap( () => setTimeout( () => this.setPostingsFormValues()))
          );
        } else {
          if (this.store.selectedInvoiceSummary?.strataId) {
            return this.store.getBudgetAccounts({strataId: this.store.selectedInvoiceSummary.strataId}).pipe(
              tap(() => {

                if (templateRef) {
                  this.showAccountCodesDialog(templateRef);
                }
              }),
              tap( () => setTimeout( () => this.setPostingsFormValues()))
            );
          } else {
            return EMPTY;
          }
        }
      })
    ));

    this.subscribe(this.onHold$.pipe(
      switchMapCatch(() => {

        return this.store.updateInvoiceOnHold({
          onHold: !this.store.isSelectedInvoiceOnHold,
          onHoldReason: this.invoiceOnHoldReason.value
        }).pipe(
          tap(() => {

            this.dialogStore.hide();
          }));
      })
    ));

    this.subscribe(this.override$.pipe(
      switchMapCatch(() => {

        return this.store.overrideCommitteeApproval(this.overrideReason.value).pipe(
          tap(() => {

            this.dialogStore.hide();
          }));
      })
    ));

    this.subscribe(this.processRejected$.pipe(
      switchMapCatch(() => {

        return this.store.processRejectedInvoice().pipe(
          tap(() => {

            this.dialogStore.hide();
          }));
      })
    ));

    this.subscribe(this.store.selectedInvoiceDetails$.pipe(
      tap(() => {

        this.selectedInvoiceDetails$.next();
      })
    ));

    this.subscribe(this.changePlan$.pipe(
      switchMapCatch(targetStrataPlan => this.store.changePlan({
        targetStrataPlan
      })),
    ));

    this.subscribe(this.emailApprovalRequest$.pipe(
      switchMapCatch(() => {

        return this.store.requestEmailApproval({
          emailAddress: this.emailApprovalAddress.value,
          emailBody: this.emailApprovalBody.value
        }).pipe(
          tap(() => {

            this.dialogStore.hide();
          }));
      })
    ));

    this.subscribe(this.emailApprovalDelete$.pipe(
      switchMapCatch(approver => this.store.deleteEmailApproval(approver))
    ));

    this.subscribe(this.secondAuthorityFlag$.pipe(
      switchMapCatch(secondAuthorityFlag => this.store.setSecondAuthority({ secondAuthorityFlag })),
      tap(() => this.invoiceAlerts$.next())
    ));

    this.subscribe(this.postingsBudget$.pipe(
      switchMapCatch(postings => {

        if (!this.budgetColumnsVisible) {

          return EMPTY;
        }

        return from(postings).pipe(
          mergeMap(posting => {

            return this.store.getBudgetAndActualTotalsForAccount({
              posting
            });
          })
        );
      })
    ));

    this.subscribe(this.appEventBus.action$.pipe(
      tap(action => {

        switch (action.kind) {

          case ActionKind.ChangeInvoicePlanSelected:

            this.confirmationStore.confirm({
              key: 'okCancel',
              header: 'change plan',
              icon: 'ui-icon-warning',
              // tslint:disable:no-non-null-assertion
              message: `
                Current invoice will be moved to:
                <br>
                <br>
                <ul>
                  <li><b>Plan Number:</b> ${action.selectedPlan.strataNumber}</li>
                  <br>
                  <li><b>Portfolio:</b> ${action.selectedPlan.manager!.name}</li>
                  <br>
                  <li><b>Address:</b> ${action.selectedPlan.address}</li>
                </ul>
              `,
              // tslint:enable:no-non-null-assertion
              rejectVisible: true,
              accept: () => this.changePlan$.next(action.selectedPlan)
            });

            break;
        }
      })
    ));

    this.subscribe(this.reprocessRejectedInvoice$.pipe(
      switchMapCatch(() => {

        return this.store.reprocessRejectedInvoice({
          reason: this.reprocessRejectedReason.value
        }).pipe(
          tap(() => {

            this.dialogStore.hide();
          }));
      })
    ));

    this.mobxReaction('CommitteeapprovalComponent: domainStore.selectedInvoiceApprovalCompany',
      () => this.domainStore.selectedInvoiceApprovalCompany,
      company => {

        if (company) {

          this.store.clearSelectedInvoice();
        }
      });

    this.mobxReaction('CommitteeapprovalComponent: store.selectedInvoiceApprovalCompany',
      () => this.store.filterByBudgetCodesFlag,
      flag => {
          this.accountCodes$.next();
        });

    this.mobxReaction('CommitteeapprovalComponent: store.selectedInvoiceSummary',
      () => this.store.selectedInvoiceSummary,
      selectedInvoiceSummary => {

        if (!selectedInvoiceSummary) {

          return;
        }

        this.selectedInvoiceDetails$.next();
      });

    this.mobxReaction('CommitteeapprovalComponent: refresh invoice summaries',
      () => ({
        selectedPortfolioId: this.domainStore.selectedPortfolioId,
        selectedInvoiceFilter: this.store.selectedInvoiceFilter
      }),
      args => {

        this.invoiceSummaries$.next();

        this.domainStore.refreshNumberOutstandingInvoices();
      });

    this.mobxReaction('CommitteeapprovalComponent: domainStore.selectedPortfolioId',
      () => this.domainStore.selectedPortfolioId,
      selectedPortfolioId => {

        this.invoiceAlerts$.next();
      });

    this.mobxReaction('CommitteeapprovalComponent: store.selectedPosting',
      () => this.store.selectedPosting,
      selectedPosting => {

        setTimeout( () => this.setPostingsFormValues());
      });

    this.mobxReaction('CommitteeapprovalComponent: store.selectedInvoiceFilter',
      () => this.store.selectedInvoiceFilter,
      selectedInvoiceFilter => {

        if (selectedInvoiceFilter.value !== InvoiceSelector.REJECTED) {

          this.store.clearBranchFilter();
        }
      });

    this.mobxReaction('CommitteeapprovalComponent: store.selectedInvoiceDetails.postings',
      () => ({
        postings: this.store.selectedInvoiceDetails && this.store.selectedInvoiceDetails.postings
      }), args => {

        if (!args.postings || args.postings.length === 0) {

          return;
        }

        this.postingsBudget$.next(args.postings);
      });

    this.mobxReaction('CommitteeapprovalComponent: store.postings',
      () => ({
        postings: this.store.postings
      }), args => {

        if (args.postings.length === 0) {

          return;
        }

        this.postingsBudget$.next(args.postings);
      });

    this.mobxReaction('CommitteeapprovalComponent: store.selectedPosting, accountNumber, fundCode',
      () => ({
        selectedPosting: this.store.selectedPosting,
        selectedPostingAccountNumber: this.store.selectedPosting && this.store.selectedPosting.accountNumber,
        selectedPostingFundCode: this.store.selectedPosting && this.store.selectedPosting.fundCode
      }), args => {

        if (!args.selectedPosting || !args.selectedPostingAccountNumber || !args.selectedPostingFundCode) {

          return;
        }

        this.postingsBudget$.next([args.selectedPosting]);
      });

    this.mobxWhen('CommitteeapprovalComponent: select invoice from route data',
      () => !!this.domainStore.taskRouteData && this.store.invoiceSummaries.length > 0,
      () => {

        const taskRouteData = this.domainStore.taskRouteData;

        if (!taskRouteData || taskRouteData.task.category !== TaskCategory.APPROVEINVOICE) {

          return;
        }

        const invoiceSummary = this.store.invoiceSummaries.find(
          invoice => invoice.creditorInvoiceEntryHeaderId === taskRouteData.task.businessKey);

        if (invoiceSummary) {

          this.store.setSelectedInvoiceSummary(invoiceSummary);

          this.store.syncInvoiceSummaryTablePageWithSelectedIndex();

        } else {

          this.store.clearSelectedInvoice({
            reselect: false
          });
        }
      });

    this.mobxReaction('CommitteeapprovalComponent: domainStore.selectedStrataId',
      () => this.domainStore.selectedStrataId,
      selectedStrataId => {

        if (this.store.selectedInvoiceFilter.value === InvoiceSelector.ALLTIMEPLAN) {

          this.invoiceSummaries$.next();
        }
      });
  }

  getApproverName(approver: AccountsPayableInvoiceDetailsApprovers) {

    return approver.name;
  }

  getApproverPosition(approver: AccountsPayableInvoiceDetailsApprovers) {

    return approver.position;
  }

  getApproverStatus(approver: AccountsPayableInvoiceDetailsApprovers) {

    return approver.status;
  }

  getApproverDetails(approver: AccountsPayableInvoiceDetailsApprovers) {

    return approver.details;
  }

  getApproverDate(approver: AccountsPayableInvoiceDetailsApprovers) {

    return approver.date;
  }

  private showActionDialog(params: {
    templateRef: TemplateRef<any>;
    header: string;
    draggable?: boolean;
  }) {

    this.dialogStore.showTemplate(params.templateRef, {
      width: '1100px',
      showHeader: true,
      header: params.header,
      draggable: params.draggable
    });
  }

  onChangePostingAccountNumber(accountNumber: string, posting: CreditorInvoiceEntryPosting) {

    this.store.setAccountNumber(accountNumber, posting);

    this.postingsBudget$.next([posting]);
  }

  onChangePostingFundCode(fundCode: string, posting: CreditorInvoiceEntryPosting) {

    this.store.setFundCode(fundCode, posting);

    this.postingsBudget$.next([posting]);
  }

  onApprove(templateRef: TemplateRef<any>) {

    this.approvalNotes.reset('');

    this.showActionDialog({
      templateRef,
      header: 'approve invoice'
    });
  }

  onReject(templateRef: TemplateRef<any>) {

    this.rejectForm.setValue({
      [RejectForm.InvoiceIncorrect]: false,
      [RejectForm.RejectionReason]: ''
    });

    this.showActionDialog({
      templateRef,
      header: 'reject invoice'
    });
  }

  onAccountCodes(templateRef: TemplateRef<any>) {

    this.accountCodes$.next(templateRef);
  }

  onInvoiceOnHold(templateRef: TemplateRef<any>) {

    this.invoiceOnHoldReason.setValue(this.store.selectedInvoiceDetails && this.store.selectedInvoiceDetails.onHoldReason);

    this.showActionDialog({
      templateRef,
      header: this.store.selectedInvoiceOnHoldButtonKind
    });
  }

  onEmailApproval(templateRef: TemplateRef<any>, approver?: AccountsPayableInvoiceDetailsApprovers) {

    if (approver) {

      this.emailApprovalForm.reset({
        [EmailApprovalForm.EmailAddress]: approver.name,
        [EmailApprovalForm.EmailBody]: approver.emailBody
      });

    } else {

      const name = 'Whittles'; // IM-274 this.store.selectedInvoiceSummary?.company.id === CompanyId.Canberra ? 'A.C.T. Strata Management Services' : 'Whittles'

      this.emailApprovalForm.reset({
        [EmailApprovalForm.EmailAddress]: '',
        [EmailApprovalForm.EmailBody]:
          // tslint:disable:max-line-length
          `To whom it may concern,\n` +
          `\n` +
          `You are receiving this invoice approval email from ${name} Accounts Management System.\n` +
          `\n` +
          `As the delegated authority to approve invoices, could you please approve the attached invoice using the Approve button below.\n` +
          `\n` +
          `If you do not wish to approve this invoice or need to seek further clarification, please contact the sender.\n`,
        // tslint:enable:max-line-length
      });
    }

    this.showActionDialog({
      templateRef,
      header: 'request email approval'
    });
  }

  onEmailApproverDelete(approver: AccountsPayableInvoiceDetailsApprovers) {

    this.confirmationStore.confirm({
      key: 'okCancel',
      header: 'delete email approval',
      icon: 'ui-icon-warning',
      message: `Are you sure you want to delete the email approval for <b>${approver.name}</b>?`,
      rejectVisible: true,
      accept: () => this.emailApprovalDelete$.next(approver)
    });
  }

  onOverride(templateRef: TemplateRef<any>) {

    this.overrideReason.setValue('');

    this.showActionDialog({
      templateRef,
      header: 'override committee approval'
    });
  }

  onProcessRejected(templateRef: TemplateRef<any>) {

    this.showActionDialog({
      templateRef,
      header: 'process rejected invoice'
    });
  }

  onReprocessRejected(templateRef: TemplateRef<any>) {

    this.showActionDialog({
      templateRef,
      header: 'return to manager'
    });
  }

  onSecondAuthorityChange(flag: boolean) {

    this.secondAuthorityFlag$.next(flag);
  }

  private showAccountCodesDialog(templateRef: TemplateRef<any>) {

    this.store.clonePostings();

    this.showActionDialog({
      templateRef,
      header: 'account codes',
      draggable: true
    });
  }

  private emailAddressValidator: ValidatorFn = control => {

    const emailApprover = this.store.findEmailApproverByEmail(control.value);

    if (emailApprover && emailApprover.date) {

      return validationError(`"${control.value}" has already approved invoice`);
    }

    return null;
  }

  private emailApprovalFormValidator: ValidatorFn = control => {

    return this.store.isEmailApprovalFormReadonly ? validationError('') : null;
  }

  private setPostingsFormValues() {

    if (!this.store.selectedPosting) {

      this.postingsForm.disable({
        emitEvent: false
      });

      this.postingsForm.setValue({
        [PostingsForm.Amount]: 0,
        [PostingsForm.Account]: null,
        [PostingsForm.FundCode]: null,
        [PostingsForm.Comment]: null
      }, {
        emitEvent: false
      });

    } else {

      if (this.store.isAllowedToActionInvoice) {

        this.postingsForm.enable({
          emitEvent: false
        });

      } else {

        this.postingsForm.disable({
          emitEvent: false
        });
      }

      this.postingsForm.setValue({
        [PostingsForm.Amount]: this.store.selectedPosting.amount.value,
        [PostingsForm.Account]: this.store.selectedPostingAccount,
        [PostingsForm.FundCode]: this.store.selectedPosting ? this.store.selectedPosting.fundCode : null,
        [PostingsForm.Comment]: this.store.selectedPosting ? this.store.selectedPosting.comments : null
      }, {
        emitEvent: false
      });
    }

    this.postingsForm.updateValueAndValidity();
  }

  onDialogCancel() {

    this.dialogStore.hide();
  }

  onAccountCodesFormSubmit() {

    this.store.updateOriginalPostingsFromClone();

    this.dialogStore.hide();
  }

  onActionInvoiceFormSubmit(action: Action) {

    switch (action) {

      case Action.Approve:

        this.approve$.next();

        break;

      case Action.ProcessRejected:

        this.processRejected$.next();

        break;

      default:

        throw new Error(`Unknown action: ${action}`);
    }
  }

  onRejectFormSubmit() {

    this.reject$.next();
  }

  onReprocessRejectedFormSubmit() {

    this.reprocessRejectedInvoice$.next();
  }

  onAddPosting() {

    this.store.addPosting();
  }

  onRemovePosting() {

    this.store.removeSelectedPosting();
  }

  onWorkOrdersDescriptionMoreClick(evt: Event, workOrder: WorkOrderSummary, overlayPanel: OverlayPanel) {

    this.store.setMoreIndicatorText(workOrder.workOrderDetails);

    overlayPanel.toggle(evt);
  }

  onAccountCodesDescriptionMoreClick(evt: Event, posting: CreditorInvoiceEntryPosting, overlayPanel: OverlayPanel) {

    this.store.setMoreIndicatorText(posting.comments);

    overlayPanel.toggle(evt);
  }

  onApproverStatusMoreClick(evt: Event, approver: AccountsPayableInvoiceDetailsApprovers, overlayPanel: OverlayPanel) {

    this.store.setMoreIndicatorText(approver.details);

    overlayPanel.toggle(evt);
  }

  onInvoiceOnHoldFormSubmit() {

    this.onHold$.next();
  }

  onOverrideFormSubmit() {

    this.override$.next();
  }

  onChangePlan() {

    this.appEventBus.emit({
      kind: ActionKind.ChangeInvoicePlan
    });
  }

  onFilterByBudgetCodesChange(flag: boolean) {
    this.store.setFilterByBudgetCodesFlag(flag);
  }


  onEmailApprovalSubmit() {

    const emailApprover = this.store.findEmailApproverByEmail(this.emailApprovalAddress.value);

    if (emailApprover) {

      this.confirmationStore.confirm({
        key: 'okCancel',
        header: 'resend approval email',
        icon: 'ui-icon-help',
        message: `Are sure you want to resend an approval email to <b>${this.emailApprovalAddress.value}</b>?`,
        accept: () => this.emailApprovalRequest$.next(),
        rejectVisible: true
      });

    } else {

      this.emailApprovalRequest$.next();
    }
  }

  isActionInvoiceWarningsVisible(action: Action) {

    return action === Action.Approve && this.store.isActionInvoiceWarningsVisible;
  }

  private approvalNotesValidator: ValidatorFn = control => {

    if (this.store.isApprovalNotesRequired && !this.store.isProcessRejectedButtonVisible) {

      return notEmptyValidator(control);
    }

    return null;
  }

  private postingsAmountValidator: ValidatorFn = control => {

    if (!this.store.isPostingAmountValid(control.value)) {

      return validationError('Amount must be > 0');
    }

    return null;
  }

  private postingsAccountValidator: ValidatorFn = control => {

    const account = control.value as (NameValuePair | undefined);

    if (!this.store.isPostingAccountNumberValid(account && account.value)) {

      return validationError('Account is required');
    }

    return null;
  }

  getInvoiceSummaryDateEntered(invoiceSummary: AccountsPayableInvoiceSummary) {

    return invoiceSummary.dateEntered;
  }

  getInvoiceSummaryPlanNumber(invoiceSummary: AccountsPayableInvoiceSummary) {

    return invoiceSummary.planNumber;
  }

  getInvoiceSummaryCreditor(invoiceSummary: AccountsPayableInvoiceSummary) {

    return invoiceSummary.creditor;
  }

  getInvoiceSummaryDescription(invoiceSummary: AccountsPayableInvoiceSummary) {

    return invoiceSummary.description;
  }

  getInvoiceSummaryAmount(invoiceSummary: AccountsPayableInvoiceSummary) {

    return invoiceSummary.amount;
  }

  getInvoiceSummaryStatus(invoiceSummary: AccountsPayableInvoiceSummary) {

    return invoiceSummary.status;
  }

  getInvoiceSummaryOnHold(invoiceSummary: AccountsPayableInvoiceSummary) {

    return invoiceSummary.onHold;
  }

  getWorkOrderNumber(workOrder: WorkOrderSummary) {

    return workOrder.orderNumber;
  }

  getWorkOrderDescriptionOfWork(workOrder: WorkOrderSummary) {

    return workOrder.descriptionOfWork;
  }

  getWorkOrderDetails(workOrder: WorkOrderSummary) {

    return workOrder.workOrderDetails;
  }

  getWorkOrderDateIssued(workOrder: WorkOrderSummary) {

    return workOrder.dateIssued;
  }

  getPostingAccountNumber(posting: CreditorInvoiceEntryPosting) {

    return posting.accountNumber;
  }

  getPostingFundCode(posting: CreditorInvoiceEntryPosting) {

    return posting.fundCode;
  }

  getPostingAccountDescription(posting: CreditorInvoiceEntryPosting) {

    return posting.accountDescription;
  }

  getPostingComments(posting: CreditorInvoiceEntryPosting) {

    return posting.comments;
  }

  getPostingAmount(posting: CreditorInvoiceEntryPosting) {

    return posting.amount;
  }

  get budgetColumnsVisible(): boolean {

    return this.appProperties.portalType === PortalType.Manager;
  }

  get approvalNotes() {

    return this.actionInvoiceForm.controls[ActionInvoiceForm.ApprovalNotes];
  }

  get invoiceIncorrect() {

    return this.rejectForm.controls[RejectForm.InvoiceIncorrect];
  }

  get rejectionReason() {

    return this.rejectForm.controls[RejectForm.RejectionReason];
  }

  get postingsAmount() {

    return this.postingsForm.controls[PostingsForm.Amount] as UntypedFormControl;
  }

  get postingsAccount() {

    return this.postingsForm.controls[PostingsForm.Account];
  }

  get postingsFundCode() {

    return this.postingsForm.controls[PostingsForm.FundCode];
  }

  get postingsComment() {

    return this.postingsForm.controls[PostingsForm.Comment] as UntypedFormControl;
  }

  get invoiceOnHoldReason() {

    return this.invoiceOnHoldForm.controls[InvoiceOnHoldForm.Reason];
  }

  get overrideReason() {

    return this.overrideForm.controls[OverrideForm.Reason];
  }

  get emailApprovalAddress() {

    return this.emailApprovalForm.controls[EmailApprovalForm.EmailAddress];
  }

  get emailApprovalBody() {

    return this.emailApprovalForm.controls[EmailApprovalForm.EmailBody];
  }

  get reprocessRejectedReason() {

    return this.reprocessRejectedForm.controls[ReprocessRejectedForm.Reason];
  }
}
