import { Component } from "@angular/core";
import { tap } from "rxjs/operators";

// note if you import common-lib -> you will get circular dependencies.
import { MediaType } from '../../models/media-type';
import { FileService } from '../../services/file.service';
import { OwnerService } from '../../services/owner.service';
import { BaseComponent } from '../base/base.component';


@Component({
  selector: 'app-covid',
  templateUrl: './covid-component.html',
  styleUrls: ['./covid-component.scss']
})
export class CovidComponent extends BaseComponent {

  constructor(
    private ownerService: OwnerService,
    private fileService: FileService
  ) {
    super();
  }

  onClick() {
    return this.ownerService.getCovidFile().pipe(
      tap(fileData => this.fileService.save({
        fileData,
        contentType: MediaType.Pdf
      }))).subscribe();
  }
}
