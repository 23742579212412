import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Optional } from '@angular/core';
import { FilterMetadata } from 'primeng/api';
import { Table } from 'primeng/table';

import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-global-filter',
  templateUrl: './global-filter.component.html',
  styleUrls: ['./global-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalFilterComponent extends BaseComponent {

  @Input() placeholder = 'Global Filter';
  @Input() filterMatchMode = 'contains';

  constructor(
    private readonly cdr: ChangeDetectorRef,
    @Optional() private readonly table?: Table) {

    super();
  }

  onInput(e: any) {

    if (this.table) {

      this.table.filterGlobal(e.target.value, this.filterMatchMode);

    } else {

      throw new Error('GlobalFilterComponent.onInput: No control specified.');
    }
  }

  refresh() {

    this.cdr.detectChanges();
  }

  get model(): string {

    if (this.table) {

      // tslint:disable-next-line: no-string-literal
      const filterMetadata = this.table.filters['global'];
      if (!Array.isArray(filterMetadata)) {
        return filterMetadata && filterMetadata.value || '';
      } else {
        const filterMetadatas = filterMetadata.filter(f => !!f.value).map(f => f.value);
        return filterMetadatas.length == 0 ? '' : filterMetadatas.join(', ');
      }
    }

    return '';
  }
}
