import { Injectable } from '@angular/core';
import { action, computed, observable } from 'mobx';
import { tap } from 'rxjs/operators';

import { FinancialYear, ProcessNotesResponse } from '../../generated';
import { WorkflowContext } from '../../models/workflow-context';
import { AgmService } from '../../services/agm.service';
import { WorkflowService } from '../../services/workflow.service';
import { BaseDomainStore } from '../../store/base-domain.store';
import { formatArray } from '../../utils/array.utils';

@Injectable()
export class Store {

  @observable
  private _financialYear?: FinancialYear;

  @observable
  private _workflowContext?: WorkflowContext;

  @observable
  private _workflowProcessId?: string;

  @observable
  private _processNotesResponse?: ProcessNotesResponse;

  @observable
  private _processNotesDialogVisible = false;

  constructor(
    private readonly domainStore: BaseDomainStore,
    private readonly workflowService: WorkflowService,
    private readonly agmService: AgmService) {
  }

  getProcessNotes() {

    return this.workflowService.getProcessNotes({
      workflowProcessId: this.workflowProcessId,
      showActivityIndicator: true
    }).pipe(
      tap(response => this.setProcessNotesResponse(response))
    );
  }

  getWorkflowProcessId() {

    return this.agmService.getWorkflowProcessId({
      // tslint:disable-next-line: no-non-null-assertion
      financialYearId: this._financialYear!.id,
      context: this._workflowContext,
      showActivityIndicator: true
    }).pipe(
      tap(workflowProcessId => this.setWorkflowProcessId(workflowProcessId))
    );
  }

  @action
  private setWorkflowProcessId(val: string) {

    this._workflowProcessId = val;
  }

  @action
  setFinancialYear(val: FinancialYear | undefined) {

    this._financialYear = val;

    this._workflowProcessId = undefined;
  }

  @action
  setWorkflowContext(val: WorkflowContext | undefined) {

    this._workflowContext = val;
  }

  @action
  private setProcessNotesResponse(processNotesResponse: ProcessNotesResponse) {

    this._processNotesResponse = processNotesResponse;
  }

  @action
  setProcessNotesDialogVisible(val: boolean) {

    this._processNotesDialogVisible = val;
  }

  @computed
  get message(): string {

    if (this.processNoteTargets.size > 0) {

      return `Show workflow notes addressed to ${formatArray(Array.from(this.processNoteTargets))}.`;
    }

    return 'New Workflow Note';
  }

  @computed
  get icon(): string {

    if (this.processNoteTargets.size > 0) {

      return 'ui-icon-info-outline';
    }

    return 'ui-icon-add';
  }

  @computed
  get processNoteTargets(): Set<string> {

    if (!this._processNotesResponse || this._processNotesResponse.processNotes.length === 0) {

      return new Set();
    }

    const notes = this._processNotesResponse.processNotes
      .map(pn => pn.target && pn.target.name)
      .filter(t => !!t) as string[];

    return new Set(notes);
  }

  @computed
  get isVisible(): boolean {

    return this.domainStore.isTaskActionable || !!this._workflowProcessId;
  }

  get processNotesDialogVisible() {

    return this._processNotesDialogVisible;
  }

  @computed
  get workflowProcessId(): string {

    return this.domainStore.taskRouteData ?
      this.domainStore.taskRouteData.task.processId || '' :
      this._workflowProcessId || '';
  }

  get financialYear() {

    return this._financialYear;
  }
}
