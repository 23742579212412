
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { groupBy } from 'lodash-es';
import { SortEvent, SortMeta } from 'primeng/api';

import { BaseAppProperties } from '../../base-app-properties';
import { BaseComponent } from '../../components/base/base.component';
import { ButtonKind } from '../../directives/button.directive';
import { Currency, FileData, FinancialYear, WhiteFolderInvoiceSummary } from '../../generated';
import { MessageSeverity } from '../../models/message';
import { PortalType } from '../../models/portal-type';
import { WorkflowContext } from '../../models/workflow-context';
import { Logger } from '../../services/logger.service';
import { FormModel } from '../../utils/form.utils';
import { property } from '../../utils/object.utils';
import { TableHeaderFilterType } from '../table-header/table-header.component';
import { TableComponent } from '../table/table.component';

import { Store } from './reconciliation-browser.store';

interface PaidInvoiceFormModel {
  reconciled: boolean;
  reconciledComments: string;
}

@Component({
  selector: 'app-reconciliation-browser',
  templateUrl: './reconciliation-browser.component.html',
  styleUrls: ['./reconciliation-browser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Store]
})
export class ReconciliationBrowserComponent extends BaseComponent implements OnInit {

  private transactionsCustomSortInvoked = false;

  readonly whiteFolderInvoiceSummaryPropertyId = property<WhiteFolderInvoiceSummary>('id');
  readonly whiteFolderInvoiceSummaryPropertyAccountNumber = property<WhiteFolderInvoiceSummary>('accountNumber');
  readonly whiteFolderInvoiceSummaryPropertyDatePaid = property<WhiteFolderInvoiceSummary>('datePaid');
  readonly whiteFolderInvoiceSummaryPropertyAmount = property<WhiteFolderInvoiceSummary>('amount');
  readonly whiteFolderInvoiceSummaryPropertyGst = property<WhiteFolderInvoiceSummary>('gst');
  readonly whiteFolderInvoiceSummaryPropertyReconciled = property<WhiteFolderInvoiceSummary>('reconciled');
  readonly whiteFolderInvoiceSummaryPropertyReference = property<WhiteFolderInvoiceSummary>('reference');
  readonly whiteFolderInvoiceSummaryPropertyAccountDescription = property<WhiteFolderInvoiceSummary>('accountDescription');

  readonly currencyPropertyValue = property<Currency>('value');

  readonly whiteFolderInvoiceSummaryPropertyAmountValue = `${this.whiteFolderInvoiceSummaryPropertyAmount}.${this.currencyPropertyValue}`;
  readonly whiteFolderInvoiceSummaryPropertyGstValue = `${this.whiteFolderInvoiceSummaryPropertyGst}.${this.currencyPropertyValue}`;

  readonly WorkflowContext = WorkflowContext;

  private readonly paidInvoicesDefaultSort: SortMeta[] = [
    {
      field: this.whiteFolderInvoiceSummaryPropertyAccountNumber,
      order: 1
    },
    {
      field: this.whiteFolderInvoiceSummaryPropertyDatePaid,
      order: 1
    }
  ];

  @ViewChild('transactionsTable') transactionsTable?: TableComponent;

  @Output() readonly formSubmit = new EventEmitter();
  @Output() readonly lockPaidInvoicesChange = new EventEmitter<boolean>();
  @Output() readonly consolidatedYearChange = new EventEmitter<boolean>();
  @Output() readonly lockPaidInvoicesOnChange = new EventEmitter<boolean>();  // Emits after lockPaidInvoicesChange
  @Output() readonly consolidatedYearOnChange = new EventEmitter<boolean>();
  @Output() readonly hideReconciledPaidInvoicesChange = new EventEmitter<boolean>();
  @Output() readonly selectedPaidInvoiceChange = new EventEmitter<WhiteFolderInvoiceSummary>();
  // tslint:disable-next-line: max-line-length
  @Output() readonly selectedPaidInvoiceReconciledOnChange = new EventEmitter<{ reconciled: boolean; invoice: WhiteFolderInvoiceSummary }>();
  @Output() readonly selectedPaidInvoicesFinancialYearChange = new EventEmitter<FinancialYear>();
  @Output() readonly taskDoneClick = new EventEmitter();
  @Output() readonly paidInvoicesChange = new EventEmitter<WhiteFolderInvoiceSummary[]>();

  @Input() numberOfRecordsReconciledMessage = '';
  @Input() financialYearReconciledMessage = '';
  @Input() numberOfRecordsReconciledSevertity?: MessageSeverity;
  @Input() paidInvoicesFinancialYears: FinancialYear[] = [];
  @Input() selectedPaidInvoicesFinancialYear?: FinancialYear;
  @Input() lockPaidInvoicesVisible = false;
  @Input() consolidatedYearVisible = false;
  @Input() auditedVisible = false;
  @Input() lockPaidInvoicesEnabled = false;
  @Input() consolidatedYearEnabled = false;
  @Input() auditedEnabled = false;
  @Input() lockPaidInvoices = false;
  @Input() consolidatedYear = false;
  @Input() audited = false;
  @Input() hideReconciledPaidInvoices = false;
  @Input() paidInvoices: WhiteFolderInvoiceSummary[] = [];
  @Input() selectedPaidInvoice?: WhiteFolderInvoiceSummary;
  @Input() isPaidInvoiceBrowserReconcileMode = false;
  @Input() selectedPaidInvoiceDocumentFileData?: FileData;
  @Input() taskDoneVisible = false;
  @Input() taskDoneEnabled = false;
  @Input() sortByAmountVisible = false;

  readonly MessageSeverity = MessageSeverity;
  readonly ButtonKind = ButtonKind;
  readonly TableHeaderFilterType = TableHeaderFilterType;
  readonly PortalType = PortalType;

  paidInvoicesMultiSortMeta = this.paidInvoicesDefaultSort;
  paidInvoiceForm: UntypedFormGroup;

  constructor(
    readonly store: Store,
    readonly appProperties: BaseAppProperties,
    private readonly fb: UntypedFormBuilder,
    private readonly logger: Logger) {

    super();
  }

  @Input() isPaidInvoiceCheckboxEnabled = (invoice: WhiteFolderInvoiceSummary | undefined) => false;

  ngOnInit() {

    const paidInvoiceFormModel: FormModel<PaidInvoiceFormModel> = {
      reconciled: [false],
      reconciledComments: ['']
    };

    this.paidInvoiceForm = this.fb.group(paidInvoiceFormModel);

    this.mobxReaction('ReconciliationBrowserComponent: store.sortByAmount',
      () => this.store.sortByAmount,
      sortByAmount => {

        this.resetSort();
      });
  }

  resetSort() {

    if (this.transactionsTable && this.store.sortByAmount) {

      this.transactionsTable.table.sortField = this.whiteFolderInvoiceSummaryPropertyAmountValue;
      this.transactionsTable.table.sortOrder = 1;
      this.transactionsTable.table.sortSingle();

    } else {

      this.paidInvoicesMultiSortMeta = [
        ...this.paidInvoicesDefaultSort
      ];
    }

    if (this.transactionsTable) {

      setTimeout(() => this.transactionsTable && this.transactionsTable.refreshHeaders());
    }
  }

  selectedPaidInvoiceUpdated(selectedPaidInvoice: WhiteFolderInvoiceSummary | undefined) {

    if (selectedPaidInvoice) {

      this.paidInvoiceReconciled.setValue(!!selectedPaidInvoice.reconciled);
      this.paidInvoiceReconciledComments.setValue(selectedPaidInvoice.reconciledComments);

      this.paidInvoiceReconciledComments.enable();

    } else {

      this.paidInvoiceReconciled.setValue(false);
      this.paidInvoiceReconciledComments.setValue('');

      this.paidInvoiceReconciledComments.disable();
    }
  }

  transactionsCustomSort(sortEvent: SortEvent) {

    if (sortEvent.field !== this.whiteFolderInvoiceSummaryPropertyAmountValue) {

      // Only valid for amount
      this.logger.warn(`ReconciliationBrowserComponent.transactionsCustomSort: sortEvent.field = ${sortEvent.field}`);

      return;
    }

    if (this.transactionsCustomSortInvoked) {

      this.transactionsCustomSortInvoked = false;

      return;
    }

    const paidInvoices = this.paidInvoices.slice();

    // Group by absolute value so as to apply a secondary sort by account number (ignore fund)
    const paidInvoicesGroupByAbsoluteAmount = groupBy(paidInvoices, (invoice: WhiteFolderInvoiceSummary) => Math.abs(invoice.amount.value));

    // The array with the final sort order
    const paidInvoicesSortByAbsoluteValueAndAccount: WhiteFolderInvoiceSummary[] = [];

    const absoluteAmounts = Object.keys(paidInvoicesGroupByAbsoluteAmount).map(k => Number(k));

    // Sort the absolute amounts ascending/descending
    absoluteAmounts.sort((a, b) => sortEvent.order === 1 ? a - b : b - a);

    absoluteAmounts.forEach(amount => {

      const invoicesForAmount = paidInvoicesGroupByAbsoluteAmount[amount].slice();

      // Sort the account number (ignore fund)
      invoicesForAmount.sort((a, b) => a.accountNumber.substring(1).localeCompare(b.accountNumber.substring(1)));

      paidInvoicesSortByAbsoluteValueAndAccount.push(...invoicesForAmount);
    });

    this.transactionsCustomSortInvoked = true;

    this.paidInvoicesChange.emit(paidInvoicesSortByAbsoluteValueAndAccount);
  }

  getInvoiceAmount(invoice: WhiteFolderInvoiceSummary) {

    return invoice.amount;
  }

  getInvoiceGst(invoice: WhiteFolderInvoiceSummary) {

    return invoice.gst;
  }

  getInvoiceAccountNumber(invoice: WhiteFolderInvoiceSummary) {

    return invoice.accountNumber;
  }

  getInvoiceReference(invoice: WhiteFolderInvoiceSummary) {

    return invoice.reference;
  }

  getInvoiceAccountDescription(invoice: WhiteFolderInvoiceSummary) {

    return invoice.accountDescription;
  }

  getInvoiceDatePaid(invoice: WhiteFolderInvoiceSummary) {

    return invoice.datePaid;
  }

  getInvoiceTransactionDate(invoice: WhiteFolderInvoiceSummary) {

    return invoice.dateTransaction;
  }

  get paidInvoiceReconciled() {

    return this.paidInvoiceForm.controls[property<PaidInvoiceFormModel>('reconciled')] as FormControl;
  }

  get paidInvoiceReconciledComments() {

    return this.paidInvoiceForm.controls[property<PaidInvoiceFormModel>('reconciledComments')];
  }
}
