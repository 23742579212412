import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  BaseComponent,
  Currency,
  DialogStore,
  LocalisationStore,
  OwnerUnitSummaryFinancials,
  property,
  switchMapCatch,
  TableHeaderFilterType
} from 'common-lib';
import { EMPTY, from, Subject } from 'rxjs';
import { catchError, finalize, mergeMap, switchMap, tap } from 'rxjs/operators';

import { RoutePath } from '../../route-path';
import { DomainStore } from '../../stores/domain.store';

import { UnitSelectorContext } from './unit-selector.context';
import { OwnerUnitSummary, Store } from './unit-selector.store';

@Component({
  selector: 'app-unit-selector',
  templateUrl: './unit-selector.component.html',
  styleUrls: ['./unit-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Store]
})
export class UnitSelectorComponent extends BaseComponent implements OnInit {

  private readonly getUnitsSummary$ = new Subject();

  readonly currencyPropertyValue = property<Currency>('value');

  readonly ownerUnitSummaryFinancialsPropertyBalanceOwning = property<OwnerUnitSummaryFinancials>('balanceOwing');
  readonly ownerUnitSummaryFinancialsPropertyReminders = property<OwnerUnitSummaryFinancials>('reminders');

  readonly ownerUnitSummaryPropertyUnitId = property<OwnerUnitSummary>('unitId');
  readonly ownerUnitSummaryPropertyState = property<OwnerUnitSummary>('state');
  readonly ownerUnitSummaryPropertyStrataName = property<OwnerUnitSummary>('strataName');
  readonly ownerUnitSummaryPropertyBuilding = property<OwnerUnitSummary>('building');
  readonly ownerUnitSummaryPropertyUnitNumber = property<OwnerUnitSummary>('unitNumber');
  readonly ownerUnitSummaryPropertyFinancials = property<OwnerUnitSummary>('financials');

  readonly ownerUnitSummaryPropertyFinancialsBalanceOwingValue =
    `${this.ownerUnitSummaryPropertyFinancials}.${this.ownerUnitSummaryFinancialsPropertyBalanceOwning}.${this.currencyPropertyValue}`;

  readonly ownerUnitSummaryPropertyFinancialsReminders =
    `${this.ownerUnitSummaryPropertyFinancials}.${this.ownerUnitSummaryFinancialsPropertyReminders}`;

  readonly TableHeaderFilterType = TableHeaderFilterType;

  @Input() showHeader = true;

  static showInDialog(dialogStore: DialogStore) {

    dialogStore.showComponent(UnitSelectorComponent, {
      context: new UnitSelectorContext(),
      dismissableMask: true
    });
  }

  constructor(
    readonly store: Store,
    readonly domainStore: DomainStore,
    readonly localisationStore: LocalisationStore,
    private readonly cdr: ChangeDetectorRef,
    private readonly dialogStore: DialogStore,
    private readonly router: Router) {

    super();
  }

  ngOnInit() {

    this.subscribe(this.getUnitsSummary$.pipe(
      switchMapCatch(() => {

        return this.store.getUnitsSummary().pipe(
          tap(units => this.centreDialog()),
          switchMap(units => from(units)),
          mergeMap(unit => {

            return this.store.getUnitsSummaryFinancials({
              unitId: unit.unitId
            }).pipe(
              catchError(e => EMPTY),
              finalize(() => {

                this.cdr.detectChanges();

                this.centreDialog();
              })
            );
          })
        );
      })
    ));

    this.getUnitsSummary$.next();
  }

  onUnitSelected() {

    this.hideDialog();
  }

  onUnitUnselected() {

    this.hideDialog();
  }

  onPayBill(ownerUnitSummary: OwnerUnitSummary) {

    this.selectUnit(ownerUnitSummary, `/${RoutePath.MakePayment}`);
  }

  onHome(ownerUnitSummary: OwnerUnitSummary) {

    this.selectUnit(ownerUnitSummary, `/${RoutePath.Home}`);
  }

  onInvoices(ownerUnitSummary: OwnerUnitSummary) {

    this.selectUnit(ownerUnitSummary, `/${RoutePath.Invoices}`);
  }

  onContact(ownerUnitSummary: OwnerUnitSummary) {

    this.selectUnit(ownerUnitSummary, `/${RoutePath.Contact}`);
  }

  getUnitState(ownerUnitSummary: OwnerUnitSummary) {

    return ownerUnitSummary.state;
  }

  getUnitStrataName(ownerUnitSummary: OwnerUnitSummary) {

    return ownerUnitSummary.strataName;
  }

  getUnitBuilding(ownerUnitSummary: OwnerUnitSummary) {

    return ownerUnitSummary.building;
  }

  getUnitNumber(ownerUnitSummary: OwnerUnitSummary) {

    return ownerUnitSummary.unitNumber;
  }

  getUnitFinancials(ownerUnitSummary: OwnerUnitSummary) {

    return ownerUnitSummary.financials;
  }

  private hideDialog() {

    if (this.dialogStore.context instanceof UnitSelectorContext) {

      this.dialogStore.hide();
    }
  }

  private centreDialog() {

    if (this.dialogStore.context instanceof UnitSelectorContext) {

      this.dialogStore.positionOverlay();
    }
  }

  private selectUnit(ownerUnitSummary: OwnerUnitSummary, route: string) {

    this.store.setSelectedUnit(ownerUnitSummary);

    this.router.navigateByUrl(route);

    this.hideDialog();
  }
}
