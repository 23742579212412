import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

export enum SafePipeBypassType {
  Html = 'html',
  Style = 'style',
  Script = 'script',
  Url = 'url',
  ResourceUrl = 'resourceUrl'
}

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {

  constructor(private readonly sanitizer: DomSanitizer) {
  }

  transform(value: any, type: string) {

    switch (type) {

      case SafePipeBypassType.Html:

        return this.sanitizer.bypassSecurityTrustHtml(value);

      case SafePipeBypassType.Style:

        return this.sanitizer.bypassSecurityTrustStyle(value);

      case SafePipeBypassType.Script:

        return this.sanitizer.bypassSecurityTrustScript(value);

      case SafePipeBypassType.Url:

        return this.sanitizer.bypassSecurityTrustUrl(value);

      case SafePipeBypassType.ResourceUrl:

        return this.sanitizer.bypassSecurityTrustResourceUrl(value);

      default:

        throw new Error(`Invalid safe type specified: ${type}`);
    }
  }
}
