import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { Authority } from '../models/authority';
import { BaseAuthenticationStore } from '../store/base-authentication.store';

export abstract class BaseAuthGuardService implements CanActivateChild {

  constructor(protected readonly authenticationStore: BaseAuthenticationStore) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

    if (childRoute.url.length === 0) {

      return true;
    }

    const hasAccess = this.hasAccess(childRoute.url[0].path);

    if (!hasAccess) {

      this.authenticationStore.logout();
    }

    return hasAccess;
  }

  hasAccess(path: string): boolean {

    if (this.authenticationStore.hasAnyAuthority(Authority.Administrator)) {

      return true;

    } else {

      return this.canAccessChild(path);
    }
  }

  protected abstract canAccessChild(path: string): boolean;
}
