    <div class="banner" style="display: flex; justify-content: center; align-items: center; padding: 5px">
      <a href="https://whittles.com.au/Whittles_COVID_response.pdf"
         style="color: white; text-decoration: underline"
         target="_blank">
      </a>
      {{message}}
<!--      <span style="margin-left: 5px;">or</span>-->
<!--      <button pButton type="button"-->
<!--              class="p-button-secondary"-->
<!--              style="color: #E53935; margin-left: 10px;"-->
<!--              (click)="onClick()"-->
<!--              label="Download PDF"-->
<!--      ></button>-->
    </div>
