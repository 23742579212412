import { Directive, HostListener } from '@angular/core';
import { TabView } from 'primeng/tabview';

import { DialogStore } from '../store/dialog.store';

// tslint:disable-next-line:directive-selector
@Directive({ selector: 'p-tabView' })
export class TabViewDirective {

  constructor(
    private readonly tabView: TabView,
    private readonly dialogStore: DialogStore) {
  }

  @HostListener('activeIndexChange', ['$event'])
  activeIndexChange(evt: any) {

    if (!this.dialogStore.visible) {

      return;
    }

    this.dialogStore.positionOverlay({ nextTick: true });
  }
}
