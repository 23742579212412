<div *mobxAutorun>
  <form class="p-grid ui-fluid form-group" [formGroup]="contactDetailsForm" (ngSubmit)="onSubmit()">
    <div class="p-col-12" style="padding-top: 0px">
      <app-info-message [message]="gatherContactDetailsMessage"></app-info-message>
    </div>
    <div class="p-col-12 p-md-8">
      <span class="p-float-label">
        <input pInputText class="p-w-100" [formControl]="email">
        <label class="p-ml-1 p-grid p-jc-start p-label">Enter your email</label>
        <ng-container *ngTemplateOutlet="tick; context: {
          showTick: isEmailTickVisible
        }"></ng-container>
        <app-form-error [form]="contactDetailsForm" [control]="[email, emails]"></app-form-error>
      </span>
    </div>
    <div *ngIf="!isRetypeEmailVisible" class="p-col-12 p-md-4">
      <ng-container *ngTemplateOutlet="updateEmailButtonTpl; context: {
        enabled: isUpdateEmailEnabled
      }"></ng-container>
    </div>
    <ng-container *ngIf="isRetypeEmailVisible">
      <div [@fadeInOut] class="p-col-12 p-md-8">
        <span class="p-float-label">
          <input pInputText class="p-w-100" [formControl]="repeatEmail" (paste)="onRepeatEmailPaste($event)">
          <label class="p-ml-1 p-grid p-jc-start p-label">Repeat your email</label>
          <ng-container *ngTemplateOutlet="tick; context: {
            showTick: isEmailTickVisible
          }"></ng-container>
          <app-form-error [form]="contactDetailsForm" [control]="[repeatEmail, emails]"></app-form-error>
        </span>
      </div>
      <div [@fadeInOut] class="p-col-12 p-md-4">
        <ng-container *ngTemplateOutlet="updateEmailButtonTpl; context: {
          enabled: isRepeatEmailEnabled
        }"></ng-container>
      </div>
    </ng-container>
    <div class="p-col-12 p-md-8">
      <span class="p-float-label">
        <input pInputText class="p-w-100" [formControl]="mobile">
        <label class="p-ml-1 p-grid p-jc-start p-label">Enter your mobile</label>
        <ng-container *ngTemplateOutlet="tick; context: {
          showTick: isMobileTickVisible
        }"></ng-container>
        <app-form-error [form]="contactDetailsForm" [control]="mobile"></app-form-error>
      </span>
    </div>
    <div class="p-col-12 p-md-4">
      <button pButton style="width: 100%" type="button" [label]="mobileButtonLabel" icon="ui-icon-phone-android"
        [disabled]="!isUpdateMobileEnabled" (click)="onUpdateMobile()"></button>
    </div>
    <div class="p-col-12">
      <hr>
    </div>
    <div class="p-col-12" style="text-align: center">
      <button pButton type="submit" icon="ui-icon-arrow-forward" [label]="store.submitButtonLabel"
        [disabled]="!submitButtonEnabled"></button>
    </div>
  </form>
</div>

<ng-template #tick let-showTick="showTick">
  <i *ngIf="showTick" class="fas fa-check wht-inputfield-icon-right tick"></i>
</ng-template>

<ng-template #updateEmailButtonTpl let-enabled="enabled">
  <button pButton style="width: 100%" type="button" icon="ui-icon-email" [label]="emailButtonLabel"
    [disabled]="!enabled" (click)="onUpdateEmail()"></button>
</ng-template>
