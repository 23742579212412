import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { action, observable } from 'mobx';

import { EMPTY, interval, Subject, Subscription } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { Environment } from '../environment';
import { HttpRequestParams } from '../models/http-request-params';
import { Logger } from '../services/logger.service';
import { BaseAuthenticationStore } from '../store/base-authentication.store';
import { mobxReaction } from '../utils/mobx.utils';

@Injectable({
  providedIn: 'root'
})
export class VersionDetectorStore {

  private static readonly VERSION_CHECK_PERIOD_SECONDS = 60;

  private readonly versionCheck$ = new Subject();

  private _versionCheckSubscription = Subscription.EMPTY;

  private _lastModifiedHeader = '';

  @observable
  private _updateAvailable = false;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly logger: Logger,
    private readonly authenticationStore: BaseAuthenticationStore,
    private readonly environment: Environment
  ) {

    this.initVersionCheck();

    mobxReaction('BaseAppComponent: authenticationStore.isAuthenticated',
      () => this.authenticationStore.isAuthenticated,
      isAuthenticated => isAuthenticated && this.versionCheck$.next());
  }

  private initVersionCheck() {

    if (!this.environment.production) {

      return;
    }

    this._versionCheckSubscription = this.versionCheck$.pipe(
      switchMap(() => this.httpClient.head('/', {
        params: {
          [HttpRequestParams.ShowActivityIndicator]: 'false'
        },
        observe: 'response'
      })),
      tap(response => this._lastModifiedHeader = response.headers.get('last-modified') || ''),
      switchMap(response => interval(VersionDetectorStore.VERSION_CHECK_PERIOD_SECONDS * 1000)),
      switchMap(() => this.httpClient.head('/', {
        params: {
          [HttpRequestParams.ShowActivityIndicator]: 'false',
        },
        headers: {
          'If-Modified-Since': this._lastModifiedHeader
        },
        observe: 'response'
      }).pipe(
        tap(response => {

          if (response.status === 200) {

            this.setUpdateAvailable();

            this.logger.info(`New UI version available - ${new Date().toLocaleString()}`);

            this._versionCheckSubscription.unsubscribe();
          }
        }),
        catchError(e => {

          // We expect 304 not modified if UI is up to date
          if (e.status !== 304) {

            this.logger.warn('Error caught checking for new UI version', e);
          }

          return EMPTY;
        }))
      ),
      catchError(e => {

        this.logger.warn('Error caught initialising VersionDetectorStore', e);

        return EMPTY;
      })
    ).subscribe();
  }

  @action
  private setUpdateAvailable() {

    this._updateAvailable = true;
  }

  get updateAvailable() {

    return this._updateAvailable;
  }
}
