import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { Table } from 'primeng/table';
import { TreeTable } from 'primeng/treetable';

import { BaseComponent } from '../base/base.component';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'tr[appTableEmptyMessage]',
  templateUrl: './table-empty-message.component.html',
  styleUrls: ['./table-empty-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableEmptyMessageComponent extends BaseComponent implements OnInit, OnDestroy {

  private observer?: MutationObserver;

  private tableHeaderRow?: Element;

  colspan = 0;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    @Optional() private readonly table?: Table,
    @Optional() private readonly treeTable?: TreeTable) {

    super();
  }

  private getNativeElement(): Element {

    if (this.table) {

      return this.table.el.nativeElement as Element;
    }

    if (this.treeTable) {

      return this.treeTable.el.nativeElement as Element;
    }

    throw new Error('TableEmptyMessageComponent.getNativeElement(): table and treeTable both null');
  }

  ngOnInit(): void {

    const tableHeaderRow = this.getNativeElement().querySelector('table > thead > tr');

    if (!tableHeaderRow) {

      return;
    }

    this.tableHeaderRow = tableHeaderRow;

    this.updateColspan();

    this.observer = new MutationObserver((mutations) => {

      mutations.forEach(mutation => {

        if (mutation.type === 'childList') {

          this.updateColspan();
        }
      });
    });

    this.observer.observe(this.tableHeaderRow, {
      childList: true
    });
  }

  ngOnDestroy(): void {

    if (this.observer) {

      this.observer.disconnect();
    }
  }

  private updateColspan() {

    if (!this.tableHeaderRow) {

      return;
    }

    this.colspan = this.tableHeaderRow.querySelectorAll('th').length;

    this.cdr.detectChanges();
  }
}
