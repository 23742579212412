import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Table } from 'primeng/table';
import { TreeTable } from 'primeng/treetable';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

import { TableFilterEvent, TableSelectionMode } from '../../models/table';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-header-input-filter',
  templateUrl: './header-input-filter.component.html',
  styleUrls: ['./header-input-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderInputFilterComponent extends BaseComponent implements OnInit {

  @Input() selectSingleFilteredTableRowOnEnter = false;
  @Input() filterMatchMode = 'contains';
  @Input() field: string;
  @Input() table?: Table;
  @Input() treeTable?: TreeTable;
  @Input() tableFilterEvent?: TableFilterEvent;
  @Input() customFilter = false;
  @Output() readonly customFilterValueChange = new EventEmitter();
  filterString$: Subject<string> = new Subject<string>();

  constructor(
    private readonly cdr: ChangeDetectorRef) {

    super();
  }

  refresh() {

    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.subscribe(this.filterString$
      .pipe(
        debounceTime(500),
        tap((str) => this.filter(str))
      ))
  }

  onClick(e: Event) {

    e.stopPropagation();
  }

  onInput(e: any) {

    this.filterString$.next(e.target.value);
    this.cdr.detectChanges();

  }

  filter(filterString: string) {

    if (this.customFilter) {

      this.customFilterValueChange.emit(filterString);

    } else if (this.table) {

      this.table.filter(filterString, this.field, this.filterMatchMode);

    } else if (this.treeTable) {

      this.treeTable.filter(filterString, this.field, this.filterMatchMode);
    }
    this.cdr.detectChanges();

  }

  get model(): string {

    if (this.table) {
      
      const filterMetadata = this.table.filters[this.field];
      if (!Array.isArray(filterMetadata)) {
        return filterMetadata && filterMetadata.value || '';
      } else {
        const filterMetadatas = filterMetadata.filter(f => !!f.value).map(f => f.value);
        return filterMetadatas.length == 0 ? '' : filterMetadatas.join(', ');
      }
    }

    if (this.treeTable) {

      const filterMetadata = this.treeTable.filters[this.field];

      return filterMetadata && filterMetadata.value || '';
    }

    return '';
  }
}
