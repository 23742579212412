<div *mobxAutorun class="layout-wrapper" [ngClass]="{'layout-compact':layoutCompact}" (click)="onLayoutClick()">

  <div #layoutContainer class="layout-container" [ngClass]="{
            'menu-layout-static': !isOverlay(),
            'menu-layout-overlay': isOverlay(),
            'layout-menu-overlay-active': overlayMenuActive,
            'menu-layout-horizontal': isHorizontal(),
            'menu-layout-slim': isSlim(),
            'layout-menu-static-inactive': staticMenuDesktopInactive,
            'layout-menu-static-active': staticMenuMobileActive}">

    <div [appHide]="!authenticationStore.isAuthenticated">
      <app-topbar></app-topbar>
    </div>

    <div [appHide]="!authenticationStore.isAuthenticated">
      <div class="layout-menu" [ngClass]="{'layout-menu-dark':darkMenu}" (click)="onMenuClick($event)">
        <p-scrollPanel #scrollPanel [style]="{height: '100%'}">
          <app-menu [reset]="resetMenu"></app-menu>
        </p-scrollPanel>
      </div>
    </div>

    <div class="layout-main">

      <div class="layout-content">

        <div [appHide]="!authenticationStore.isAuthenticated">
          <p-panel [appHide]="appStore.isMqAliasGtSm" [toggleable]="false" [collapsed]="true" [style]="{margin: '7px'}">
            <p-header>
              <div fxLayoutAlign="center center">
                <div>{{localisationStore.unitLabel}} {{domainStore.selectedUnitBuildingTitle}}</div>
                <a href="#" (click)="onSelectUnit($event)">
                  <i fxLayoutAlign="center center" class="material-icons selected-unit-address-icon">home</i>
                </a>
              </div>
            </p-header>
          </p-panel>
        </div>

        <div [appHide]="appStore.logoutInvoked">
          <router-outlet (activate)="onActivate($event)" (deactivate)="onDeactivate($event)"></router-outlet>
        </div>

        <p-dialog #dialog [visible]="dialogStore.visible" (visibleChange)="dialogStore.setVisible($event)"
          [header]="dialogStore.header" [showHeader]="dialogStore.showHeader" [responsive]="dialogStore.responsive"
          [resizable]="dialogStore.resizable" [draggable]="dialogStore.draggable" [modal]="dialogStore.modal"
          [dismissableMask]="dialogStore.dismissableMask" [breakpoint]="dialogStore.breakpoint"
          [style]="dialogStore.style" [contentStyle]="dialogStore.contentStyle" [styleClass]="dialogStore.styleClass"
          [closable]="dialogStore.closable" [closeOnEscape]="dialogStore.closeOnEscape" [blockScroll]="true"
          (onHide)="dialogStore.onHide()" (onShow)="dialogStore.onShow(dialog)">
          <ng-container *ngComponentOutlet="dialogStore.component"></ng-container>
          <ng-container *ngTemplateOutlet="dialogStore.template"></ng-container>
        </p-dialog>

        <p-dialog appDialog [(visible)]="loginMessageVisible" [baseZIndex]="2000" [style]="{height: '90%'}"
          [dismissableMask]="true" [showHeader]="true">
          <app-login-message (dismissClick)="loginMessageVisible = false"></app-login-message>
        </p-dialog>

        <p-confirmDialog key="app" [closable]="confirmationStore.closable"
          [closeOnEscape]="confirmationStore.closeOnEscape" [acceptLabel]="confirmationStore.acceptLabel"
          [acceptIcon]="confirmationStore.acceptIcon" [rejectLabel]="confirmationStore.rejectLabel"
          [rejectIcon]="confirmationStore.rejectIcon" [style]="confirmationStore.style" [blockScroll]="true">
        </p-confirmDialog>
      </div>
    </div>

    <div class="layout-mask"></div>
  </div>

  <app-spinner appGlobalSpinner [show]="appStore.activityIndicatorVisible"></app-spinner>

  <p-toast appGlobalToast></p-toast>
</div>

<ng-template #contactDetailsTpl>
  <app-contact-details [email]="ownerEmail" [mobile]="ownerMobile" [emailUpdated]="ownerEmailUpdated"
    [mobileUpdated]="ownerMobileUpdated" (updateMobileClick)="onContactDetailsUpdateMobileClick($event)"
    (updateEmailClick)="onContactDetailsUpdateEmailClick($event)" (proceedClick)="dialogStore.hide()">
  </app-contact-details>
</ng-template>

<ng-template #selectOwnerTpl>
  <app-owner-selector [existingEmail]="ownerEmail" [newEmail]="ownerSelectorNewEmail" [existingMobile]="ownerMobile"
    [newMobile]="ownerSelectorNewMobile" [owners]="ownerSelectorOwners" (selectedOwners)="onSelectedOwners($event)">
  </app-owner-selector>
</ng-template>

<ng-template #termsAndConditionsTpl>
  <app-terms-and-conditions></app-terms-and-conditions>
</ng-template>
