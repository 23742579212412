<div *ngIf="showHelpTable" class="p-grid ui-fluid">
  <div class="p-col-12">
    <app-info-message message="Select a row to show the associated help video."></app-info-message>
  </div>
  <div class="p-col-12">
    <app-table selectionMode="single" [value]="videos" (rowSelect)="onRowSelected($event.data, videoTpl)"
      (rowUnselect)="onRowUnselected($event.data, videoTpl)" [showCaption]="false">
      <ng-template pTemplate="header">
        <tr appTableRow>
          <th [appTableHeader]="helpVideoPropertyTitle" title="title" [filter]="TableHeaderFilterType.Input"
            pSortableColumn></th>
          <th [appTableHeader]="helpVideoPropertyTags" title="tags" [filter]="TableHeaderFilterType.Input"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-helpVideo>
        <tr appTableRow [pSelectableRow]="helpVideo">
          <td appTableData>{{getTitle(helpVideo)}}</td>
          <td appTableData>{{getTags(helpVideo)}}</td>
        </tr>
      </ng-template>
    </app-table>
  </div>
</div>

<ng-template #videoTpl>
  <div class="iframe-container">
    <iframe [src]="videoUrl | safe:'resourceUrl'" frameborder="0" webkitallowfullscreen mozallowfullscreen style="height: 70vh;"
      allowfullscreen></iframe>
    <span class="close-icon-container">
      <app-icon-button [kind]="IconKind.Close" (iconClick)="onCloseIconClick()" iconClass="wht-icon-colour"
        [iconStyle]="{fontSize: '24px', width: '24px', backgroundColor: 'white'}"
        [fontAwesomeStyle]="FontAwesomeStyle.Light">
      </app-icon-button>
    </span>
  </div>
</ng-template>
