import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import {
  BaseComponent,
  BrowserUtils,
  ButtonKind,
  DialogStore,
  DialogWidth,
  formatPhoneNumberForDisplay,
  MessageSeverity,
  property,
  User
} from 'common-lib';

import { Store } from './owner-selector.store';

@Component({
  selector: 'app-owner-selector',
  templateUrl: './owner-selector.component.html',
  styleUrls: ['./owner-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Store]
})
export class OwnerSelectorComponent extends BaseComponent implements OnInit {

  readonly userIdProp = property<User>('id');
  readonly userfullNameProp = property<User>('fullName');
  readonly userFirstNameProp = property<User>('firstName');
  readonly userEmailProp = property<User>('email');
  readonly userMobileProp = property<User>('mobile');

  readonly ButtonKind = ButtonKind;
  readonly MessageSeverity = MessageSeverity;

  @Input()
  message = '';

  @Input()
  existingEmail = '';

  @Input()
  newEmail = '';

  @Input()
  existingMobile = '';

  @Input()
  newMobile = '';

  @Input()
  owners: User[] = [];

  @Input()
  showDialogFooter = true;

  @Output()
  readonly selectedOwners = new EventEmitter<User[]>();

  static showInDialog(dialogStore: DialogStore, templateRef: TemplateRef<any>) {

    dialogStore.showTemplate(templateRef, {
      header: 'select owners',
      showHeader: true,
      closable: false,
      width: BrowserUtils.Tablet || BrowserUtils.Mobile ? '100%' : DialogWidth.Screen50
    });
  }

  constructor(
    readonly store: Store,
    private readonly dialogStore: DialogStore) {

    super();
  }

  ngOnInit(): void {

    // Pre-select all owners
    this.store.setOwnersSelected(this.owners);

    this.mobxWhen('OwnerSelectorComponent: Reposition dialog on first owner unselection',
      () => this.store.ownersSelected.length !== this.owners.length,
      () => this.dialogStore.positionOverlay({ nextTick: true }));
  }

  onCancel() {

    // Emit empty array on cancel
    this.selectedOwners.emit([]);
  }

  onOk() {

    this.selectedOwners.emit(this.store.ownersSelected);
  }

  displayName(user: User) {

    return `${user.firstName} ${user.lastName}`;
  }

  displayMoblile(user: User) {

    return formatPhoneNumberForDisplay(user.mobile);
  }

  isNewEmailOrMobileVisible(user: User): boolean {

    return !!this.store.ownersSelected.find(u => u.id === user.id);
  }

  get emailOrMobile(): string {

    return this.newEmail ? 'email' : 'mobile';
  }

  get infoMessage(): string {

    if (this.newEmail || this.newMobile) {

      const existingEmailOrMobile = this.newEmail ? this.existingEmail : formatPhoneNumberForDisplay(this.existingMobile);

      return `Multiple owner records have the same ${this.emailOrMobile} <b>${existingEmailOrMobile}</b>.<br><br>
        Please select the owner records you wish to update with the new ${this.emailOrMobile} <b>${this.newEmail || this.newMobile}</b>.`;
    }

    return this.message;
  }

  get isUnlinkedOwnersMessageVisible(): boolean {

    return this.owners.length !== this.store.ownersSelected.length;
  }

  get unlinkedOwnersMessage(): string {

    return 'Only the selected owner records will be updated. ' +
      `You will need to use the old ${this.emailOrMobile} to login to and access the unselected owner information.`;
  }
}
