import {
  addDays as addDaysFns,
  addHours as addHoursFns,
  addYears as addYearsFns,
  isFuture as isFutureFns,
  isPast as isPastFns,
  parseISO
} from 'date-fns';
import { SelectItem } from 'primeng/api';

import { DateTimeFormat } from '../models/date-time-format';
import { DateFormatPipe } from '../pipes/date-format.pipe';

const dateFormatPipe = new DateFormatPipe('en-AU');

export const months: SelectItem[] = [
  { label: '01', value: '01' },
  { label: '02', value: '02' },
  { label: '03', value: '03' },
  { label: '04', value: '04' },
  { label: '05', value: '05' },
  { label: '06', value: '06' },
  { label: '07', value: '07' },
  { label: '08', value: '08' },
  { label: '09', value: '09' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' }
];

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export function years(plus = 10): SelectItem[] {

  const yearsItems: SelectItem[] = [];

  for (let year = 0; year <= plus; year++) {

    const formattedYearLabel = formatDate(addYears(new Date(), year), {
      format: 'yyyy'
    });

    const formattedYearValue = formatDate(addYears(new Date(), year), {
      format: 'yy'
    });

    yearsItems.push({
      label: formattedYearLabel,
      value: formattedYearValue
    });
  }

  return yearsItems;
}

export function formatDate(date: Date | string | number, params?: {
  format?: DateTimeFormat | string;
}): string {

  // https://angular.io/api/common/DatePipe
  return dateFormatPipe.transform(date, params && params.format) || '';
}

export function addDays(date: Date | number | string, amount: number): Date {

  return addDaysFns(typeof date === 'string' ? parseISO(date) : date, amount);
}

export function addHours(date: Date | number | string, amount: number): Date {

  return addHoursFns(typeof date === 'string' ? parseISO(date) : date, amount);
}

export function addYears(date: Date | number | string, amount: number): Date {

  return addYearsFns(typeof date === 'string' ? parseISO(date) : date, amount);
}

export function isFuture(date: Date | number | string): boolean {

  return isFutureFns(typeof date === 'string' ? parseISO(date) : date);
}

export function isPast(date: Date | number | string): boolean {

  return isPastFns(typeof date === 'string' ? parseISO(date) : date);
}

export function compareDateStrings(direction: boolean, a: string | null, b: string | null): number {
  if (a == null && b == null) {
    return 0; // Both are null, consider them equal
  }
  if (a == null) {
    return 1; // Move null to the end
  }
  if (b == null) {
    return -1; // Move null to the end
  }

  const aStr = a.split('/');
  const bStr = b.split('/');
  const aDate = new Date(`20${aStr[2]}/${aStr[1]}/${aStr[0]}`);
  const bDate = new Date(`20${bStr[2]}/${bStr[1]}/${bStr[0]}`);

  return direction ? aDate.getTime() - bDate.getTime() : bDate.getTime() - aDate.getTime();
}