import { Injectable } from '@angular/core';
import { action, computed, observable } from 'mobx';

@Injectable()
export class Store {

  @observable
  private _emailDirtied = false;

  @observable
  private _emailUpdated = false;

  @observable
  private _mobileUpdated = false;

  @action
  setEmailUpdated(val: boolean) {

    this._emailUpdated = val;
  }

  @action
  setMobileUpdated(val: boolean) {

    this._mobileUpdated = val;
  }

  @action
  setEmailDirtied(val: boolean) {

    this._emailDirtied = val;
  }

  @computed
  get submitButtonLabel(): string {

    return `${this.emailUpdated || this.mobileUpdated ? 'proceed' : 'skip'} to owner portal`;
  }

  get emailDirtied() {

    return this._emailDirtied;
  }

  get emailUpdated() {

    return this._emailUpdated;
  }

  get mobileUpdated() {

    return this._mobileUpdated;
  }
}
