import { Injectable } from '@angular/core';
import { action, computed, observable } from 'mobx';
import { tap } from 'rxjs/operators';

import { Owner, Unit, UnitSummary } from '../../generated';
import { StrataService } from '../../services/strata.service';

@Injectable()
export class Store {

  @observable
  private _owner: Owner;

  @observable
  private _selectedUnit?: Unit;

  @observable
  private _unitSummary?: UnitSummary;

  constructor(private readonly strataService: StrataService) {
  }

  getUnitSummary() {

    return this.strataService.getUnitSummary({
      // tslint:disable-next-line: no-non-null-assertion
      unitId: this._selectedUnit!.id
    }).pipe(
      tap(unitSummary => this.setUnitSummary(unitSummary))
    );
  }

  @action
  private setUnitSummary(unitSummary: UnitSummary) {

    this._unitSummary = unitSummary;
  }

  @action
  setOwner(owner: Owner) {

    this._owner = owner;
  }

  @action
  setSelectedUnit(arg: Unit | undefined) {

    this._selectedUnit = arg;
  }

  get selectedUnit() {

    return this._selectedUnit;
  }

  get unitSummary() {

    return this._unitSummary;
  }

  get owner() {

    return this._owner;
  }

  @computed
  get units(): Unit[] {

    return this._owner.units;
  }
}
