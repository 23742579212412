import { Component, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent, BrowserUtils, DialogStore, LocalisationStore, LoginType } from 'common-lib';
import { computed } from 'mobx';

import { AppComponent } from './app.component';
import { BuildingSelectorComponent } from './components/building-selector/building-selector.component';
import { UnitSelectorComponent } from './components/unit-selector/unit-selector.component';
import { MenuOption } from './models/menu-option';
import { RoutePath } from './route-path';
import { AppStore } from './stores/app.store';
import { AuthenticationStore } from './stores/authentication.store';
import { DomainStore } from './stores/domain.store';

@Injectable()
export class Store {

  constructor(private readonly authenticationStore: AuthenticationStore) {
  }

  @computed
  get selectUnitBuildingIcon(): string {

    switch (this.authenticationStore.loginType) {

      case LoginType.BUILDING:

        return 'fa-building';

      default:

        return 'fa-home';
    }
  }
}

// tslint:disable-next-line: prefer-on-push-component-change-detection
@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  styleUrls: ['./app.topbar.component.scss'],
  providers: [Store]
})
export class AppTopBarComponent extends BaseComponent {

  readonly MenuOption = MenuOption;

  constructor(
    readonly store: Store,
    readonly app: AppComponent,
    readonly domainStore: DomainStore,
    readonly appStore: AppStore,
    readonly localisationStore: LocalisationStore,
    private readonly authenticationStore: AuthenticationStore,
    private readonly dialogStore: DialogStore,
    private readonly router: Router) {

    super();
  }

  onMenuOption(evt: Event, menuOption: MenuOption) {

    // This will close the menu
    // this.app.onLayoutClick();

    evt.preventDefault();

    switch (menuOption) {

      case MenuOption.SelectUnitBuilding:

        switch (this.authenticationStore.loginType) {

          case LoginType.BUILDING:

            if (BrowserUtils.Tablet || BrowserUtils.Mobile) {

              this.router.navigate(['/', RoutePath.SelectBuilding]);

            } else {

              BuildingSelectorComponent.showInDialog(this.dialogStore);
            }

            break;

          default:

            if (BrowserUtils.Tablet || BrowserUtils.Mobile) {

              this.router.navigate(['/', RoutePath.SelectUnit]);

            } else {

              UnitSelectorComponent.showInDialog(this.dialogStore);
            }

            break;
        }

        break;
    }
  }
}
