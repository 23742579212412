import Bowser from 'bowser';

const parsedResult = Bowser.parse(window.navigator.userAgent);

export const BrowserUtils = {

  get Safari(): boolean {

    return parsedResult.browser.name === 'Safari';
  },

  get Chrome(): boolean {

    return parsedResult.browser.name === 'Chrome';
  },

  get Chromium(): boolean {

    return parsedResult.browser.name === 'Chromium';
  },

  get ChromiumEdge(): boolean {

    return parsedResult.browser.name === 'Microsoft Edge' && parsedResult.engine.name === 'Blink';
  },

  get Firefox(): boolean {

    return parsedResult.browser.name === 'Firefox';
  },

  get MacOS(): boolean {

    return parsedResult.os.name === 'macOS';
  },

  get Windows(): boolean {

    return parsedResult.os.name === 'Windows';
  },

  get Linux(): boolean {

    return parsedResult.os.name === 'Linux';
  },

  get Tablet(): boolean {

    return parsedResult.platform.type === 'tablet';
  },

  get Mobile(): boolean {

    return parsedResult.platform.type === 'mobile';
  },

  get Desktop(): boolean {

    return parsedResult.platform.type === 'desktop';
  }
};

export const canRenderPdfInline =
  (BrowserUtils.MacOS || BrowserUtils.Windows || BrowserUtils.Linux) &&
  (BrowserUtils.Chrome || BrowserUtils.Chromium || BrowserUtils.Firefox || BrowserUtils.Safari || BrowserUtils.ChromiumEdge);
