import { Directive, HostBinding, HostListener, Input } from '@angular/core';

@Directive({ selector: '[appAnchor]' })
export class AnchorDirective {

  @HostBinding('class.wht-a-link') readonly whtAlink = true;

  @HostBinding('attr.href') @Input() href = '#';

  @HostListener('click', ['$event'])
  onClick(evt: Event) {

    evt.preventDefault();
  }
}
