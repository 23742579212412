/**
 * Whittles API
 * Whittles API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Currency } from './currency';


export interface AccountBalance { 
    balance: Currency;
    outstandingBalance?: Currency;
    name: string;
    accountType: AccountBalance.AccountTypeEnum;
    maturity?: string;
}
export namespace AccountBalance {
    export type AccountTypeEnum = 'Fund' | 'Term';
    export const AccountTypeEnum = {
        Fund: 'Fund' as AccountTypeEnum,
        Term: 'Term' as AccountTypeEnum
    };
}
