import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { NG_VALIDATORS } from '@angular/forms';

import { KeyFilterDirective } from './key-filter.directive';

const minus = '-';
const decimal = '.';
const currencyMask = /^(?=.*[0-9])\d*(?:\.\d{1,2})?$/;

@Directive({
  selector: '[appCurrencyFilter]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: CurrencyFilterDirective,
      multi: true
    }]
})
export class CurrencyFilterDirective extends KeyFilterDirective implements OnInit {

  @Input() allowNegative = false;

  constructor(readonly el: ElementRef) {

    super(el);
  }

  ngOnInit(): void {

    this.validationFunction = this.currencyValidator;
  }

  private currencyValidator(value: string): boolean {

    if (!value) {

      return true;
    }

    let valueToTest = value;

    if (this.allowNegative && value.startsWith(minus)) {

      if (value.length === 1) {

        return true;
      }

      valueToTest = valueToTest.substring(1);
    }

    if (currencyMask.test(valueToTest)) {

      return true;
    }

    if (valueToTest.endsWith(decimal)) {

      const decimals = valueToTest.split(decimal);
      const decimalsCount = decimals.length - 1;

      if (decimalsCount === 1 && Number.isInteger(Number(decimals[0]))) {

        return true;
      }
    }

    return false;
  }
}
