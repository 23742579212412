import { Injectable } from '@angular/core';

import { BaseHelpStore, HelpVideo, HelpTag } from 'common-lib';

import { AppProperties } from '../app-properties';

@Injectable({
  providedIn: 'root'
})
export class HelpStore extends BaseHelpStore {

  constructor(appProperties: AppProperties) {

    super(appProperties);
  }

  protected getVideos(): HelpVideo[] {

    return [
      {
        title: 'Approve Invoice',
        tags: [HelpTag.Invoice],
        url: 'https://player.vimeo.com/video/310486010'
      },
      {
        title: 'Owner Profile',
        tags: [HelpTag.OwnerProfile],
        url: 'https://player.vimeo.com/video/310043981'
      },
      {
        title: 'Owner Payments',
        tags: [HelpTag.OwnerPayments],
        url: 'https://player.vimeo.com/video/310209080'
      },
      {
        title: 'Owner Invoices',
        tags: [HelpTag.OwnerInvoices],
        url: 'https://player.vimeo.com/video/310047030'
      },
      {
        title: 'Plan Documents',
        tags: [HelpTag.OwnerPortalDocuments],
        url: 'https://player.vimeo.com/video/310216141'
      },
      {
        title: 'Plan Financials',
        tags: [HelpTag.OwnerFinancials],
        url: 'https://player.vimeo.com/video/310233247'
      },
      {
        title: 'Owner Home',
        tags: [HelpTag.OwnerHome],
        url: 'https://player.vimeo.com/video/310207526'
      }
    ];
  }
}
