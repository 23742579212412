<button *mobxAutorun pButton type="button" [ngClass]="{'amber-btn': store.processNoteTargets.size > 0}" [icon]="store.icon"
  [titleCase]="false" [class.wht-display-none]="!store.isVisible" [disabled]="!store.isVisible" [label]="store.message"
  (click)="onClick()"></button>

<p-dialog #processNotesDialog appDialog header="Workflow Notes" [showHeader]="true"
  [visible]="store.processNotesDialogVisible" (visibleChange)="store.setProcessNotesDialogVisible($event)">

  <app-process-notes [workflowProcessId]="store.workflowProcessId" (processNotesFetched)="onProcessNotesFetched()"
    [editable]="true">
  </app-process-notes>
</p-dialog>
