import { Params } from '@angular/router';
import { SelectItem } from 'primeng/api';

import {
  FinancialYear,
  JournalAdviceStatus,
  NameValuePair,
  NoteType,
  TaskCategory,
  TaskState,
  TaskType,
  User
} from '../generated';
import { FinancialStanding } from '../models/financial-standing';

import { findDuplicates } from './array.utils';
import { property } from './object.utils';
import { toSentenceCase, toTitleCase } from './string.utils';

export function mapAccountCodesToSelectItems(accountCodes: NameValuePair[]): SelectItem[] {

  return accountCodes.map(account => ({
    label: `${account.value} - ${account.name}`,
    value: account
  }));
}

export function mapFundCodesToSelectItems(fundCodes: string[]): SelectItem[] {

  return fundCodes.map(code => ({
    label: code,
    value: code
  }));
}

export function mapFinancialYears(financialYears: FinancialYear[]): FinancialYear[] {

  return financialYears.map((financialYear, index) => {

    const mappedFinancialYear = {
      ...financialYear
    };

    if (!mappedFinancialYear.displayTitle) {

      mappedFinancialYear.displayTitle = index === 0 ? 'Current' : `Year Ending - ${financialYear.endMonth}/${financialYear.endYear}`;
    }

    return mappedFinancialYear;
  });
}

export function mapToSelectItems<T>(arr: T[], map: Map<T, string>): SelectItem[] {

  const selectItems: SelectItem[] = arr.map(s => {

    return {
      label: map.get(s) || s as unknown as string,
      value: s
    };
  });

  // tslint:disable-next-line: no-non-null-assertion
  selectItems.sort((a, b) => a.label!.localeCompare(b.label!));

  return selectItems;
}

function insertNullEntry(selectItems: SelectItem[], nullLabel?: string): SelectItem[] {

  return [
    {
      label: nullLabel || 'All',
      value: undefined
    },
    ...selectItems
  ];
}

export function mapNameValuePairsToQueryParams(nameValuePairs: NameValuePair[]): Params {

  let params: Params = {};

  nameValuePairs.forEach(nvp => {

    params = {
      ...params,
      [nvp.name]: nvp.value
    };
  });

  return params;
}

export const taskCategoryMap = new Map<TaskCategory, string>([
  [TaskCategory.AGMPROCESS, 'AGM Process'],
  [TaskCategory.APPROVEINVOICE, 'Approve Invoice'],
  [TaskCategory.JOURNALPROCESS, 'Journal Process']
]);

export const taskCategories = mapToSelectItems(Object.values(TaskCategory), taskCategoryMap);
export const taskCategoriesWithNull = insertNullEntry(taskCategories);

export const taskTypeMap = new Map<TaskType, string>([
  [TaskType.AGMACCOUNTSPROCESSMEETINGSUMMARYSHEET, 'Accounts Process Meeting Summary Sheet'],
  [TaskType.ACCOUNTSPROCESSESREJECTEDINVOICE, 'Accounts Process Rejected Invoice'],
  [TaskType.AGMACCOUNTSAUDITFINANCIALRECORDS, 'Accounts Audit Financial Records'],
  [TaskType.AGMACCOUNTSFINALIZEIE, 'Accounts Finalize I&E'],
  [TaskType.AGMACCOUNTSPROCESSJOURNALS, 'Accounts Process Journals'],
  [TaskType.AGMACCOUNTSRECONCILEEOY, 'Accounts Reconcile EOY'],
  [TaskType.AGMBCMADOPTSINTERIMRESOLUTIONS, 'BCM Adopts Interim Resolutions'],
  [TaskType.AGMBCMCOLLATESMINUTES, 'BCM Collates Minutes'],
  [TaskType.AGMBCMCOLLATESNOTICE, 'BCM Collates Notice'],
  [TaskType.AGMBCMCONDUCTSADHOCSGMPROCESS, 'BCM Conducts Adhoc SGM Process'],
  [TaskType.AGMBCMCONDUCTSBUDGETAGENDAREVIEW, 'BCM Conducts Budget Agenda Review'],
  [TaskType.AGMBCMCONDUCTSMEETING, 'BCM Conducts Meeting'],
  [TaskType.AGMBCMPREPARESBUDGET, 'BCM Prepares Budget'],
  [TaskType.AGMBCMPROCESSESPROXYFORMS, 'BCM Processes Proxy Forms'],
  [TaskType.AGMBCMRECORDSQUORUM, 'BCM Records Quorum'],
  [TaskType.AGMBCMREVIEWSIE, 'BCM Reviews I&E'],
  [TaskType.AGMBCMSENDSMINUTES, 'BCM Sends Minutes'],
  [TaskType.AGMBCMSENDSNOTICE, 'BCM Sends Notice'],
  [TaskType.AGMBCMRECONVENESMEETING, 'BCM Reconvenes Meeting'],
  [TaskType.AGMCOMMITTEEREVIEWSDRAFTMINUTES, 'Committee Reviews Draft Minutes'],
  [TaskType.AGMBCMCOMPLETESMEETINGSUMMARYSHEET, 'BCM Completes Meeting Summary Sheet'],
  [TaskType.AGMBCMCREATESWORKORDERS, 'BCM Creates Work Orders'],
  [TaskType.AGMBCMUPDATESADOPTSBUDGET, 'BCM Updates & Adopt Budget'],
  [TaskType.AGMBCMUPDATESCOMMITTEE, 'BCM Updates Committee'],
  [TaskType.AGMBCMUPDATESPLANDETAILS, 'BCM Updates Plan Details'],
  [TaskType.AGMBCMUPLOADSFILES, 'BCM Uploads Files'],
  [TaskType.AGMBCMUPLOADSPROXYVOTINGPAPERS, 'BCM Uploads Proxy & Voting Papers'],
  [TaskType.AGMINTERIMRESOLUTIONSPERIOD, 'Interim Resolutions Period'],
  [TaskType.COMMITTEEMEMBERAPPROVESINVOICE, 'Committee Member Approves Invoice'],
  [TaskType.JOURNALACCOUNTSPROCESSJOURNALS, 'Accounts Process Adhoc Journals'],
  [TaskType.MANAGERAPPROVESINVOICE, 'Manager Approves Invoice']
]);

export const taskTypes = mapToSelectItems(Object.values(TaskType), taskTypeMap);
export const taskTypesWithNull = insertNullEntry(taskTypes);

export const taskStateMap = new Map<TaskState, string>([
  [TaskState.DONE, 'Done'],
  [TaskState.TODO, 'To Do']
]);

export const taskStates = mapToSelectItems(Object.values(TaskState), taskStateMap);
export const taskStatesWithNull = insertNullEntry(taskStates);

export const planAccessGroups: SelectItem[] = [
  {
    label: 'Auditor',
    value: 'AUDITOR'
  },
  {
    label: 'Building Manager',
    value: 'BUILDING_MANAGER'
  },
  {
    label: 'Search Agent',
    value: 'REAL_ESTATE_AGENT'
  },
  {
    label: 'External Committee Member',
    value: 'EXTERNAL_COMMITTEE_MEMBER'
  },
  {
    label: 'Debt Collection Agencies',                                          // IM-168
    value: 'DEBT_COLLECTION_AGENCIES'
  }
];

export const journalAdviceStatuses = Object.keys(JournalAdviceStatus).map(status => ({
  label: status,
  value: status
}));
export const journalAdviceStatusesWithNull = insertNullEntry(journalAdviceStatuses);

export const planEventTypeDisplayMap = new Map<string, { title: string; icon: string; cssClass: string; }>([
  ['DOC_CREATED', {
    title: 'Document',
    icon: 'fa-file',
    cssClass: 'wht-pink'
  }],
  ['WORKORDER_CREATED', {
    title: 'Work Order',
    icon: 'fa-digging',
    cssClass: 'wht-purple'
  }],
  ['QUOTE_CREATED', {
    title: 'Quote',
    icon: 'fa-hands-helping',
    cssClass: 'wht-darkgreen'
  }],
  ['INVOICE_CREATED', {
    title: 'Invoice',
    icon: 'fa-file-invoice-dollar',
    cssClass: 'wht-amber'
  }]
]);

function appendUserLoginNameToFullName(user: User): User {

  return {
    ...user,
    fullName: `${user.fullName} (${user.loginName})`
  };
}

export function appendNonUniqueUsersLoginNameToFullName(users: User[]): User[] {

  const duplicateUsersByFullName = findDuplicates(users, property<User>('fullName'));

  return users.map(u => duplicateUsersByFullName.includes(u) ? appendUserLoginNameToFullName(u) : u);
}

export const noteTypes: SelectItem[] = Object.values(NoteType).map(n => ({ label: toTitleCase(n), value: n }));

export const financialStandings: SelectItem[] = Object.entries(FinancialStanding).map(e => ({ label: toSentenceCase(e[0]), value: e[1] }));
