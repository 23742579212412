/**
 * Whittles API
 * Whittles API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface AccountsPayableInvoiceAlert { 
    description: string;
    type: AccountsPayableInvoiceAlert.TypeEnum;
}
export namespace AccountsPayableInvoiceAlert {
    export type TypeEnum = 'SECOND_AUTHORITY_APPROVED' | 'LINKED_WORK_ORDER_OPEN';
    export const TypeEnum = {
        SECONDAUTHORITYAPPROVED: 'SECOND_AUTHORITY_APPROVED' as TypeEnum,
        LINKEDWORKORDEROPEN: 'LINKED_WORK_ORDER_OPEN' as TypeEnum
    };
}
