import { Injectable } from '@angular/core';
import { User } from 'common-lib';
import { action, computed, observable } from 'mobx';

@Injectable()
export class Store {

  @observable
  private _ownersSelected: User[] = [];

  @action
  setOwnersSelected(ownersSelected: User[]) {

    this._ownersSelected = ownersSelected;
  }

  @computed
  get isOKEnabled(): boolean {

    return this.ownersSelected.length > 0;
  }

  get ownersSelected() {

    return this._ownersSelected;
  }
}
