<ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
  <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass"
    *ngIf="child.visible === false ? false : true">
    <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" class="ripplelink"
      *ngIf="!child.routerLink" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
      <i *ngIf="child.icon" class="material-icons">{{child.icon}}</i>
      <i *ngIf="child.fawIcon" class="{{child.fawStyle || 'fas'}} {{child.fawIcon}} fa-fw"></i>
      <span>{{child.label | titleCase}}</span>
      <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
      <i class="material-icons submenu-icon" *ngIf="child.items">keyboard_arrow_down</i>
    </a>

    <a (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" class="ripplelink" *ngIf="child.routerLink"
      [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink"
      [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
      <i *ngIf="child.icon" class="material-icons">{{child.icon}}</i>
      <i *ngIf="child.fawIcon" class="{{child.fawStyle || 'fas'}} {{child.fawIcon}} fa-fw"></i>
      <span>{{child.label | titleCase}}</span>
      <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
      <i class="material-icons submenu-icon" *ngIf="child.items">keyboard_arrow_down</i>
    </a>
    <div class="layout-menu-tooltip">
      <div class="layout-menu-tooltip-arrow"></div>
      <div class="layout-menu-tooltip-text">{{child.label | titleCase}}</div>
    </div>
    <ul app-submenu [item]="child" *ngIf="child.items" [visible]="isActive(i)" [reset]="reset"
      [parentActive]="isActive(i)" [@children]="(app.isSlim()||app.isHorizontal())&&root ? isActive(i) ?
          'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
  </li>
</ng-template>
