export enum MqAlias {
  Xs = 'xs',
  GtXs = 'gt-xs',
  LtSm = 'lt-sm',
  Sm = 'sm',
  GtSm = 'gt-sm',
  LtMd = 'lt-md',
  Md = 'md',
  GtMd = 'gt-md',
  LtLg = 'lt-lg',
  Lg = 'lg',
  GtLg = 'gt-lg',
  LtXl = 'lt-xl',
  Xl = 'xl'
}
