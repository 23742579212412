import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseAppProperties } from '../base-app-properties';
import { Configuration, UnitService as UnitServiceGenerated } from '../generated';

@Injectable({
  providedIn: 'root'
})
export class UnitService {

  private readonly unitServiceGenerated: UnitServiceGenerated;

  constructor(httpClient: HttpClient, appProperties: BaseAppProperties) {

    this.unitServiceGenerated = new UnitServiceGenerated(httpClient, appProperties.irisServerBasePath, new Configuration());
  }

  getUnit(args: {
    id: string;
  }) {

    return this.unitServiceGenerated.getUnit(args.id);
  }
}
