/**
 * Whittles API
 * Whittles API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface AccountsPayableInvoiceActionResponse { 
    status: string;
    error?: AccountsPayableInvoiceActionResponse.ErrorEnum;
}
export namespace AccountsPayableInvoiceActionResponse {
    export type ErrorEnum = 'gstChanged' | 'processRejectedNotFound';
    export const ErrorEnum = {
        GstChanged: 'gstChanged' as ErrorEnum,
        ProcessRejectedNotFound: 'processRejectedNotFound' as ErrorEnum
    };
}
