import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';

import { ValidationErrorCodes, validationErrorMessage } from '../../utils/form.utils';
import { BaseComponent } from '../base/base.component';

export enum FormErrorVisibilityMode {
  CSS = 'CSS',
  DOM = 'DOM'
}

export enum FormErrorFontSize {
  Small = 'Small',
  Normal = 'Normal'
}

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormErrorComponent extends BaseComponent implements OnInit {

  readonly VisibilityMode = FormErrorVisibilityMode;
  readonly FontSize = FormErrorFontSize;

  @Input() visibilityMode: FormErrorVisibilityMode = FormErrorVisibilityMode.CSS;
  @Input() visible = false;
  // tslint:disable-next-line: no-input-rename
  @Input('form') formInput?: UntypedFormGroup;
  @Input() control?: AbstractControl | AbstractControl[];
  @Input() dirty = true;
  @Input() touched = true;
  @Input() untouched = false;
  @Input() fontSize: FormErrorFontSize = FormErrorFontSize.Small;
  @Input() positionStatic = false;
  @Input() detectStatusChange = false;
  @Input() checkControl = false;
  @Input() validationMessage = ''

  controlHasError = false;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    @Optional() private readonly parentForm?: UntypedFormGroup) {

    super();
  }

  ngOnInit() {

    if (!this.form || !this.control) {

      return;
    }

    this.subscribe(this.form.valueChanges.pipe(
      tap(() => this.checkForError())
    ));

    if (this.detectStatusChange) {

      const controlArray = Array.isArray(this.control) ? this.control : [this.control];

      controlArray.forEach(control => {

        this.subscribe(control.statusChanges.pipe(
          tap(() => this.checkForError())
        ));
      });
    }

    this.checkForError();
  }

  private getFirstControlWithError(): AbstractControl | null {

    if (this.control == null) {

      return null;
    }

    if (Array.isArray(this.control)) {

      const firstControlWithError = this.control.find(c => c.errors != null);

      return firstControlWithError == null ? null : firstControlWithError;

    } else {

      return this.control.errors == null ? null : this.control;
    }
  }

  private checkForError() {

    this.controlHasError = this.getFirstControlWithError() != null;

    // this.controlHasError may have changed so need to detectChanges()
    this.cdr.detectChanges();
  }

  get isVisible(): boolean {

    if (this.form == null) {

      return this.visible;

    } else {

      if (this.visible || this.controlHasError) {

        if (this.checkControl) {

          const control = this.control as AbstractControl;

          const controlShow = ((control.touched && this.touched) ||
            (control.dirty && this.dirty) ||
            (control.untouched && this.untouched));

          return controlShow;

        } else {

          const formShow = ((this.form.touched && this.touched) ||
            (this.form.dirty && this.dirty) ||
            (this.form.untouched && this.untouched));

          return formShow;
        }

      } else {

        return false;
      }
    }
  }

  get formControlError(): string {

    const firstControlWithError = this.getFirstControlWithError();

    if (!firstControlWithError) {

      return '';

    } else if (firstControlWithError.hasError(ValidationErrorCodes.Custom)) {

      return firstControlWithError.getError(ValidationErrorCodes.Custom);

    } else {

      return validationErrorMessage(firstControlWithError, this.validationMessage);
    }
  }

  get form(): UntypedFormGroup | undefined {

    return this.formInput || this.parentForm;
  }
}
