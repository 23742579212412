import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Table } from 'primeng/table';
import { TreeTable } from 'primeng/treetable';

import { TableFilterEvent, TableSelectionMode } from '../../models/table';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-input-filter',
  templateUrl: './input-filter.component.html',
  styleUrls: ['./input-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputFilterComponent extends BaseComponent implements OnInit {

  @Input() selectSingleFilteredTableRowOnEnter = false;
  @Input() filterMatchMode = 'contains';
  @Input() field: string;
  @Input() table?: Table;
  @Input() treeTable?: TreeTable;
  @Input() tableFilterEvent?: TableFilterEvent;
  @Input() customFilter = false;
  @Input() readonly = false;
  @Input() showInputButton = false;
  @Input() inputButtonIcon = '';
  @Input() inputButtonTooltip = '';
  @Input() inputButtonLabel = '';

  @Output() readonly inputFilterClick = new EventEmitter();
  @Output() readonly inputValueChanged = new EventEmitter();
  @Output() readonly customFilterValueChange = new EventEmitter();
  @Output() readonly inputButtonClicked = new EventEmitter<string | undefined>();

  constructor(
    private readonly cdr: ChangeDetectorRef) {

    super();
  }

  refresh() {

    this.cdr.detectChanges();
  }

  ngOnInit(): void {
  }

  onClick(e: Event) {

    this.inputFilterClick.emit();
    e.stopPropagation();
  }

  onInput(e: any) {

    if (this.customFilter) {

      this.customFilterValueChange.emit(e.target.value);

    } else if (this.table) {

      this.table.filter(e.target.value, this.field, this.filterMatchMode);
      this.inputValueChanged.emit(e.target.value);

    } else if (this.treeTable) {

      this.treeTable.filter(e.target.value, this.field, this.filterMatchMode);
    }
  }

  onKeyupEnter(e: KeyboardEvent) {

    if (!this.selectSingleFilteredTableRowOnEnter) {

      return;
    }

    const filteredValue = this.tableFilterEvent && this.tableFilterEvent.filteredValue.length > 0 && this.tableFilterEvent.filteredValue[0];

    if (filteredValue && this.table && this.table.selectionMode === TableSelectionMode.Single) {

      this.table.selectionChange.emit(filteredValue);
    }

    if (filteredValue && this.treeTable && this.treeTable.selectionMode === TableSelectionMode.Single) {

      this.treeTable.selectionChange.emit(filteredValue);
    }
  }

  setTableFilterValue(value: any) {
    if (this.table) {
      this.table.filter(value, this.field, this.filterMatchMode);
    }

    if (this.treeTable) {
      this.treeTable.filter(value, this.field, this.filterMatchMode);
    }

    this.cdr.detectChanges();
  }

  btnClick(event: PointerEvent) {
    event.stopPropagation();
    this.inputButtonClicked.emit(this.model || undefined);
  }


  get model(): string {

    if (this.table) {

      const filterMetadata = this.table.filters[this.field];
      if (!Array.isArray(filterMetadata)) {
        return filterMetadata && filterMetadata.value || '';
      } else {
        const filterMetadatas = filterMetadata.filter(f => !!f.value).map(f => f.value);
        return filterMetadatas.length == 0 ? '' : filterMetadatas.join(', ');
      }
      //return filterMetadata && filterMetadata.value || '';
    }

    if (this.treeTable) {

      const filterMetadata = this.treeTable.filters[this.field];

      return filterMetadata && filterMetadata.value || '';
    }

    return '';
  }
}
