import { LoginType } from "../generated";

export { LoginType };

export function parseLoginType(s: string | undefined | null): LoginType | undefined {

  if (!s) {

    return undefined;
  }

  return LoginType[s.toUpperCase() as LoginType];
}
