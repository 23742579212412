import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { BaseAppProperties } from '../base-app-properties';
import {
  Configuration,
  CreateJournalAdviceParams,
  ProcessJournalsParams,
  ReconcilePaidInvoiceParams,
  UpdatePaidInvoicesFinancialYearSummaryParams,
  UploadDocumentParams,
  UploadPaidInvoiceDocumentParams,
  WhiteFolderService as WhiteFolderServiceGenerated
} from '../generated';
import { PortalType } from '../models/portal-type';
import { mapFinancialYears } from '../utils/type-mapping.utils';

@Injectable({
  providedIn: 'root'
})
export class WhiteFolderService {

  private readonly whiteFolderServiceGenerated: WhiteFolderServiceGenerated;

  constructor(httpClient: HttpClient, appProperties: BaseAppProperties) {

    this.whiteFolderServiceGenerated = new WhiteFolderServiceGenerated(httpClient, appProperties.irisServerBasePath, new Configuration());
  }

  getFinancialYears(params: {
    strataId: string;
  }) {

    return this.whiteFolderServiceGenerated.getFinancialYears(params.strataId).pipe(
      map(financialYears => mapFinancialYears(financialYears))
    );
  }

  getBrowserFinancialYears(params: {
    strataId: string;
  }) {

    return this.whiteFolderServiceGenerated.getBrowserFinancialYears(params.strataId).pipe(
      map(financialYears => mapFinancialYears(financialYears))
    );
  }

  getBrowserCategories(params: {
    portalType: PortalType,
    strataId?: string;
  }) {

    return this.whiteFolderServiceGenerated.getBrowserCategories(params.portalType, params.strataId);
  }

  getBrowserDocuments(params: {
    financialYear: string;
    portalType: PortalType;
  }) {

    return this.whiteFolderServiceGenerated.getBrowserDocuments(params.financialYear, params.portalType);
  }

  getDocumentFileData(params: {
    id: string;
    portalType: PortalType;
  }) {

    return this.whiteFolderServiceGenerated.getDocumentFileData(params.id, params.portalType);
  }

  getPaidInvoicesFinancialYearSummary(params: {
    financialYear: string;
  }) {

    return this.whiteFolderServiceGenerated.getPaidInvoicesFinancialYearSummary(params.financialYear);
  }

  reconcilePaidInvoice(params: ReconcilePaidInvoiceParams) {

    return this.whiteFolderServiceGenerated.reconcilePaidInvoice(params);
  }

  updatePaidInvoicesFinancialYearSummary(params: UpdatePaidInvoicesFinancialYearSummaryParams) {

    return this.whiteFolderServiceGenerated.updatePaidInvoicesFinancialYearSummary(params);
  }

  uploadDocument(uploadDocumentParams: UploadDocumentParams) {

    return this.whiteFolderServiceGenerated.uploadDocument(uploadDocumentParams);
  }

  deleteDocument(params: {
    id: string;
  }) {

    return this.whiteFolderServiceGenerated.deleteDocument(params.id);
  }

  uploadPaidInvoiceDocument(uploadPaidInvoiceDocumentParams: UploadPaidInvoiceDocumentParams) {

    return this.whiteFolderServiceGenerated.uploadPaidInvoiceDocument(uploadPaidInvoiceDocumentParams);
  }

  deletePaidInvoiceDocument(params: {
    transactionId: string;
  }) {

    return this.whiteFolderServiceGenerated.deletePaidInvoiceDocument(params.transactionId);
  }

  getJournalAdvice(params: {
    financialYearId: string
  }) {

    return this.whiteFolderServiceGenerated.getJournalAdvice(params.financialYearId);
  }

  createJournalAdvice(params: CreateJournalAdviceParams) {

    return this.whiteFolderServiceGenerated.createJournalAdvice(params);
  }

  deleteJournalAdvice(params: {
    id: string
  }) {

    return this.whiteFolderServiceGenerated.deleteJournalAdvice(params.id);
  }

  processJournals(processJournalsParams: ProcessJournalsParams) {

    return this.whiteFolderServiceGenerated.processJournals(processJournalsParams);
  }

  startJournalWorkflow(params: {
    financialYearId: string
  }) {

    return this.whiteFolderServiceGenerated.startJournalWorkflow(params.financialYearId);
  }

  getJournalAdviceSupportingDocuments(params: {
    financialYearId: string;
    showActivityIndicator?: boolean;
  }) {

    const showActivityIndicator = params.showActivityIndicator == null ? false : params.showActivityIndicator;

    return this.whiteFolderServiceGenerated.getJournalAdviceSupportingDocuments(params.financialYearId, showActivityIndicator);
  }
}
