import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { User } from '../../generated';
import { ActionKind } from '../../models/action';
import { Heading } from '../../models/heading';
import { HelpTag } from '../../models/help-tag';
import { switchMapCatch } from '../../rxjs/operators';
import { AppEventBus } from '../../services/app-event-bus.service';
import { BaseAppStore } from '../../store/base-app.store';
import {
  formatPhoneNumberForDisplay,
  FormModel,
  notEmptyValidator,
  passwordValidator,
  phoneValidator,
  validationError
} from '../../utils/form.utils';
import { property } from '../../utils/object.utils';
import { BaseComponent } from '../base/base.component';
import { FormErrorFontSize } from '../form-error/form-error.component';

import { AccountsNoticesMethod, Store } from './owner-profile.store';

interface ProfileFormModel {
  readonly emails: {
    readonly email: string;
    readonly confirmEmail: string;
  };
  readonly passwords: {
    readonly loginPassword: string;
    readonly confirmLoginPassword: string;
  };
  readonly homePhone: string;
  readonly workPhone: string;
  readonly mobile: string;
  readonly emailCc: string;
  readonly correspondenceMethod: string;
  readonly comments: string;
}

@Component({
  selector: 'app-owner-profile',
  templateUrl: './owner-profile.component.html',
  styleUrls: ['./owner-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Store]
})
export class OwnerProfileComponent extends BaseComponent implements OnInit {

  private readonly getOwnerProfileDetails$ = new Subject();
  private readonly submit$ = new Subject();

  readonly updateProfileDetailsMessage = `If you would like to update name, address, email or mail method,
    provide the details in the box below and then select "Save Changes". Our office staff will attend to your request.`;

  readonly HelpTag = HelpTag;
  readonly FormErrorFontSize = FormErrorFontSize;
  readonly Heading = Heading;

  readonly correspondenceMethods: SelectItem[] = [
    {
      label: AccountsNoticesMethod.MailOnly,
      value: User.MailMethodEnum.MAIL
    },
    {
      label: AccountsNoticesMethod.EmailOnly,
      value: User.MailMethodEnum.EMAIL
    }
  ];

  profileForm: UntypedFormGroup;

  @Input() showComments = true;

  @Input() set unitId(val: string | undefined) {

    this.store.setUnitId(val);
  }

  @Input() set userId(val: string | undefined) {

    this.store.setUserId(val);
  }

  constructor(
    readonly store: Store,
    readonly appStore: BaseAppStore,
    private readonly fb: UntypedFormBuilder,
    private readonly appEventBus: AppEventBus) {

    super();
  }

  ngOnInit(): void {

    const emailsFormModel: FormModel<ProfileFormModel['emails']> = {
      email: ['', [this.existingEmailValidator, Validators.email]],
      confirmEmail: ['', [Validators.email]]
    };

    const passwordsFormModel: FormModel<ProfileFormModel['passwords']> = {
      loginPassword: ['', [passwordValidator]],
      confirmLoginPassword: ['', [passwordValidator]]
    };

    const profileFormModel: FormModel<ProfileFormModel> = {
      emails: this.fb.group(emailsFormModel, { validators: this.emailMatchValidator }),
      passwords: this.fb.group(passwordsFormModel, { validators: this.passwordMatchValidator }),
      homePhone: ['', [phoneValidator]],
      workPhone: ['', [phoneValidator]],
      mobile: ['', [this.existingMobileValidator, phoneValidator]],
      emailCc: ['', [Validators.email]],
      correspondenceMethod: [User.MailMethodEnum.MAIL],
      comments: ['']
    };

    this.profileForm = this.fb.group(profileFormModel, { validators: this.formValidator });

    this.subscribe(this.getOwnerProfileDetails$.pipe(
      switchMapCatch(() => this.store.getOwnerProfileDetails())
    ));

    this.subscribe(this.submit$.pipe(
      switchMapCatch(() => {

        return this.store.updateProfileDetails({
          owner: {
            // tslint:disable-next-line:no-non-null-assertion
            id: this.store.ownerProfileDetails!.owner.id,
            firstName: '',
            lastName: '',
            homePhone: this.profileFormModelValue.homePhone,
            busPhone: this.profileFormModelValue.workPhone,
            mobile: this.profileFormModelValue.mobile,
            // tslint:disable-next-line: max-line-length
            password: this.profileFormModelValue.passwords.loginPassword && this.profileFormModelValue.passwords.loginPassword.trim() || undefined,
            email: this.profileFormModelValue.emails.email,
            emailCc: this.profileFormModelValue.emailCc,
            mailMethod: this.profileFormModelValue.correspondenceMethod as User.MailMethodEnum
          },
          comments: this.profileFormModelValue.comments
        });
      })
    ));

    this.subscribe(this.appEventBus.action$.pipe(
      tap(action => {

        if (action.kind === ActionKind.ContactDetailsProceed && (action.emailUpdated || action.mobileUpdated)) {

          this.getOwnerProfileDetails$.next();
        }
      })
    ));

    this.mobxReaction('OwnerProfileComponent: store.ownerProfileDetails',
      () => this.store.ownerProfileDetails,
      ownerProfileDetails => this.populateFromStore());

    this.mobxReaction('OwnerProfileComponent: store.unitId OR this.store.userId',
      () => ({
        unitId: this.store.unitId,
        userId: this.store.userId
      }),
      effect => this.getOwnerProfileDetails$.next());
  }

  resetForm() {

    this.populateFromStore();
  }

  private existingMobileValidator: ValidatorFn = (control: AbstractControl) => {

    if (this.store.isExistingMobileValid) {

      return notEmptyValidator(control);
    }

    return null;
  }

  private existingEmailValidator: ValidatorFn = (control: AbstractControl) => {

    if (this.store.isExistingEmailValid) {

      return notEmptyValidator(control);
    }

    return null;
  }

  private emailMatchValidator: ValidatorFn = (control: AbstractControl) => {

    const emailValues = control.value as ProfileFormModel['emails'];

    return control.pristine || emailValues.email.toLowerCase() === emailValues.confirmEmail.toLowerCase() ?
      null : validationError(`Emails don't match`);
  }

  private passwordMatchValidator: ValidatorFn = (control: AbstractControl) => {

    const passwordsModelValue = control.value as ProfileFormModel['passwords'];

    const bothNull = passwordsModelValue.loginPassword == null && passwordsModelValue.confirmLoginPassword == null;

    const bothEmpty = (passwordsModelValue.loginPassword != null && passwordsModelValue.loginPassword.length === 0) &&
      (passwordsModelValue.confirmLoginPassword != null && passwordsModelValue.confirmLoginPassword.length === 0);

    if (bothNull || bothEmpty) {

      return null;
    }

    const loginPasswordValueNotBlank = passwordsModelValue.loginPassword != null &&
      passwordsModelValue.loginPassword.trim().length !== 0;

    const confirmLoginPasswordValueNotBlank = passwordsModelValue.confirmLoginPassword != null &&
      passwordsModelValue.confirmLoginPassword.trim().length !== 0;

    if (loginPasswordValueNotBlank && confirmLoginPasswordValueNotBlank &&
      (passwordsModelValue.loginPassword === passwordsModelValue.confirmLoginPassword)) {

      return null;
    }

    return validationError(`Passwords don't match`);
  }

  private formValidator: ValidatorFn = (control: AbstractControl) => {

    const formModelValue = control.value as ProfileFormModel;

    if (formModelValue.correspondenceMethod === User.MailMethodEnum.EMAIL &&
      (!formModelValue.emails.email || !formModelValue.emails.email.trim())) {

      return validationError('Need a valid correspondence email');
    }

    return null;
  }

  private populateFromStore() {

    if (this.store.ownerProfileDetails) {

      const emails: ProfileFormModel['emails'] = {
        email: this.store.ownerProfileDetails.owner.email || '',
        confirmEmail: '',
      };

      const passwords: ProfileFormModel['passwords'] = {
        loginPassword: '',
        confirmLoginPassword: '',
      };

      const loginFormModel: ProfileFormModel = {
        emails,
        passwords,
        correspondenceMethod: this.store.ownerProfileDetails.owner.mailMethod || '',
        emailCc: this.store.ownerProfileDetails.owner.emailCc || '',
        homePhone: formatPhoneNumberForDisplay(this.store.ownerProfileDetails.owner.homePhone),
        workPhone: formatPhoneNumberForDisplay(this.store.ownerProfileDetails.owner.busPhone),
        mobile: formatPhoneNumberForDisplay(this.store.ownerProfileDetails.owner.mobile),
        comments: ''
      };

      this.profileForm.reset(loginFormModel);

    } else {

      this.profileForm.reset();
    }

    // Need this here as emailMatchValidator uses control.pristine
    this.emails.updateValueAndValidity();

    this.store.setPasswordVisible(false);
  }

  onSubmit() {

    this.submit$.next();
  }

  onConfirmEmailPaste(evt: Event) {

    evt.preventDefault();
  }

  get profileFormModelValue() {

    return this.profileForm.value as ProfileFormModel;
  }

  get emails() {

    return this.profileForm.controls[property<ProfileFormModel>('emails')] as UntypedFormGroup;
  }

  get email() {

    return this.emails.controls[property<ProfileFormModel['emails']>('email')];
  }

  get confirmEmail() {

    return this.emails.controls[property<ProfileFormModel['emails']>('confirmEmail')];
  }

  get passwords() {

    return this.profileForm.controls[property<ProfileFormModel>('passwords')] as UntypedFormGroup;
  }

  get loginPassword() {

    return this.passwords.controls[property<ProfileFormModel['passwords']>('loginPassword')];
  }

  get confirmLoginPassword() {

    return this.passwords.controls[property<ProfileFormModel['passwords']>('confirmLoginPassword')];
  }

  get homePhone() {

    return this.profileForm.controls[property<ProfileFormModel>('homePhone')];
  }

  get workPhone() {

    return this.profileForm.controls[property<ProfileFormModel>('workPhone')];
  }

  get mobile() {

    return this.profileForm.controls[property<ProfileFormModel>('mobile')];
  }

  get emailCc() {

    return this.profileForm.controls[property<ProfileFormModel>('emailCc')];
  }

  get correspondenceMethod() {

    return this.profileForm.controls[property<ProfileFormModel>('correspondenceMethod')];
  }

  get comments() {

    return this.profileForm.controls[property<ProfileFormModel>('comments')];
  }
}
