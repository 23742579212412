import { Directive, Input, Optional } from '@angular/core';
import { ButtonDirective as PrimeNGButtonDirective } from 'primeng/button';

import { TableDataComponent } from '../components/table-data/table-data.component';

export enum ButtonKind {
  Action = 'action',
  Add = 'add',
  Cancel = 'cancel',
  Close = 'close',
  ChangePlan = 'change plan',
  ChangeUser = 'change user',
  Clear = 'clear',
  Delete = 'delete',
  Details = 'details',
  Download = 'download',
  Edit = 'edit',
  FolderOpen = 'open',
  New = 'new',
  Notes = 'notes',
  Ok = 'OK',
  Select = 'select',
  Save = 'save',
  Search = 'search',
  PutOnHold = 'put on hold',
  RemoveOnHold = 'remove on hold',
  Reset = 'reset',
  Preferences = 'preferences',
  Update = 'update',
  Yes = 'yes',
  No = 'no',
  Archive = 'archive',
  Back = 'back',
  Next = 'next',
  Link = 'link',
  Unlink = 'unlink',
}

enum ButtonCssClass {
  Orange = 'orange-btn',
  Red = 'red-btn'
}

const buttonCssClasses = Object.values(ButtonCssClass);

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'button[pButton]'
})
export class ButtonDirective {

  @Input() titleCase = true;
  @Input() forTableColumn = false;

  @Input() set label(val: string | undefined) {

    if (val && this.titleCase) {

      // @ts-ignore
      this.pButton.label = val.toTitleCase();
    }
  }

  @Input() set kind(val: ButtonKind | undefined) {

    if (val) {

      this.buttonClassList.remove(...buttonCssClasses);

      // @ts-ignore
      this.pButton.label = val.toTitleCase();
    }

    switch (val) {

      case ButtonKind.Action:

        this.pButton.icon = 'ui-icon-arrow-forward';

        break;

      case ButtonKind.New:
      case ButtonKind.Add:

        this.pButton.icon = 'ui-icon-add';

        break;

      case ButtonKind.Edit:

        if (this.forTableColumn || this.tableDataComponent) {
          this.pButton.label = undefined as any;
        }

        this.pButton.icon = 'ui-icon-edit';

        break;

      case ButtonKind.Delete:

        if (this.forTableColumn || this.tableDataComponent) {

          this.pButton.label = undefined as any;

          this.buttonClassList.add(ButtonCssClass.Red);
        }

        this.pButton.icon = 'ui-icon-clear';

        break;

      case ButtonKind.Save:

        this.pButton.icon = 'ui-icon-save';

        break;

      case ButtonKind.Search:

        this.pButton.icon = 'ui-icon-search';

        break;

      case ButtonKind.Yes:
      case ButtonKind.Ok:
      case ButtonKind.Select:

        this.pButton.icon = 'ui-icon-check';

        break;

      case ButtonKind.No:
      case ButtonKind.Cancel:
      case ButtonKind.Clear:
      case ButtonKind.Close:

        this.pButton.icon = 'ui-icon-clear';

        break;

      case ButtonKind.PutOnHold:
      case ButtonKind.RemoveOnHold:

        this.pButton.icon = 'ui-icon-block';

        this.buttonClassList.add(ButtonCssClass.Orange);

        break;

      case ButtonKind.ChangePlan:

        this.pButton.icon = 'fas fa-building';
        this.pButton.label = 'Change';

        break;

      case ButtonKind.ChangeUser:

        this.pButton.icon = 'fas fa-user';
        this.pButton.label = 'Change';

        break;

      case ButtonKind.Reset:

        this.pButton.icon = 'ui-icon-replay';

        break;

      case ButtonKind.Download:

        this.pButton.icon = 'ui-icon-file-download';

        break;

      case ButtonKind.FolderOpen:

        this.pButton.icon = 'fas fa-folder-open';

        break;

      case ButtonKind.Details:

        this.pButton.icon = 'ui-icon-details';

        break;

      case ButtonKind.Notes:

        this.pButton.icon = 'fas fa-sticky-note';

        break;

      case ButtonKind.Preferences:

        this.pButton.icon = 'ui-icon-settings';

        break;

      case ButtonKind.Update:

        this.pButton.icon = 'fas fa-thin fa-percent';

        break;
      
      case ButtonKind.Archive:
        this.pButton.icon = 'pi pi-archive';

        break;
      
      case ButtonKind.Next:
        this.pButton.icon = 'fas fa-regular fa-arrow-right';

        break;
        
      case ButtonKind.Back:
        this.pButton.icon = 'fas fa-regular fa-arrow-left';

        break;

      case ButtonKind.Link:
        this.pButton.icon = 'fas fa-link'; //'ui-icon-link';

        break;
      
      case ButtonKind.Unlink:
        this.pButton.icon = 'fas fa-unlink'; //'ui-icon-unlink';

        break;
        
    }
  }

  constructor(
    private readonly pButton: PrimeNGButtonDirective,
    @Optional() private readonly tableDataComponent?: TableDataComponent) {
  }

  private get buttonClassList() {

    return this.pButton.el.nativeElement.classList as DOMTokenList;
  }
}
