import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Password, PasswordDirective } from 'primeng/password';

import { BaseComponent } from '../base/base.component';

enum PasswordStrength {
  None = 'Password Strength',
  Weak = 'Weak',
  Medium = 'Medium',
  Strong = 'Strong'
}

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordStrengthComponent extends BaseComponent implements OnInit {

  // Used in testStrength
  private normalize = PasswordDirective.prototype.normalize.bind(this);

  private testStrength = PasswordDirective.prototype.testStrength.bind(this);

  @Input() password = '';

  ngOnInit(): void {
  }

  get strengthValue(): number {

    if (!this.password) {

      return 0;
    }

    const lowerCaseFirstLetter = `${this.password.charAt(0).toLowerCase()}${this.password.slice(1)}`;

    return this.testStrength(lowerCaseFirstLetter);
  }

  get strengthLabel(): PasswordStrength {

    if (!this.password) {

      return PasswordStrength.None;
    }

    const passwordStrengthValue = this.strengthValue;

    if (passwordStrengthValue < 30) {

      return PasswordStrength.Weak;
    }

    if (passwordStrengthValue < 80) {

      return PasswordStrength.Medium;
    }

    return PasswordStrength.Strong;
  }

  get containerClass(): string {

    return this.strengthLabel.toLowerCase();
  }
}

