function subdomainMatches(subdomains: string[]): boolean {

  const subdomain = window.location.hostname.split('.')[0].toLowerCase();

  return subdomains.includes(subdomain);
}

export const isDevSubdomain = subdomainMatches(['iris-dev', 'owner-dev', 'building-dev']);
export const isUatSubdomain = subdomainMatches(['iris-uat', 'owner-uat', 'building-uat']);
export const isProductionSubdomain = subdomainMatches(['iris', 'owner', 'building']);
