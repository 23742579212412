import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BaseComponent } from '../../components/base/base.component';
import { splitNewlines } from '../../utils/string.utils';

@Component({
  selector: 'app-text-block',
  templateUrl: './text-block.component.html',
  styleUrls: ['./text-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextBlockComponent extends BaseComponent {

  private _lines: string[] = [];

  @Input() set lines(input: string | string[] | undefined) {

    if (!input) {

      this._lines = [];

    } else if (typeof input === 'string') {

      this._lines = splitNewlines(input);

    } else {

      this._lines = input;
    }
  }

  get lines() {

    return this._lines;
  }
}
