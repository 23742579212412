import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params, Router, RouterStateSnapshot } from '@angular/router';

import { RoutePath } from '../route-path';

import { BaseAppStore } from '../store/base-app.store';
import { BaseAuthenticationStore } from '../store/base-authentication.store';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private readonly authenticationStore: BaseAuthenticationStore,
    private readonly appStore: BaseAppStore,
    private readonly router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    return this.checkLogin(state.url, route.queryParams);
  }

  private checkLogin(url: string, queryParams: Params): boolean {

    if (this.authenticationStore.isAuthenticated) {

      return true;
    }

    // Store the attempted URL for redirecting
    this.appStore.setRedirectUrl(url);

    // Navigate to the login page
    this.router.navigate([`/${RoutePath.Login}`], {
      queryParams,
      queryParamsHandling: 'merge'
    });

    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LoginAuthGuard implements CanActivate {

  constructor(
    private readonly authenticationStore: BaseAuthenticationStore,
    private readonly router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (!this.authenticationStore.isAuthenticated) {

      return true;
    }

    // Already logged in so navigate to home page
    this.router.navigate(['/']);

    return false;
  }
}
