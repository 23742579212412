<div *mobxAutorun class="ui-fluid p-grid form-group">
  <div class="p-col-12 p-md-2">
    <label>Unit</label>
  </div>
  <div class="p-col-12 p-md-10">
    <p-dropdown placeholder="Select Unit" [dataKey]="unitPropertyId" [optionLabel]="unitPropertyUnitNumber"
      [options]="store.units" [filter]="true" [resetFilterOnHide]="true" [ngModel]="store.selectedUnit"
      (ngModelChange)="store.setSelectedUnit($event)">
    </p-dropdown>
  </div>
  <div class="p-col-12">
    <hr>
  </div>
  <form class="p-grid form-group" [formGroup]="unitForm" (ngSubmit)="onUnitFormSubmit()">
    <div class="p-col-12 p-md-2">
      <label>Registered Owner(s)</label>
    </div>
    <div class="p-col-12 p-md-4">
      {{store.owner.user.registeredOwner}}
    </div>
    <div class="p-col-12 p-md-2">
      <label>Owner Unit Code</label>
    </div>
    <div class="p-col-12 p-md-4">
      {{store.unitSummary?.unit.alphaCode}}
    </div>
    <div class="p-col-12 p-md-2">
      <label>Unit Entitlement</label>
    </div>
    <div class="p-col-12 p-md-4">
      {{store.unitSummary?.unit.unitEntitlement}}
    </div>
    <div class="p-col-12 p-md-2">
      <label>Address Via</label>
    </div>
    <div class="p-col-12 p-md-4">
      {{store.unitSummary?.addressMode}}
    </div>
    <div class="p-col-12 p-md-2">
      <label>Address Type</label>
    </div>
    <div class="p-col-12 p-md-4">
      <p-dropdown placeholder="Select Address Type" [dataKey]="nameValuePairPropertyValue"
        [options]="store.unitSummary?.unitTypeOptions" [optionLabel]="nameValuePairPropertyName" [showClear]="true"
        [formControl]="unitAddressType">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-2">
      <label>Debt Status</label>
    </div>
    <div class="p-col-12 p-md-4">
      <p-dropdown placeholder="Select Debt Status" [options]="financialStandings" [formControl]="unitFinancialStanding"
        [showClear]="true">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-2">
      <label>Unit Additional Info</label>
    </div>
    <div class="p-col-12 p-md-10">
      <textarea pInputTextarea [rows]="5" [formControl]="unitAdditionalInfo"></textarea>
    </div>
    <div class="p-col-12" style="text-align: center">
      <app-content-container layoutAlign="center center">
        <button pButton type="submit" [kind]="ButtonKind.Save"></button>
      </app-content-container>
    </div>
  </form>
</div>
