import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'common-lib';

import { Store } from './login-message.store';

@Component({
  selector: 'app-login-message',
  templateUrl: './login-message.component.html',
  styleUrls: ['./login-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Store]
})
export class LoginMessageComponent extends BaseComponent implements OnInit {

  @Output()
  readonly dismissClick = new EventEmitter();

  constructor(
    readonly store: Store) {

    super();
  }

  ngOnInit(): void {

    this.subscribe(this.store.getLoginMessage());
  }
}
