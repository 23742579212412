import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[appDialogFooter]'
})
export class DialogFooterDirective {

  @HostBinding('class.p-dialog-footer') readonly uiDialogFooter = true;

  @HostBinding('class.wht-ui-dialog-footer') readonly whtUiDialogFooter = true;
}
