import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { BaseComponent } from '../base/base.component';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'tr[appTableRow]',
  templateUrl: './table-row.component.html',
  styleUrls: ['./table-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableRowComponent extends BaseComponent implements OnInit {

  ngOnInit(): void {
  }
}
