/**
 * Whittles API
 * Whittles API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type DomainObjectType = 'organisation' | 'company' | 'user';

export const DomainObjectType = {
    Organisation: 'organisation' as DomainObjectType,
    Company: 'company' as DomainObjectType,
    User: 'user' as DomainObjectType
};
