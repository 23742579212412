<!-- <ng-container *ngIf="appProperties.portalType === PortalType.Manager; then normalTitle else decoratedTitle">
</ng-container>
<ng-template #normalTitle>
  <h1>
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </h1>
</ng-template>
<ng-template #decoratedTitle>
  <div class="container">
    <span>
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </span>
  </div>
</ng-template>
<ng-template #contentTpl>
  <ng-content></ng-content>
</ng-template> -->
<h1>
  <ng-content></ng-content>
</h1>
