/**
 * Whittles API
 * Whittles API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BudgetActualTotals } from '../model/budgetActualTotals';
import { Levy } from '../model/levy';
import { NameValuePair } from '../model/nameValuePair';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class BudgetService {

    protected basePath = 'https://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param strataId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBudgetAccounts(strataId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<NameValuePair>>;
    public getBudgetAccounts(strataId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NameValuePair>>>;
    public getBudgetAccounts(strataId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NameValuePair>>>;
    public getBudgetAccounts(strataId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataId === null || strataId === undefined) {
            throw new Error('Required parameter strataId was null or undefined when calling getBudgetAccounts.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<NameValuePair>>(`${this.basePath}/budget/accounts`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataId 
     * @param accountNumber 
     * @param fundCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBudgetAndActualTotalsForAccount(strataId: string, accountNumber: string, fundCode: string, observe?: 'body', reportProgress?: boolean): Observable<BudgetActualTotals>;
    public getBudgetAndActualTotalsForAccount(strataId: string, accountNumber: string, fundCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BudgetActualTotals>>;
    public getBudgetAndActualTotalsForAccount(strataId: string, accountNumber: string, fundCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BudgetActualTotals>>;
    public getBudgetAndActualTotalsForAccount(strataId: string, accountNumber: string, fundCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataId === null || strataId === undefined) {
            throw new Error('Required parameter strataId was null or undefined when calling getBudgetAndActualTotalsForAccount.');
        }

        if (accountNumber === null || accountNumber === undefined) {
            throw new Error('Required parameter accountNumber was null or undefined when calling getBudgetAndActualTotalsForAccount.');
        }

        if (fundCode === null || fundCode === undefined) {
            throw new Error('Required parameter fundCode was null or undefined when calling getBudgetAndActualTotalsForAccount.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }
        if (accountNumber !== undefined && accountNumber !== null) {
            queryParameters = queryParameters.set('accountNumber', <any>accountNumber);
        }
        if (fundCode !== undefined && fundCode !== null) {
            queryParameters = queryParameters.set('fundCode', <any>fundCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<BudgetActualTotals>(`${this.basePath}/budget/account/totals`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param strataFinancialYearId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBudgetLevies(strataFinancialYearId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Levy>>;
    public getBudgetLevies(strataFinancialYearId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Levy>>>;
    public getBudgetLevies(strataFinancialYearId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Levy>>>;
    public getBudgetLevies(strataFinancialYearId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (strataFinancialYearId === null || strataFinancialYearId === undefined) {
            throw new Error('Required parameter strataFinancialYearId was null or undefined when calling getBudgetLevies.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (strataFinancialYearId !== undefined && strataFinancialYearId !== null) {
            queryParameters = queryParameters.set('strataFinancialYearId', <any>strataFinancialYearId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Levy>>(`${this.basePath}/budget/levies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
