import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { HttpRequestParams } from '../models/http-request-params';
import { Logger } from '../services/logger.service';
import { BaseAppStore } from '../store/base-app.store';
import { BaseAuthenticationStore } from '../store/base-authentication.store';

@Injectable()
export class BasicAuthHeaderInterceptor implements HttpInterceptor {

  constructor(private readonly authenticationStore: BaseAuthenticationStore) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.authenticationStore.isAuthenticated) {

      return next.handle(req);
    }

    const newReq = req.clone({
      headers: req.headers.set('Authorization', `Basic ${this.authenticationStore.basicAuth}`)
    });

    return next.handle(newReq);
  }
}

@Injectable()
export class ActivityIndicatorIntercpetor implements HttpInterceptor {

  constructor(private readonly appStore: BaseAppStore) {
  }

  private static shouldShowActivityIndicator(req: HttpRequest<any>): boolean {

    const showActivityIndicator = req.params.get(HttpRequestParams.ShowActivityIndicator);

    return showActivityIndicator == null || showActivityIndicator === 'true' || showActivityIndicator as unknown as boolean === true;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const showActivityIndicator = ActivityIndicatorIntercpetor.shouldShowActivityIndicator(req);

    if (showActivityIndicator) {

      this.appStore.showActivityIndicator({
        flag: true
      });
    }

    return next.handle(req).pipe(
      finalize(() => {

        if (showActivityIndicator) {

          this.appStore.showActivityIndicator({
            flag: false
          });
        }
      })
    );
  }
}

@Injectable()
export class HttpErrorIntercpetor implements HttpInterceptor {

  constructor(
    private logger: Logger,
    private authenticationStore: BaseAuthenticationStore) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {

        // IM-87 prevent infinite loop when logout req returns 401 or 403
        const logoutReq: boolean = req.url.includes('authentication/logoff')

        if (error.status === 401 || error.status === 403) {

          this.logger.warn('401 or 403 received from server, logging out ...', error);

          if (!logoutReq) this.authenticationStore.logout();
        }

        return throwError(error);
      })
    );
  }
}
