import { BreakPoint } from '@angular/flex-layout';

/**
 * Matches PrimeNG Grid CSS - https://www.primefaces.org/primeng/#/grid
 */
const SM = 640;
const MD = 1024;
const LG = 1440;
const XL = 5000;

export function mapFlexLayoutBreakPointToPrimeNG(bp: BreakPoint): BreakPoint {

  switch (bp.alias) {

    case 'xs': bp.mediaQuery = `(min-width: -1px) and (max-width: -1px)`; break; // should never match as PrimeNG does not have xs
    case 'gt-xs': bp.mediaQuery = `(min-width: 0px)`; break;
    case 'lt-sm': bp.mediaQuery = `(max-width: -1px)`; break; // should never match as PrimeNG does not have xs
    case 'sm': bp.mediaQuery = `(min-width: 0px) and (max-width: ${SM}px)`; break;
    case 'gt-sm': bp.mediaQuery = `(min-width: ${SM + 1}px)`; break;
    case 'lt-md': bp.mediaQuery = `(max-width: ${SM}px)`; break;
    case 'md': bp.mediaQuery = `(min-width: ${SM + 1}px) and (max-width: ${MD}px)`; break;
    case 'gt-md': bp.mediaQuery = `(min-width: ${MD + 1}px)`; break;
    case 'lt-lg': bp.mediaQuery = `(max-width: ${MD}px)`; break;
    case 'lg': bp.mediaQuery = `(min-width: ${MD + 1}px) and (max-width: ${LG}px)`; break;
    case 'gt-lg': bp.mediaQuery = `(min-width: ${LG + 1}px)`; break;
    case 'lt-xl': bp.mediaQuery = `(max-width: ${LG}px)`; break;
    case 'xl': bp.mediaQuery = `(min-width: ${LG + 1}px) and (max-width: ${XL}px)`; break;
  }

  return bp;
}
