import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { toJS } from 'mobx';
import { Message } from 'primeng/api';

import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-info-message',
  templateUrl: './info-message.component.html',
  styleUrls: ['./info-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoMessageComponent extends BaseComponent {

  @Input() message?: string | string[] | Message;

  @Input() showIcon = false;

  @Input() closable = false;

  @Input() severity = 'info';

  get msgs(): Message[] | undefined {

    if (!this.message) {

      return undefined;
    }

    const message = toJS(this.message);

    if (typeof message === 'string') {

      return [{
        severity: this.severity,
        detail: message
      }];

    } else if (Array.isArray(message)) {

      return message.map(m => ({
        severity: this.severity,
        detail: m
      }));

    } else if (message.summary || message.detail) {

      return [{
        ...message,
        // @ts-ignore
        summary: message.summary ? message.summary.toTitleCase() : message.summary
      }];
    }

    return undefined;
  }
}
