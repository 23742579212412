/**
 * Whittles API
 * Whittles API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Company } from './company';
import { Currency } from './currency';
import { Document } from './document';


export interface AccountsPayableInvoiceSummary { 
    workflowProcessInstanceId: string;
    creditorInvoiceEntryHeaderId: string;
    strataId: string;
    invoiceDocument: Document;
    dateEntered: Date;
    description: string;
    amount: Currency;
    status: string;
    creditor: string;
    creditorCode: string;
    planNumber: string;
    gstRegistered: boolean;
    planAddress: string;
    secondAuthorityRequired: AccountsPayableInvoiceSummary.SecondAuthorityRequiredEnum;
    onHold?: boolean;
    company: Company;
}
export namespace AccountsPayableInvoiceSummary {
    export type SecondAuthorityRequiredEnum = 'NOT_REQUIRED' | 'REQUIRED' | 'OBTAINED';
    export const SecondAuthorityRequiredEnum = {
        NOTREQUIRED: 'NOT_REQUIRED' as SecondAuthorityRequiredEnum,
        REQUIRED: 'REQUIRED' as SecondAuthorityRequiredEnum,
        OBTAINED: 'OBTAINED' as SecondAuthorityRequiredEnum
    };
}
