import { animate, state, style, transition, trigger } from '@angular/animations';

const fadeInOutTriggerTimingsDefault = '100ms';

export const fadeInOutTrigger = trigger('fadeInOut', [
  transition(':enter', // :enter is alias to 'void => *'
    [
      style({ opacity: 0 }), // style applied at the start of the transition (style removed at end of transition)
      animate('{{ time }}', style({ opacity: 1 })) // (style removed at end of transition)
    ],
    {
      params: {
        time: fadeInOutTriggerTimingsDefault
      }
    }),
  transition(':leave', // :leave is alias to '* => void'
    [
      animate('{{ time }}', style({ opacity: 0 })) // (style removed at end of transition)
    ],
    {
      params: {
        time: fadeInOutTriggerTimingsDefault
      }
    })
]);

/* This trigger was the original AppSubMenuComponent trigger that came with the PrimeNG Ultima template */
export const appSubmenuTrigger = trigger('children', [
  state('hiddenAnimated', style({
    height: '0px'
  })),
  state('visibleAnimated', style({
    height: '*'
  })),
  state('visible', style({
    height: '*',
    'z-index': 100
  })),
  state('hidden', style({
    height: '0px',
    'z-index': '*'
  })),
  transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
  transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
]);
