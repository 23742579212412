import { StrataPlanDetails, WhiteFolderFinancialYearSummary } from '../generated';

export enum ActionKind {
  ChangeInvoicePlan = 'ChangeInvoicePlan',
  ChangeInvoicePlanSelected = 'ChangeInvoicePlanSelected',
  ChangeTransactionPlan = 'ChangeTransactionPlan',
  ChangeTransactionPlanSelected = 'ChangeTransactionPlanSelected',
  ContactDetailsProceed = 'ContactDetailsProceed',
  FinancialYearSummaryModified = 'FinancialYearSummaryModified'
}

export interface ChangeInvoicePlan {
  readonly kind: ActionKind.ChangeInvoicePlan;
}

export interface ChangeInvoicePlanSelected {
  readonly kind: ActionKind.ChangeInvoicePlanSelected;
  readonly selectedPlan: StrataPlanDetails;
}

export interface ChangeTransactionPlan {
  readonly kind: ActionKind.ChangeTransactionPlan;
}

export interface ChangeTransactionPlanSelected {
  readonly kind: ActionKind.ChangeTransactionPlanSelected;
  readonly selectedPlan: StrataPlanDetails;
}

export interface ContactDetailsProceed {
  readonly kind: ActionKind.ContactDetailsProceed;
  readonly emailUpdated: boolean;
  readonly mobileUpdated: boolean;
}

export interface FinancialYearSummaryModified {
  readonly kind: ActionKind.FinancialYearSummaryModified;
  readonly financialYearId: string;
  readonly financialYearSummary: WhiteFolderFinancialYearSummary;
}

export type Action =
  | ChangeInvoicePlan
  | ChangeInvoicePlanSelected
  | ChangeTransactionPlan
  | ChangeTransactionPlanSelected
  | ContactDetailsProceed
  | FinancialYearSummaryModified
  ;
