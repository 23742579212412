export function setSelectionRange(el: HTMLInputElement, params: {
  start: number;
  end: number;
  direction?: 'forward' | 'backward' | 'none';
  onNextTick?: boolean;
}) {

  if (!el.setSelectionRange) {

    return;
  }

  if (params.onNextTick == null || params.onNextTick) {

    setTimeout(() => el.setSelectionRange(params.start, params.end, params.direction));

  } else {

    el.setSelectionRange(params.start, params.end, params.direction);
  }
}

export function isMouseClickWithinCheckbox(e: MouseEvent): boolean {

  const targetElement = e.target as HTMLElement;

  const mouseClickWithinCheckbox = e.offsetX > 0 && e.offsetY > 0 &&
    (targetElement.offsetWidth - e.offsetX) > 0 && (targetElement.offsetHeight - e.offsetY) > 0;

  return mouseClickWithinCheckbox;
}
