import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

import { Logger } from '../services/logger.service';

@Pipe({ name: 'currencyFormat' })
export class CurrencyFormatPipe extends CurrencyPipe implements PipeTransform {

  constructor(
    @Inject(LOCALE_ID) locale: string,
    private readonly logger: Logger) {

    super(locale);
  }

  transform(value: any) {

    if (value == null) {

      return value;
    }

    try {

      let currencyValue = value;

      if (typeof currencyValue === 'string') {

        // Prevent double $
        currencyValue = currencyValue.replace(/$/g, '');

      } else if (typeof currencyValue.value === 'number') {

        // Currency defined as
        /*
          interface Currency {
            value: number;
          }
        */
        currencyValue = currencyValue.value;
      }

      if (Number.isFinite(currencyValue)) {

        currencyValue = currencyValue / 100;
      }

      return super.transform(currencyValue, 'AUD', '$');

    } catch (e) {

      this.logger.error('Error caught in CurrencyFormatPipe', e);

      return '';
    }
  }
}
