import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Router } from '@angular/router';
import { BaseAppStore, Environment, Logger } from 'common-lib';

@Injectable({
  providedIn: 'root'
})
export class AppStore extends BaseAppStore {

  constructor(
    logger: Logger,
    router: Router,
    mediaObserver: MediaObserver,
    breakpointObserver: BreakpointObserver,
    environment: Environment) {

    super(logger, router, mediaObserver, breakpointObserver, environment);
  }
}
