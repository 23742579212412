export enum TableStateStorage {
  Session = 'session',
  Local = 'local'
}

export enum TableSortMode {
  Single = 'single',
  Multiple = 'multiple'
}

export enum TableSelectionMode {
  Single = 'single',
  Multiple = 'multiple'
}

export interface TableFilterEvent {
  filteredValue: unknown[];
  filters: {
    [column: string]: {
      matchMode: string;
      value: string | string[];
    }
  };
}

export interface CustomTableFilterValue {
  value: string | string[];
  field: string;
}
