import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';

import { BaseComponent } from '../../components/base/base.component';
import { SpinnerSize } from '../../components/spinner/spinner.component';
import { MediaType } from '../../models/media-type';
import { SafePipeBypassType } from '../../pipes/safe.pipe';
import { FileService } from '../../services/file.service';
import { Logger } from '../../services/logger.service';
import { canRenderPdfInline } from '../../utils/browser.utils';
import { base64toBlob } from '../../utils/file.utils';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfComponent extends BaseComponent implements OnInit, OnDestroy {

  private _src?: string;

  @ViewChild('container') readonly container: ElementRef<HTMLDivElement>;

  @Input() filename = 'file.pdf';
  @Input() visible = true;

  readonly canRenderPdfInline = canRenderPdfInline;
  readonly SpinnerSize = SpinnerSize;
  readonly SafePipeBypassType = SafePipeBypassType;

  loading = false;
  objectURL?: string;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly fileService: FileService,
    private readonly logger: Logger) {

    super();
  }

  @Input() set src(value: string | undefined) {

    this._src = value;

    this.cleanupObjectURL();

    if (this.canRenderPdfInline) {

      this.loading = !!this._src;

      if (this._src) {

        this.objectURL = URL.createObjectURL(base64toBlob(this._src, MediaType.Pdf));
      }

    } else {

      this.loading = false;
    }
  }

  get src() {

    return this._src;
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.cleanupObjectURL();
  }

  iframeOnLoad(evt: any) {

    this.loading = false;

    this.cdr.markForCheck();

    if (evt instanceof Error) {

      this.logger.error('Failed to load PDF', evt);
    }
  }

  onAnchorClick() {

    this.fileService.save({
      fileData: {
        filename: this.filename,
        // tslint:disable-next-line:no-non-null-assertion
        data: this._src!
      },
      contentType: MediaType.Pdf
    });
  }

  private cleanupObjectURL() {

    if (!this.objectURL) {

      return;
    }

    URL.revokeObjectURL(this.objectURL);

    this.objectURL = undefined;
  }

  get pdfIframeVisible(): boolean {

    return !!this.src && this.visible;
  }

  get spinnerVisible(): boolean {

    return this.loading && this.visible;
  }
}
