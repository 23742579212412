import { Injectable } from '@angular/core';
import { action, observable } from 'mobx';
import { finalize, tap } from 'rxjs/operators';

import { Owner, Unit } from '../../generated';
import { startWithTap } from '../../rxjs/operators';
import { StrataService } from '../../services/strata.service';
import { BaseDomainStore } from '../../store/base-domain.store';
import { formatPhoneNumberForDisplay } from '../../utils/form.utils';

export enum TabPanel {
  Profile,
  Unit,
  AccountHistory
}

@Injectable()
export class Store {

  @observable
  private _selectedTabPanel = TabPanel.Profile;

  @observable
  private _spinnerVisible = false;

  @observable
  private _owners: Owner[] = [];

  @observable
  private _selectedOwners?: Owner[];

  @observable
  private _selectedUnit?: Unit;

  constructor(
    private readonly strataService: StrataService) {
  }

  getOwners(strataId: string, includeExternalCommittee: boolean) {

    return this.strataService.getOwners({
      strataId: strataId,
      includeExternalCommittee: includeExternalCommittee,
      showActivityIndicator: false
    }).pipe(
      startWithTap(() => this.setSpinnerVisible(true)),
      tap(owners => this.setOwners(owners)),
      finalize(() => this.setSpinnerVisible(false))
    );
  }

  @action
  private setSpinnerVisible(val: boolean) {

    this._spinnerVisible = val;
  }

  @action
  private setOwners(owners: Owner[]) {

    this._owners = owners;

    this.owners.forEach(owner => {

      owner.user.mobile = formatPhoneNumberForDisplay(owner.user.mobile, {
        removeWhiteSpace: true
      });
    });
  }

  @action
  setSelectedOwners(owners: Owner[] | undefined) {

    this._selectedOwners = owners;
  }

  @action
  setSelectedUnit(unit: Unit) {

    this._selectedUnit = unit;
  }

  @action
  setSelectedTabPanel(val: TabPanel) {

    this._selectedTabPanel = val;
  }

  get spinnerVisible() {

    return this._spinnerVisible;
  }

  get owners() {

    return this._owners;
  }

  get selectedTabPanel() {

    return this._selectedTabPanel;
  }

  get selectedOwner(): Owner | undefined {

    return this._selectedOwners && this._selectedOwners.length > 0 ? this._selectedOwners[0] : undefined;
  }

  get selectedOwners() {

    return this._selectedOwners;
  }

  get selectedUnit() {

    return this._selectedUnit;
  }
}
