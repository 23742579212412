export enum State {
  VIC = 'VIC',
  NSW = 'NSW',
  SA = 'SA',
  WA = 'WA',
  NT = 'NT',
  ACT = 'ACT',
  QLD = 'QLD',
  TAS = 'TAS'
}
