<div *mobxAutorun class="p-grid ui-fluid form-group">
  <div class="p-col-12">
    <div class="login-message-container">
      <div class="wht-login-message" [innerHTML]="store.loginMessage"></div>
    </div>
  </div>
  <div class="p-col-12" style="text-align: center">
    <button pButton type="button" label="dismiss" (click)="dismissClick.emit()"></button>
  </div>
</div>
