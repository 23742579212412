import { Injectable } from '@angular/core';
import { action, computed, observable } from 'mobx';
import { finalize, tap } from 'rxjs/operators';

import { NameValuePair, ProcessNote, ProcessNotesResponse } from '../../generated';
import { CrudOperation } from '../../models/crud-operation';
import { startWithTap } from '../../rxjs/operators';
import { WorkflowService } from '../../services/workflow.service';
import { BaseAuthenticationStore } from '../../store/base-authentication.store';
import { DialogStore } from '../../store/dialog.store';
import { MessageStore } from '../../store/message.store';

@Injectable()
export class Store {

  static readonly GeneralNVPair: NameValuePair = {
    name: 'General',
    value: ''
  };

  @observable
  private _workflowProcessId = '';

  @observable
  private _processNotesResponse?: ProcessNotesResponse;

  @observable
  private _selectedProcessNote?: ProcessNote;

  @observable
  private _crudOperation?: CrudOperation;

  @observable
  private _spinnerVisible = false;

  constructor(
    private readonly workflowService: WorkflowService,
    private readonly messageStore: MessageStore,
    private readonly diaogStore: DialogStore,
    private readonly authenticationStore: BaseAuthenticationStore) {
  }

  getProcessNotes() {

    return this.workflowService.getProcessNotes({
      workflowProcessId: this._workflowProcessId,
      showActivityIndicator: false
    }).pipe(
      startWithTap(() => this.setSpinnerVisible(true)),
      tap(response => this.setProcessNotesResponse(response)),
      finalize(() => this.setSpinnerVisible(false))
    );
  }

  @action
  private setSpinnerVisible(val: boolean) {

    this._spinnerVisible = val;
  }

  updateProcessNotes(args: {
    text: string;
    target: NameValuePair;
  }) {

    // tslint:disable-next-line: no-non-null-assertion
    const processNotes = this._processNotesResponse!.processNotes.slice();

    switch (this._crudOperation) {

      case CrudOperation.Create:

        processNotes.unshift({
          text: args.text,
          target: args.target.value ? args.target : undefined,
          createdDate: new Date(),
          createdBy: {
            id: this.authenticationStore.userId || ''
          }
        });

        break;

      case CrudOperation.Update:
        {
          const selectedNoteIndex = processNotes.findIndex(pn => pn === this._selectedProcessNote);

          processNotes[selectedNoteIndex] = {
            ...processNotes[selectedNoteIndex],
            text: args.text,
            target: args.target.value ? args.target : undefined
          };
        }

        break;

      case CrudOperation.Delete:
        {
          const selectedNoteIndex = processNotes.findIndex(pn => pn === this._selectedProcessNote);

          processNotes.splice(selectedNoteIndex, 1);
        }

        break;
    }

    return this.workflowService.updateProcessNotes({
      workflowProcessId: this._workflowProcessId,
      notes: processNotes
    }).pipe(
      tap(() => this.afterSuccessfulCrudOperation())
    );
  }

  private afterSuccessfulCrudOperation() {

    this.setSelectedProcessNote(undefined);

    this.diaogStore.hide();

    this.messageStore.pushMessage(`Process note ${this._crudOperation}d`);
  }

  @action
  setWorkflowProcessId(val: string) {

    this._workflowProcessId = val;

    this._processNotesResponse = undefined;
    this._selectedProcessNote = undefined;
  }

  @action
  private setProcessNotesResponse(processNotesResponse: ProcessNotesResponse) {

    this._processNotesResponse = processNotesResponse;

    this._selectedProcessNote = undefined;
  }

  @action
  setSelectedProcessNote(processNote: ProcessNote | undefined) {

    this._selectedProcessNote = processNote;
  }

  @action
  setCrudOperation(crudOperation: CrudOperation) {

    this._crudOperation = crudOperation;
  }

  isNoteEditable(note: ProcessNote): boolean {

    if (this.authenticationStore.isAdmin) {

      return true;
    }

    return note.createdBy.id === this.authenticationStore.userId;
  }

  get workflowProcessId() {

    return this._workflowProcessId;
  }

  get selectedProcessNote() {

    return this._selectedProcessNote;
  }

  get crudOperation() {

    return this._crudOperation;
  }

  @computed
  get processNotes(): ProcessNote[] {

    return this._processNotesResponse && this._processNotesResponse.processNotes || [];
  }

  @computed
  get processNotesTargets(): NameValuePair[] {

    if (!this._processNotesResponse || !this._processNotesResponse.targets) {

      return [];
    }

    return [
      Store.GeneralNVPair,
      ...this._processNotesResponse.targets
    ];
  }

  get spinnerVisible() {

    return this._spinnerVisible;
  }

  @computed
  get isSelectedNoteEditable(): boolean {

    return !this._selectedProcessNote || this.isNoteEditable(this._selectedProcessNote);
  }
}
