import { Directive } from '@angular/core';

import { SpinnerComponent, SpinnerSize } from '../components/spinner/spinner.component';

export const globalSpinnerContainerZindex = 10000000;

@Directive({ selector: '[appGlobalSpinner]' })
export class GlobalSpinnerDirective {

  constructor(spinner: SpinnerComponent) {

    spinner.size = SpinnerSize.Default;
    spinner.alphaBackground = true;
    spinner.absoluteFill = false;
    spinner.containerClass = 'wht-fullscreen';
    spinner.containerStyle = {
      zIndex: `${globalSpinnerContainerZindex}`
    };
  }
}
