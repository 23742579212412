/**
 * Whittles API
 * Whittles API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AccountsPayableInvoiceActionResponse } from '../model/accountsPayableInvoiceActionResponse';
import { AccountsPayableInvoiceAlert } from '../model/accountsPayableInvoiceAlert';
import { AccountsPayableInvoiceDetails } from '../model/accountsPayableInvoiceDetails';
import { AccountsPayableInvoiceSummary } from '../model/accountsPayableInvoiceSummary';
import { ApproveInvoiceParams } from '../model/approveInvoiceParams';
import { ChangePlanParams } from '../model/changePlanParams';
import { NameValuePair } from '../model/nameValuePair';
import { OnHoldInvoiceParams } from '../model/onHoldInvoiceParams';
import { OverrideCommitteeApprovalParams } from '../model/overrideCommitteeApprovalParams';
import { ProcessRejectedInvoiceParams } from '../model/processRejectedInvoiceParams';
import { RejectInvoiceParams } from '../model/rejectInvoiceParams';
import { RequestEmailApprovalParams } from '../model/requestEmailApprovalParams';
import { SecondAuthorityParams } from '../model/secondAuthorityParams';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class AccountsPayableService {

    protected basePath = 'https://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param approveInvoiceParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approveInvoice(approveInvoiceParams: ApproveInvoiceParams, observe?: 'body', reportProgress?: boolean): Observable<AccountsPayableInvoiceActionResponse>;
    public approveInvoice(approveInvoiceParams: ApproveInvoiceParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AccountsPayableInvoiceActionResponse>>;
    public approveInvoice(approveInvoiceParams: ApproveInvoiceParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AccountsPayableInvoiceActionResponse>>;
    public approveInvoice(approveInvoiceParams: ApproveInvoiceParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (approveInvoiceParams === null || approveInvoiceParams === undefined) {
            throw new Error('Required parameter approveInvoiceParams was null or undefined when calling approveInvoice.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AccountsPayableInvoiceActionResponse>(`${this.basePath}/accounts-payable/invoice/approve`,
            approveInvoiceParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param creditorInvoiceEntryHeaderId 
     * @param reason 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelInvoice(creditorInvoiceEntryHeaderId: string, reason?: string, observe?: 'body', reportProgress?: boolean): Observable<AccountsPayableInvoiceActionResponse>;
    public cancelInvoice(creditorInvoiceEntryHeaderId: string, reason?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AccountsPayableInvoiceActionResponse>>;
    public cancelInvoice(creditorInvoiceEntryHeaderId: string, reason?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AccountsPayableInvoiceActionResponse>>;
    public cancelInvoice(creditorInvoiceEntryHeaderId: string, reason?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (creditorInvoiceEntryHeaderId === null || creditorInvoiceEntryHeaderId === undefined) {
            throw new Error('Required parameter creditorInvoiceEntryHeaderId was null or undefined when calling cancelInvoice.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (creditorInvoiceEntryHeaderId !== undefined && creditorInvoiceEntryHeaderId !== null) {
            queryParameters = queryParameters.set('creditorInvoiceEntryHeaderId', <any>creditorInvoiceEntryHeaderId);
        }
        if (reason !== undefined && reason !== null) {
            queryParameters = queryParameters.set('reason', <any>reason);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<AccountsPayableInvoiceActionResponse>(`${this.basePath}/accounts-payable/invoice/cancel`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param changePlanParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changePlan(changePlanParams: ChangePlanParams, observe?: 'body', reportProgress?: boolean): Observable<AccountsPayableInvoiceActionResponse>;
    public changePlan(changePlanParams: ChangePlanParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AccountsPayableInvoiceActionResponse>>;
    public changePlan(changePlanParams: ChangePlanParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AccountsPayableInvoiceActionResponse>>;
    public changePlan(changePlanParams: ChangePlanParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (changePlanParams === null || changePlanParams === undefined) {
            throw new Error('Required parameter changePlanParams was null or undefined when calling changePlan.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AccountsPayableInvoiceActionResponse>(`${this.basePath}/accounts-payable/invoice/changePlan`,
            changePlanParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowProcessInstanceId 
     * @param emailAddress 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteEmailApproval(workflowProcessInstanceId: string, emailAddress: string, observe?: 'body', reportProgress?: boolean): Observable<AccountsPayableInvoiceActionResponse>;
    public deleteEmailApproval(workflowProcessInstanceId: string, emailAddress: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AccountsPayableInvoiceActionResponse>>;
    public deleteEmailApproval(workflowProcessInstanceId: string, emailAddress: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AccountsPayableInvoiceActionResponse>>;
    public deleteEmailApproval(workflowProcessInstanceId: string, emailAddress: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowProcessInstanceId === null || workflowProcessInstanceId === undefined) {
            throw new Error('Required parameter workflowProcessInstanceId was null or undefined when calling deleteEmailApproval.');
        }

        if (emailAddress === null || emailAddress === undefined) {
            throw new Error('Required parameter emailAddress was null or undefined when calling deleteEmailApproval.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowProcessInstanceId !== undefined && workflowProcessInstanceId !== null) {
            queryParameters = queryParameters.set('workflowProcessInstanceId', <any>workflowProcessInstanceId);
        }
        if (emailAddress !== undefined && emailAddress !== null) {
            queryParameters = queryParameters.set('emailAddress', <any>emailAddress);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<AccountsPayableInvoiceActionResponse>(`${this.basePath}/accounts-payable/invoice/delete-email-approval`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChartOfAccounts(observe?: 'body', reportProgress?: boolean): Observable<Array<NameValuePair>>;
    public getChartOfAccounts(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NameValuePair>>>;
    public getChartOfAccounts(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NameValuePair>>>;
    public getChartOfAccounts(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<NameValuePair>>(`${this.basePath}/accounts-payable/chart-of-accounts`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param portfolioId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInvoiceAlerts(portfolioId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AccountsPayableInvoiceAlert>>;
    public getInvoiceAlerts(portfolioId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AccountsPayableInvoiceAlert>>>;
    public getInvoiceAlerts(portfolioId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AccountsPayableInvoiceAlert>>>;
    public getInvoiceAlerts(portfolioId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (portfolioId === null || portfolioId === undefined) {
            throw new Error('Required parameter portfolioId was null or undefined when calling getInvoiceAlerts.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (portfolioId !== undefined && portfolioId !== null) {
            queryParameters = queryParameters.set('portfolioId', <any>portfolioId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<AccountsPayableInvoiceAlert>>(`${this.basePath}/accounts-payable/invoices/alerts`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param creditorInvoiceEntryHeaderId 
     * @param workflowProcessInstanceId 
     * @param portalType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInvoiceDetails(creditorInvoiceEntryHeaderId: string, workflowProcessInstanceId: string, portalType: string, observe?: 'body', reportProgress?: boolean): Observable<AccountsPayableInvoiceDetails>;
    public getInvoiceDetails(creditorInvoiceEntryHeaderId: string, workflowProcessInstanceId: string, portalType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AccountsPayableInvoiceDetails>>;
    public getInvoiceDetails(creditorInvoiceEntryHeaderId: string, workflowProcessInstanceId: string, portalType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AccountsPayableInvoiceDetails>>;
    public getInvoiceDetails(creditorInvoiceEntryHeaderId: string, workflowProcessInstanceId: string, portalType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (creditorInvoiceEntryHeaderId === null || creditorInvoiceEntryHeaderId === undefined) {
            throw new Error('Required parameter creditorInvoiceEntryHeaderId was null or undefined when calling getInvoiceDetails.');
        }

        if (workflowProcessInstanceId === null || workflowProcessInstanceId === undefined) {
            throw new Error('Required parameter workflowProcessInstanceId was null or undefined when calling getInvoiceDetails.');
        }

        if (portalType === null || portalType === undefined) {
            throw new Error('Required parameter portalType was null or undefined when calling getInvoiceDetails.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowProcessInstanceId !== undefined && workflowProcessInstanceId !== null) {
            queryParameters = queryParameters.set('workflowProcessInstanceId', <any>workflowProcessInstanceId);
        }
        if (portalType !== undefined && portalType !== null) {
            queryParameters = queryParameters.set('portalType', <any>portalType);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<AccountsPayableInvoiceDetails>(`${this.basePath}/accounts-payable/invoices/${encodeURIComponent(String(creditorInvoiceEntryHeaderId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param creditorInvoiceEntryHeaderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInvoiceStatus(creditorInvoiceEntryHeaderId: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getInvoiceStatus(creditorInvoiceEntryHeaderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getInvoiceStatus(creditorInvoiceEntryHeaderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getInvoiceStatus(creditorInvoiceEntryHeaderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (creditorInvoiceEntryHeaderId === null || creditorInvoiceEntryHeaderId === undefined) {
            throw new Error('Required parameter creditorInvoiceEntryHeaderId was null or undefined when calling getInvoiceStatus.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (creditorInvoiceEntryHeaderId !== undefined && creditorInvoiceEntryHeaderId !== null) {
            queryParameters = queryParameters.set('creditorInvoiceEntryHeaderId', <any>creditorInvoiceEntryHeaderId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/accounts-payable/invoice/status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param portalType 
     * @param invoiceSelector 
     * @param companyId 
     * @param portfolioId 
     * @param strataId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInvoiceSummaries(portalType: string, invoiceSelector?: string, companyId?: string, portfolioId?: string, strataId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AccountsPayableInvoiceSummary>>;
    public getInvoiceSummaries(portalType: string, invoiceSelector?: string, companyId?: string, portfolioId?: string, strataId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AccountsPayableInvoiceSummary>>>;
    public getInvoiceSummaries(portalType: string, invoiceSelector?: string, companyId?: string, portfolioId?: string, strataId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AccountsPayableInvoiceSummary>>>;
    public getInvoiceSummaries(portalType: string, invoiceSelector?: string, companyId?: string, portfolioId?: string, strataId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (portalType === null || portalType === undefined) {
            throw new Error('Required parameter portalType was null or undefined when calling getInvoiceSummaries.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (portalType !== undefined && portalType !== null) {
            queryParameters = queryParameters.set('portalType', <any>portalType);
        }
        if (invoiceSelector !== undefined && invoiceSelector !== null) {
            queryParameters = queryParameters.set('invoiceSelector', <any>invoiceSelector);
        }
        if (companyId !== undefined && companyId !== null) {
            queryParameters = queryParameters.set('companyId', <any>companyId);
        }
        if (portfolioId !== undefined && portfolioId !== null) {
            queryParameters = queryParameters.set('portfolioId', <any>portfolioId);
        }
        if (strataId !== undefined && strataId !== null) {
            queryParameters = queryParameters.set('strataId', <any>strataId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<AccountsPayableInvoiceSummary>>(`${this.basePath}/accounts-payable/invoices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getJournalChartOfAccounts(observe?: 'body', reportProgress?: boolean): Observable<Array<NameValuePair>>;
    public getJournalChartOfAccounts(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NameValuePair>>>;
    public getJournalChartOfAccounts(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NameValuePair>>>;
    public getJournalChartOfAccounts(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<NameValuePair>>(`${this.basePath}/accounts-payable/journal-chart-of-accounts`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param creditorInvoiceEntryHeaderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public initiateInvoiceApproval(creditorInvoiceEntryHeaderId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public initiateInvoiceApproval(creditorInvoiceEntryHeaderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public initiateInvoiceApproval(creditorInvoiceEntryHeaderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public initiateInvoiceApproval(creditorInvoiceEntryHeaderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (creditorInvoiceEntryHeaderId === null || creditorInvoiceEntryHeaderId === undefined) {
            throw new Error('Required parameter creditorInvoiceEntryHeaderId was null or undefined when calling initiateInvoiceApproval.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (creditorInvoiceEntryHeaderId !== undefined && creditorInvoiceEntryHeaderId !== null) {
            queryParameters = queryParameters.set('creditorInvoiceEntryHeaderId', <any>creditorInvoiceEntryHeaderId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/accounts-payable/invoice/initiate-approval`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param overrideCommitteeApprovalParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public overrideCommitteeApproval(overrideCommitteeApprovalParams: OverrideCommitteeApprovalParams, observe?: 'body', reportProgress?: boolean): Observable<AccountsPayableInvoiceActionResponse>;
    public overrideCommitteeApproval(overrideCommitteeApprovalParams: OverrideCommitteeApprovalParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AccountsPayableInvoiceActionResponse>>;
    public overrideCommitteeApproval(overrideCommitteeApprovalParams: OverrideCommitteeApprovalParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AccountsPayableInvoiceActionResponse>>;
    public overrideCommitteeApproval(overrideCommitteeApprovalParams: OverrideCommitteeApprovalParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (overrideCommitteeApprovalParams === null || overrideCommitteeApprovalParams === undefined) {
            throw new Error('Required parameter overrideCommitteeApprovalParams was null or undefined when calling overrideCommitteeApproval.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AccountsPayableInvoiceActionResponse>(`${this.basePath}/accounts-payable/invoice/override-committee-approval`,
            overrideCommitteeApprovalParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param processRejectedInvoiceParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public processRejectedInvoice(processRejectedInvoiceParams: ProcessRejectedInvoiceParams, observe?: 'body', reportProgress?: boolean): Observable<AccountsPayableInvoiceActionResponse>;
    public processRejectedInvoice(processRejectedInvoiceParams: ProcessRejectedInvoiceParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AccountsPayableInvoiceActionResponse>>;
    public processRejectedInvoice(processRejectedInvoiceParams: ProcessRejectedInvoiceParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AccountsPayableInvoiceActionResponse>>;
    public processRejectedInvoice(processRejectedInvoiceParams: ProcessRejectedInvoiceParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (processRejectedInvoiceParams === null || processRejectedInvoiceParams === undefined) {
            throw new Error('Required parameter processRejectedInvoiceParams was null or undefined when calling processRejectedInvoice.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AccountsPayableInvoiceActionResponse>(`${this.basePath}/accounts-payable/invoice/process-rejected`,
            processRejectedInvoiceParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param rejectInvoiceParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rejectInvoice(rejectInvoiceParams: RejectInvoiceParams, observe?: 'body', reportProgress?: boolean): Observable<AccountsPayableInvoiceActionResponse>;
    public rejectInvoice(rejectInvoiceParams: RejectInvoiceParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AccountsPayableInvoiceActionResponse>>;
    public rejectInvoice(rejectInvoiceParams: RejectInvoiceParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AccountsPayableInvoiceActionResponse>>;
    public rejectInvoice(rejectInvoiceParams: RejectInvoiceParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (rejectInvoiceParams === null || rejectInvoiceParams === undefined) {
            throw new Error('Required parameter rejectInvoiceParams was null or undefined when calling rejectInvoice.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AccountsPayableInvoiceActionResponse>(`${this.basePath}/accounts-payable/invoice/reject`,
            rejectInvoiceParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param creditorInvoiceEntryHeaderId 
     * @param reason 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reprocessRejectedInvoice(creditorInvoiceEntryHeaderId: string, reason?: string, observe?: 'body', reportProgress?: boolean): Observable<AccountsPayableInvoiceActionResponse>;
    public reprocessRejectedInvoice(creditorInvoiceEntryHeaderId: string, reason?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AccountsPayableInvoiceActionResponse>>;
    public reprocessRejectedInvoice(creditorInvoiceEntryHeaderId: string, reason?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AccountsPayableInvoiceActionResponse>>;
    public reprocessRejectedInvoice(creditorInvoiceEntryHeaderId: string, reason?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (creditorInvoiceEntryHeaderId === null || creditorInvoiceEntryHeaderId === undefined) {
            throw new Error('Required parameter creditorInvoiceEntryHeaderId was null or undefined when calling reprocessRejectedInvoice.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (creditorInvoiceEntryHeaderId !== undefined && creditorInvoiceEntryHeaderId !== null) {
            queryParameters = queryParameters.set('creditorInvoiceEntryHeaderId', <any>creditorInvoiceEntryHeaderId);
        }
        if (reason !== undefined && reason !== null) {
            queryParameters = queryParameters.set('reason', <any>reason);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<AccountsPayableInvoiceActionResponse>(`${this.basePath}/accounts-payable/invoice/reprocess-rejected`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param requestEmailApprovalParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestEmailApproval(requestEmailApprovalParams: RequestEmailApprovalParams, observe?: 'body', reportProgress?: boolean): Observable<AccountsPayableInvoiceActionResponse>;
    public requestEmailApproval(requestEmailApprovalParams: RequestEmailApprovalParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AccountsPayableInvoiceActionResponse>>;
    public requestEmailApproval(requestEmailApprovalParams: RequestEmailApprovalParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AccountsPayableInvoiceActionResponse>>;
    public requestEmailApproval(requestEmailApprovalParams: RequestEmailApprovalParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (requestEmailApprovalParams === null || requestEmailApprovalParams === undefined) {
            throw new Error('Required parameter requestEmailApprovalParams was null or undefined when calling requestEmailApproval.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AccountsPayableInvoiceActionResponse>(`${this.basePath}/accounts-payable/invoice/request-email-approval`,
            requestEmailApprovalParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param secondAuthorityParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setSecondAuthority(secondAuthorityParams: SecondAuthorityParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setSecondAuthority(secondAuthorityParams: SecondAuthorityParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setSecondAuthority(secondAuthorityParams: SecondAuthorityParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setSecondAuthority(secondAuthorityParams: SecondAuthorityParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (secondAuthorityParams === null || secondAuthorityParams === undefined) {
            throw new Error('Required parameter secondAuthorityParams was null or undefined when calling setSecondAuthority.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/accounts-payable/invoice/second-authority`,
            secondAuthorityParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param onHoldInvoiceParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateInvoiceOnHold(onHoldInvoiceParams: OnHoldInvoiceParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateInvoiceOnHold(onHoldInvoiceParams: OnHoldInvoiceParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateInvoiceOnHold(onHoldInvoiceParams: OnHoldInvoiceParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateInvoiceOnHold(onHoldInvoiceParams: OnHoldInvoiceParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (onHoldInvoiceParams === null || onHoldInvoiceParams === undefined) {
            throw new Error('Required parameter onHoldInvoiceParams was null or undefined when calling updateInvoiceOnHold.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/accounts-payable/invoice/on-hold`,
            onHoldInvoiceParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
