import { Directive, Input } from '@angular/core';
import { repeat } from 'lodash-es';
import { Dropdown } from 'primeng/dropdown';

export enum DropdownKind {
  AccountCode
}

export const dropdownSeparatorLabel = repeat('-', 20);
export const dropdownSeparatorId = '-1';

// tslint:disable-next-line:directive-selector
@Directive({ selector: 'p-dropdown' })
export class DropdownDirective {

  @Input() set kind(val: DropdownKind) {

    switch (val) {

      case DropdownKind.AccountCode:

        this.dropdown.placeholder = 'Select Account';
        this.dropdown.filter = true;
        this.dropdown.filterBy = 'value.value,value.name';
        this.dropdown.resetFilterOnHide = true;
        // this.dropdown.virtualScroll = true;
        // this.dropdown.itemSize = 35.5;

        break;
    }
  }

  @Input() set placeholder(val: string | undefined) {

    if (val) {

      // @ts-ignore
      this.dropdown.placeholder = val.toTitleCase();
    }
  }

  constructor(private readonly dropdown: Dropdown) {
  }
}
