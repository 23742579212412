<div *mobxAutorun class="topbar clearfix">
  <div class="topbar-left" style="padding: 10px;">
    <div class="wht-logo-container" fxLayoutAlign="center center">
      <img class="wht-logo"
        src="/assets/images/whittles-logo.svg" />
    </div>
  </div>

  <div class="wht-app-topbar-container" fxLayoutAlign="end center" fxLayoutGap="10px">
    <div fxLayoutAlign="start center" fxLayoutGap="10px">
      <div [appHide]="appStore.isMqAliasLtMd">{{localisationStore.unitLabel}} {{domainStore.selectedUnitBuildingTitle}}</div>
      <a href="#" (click)="onMenuOption($event, MenuOption.SelectUnitBuilding)">
        <i class="fas {{store.selectUnitBuildingIcon}}"></i>
      </a>
      <a href="#" (click)="app.onMenuButtonClick($event)">
        <i class="far fa-ellipsis-h"></i>
      </a>
    </div>
  </div>
</div>
