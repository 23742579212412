import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { BaseComponent } from '../base/base.component';

import { Store } from './owner-account-history.store';

@Component({
  selector: 'app-owner-account-history',
  templateUrl: './owner-account-history.component.html',
  styleUrls: ['./owner-account-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Store]
})
export class OwnerAccountHistoryComponent extends BaseComponent implements OnInit {

  constructor(readonly store: Store) {

    super();
  }

  ngOnInit(): void {
  }
}
