import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { saveAs } from 'file-saver';

import { FileData } from '../generated';

import { MediaType } from '../models/media-type';
import { ConfirmationStore } from '../store/confirmation.store';
import { base64toBlob } from '../utils/file.utils';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private readonly confirmationStore: ConfirmationStore) {
  }

  save(params: {
    fileData: FileData;
    type?: string;
    contentType?: string
  }): void {

    // post-pend a .pdf is a sm_document
    let filename = params.fileData.filename;

    let contentType = params.contentType;

    if (filename.split('.').length === 1 || params.type === 'DOCUMENT') {

      filename += '.pdf';

      if (!contentType) {

        contentType = MediaType.Pdf;
      }
    }

    saveAs(base64toBlob(params.fileData.data, contentType), filename);
  }

  readAsDataURL(file: File): Observable<string> {

    return new Observable(obs => {

      const fileReader = new FileReader();

      fileReader.onload = () => {

        obs.next(fileReader.result as string);

        obs.complete();
      };

      fileReader.onerror = () => {

        this.confirmationStore.confirm({
          header: 'error reading file',
          icon: 'ui-icon-error',
          message: `The file "${file.name}" could not be read.`
        });

        obs.error(fileReader.error);
      };

      fileReader.readAsDataURL(file);

      return () => fileReader.abort();
    });
  }
}
