import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'titleCase' })
export class TitleCasePipe implements PipeTransform {

  transform(text: any) {

    if (!text || typeof text !== 'string') {

      return text;
    }

    // @ts-ignore
    return text.toTitleCase();
  }
}
