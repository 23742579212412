import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-more-indicator',
  templateUrl: './more-indicator.component.html',
  styleUrls: ['./more-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoreIndicatorComponent extends BaseComponent {

  @Input() label = '';
  @Input() parentheses = false;
  @Input() truncateLabel = false;

  @Output() readonly moreClick = new EventEmitter<Event>();

  get leftLabel(): string {

    return `${this.parentheses ? '(' : ''}${this.label}`;
  }

  get rightLabel(): string {

    return `${this.parentheses ? ')' : ''}`;
  }
}
