import { BreakpointObserver } from '@angular/cdk/layout';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import {
  ActivatedRoute,
  Event as RouterEvent,
  NavigationEnd,
  NavigationStart,
  Params,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router
} from '@angular/router';
import { action, computed, observable } from 'mobx';

import { Environment } from '../environment';
import { MqAlias } from '../models/mq-alias';
import { Orientation } from '../models/orientation';
import { RouteData } from '../models/route-data';
import { RoutePath } from '../route-path';
import { Logger } from '../services/logger.service';

export enum RouteRefreshUrlMatch {
  None,
  Exact,
  Pathname
}

export abstract class BaseAppStore {

  @observable
  private _activityIndicator = {
    showCount: 0
  };

  @observable
  private _mediaChanges: MediaChange[] = [];

  @observable
  private _logoutInvoked = false;

  // Store the URL so we can redirect after logging in
  @observable
  private _redirectUrl = '';

  // The URL to return to refreshing the same route
  @observable
  private _refreshUrl = '';

  // Current url
  @observable
  private _url = '';

  @observable
  private _orientation: Orientation;

  @observable
  private _pageNotFound = false;

  @observable
  private _displayBackgroundSplash = false;

  @observable
  private _routeRefreshUrlMatch = RouteRefreshUrlMatch.Pathname;

  @observable
  private _routeData?: RouteData;

  constructor(
    protected readonly logger: Logger,
    protected readonly router: Router,
    protected readonly mediaObserver: MediaObserver,
    protected readonly breakpointObserver: BreakpointObserver,
    protected readonly environment: Environment) {

    // tslint:disable-next-line: rxjs-no-ignored-subscription
    this.router.events.subscribe(e => this.setRouteEvent(e));

    // tslint:disable-next-line: rxjs-no-ignored-subscription
    this.mediaObserver.asObservable().subscribe(mediaChanges => this.setMediaChanges(mediaChanges));

    this.breakpointObserver.observe([
      '(orientation: portrait)'
      // tslint:disable-next-line: rxjs-no-ignored-subscription
    ]).subscribe(breakpointState => {

      if (breakpointState.matches) {

        this.setOrientation(Orientation.Portrait);
      }
    });

    this.breakpointObserver.observe([
      '(orientation: landscape)'
      // tslint:disable-next-line: rxjs-no-ignored-subscription
    ]).subscribe(breakpointState => {

      if (breakpointState.matches) {

        this.setOrientation(Orientation.Landscape);
      }
    });
  }

  static getPathnameFromRelativeUrl(urlStr: string, params: Params = {}): string {
    const url = new URL(urlStr, `${location.protocol}//${location.host}`);

    for (let key in params) {
      let value = params[key];
      url.searchParams.append(key, value);
    }

    return url.href;
  }

  @action
  private setMediaChanges(mediaChanges: MediaChange[]) {

    this._mediaChanges = mediaChanges;
  }

  @action
  private setOrientation(value: Orientation) {

    this._orientation = value;
  }

  private shouldRefreshCurrentRoute(navigatingToUrl: string): boolean {

    switch (this.routeRefreshUrlMatch) {

      case RouteRefreshUrlMatch.None:

        return false;

      case RouteRefreshUrlMatch.Exact:

        if (this.url === navigatingToUrl) {

          return true;
        }

        break;

      case RouteRefreshUrlMatch.Pathname:

        if (BaseAppStore.getPathnameFromRelativeUrl(this.url) === BaseAppStore.getPathnameFromRelativeUrl(navigatingToUrl)) {

          return true;
        }

        break;
    }

    return false;
  }

  @action
  private setRouteEvent(routerEvent: RouterEvent) {

    if (routerEvent instanceof NavigationStart) {

      try {

        if (this.shouldRefreshCurrentRoute(routerEvent.url)) {

          // Capture the URL that is being navigated to
          this._refreshUrl = routerEvent.url;

          this.router.navigateByUrl(`/${RoutePath.Refresh}`, {
            skipLocationChange: true
          });
        }

      } catch (e) {

        this.logger.warn('BaseAppStore.setRouteEvent exception caught invoking shouldRefreshCurrentRoute', this.url, routerEvent.url, e);
      }

    } else if (routerEvent instanceof RouteConfigLoadStart) {

      this.showActivityIndicator({
        flag: true
      });

    } else if (routerEvent instanceof RouteConfigLoadEnd) {

      this.showActivityIndicator({
        flag: false
      });

    } else if (routerEvent instanceof NavigationEnd) {

      const navigation = this.router.getCurrentNavigation();

      this._routeData = navigation?.extras?.state as RouteData;

      this._url = routerEvent.urlAfterRedirects;

      // Reset to RouteRefreshUrlMatch.Pathname at the end of navigation
      if (this._routeRefreshUrlMatch !== RouteRefreshUrlMatch.None) {

        this._routeRefreshUrlMatch = RouteRefreshUrlMatch.Pathname;
      }
    }
  }

  @action
  setRouteData(routeData: RouteData | undefined) {

    this._routeData = routeData;
  }

  @action
  showActivityIndicator(params: {
    flag: boolean;
    force?: boolean;
  }) {

    if (!params.flag && params.force) {

      this._activityIndicator.showCount = 0;

    } else {

      this._activityIndicator.showCount = params.flag ?
        this._activityIndicator.showCount + 1 : this._activityIndicator.showCount - 1;
    }

    if (!this.environment.production) {

      console.assert(this._activityIndicator.showCount >= 0,
        `UiStore.activityIndicator.showCount should not be negative: (${this._activityIndicator.showCount})`);
    }
  }

  @action
  setRedirectUrl(redirectUrl: string) {

    this._redirectUrl = redirectUrl;
  }

  @action
  setPageNotFound(pageNotFound: boolean) {

    this._pageNotFound = pageNotFound;
  }

  @action
  setDisplayBackgroundSplash(displayBackgroundSplash: boolean) {

    this._displayBackgroundSplash = displayBackgroundSplash;
  }

  @action
  flagLogoutInvoked() {

    this._logoutInvoked = true;
  }

  redirectAfterValidAuthentication() {

    this.router.navigateByUrl(this.redirectUrl || '/', {
      replaceUrl: true
    });

    this.setRedirectUrl('');
  }

  refreshCurrentRoute() {

    this.router.navigateByUrl(this.url);
  }

  @action
  setRouteRefreshUrlMatch(routeRefreshUrlMatch: RouteRefreshUrlMatch) {

    this._routeRefreshUrlMatch = routeRefreshUrlMatch;
  }

  @action
  removeQueryParams(args: {
    activatedRoute: ActivatedRoute;
    paramsToRemove: string[];
  }) {

    // Set RouteRefreshUrlMatch.Exact so we don't get a route refresh when removing qurey params
    this._routeRefreshUrlMatch = RouteRefreshUrlMatch.Exact;

    const nullQueryParams = args.paramsToRemove.reduce((prev, curr) => {

      return {
        ...prev,
        [curr]: null
      };

    }, {});

    // Remove query params
    this.router.navigate([], {
      relativeTo: args.activatedRoute,
      replaceUrl: true,
      queryParams: nullQueryParams,
      queryParamsHandling: 'merge'
    });
  }

  getMediaChangesByMqAlias(mqAlias: MqAlias): MediaChange[] {

    return this._mediaChanges.filter(mediaChanges => mediaChanges.matches && mediaChanges.mqAlias === mqAlias);
  }

  @computed
  get activityIndicatorVisible(): boolean {

    return this._activityIndicator.showCount > 0;
  }

  @computed
  get isMqAliasTabletOrMobile(): boolean {

    return this.getMediaChangesByMqAlias(MqAlias.LtLg).length > 0;
  }

  @computed
  get isMqAliasLtMd(): boolean {

    return this.getMediaChangesByMqAlias(MqAlias.LtMd).length > 0;
  }

  @computed
  get isMqAliasGtSm(): boolean {

    return this.getMediaChangesByMqAlias(MqAlias.GtSm).length > 0;
  }

  get logoutInvoked() {

    return this._logoutInvoked;
  }

  get redirectUrl() {

    return this._redirectUrl;
  }

  get refreshUrl() {

    return this._refreshUrl;
  }

  get url() {

    return this._url;
  }

  get orientation() {

    return this._orientation;
  }

  get pageNotFound() {

    return this._pageNotFound;
  }

  get displayBackgroundSplash() {

    return this._displayBackgroundSplash;
  }

  get routeRefreshUrlMatch() {

    return this._routeRefreshUrlMatch;
  }

  get routeData() {

    return this._routeData;
  }
}
