const REGEXP_NEWLINE = /\\n|\n/g;

export function splitNewlines(s: string | undefined): string[] {

  if (!s) {

    return [];
  }

  return s.split(REGEXP_NEWLINE);
}

export function replaceNewlines(s: string, replaceValue = '<br>'): string {

  if (!s) {

    return s;
  }

  return s.replace(REGEXP_NEWLINE, replaceValue);
}

export function isBlank(s: string | undefined): boolean {

  return !s || !s.trim();
}

export function toTitleCase(s: string, args?: {
  stripUnderscore?: boolean;
}): string {

  if (!s) {

    return s;
  }

  const stripUnderscore = !args || args.stripUnderscore;

  let temp = s;

  if (stripUnderscore) {

    temp = temp.replace(/_/g, ' ');
  }
  return temp.replace(/\b([a-z])/g, (_, letter)=> letter.toUpperCase());
}
// @ts-ignore
String.prototype.toTitleCase = function(){ return toTitleCase(String(this));}

export function toSentenceCase(s: string): string {

  const result = s.replace(/([A-Z])/g, ' $1');

  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function toPretty(str: string) {
  const result: string[] = str.toLowerCase().replace('_', ' ').split(' ');
  result.forEach((w: string, index: number) => result[index] = toTitleCase(w));
  return result.join(' ');
}

export function toPrettyFixDash(str: string) {
  return toPretty(str).split('-').join(' ');
}

export function removeLeadingZeros(inputStr: string | undefined): string {
  if (inputStr == null || !inputStr.trim().length) return '';

  // Use parseInt to attempt parsing the input string as an integer
  const number = parseInt(inputStr, 10);

  // Check if the parsed result is NaN (not a number) or if it's followed by non-numeric characters
  if (isNaN(number) || number !== Number(inputStr)) {
    return inputStr; // Return the original input if it's not a valid number
  }

  // Convert the number back to a string
  return number.toString();
}
