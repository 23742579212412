export enum RoutePath {
  Home = 'home',
  SelectUnit = 'select-unit',
  SelectBuilding = 'select-building',
  Profile = 'profile',
  Contact = 'contact',
  PlanInfo = 'plan-info',
  PlanSummary = 'plan-summary',
  MakePayment = 'make-payment',
  AccountHistory = 'account-history',
  Invoices = 'invoices',
  Documents = 'documents',
  CommitteeApproval = 'committee-approval',
  FinancialRecords = 'financial-records',
  FinancialTransactions = 'financial-transactions'
}
