import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent, LoginType } from 'common-lib';

import { RoutePath } from '../../route-path';
import { AuthenticationStore } from '../../stores/authentication.store';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InitComponent extends BaseComponent implements OnInit {

  constructor(
    private readonly router: Router,
    private readonly authenticationStore: AuthenticationStore) {

    super();
  }

  ngOnInit(): void {

    const initialUrl = this.authenticationStore.loginType === LoginType.BUILDING ? [RoutePath.SelectBuilding] : [RoutePath.Home];

    this.router.navigate(initialUrl, {
      replaceUrl: true
    });
  }
}
