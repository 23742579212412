<div class="card" [class.card-w-title]="title">
  <div *ngIf="showCovid">
    <app-covid></app-covid>
  </div>
  <div *ngIf="showNotification">
    <app-owner-notification></app-owner-notification>
  </div>
  <div fxLayoutAlign="space-between stretch">
    <app-card-header *ngIf="title">{{title | titleCase}}</app-card-header>
    <ng-container *ngIf="showDropdownContainer">
        <!-- *ngIf="showGlobalCheckBox" -->

      <div class="header-dropdown-container">
        <div *ngIf="showGlobalCheckbox">
          <span class="header-dropdown-title">Show Global Only</span>
          <p-checkbox [(ngModel)]="showGlobal" [binary]="true" inputId="binary" (onChange)="checkboxOnChange($event)"></p-checkbox>
        </div>
        <div style="min-width: 18em;" >
          <span class="header-dropdown-title">Company: </span>
          <p-dropdown [optionLabel]="field" [ngModel]="selectedDropdownItem" [options]="options" #companyDropdown
            (onChange)="dropdownOnChange($event)" [disabled]="companyDisable">
          </p-dropdown>
        </div>
      </div>
    </ng-container>
    <div *ngIf="helpIconVisible" class="help-icon-container">
      <app-help-icon [tags]="helpTags" [helpIcon]="helpIcon"></app-help-icon>
    </div>
  </div>
  <ng-content></ng-content>
</div>
