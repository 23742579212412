
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
  Configuration,
  BudgetService as BudgetServiceGenerated
} from '../generated';

import { BaseAppProperties } from '../base-app-properties';

@Injectable({
  providedIn: 'root'
})
export class BudgetService {

  private readonly budgetServiceGenerated: BudgetServiceGenerated;

  constructor(httpClient: HttpClient, appProperties: BaseAppProperties) {

    this.budgetServiceGenerated = new BudgetServiceGenerated(httpClient, appProperties.irisServerBasePath, new Configuration());
  }

  getBudgetAndActualTotalsForAccount(params: {
    strataId: string;
    accountNumber: string;
    fundCode: string;
  }) {
    return this.budgetServiceGenerated.getBudgetAndActualTotalsForAccount(params.strataId, params.accountNumber, params.fundCode);
  }

  getBudgetLevies(params: {
    strataFinancialYearId: string;
  }) {
    return this.budgetServiceGenerated.getBudgetLevies(params.strataFinancialYearId);
  }

  getBudgetAccounts(params: {
    strataId: string;
  }) {
    return this.budgetServiceGenerated.getBudgetAccounts(params.strataId);
  }


}
