import { Component, ChangeDetectionStrategy, Input, Inject } from '@angular/core';

import { BaseComponent } from '../base/base.component';
import { PortalType } from '../../models/portal-type';
import { BaseAppProperties } from '../../base-app-properties';

@Component({
  selector: 'app-card-header',
  templateUrl: './card-header.component.html',
  styleUrls: ['./card-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardHeaderComponent extends BaseComponent {

  readonly PortalType = PortalType;

  constructor(readonly appProperties: BaseAppProperties) {

    super();
  }
}
