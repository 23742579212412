/**
 * Whittles API
 * Whittles API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type JournalAdviceStatus = 'DRAFT' | 'PROCESSED' | 'SUBMITTED';

export const JournalAdviceStatus = {
    DRAFT: 'DRAFT' as JournalAdviceStatus,
    PROCESSED: 'PROCESSED' as JournalAdviceStatus,
    SUBMITTED: 'SUBMITTED' as JournalAdviceStatus
};
