<div *mobxAutorun class="p-grid">
  <div class="p-col-12">
    <app-info-message message="Select the relevant building."></app-info-message>
  </div>
  <div class="p-col-12">
    <app-table selectionMode="single" [showCaption]="showHeader" [value]="store.buildings"
      [selection]="store.selectedBuilding" (selectionChange)="store.setSelectedBuilding($event)"
      (rowSelect)="onBuildingSelected()" (rowUnselect)="onBuildingUnselected()">
      <ng-template pTemplate="caption">Selected Building - {{store.selectedBuildingTitle}}</ng-template>
      <ng-template pTemplate="header">
        <tr appTableRow>
          <th [appTableHeader]="buildingPropertyState" title="state" [filter]="TableHeaderFilterType.Input"
            pSortableColumn></th>
          <th [appTableHeader]="buildingPropertyCompany" title="company" [filter]="TableHeaderFilterType.Input"
            pSortableColumn></th>
          <th [appTableHeader]="buildingPropertyPlanName" title="plan" [filter]="TableHeaderFilterType.Input"
            pSortableColumn></th>
          <th [appTableHeader]="buildingPropertyAddress" title="building" [filter]="TableHeaderFilterType.Input"
            pSortableColumn></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-building>
        <tr appTableRow [pSelectableRow]="building">
          <td appTableData>{{getBuildingState(building)}}</td>
          <td appTableData>{{getBuildingCompany(building)}}</td>
          <td appTableData>{{getBuildingPlanName(building)}}</td>
          <td appTableData>{{getBuildingAddress(building)}}</td>
        </tr>
      </ng-template>
    </app-table>
  </div>
</div>
