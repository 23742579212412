import { action, computed, observable } from 'mobx';

import { BaseAppProperties } from '../base-app-properties';
import { HelpTag } from '../models/help-tag';
import { HelpVideo } from '../models/help-video';
import { addHours, isPast } from '../utils/date-time.utils';
import { getFromLocalStorage, setInLocalStorage } from '../utils/storage.utils';

interface HelpVideoReference {
  readonly title: string;
  readonly date: Date;
}

const VIDEO_AGE_EXPIRY_HOURS = 24 * 7;

export abstract class BaseHelpStore {

  @observable
  private _newHelpVideosCount = 0;

  protected abstract getVideos(): HelpVideo[];

  constructor(protected readonly appProperties: BaseAppProperties) {

    this.updateNewHelpVideosCount();
  }

  @action
  private updateNewHelpVideosCount() {

    const previousNewHelpVideoReferences = getFromLocalStorage<HelpVideoReference[]>(this.helpVideosLocalStorageKey);

    const updatedNewHelpVideoReferences: HelpVideoReference[] = [];

    this.videos.filter(video => video.tags.includes(HelpTag.New)).forEach(newVideo => {

      const previousNewHelpVideoReference = previousNewHelpVideoReferences &&
        previousNewHelpVideoReferences.find(videoRef => videoRef.title === newVideo.title);

      if (!previousNewHelpVideoReference) {

        updatedNewHelpVideoReferences.push({
          title: newVideo.title,
          date: new Date()
        });

        this._newHelpVideosCount += 1;

      } else {

        updatedNewHelpVideoReferences.push(previousNewHelpVideoReference);

        if (!this.isExistingHelpVideoExpired(previousNewHelpVideoReference)) {

          this._newHelpVideosCount += 1;
        }
      }
    });

    setInLocalStorage(this.helpVideosLocalStorageKey, updatedNewHelpVideoReferences);
  }

  private isExistingHelpVideoExpired(helpVideoReference: HelpVideoReference): boolean {

    return (isPast(addHours(helpVideoReference.date, VIDEO_AGE_EXPIRY_HOURS)));
  }

  private get helpVideosLocalStorageKey() {

    return `${this.appProperties.localStoragePrefix}.help_videos`;
  }

  @computed
  get videos(): HelpVideo[] {

    return this.getVideos();
  }

  get newHelpVideosCount(): number {

    return this._newHelpVideosCount;
  }
}
