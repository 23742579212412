import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FilterMetadata, SelectItem } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { MultiSelect } from 'primeng/multiselect';
import { Table } from 'primeng/table';
import { TreeTable } from 'primeng/treetable';

import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-dropdown-filter',
  templateUrl: './dropdown-filter.component.html',
  styleUrls: ['./dropdown-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownFilterComponent extends BaseComponent implements OnInit, AfterViewInit {

  @ViewChild(MultiSelect) readonly multiSelectFilter?: MultiSelect;
  @ViewChild(Dropdown) readonly dropdownFilter?: Dropdown;

  // tslint:disable-next-line: no-input-rename
  @Input('filter') _filter?: boolean;

  // tslint:disable-next-line: no-input-rename
  @Input('resetFilterOnHide') _resetFilterOnHide?: boolean;

  @Input() defaultLabel = 'All';
  @Input() multiSelect = false;
  @Input() filterBy = 'label';
  @Input() options: SelectItem[] = [];
  @Input() field: string;
  @Input() table?: Table;
  @Input() treeTable?: TreeTable;
  @Input() customFilter = false;
  @Input() appendFilterTo: unknown;

  @Output() readonly customFilterValueChange = new EventEmitter();

  multiSelectValue: any;

  constructor(private readonly cdr: ChangeDetectorRef) {

    super();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    this.restoreFilterState();
  }

  refresh() {

    this.restoreFilterState();
  }

  // primeNG table filters [s: string]: FilterMetadata | FilterMetadata[]
  private getFilterMetadata(): FilterMetadata | FilterMetadata[] {

    if (this.table) {
      return this.table.filters[this.field];
    }

    if (this.treeTable) {

      return this.treeTable.filters[this.field];
    }

    throw new Error('DropdownFilterComponent.filterMetadata: table and treeTable both null');
  }

  private restoreFilterState() {

    const filterMetadata = this.getFilterMetadata();

    let filterValue: any;
    if (!Array.isArray(filterMetadata)) {
      filterValue = filterMetadata && filterMetadata.value;
    } else {
      filterValue = filterMetadata.map(f => f.value!);
    }

    if (this.multiSelectFilter) {

      this.multiSelectFilter.writeValue(filterValue);
    }

    if (this.dropdownFilter) {

      this.dropdownFilter.writeValue(filterValue);
    }

    this.cdr.detectChanges();
  }

  onChange(evt: {
    value: any;
  }) {

    const matchMode = this.multiSelect ? 'in' : 'equals';

    if (this.customFilter) {

      this.customFilterValueChange.emit(evt.value);

    } else if (this.table) {

      this.table.filter(evt.value, this.field, matchMode);

    } else if (this.treeTable) {

      this.treeTable.filter(evt.value, this.field, matchMode);
    }
  }

  onClick(evt: Event) {

    evt.stopPropagation();
  }

  onClear(evt: Event) {

    evt.stopPropagation();
  }

  get filter(): boolean {

    return this._filter == null ? this.multiSelect : this._filter;
  }

  get resetFilterOnHide(): boolean {

    if (!this.filter) {

      return false;
    }

    return this._resetFilterOnHide == null ? true : this._resetFilterOnHide;
  }

  get appendTo() {

    if (this.appendFilterTo) {

      return this.appendFilterTo;
    }

    return this.table || this.treeTable || 'body';
  }
}
