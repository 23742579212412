import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of, OperatorFunction, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { showHttpErrorDialog } from '../../utils/error.utils';

export function switchMapCatch<T, R>(project: (value: T, index: number) => Observable<R>): OperatorFunction<T, any> {

  return switchMap((value, index) => {

    return project(value, index).pipe(
      catchError(error => {

        if (error instanceof HttpErrorResponse) {

          showHttpErrorDialog({
            error,
            remoteLog: true
          });

          return of(error);
        }

        return throwError(error);
      })
    );
  });
}

