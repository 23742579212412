<p-multiSelect *ngIf="multiSelect else dropdown" [defaultLabel]="defaultLabel" [options]="options"
  (onChange)="onChange($event)" (onClick)="onClick($event)" [filter]="filter" [resetFilterOnHide]="resetFilterOnHide"
  [filterBy]="filterBy" [appendTo]="appendTo"></p-multiSelect>

<ng-template #dropdown>
  <p-dropdown [style]="{width: '100%'}" [options]="options" (onChange)="onChange($event)" (onClick)="onClick($event)"
    [filter]="filter" [resetFilterOnHide]="resetFilterOnHide" [filterBy]="filterBy" 
    [appendTo]="appendTo" (onClear)="onClear($event)"
    [showClear]="true"  [placeholder]="defaultLabel"></p-dropdown>
</ng-template>
