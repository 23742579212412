import { Injectable } from '@angular/core';
import { AuthenticationService, GoogleRecaptchaSiteVerifyResponse, Logger, WindowRef } from 'common-lib';
import { action, observable } from 'mobx';
import { from, Observable, of } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';

import { AppStore } from './app.store';

@Injectable({
  providedIn: 'root'
})
export class GoogleRecaptchaStore {

  private static readonly emptyResponse: GoogleRecaptchaSiteVerifyResponse = {
    success: false,
    score: 0,
    action: '',
    challengeTs: new Date(),
    hostname: '',
    errorCodes: []
  };

  private static readonly onLoadCallbackName = '__onRecaptchaLoadCallback';

  private static readonly siteKey = '6LfL458UAAAAAPZ56Flz9D6sfPipaFfoQa7RZq6X';

  @observable
  private _initialised = false;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly appStore: AppStore,
    private readonly logger: Logger,
    private readonly windowRef: WindowRef) {

    this.loadApi();
  }

  private loadApi() {

    (this.windowRef.nativeWindow as any)[GoogleRecaptchaStore.onLoadCallbackName] = this.onRecaptchaLoadCallback;

    const scriptElement = this.windowRef.nativeWindow.document.createElement('script');

    scriptElement.type = 'text/javascript';
    scriptElement.src = `https://www.google.com/recaptcha/api.js?render=explicit&onload=${GoogleRecaptchaStore.onLoadCallbackName}`;
    scriptElement.async = true;
    scriptElement.defer = true;

    this.windowRef.nativeWindow.document.body.appendChild(scriptElement);
  }

  private onRecaptchaLoadCallback = () => {

    this.setInitialised(true);
  }

  @action
  private setInitialised(initialised: boolean) {

    this._initialised = initialised;
  }

  render(containerElement: HTMLElement): RecaptchaWidgetId {

    return grecaptcha.render(containerElement, {
      sitekey: GoogleRecaptchaStore.siteKey,
      badge: 'inline',
      size: 'invisible'
    });
  }

  executeSiteVerify(args: {
    widgetId: RecaptchaWidgetId;
    action: string;
  }): Observable<GoogleRecaptchaSiteVerifyResponse> {

    if (args.widgetId == null) {

      this.logger.warn('GoogleRecaptchaStore.executeSiteVerify - null widgetId, exiting');

      return of(GoogleRecaptchaStore.emptyResponse);
    }

    this.appStore.showActivityIndicator({ flag: true });

    grecaptcha.reset(args.widgetId);

    return from(grecaptcha.execute(args.widgetId, {
      action: args.action
    })).pipe(
      switchMap(recaptchaToken => {

        return this.authenticationService.googleRecaptchaSiteVerify({ recaptchaToken });
      }),
      catchError(e => {

        this.logger.error('Error invoking GoogleRecaptchaStore.execute', e);

        return of(GoogleRecaptchaStore.emptyResponse);
      }),
      finalize(() => {

        this.appStore.showActivityIndicator({ flag: false });
      })
    );
  }

  get initialised() {

    return this._initialised;
  }
}
