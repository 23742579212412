import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseAppProperties } from '../base-app-properties';
import {
  AccountsPayableService as AccountsPayableServiceGenerated,
  ApproveInvoiceParams,
  ChangePlanParams,
  Configuration,
  OnHoldInvoiceParams,
  OverrideCommitteeApprovalParams,
  ProcessRejectedInvoiceParams,
  RejectInvoiceParams,
  RequestEmailApprovalParams,
  SecondAuthorityParams
} from '../generated';
import { PortalType } from '../models/portal-type';

@Injectable({
  providedIn: 'root'
})
export class AccountsPayableService {

  private readonly accountsPayableGenerated: AccountsPayableServiceGenerated;

  constructor(httpClient: HttpClient, appProperties: BaseAppProperties) {

    this.accountsPayableGenerated = new AccountsPayableServiceGenerated(httpClient, appProperties.irisServerBasePath, new Configuration());
  }

  getChartOfAccounts() {

    return this.accountsPayableGenerated.getChartOfAccounts();
  }

  getJournalChartOfAccounts() {

    return this.accountsPayableGenerated.getJournalChartOfAccounts();
  }

  getInvoiceSummaries(params: {
    portalType: PortalType;
    invoiceSelector: string;
    portfolioId?: string;
    strataId?: string;
  }) {

    return this.accountsPayableGenerated.getInvoiceSummaries(
      params.portalType,
      params.invoiceSelector,
      undefined,
      params.portfolioId,
      params.strataId);
  }

  getInvoiceAlerts(params: {
    portfolioId: string;
  }) {

    return this.accountsPayableGenerated.getInvoiceAlerts(params.portfolioId);
  }

  getInvoiceDetails(params: {
    creditorInvoiceEntryHeaderId: string;
    workflowProcessInstanceId: string;
    portalType: PortalType;
  }) {

    return this.accountsPayableGenerated.getInvoiceDetails(
      params.creditorInvoiceEntryHeaderId,
      params.workflowProcessInstanceId,
      params.portalType);
  }

  approveInvoice(approvalParams: ApproveInvoiceParams) {

    return this.accountsPayableGenerated.approveInvoice(approvalParams);
  }

  rejectInvoice(params: RejectInvoiceParams) {

    return this.accountsPayableGenerated.rejectInvoice(params);
  }

  updateInvoiceOnHold(params: OnHoldInvoiceParams) {

    return this.accountsPayableGenerated.updateInvoiceOnHold(params);
  }

  overrideCommitteeApproval(overrideCommitteeApprovalParams: OverrideCommitteeApprovalParams) {

    return this.accountsPayableGenerated.overrideCommitteeApproval(overrideCommitteeApprovalParams);
  }

  processRejectedInvoice(processRejectedInvoiceParams: ProcessRejectedInvoiceParams) {

    return this.accountsPayableGenerated.processRejectedInvoice(processRejectedInvoiceParams);
  }

  reprocessRejectedInvoice(params: {
    creditorInvoiceEntryHeaderId: string;
    reason?: string
  }) {

    return this.accountsPayableGenerated.reprocessRejectedInvoice(params.creditorInvoiceEntryHeaderId, params.reason);
  }

  changePlan(changePlanParams: ChangePlanParams) {

    return this.accountsPayableGenerated.changePlan(changePlanParams);
  }

  requestEmailApproval(requestEmailApprovalParams: RequestEmailApprovalParams) {

    return this.accountsPayableGenerated.requestEmailApproval(requestEmailApprovalParams);
  }

  deleteEmailApproval(params: {
    workflowProcessInstanceId: string;
    emailAddress: string;
  }) {

    return this.accountsPayableGenerated.deleteEmailApproval(params.workflowProcessInstanceId, params.emailAddress);
  }

  setSecondAuthority(params: SecondAuthorityParams) {

    return this.accountsPayableGenerated.setSecondAuthority(params);
  }
}
