import { Directive, Input } from '@angular/core';
import { TabPanel } from 'primeng/tabview';

// tslint:disable-next-line:directive-selector
@Directive({ selector: 'p-tabPanel' })
export class TabPanelDirective {

  @Input() set header(val: string | undefined) {

    if (val) {

      // @ts-ignore
      this.tabPanel.header = val.toTitleCase();
    }
  }

  constructor(private readonly tabPanel: TabPanel) {
  }
}
