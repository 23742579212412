import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DialogWidth } from '../../models/dialog-width';
import { BaseDomainStore } from '../../store/base-domain.store';
import { ConfirmationStore } from '../../store/confirmation.store';
import { taskTypeMap } from '../../utils/type-mapping.utils';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-task-done-button',
  templateUrl: './task-done-button.component.html',
  styleUrls: ['./task-done-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskDoneButtonComponent extends BaseComponent implements OnInit {

  @Input() enabled = false;

  @Output() readonly taskDoneClick = new EventEmitter();

  constructor(
    private readonly conirmationStore: ConfirmationStore,
    private readonly domainStore: BaseDomainStore) {

    super();
  }

  ngOnInit(): void {
  }

  onClick() {

    const header = this.domainStore.taskRouteData && taskTypeMap.get(this.domainStore.taskRouteData.task.type) || 'Task Done';

    this.conirmationStore.confirm({
      key: 'okCancel',
      header,
      width: DialogWidth.Screen25,
      rejectVisible: true,
      message: 'Mark Task Done?',
      accept: () => this.taskDoneClick.emit()
    });
  }
}
