import { Injectable } from '@angular/core';
import { action, observable } from 'mobx';

export interface PasswordFormModel {
  readonly newPassword: string;
  readonly repeatNewPassword: string;
}

@Injectable()
export class Store {

  @observable
  private _passwordUpdateFailed = false;

  @observable
  private _passwordFormModel: PasswordFormModel = {
    newPassword: '',
    repeatNewPassword: ''
  };

  @action
  setPasswordUpdateFailed(val: boolean) {

    this._passwordUpdateFailed = val;
  }

  @action
  setPasswordFormModel(val: PasswordFormModel) {

    this._passwordFormModel = val;
  }

  get passwordUpdateFailed() {

    return this._passwordUpdateFailed;
  }

  get passwordFormModel() {

    return this._passwordFormModel;
  }
}
