import { Injectable } from '@angular/core';
import { BaseAuthenticationStore, Environment, Logger, property, WindowRef } from 'common-lib';
import { action, computed } from 'mobx';

import { AppProperties } from '../app-properties';
import { Authentication } from '../models/authentication';

import { AppStore } from './app.store';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationStore extends BaseAuthenticationStore {

  constructor(
    logger: Logger,
    windowRef: WindowRef,
    appStore: AppStore,
    environment: Environment,
    appProperties: AppProperties) {

    super('whittles.ownerportal.authentication', logger, windowRef, appStore, environment, appProperties);
  }

  @action
  setAuthentication(authentication: Authentication | undefined) {

    super.setAuthentication(authentication);

    if (this.authentication) {

      // Don't store the password in the authentication object - always want it to come from the cookie,
      // so that if a logout occurs on any browser tab, all other tabs should log out as well
      Object.defineProperty(this.authentication, property<Authentication>('password'), {
        get: () => {

          const authCookie = this.getAuthenticationCookie();

          return authCookie && authCookie.password || '';
        }
      });
    }
  }

  getAuthentication() {

    return this.authentication as Authentication | undefined;
  }

  @computed
  get isLegacyOwnerLogin(): boolean {

    return !!this.authentication &&
      !!this.authentication.identity.accountNumber &&
      !!this.authentication.identity.plan &&
      !!this.authentication.identity.unitNumber;
  }

  @computed
  get isImpersonationLogin(): boolean {

    return !!this.authentication && !!this.authentication.identity.impersonatorUsername;
  }
}
