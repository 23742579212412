import { action, computed, observable } from 'mobx';
import { SelectItem } from 'primeng/api';
import { of, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { BaseAppProperties } from '../base-app-properties';
import { Company, LoginType, StrataPlanDetails, Task, TaskCategory } from '../generated';
import { Authority } from '../models/authority';
import { LocalisationContext } from '../models/localisation-context';
import { PortalType } from '../models/portal-type';
import { RouteDataKind, TaskRouteData } from '../models/route-data';
import { retryDelay, switchMapCatch } from '../rxjs/operators';
import { StrataService } from '../services/strata.service';
import { UserService } from '../services/user.service';
import { BaseAuthenticationStore } from '../store/base-authentication.store';
import { mobxReaction } from '../utils/mobx.utils';
import { mapFundCodesToSelectItems } from '../utils/type-mapping.utils';

import { BaseAppStore } from './base-app.store';
import { removeLeadingZeros } from '../utils/string.utils';
import { DomainUnit } from '../models/domain-unit';

export abstract class BaseDomainStore {

  private static readonly all = 'All';

  private readonly selectedPlanDetails$ = new Subject<string>();
  private readonly selectedUnitDetails$ = new Subject<string>();
  protected readonly getNumberOutstandingInvoices$ = new Subject();
  readonly selectedStrataDetails$ = new Subject<StrataPlanDetails>();

  @observable
  private _selectedInvoiceApprovalCompany?: Company;

  @observable
  private _companiesForUser: Company[] = [];

  @observable
  private _branchesForUser: Company[] = [];

  @observable
  private _selectedPlanDetails?: StrataPlanDetails;

  @observable
  private _selectedUnit?: DomainUnit;

  @observable
  private _selectedStrataFinancialYearId?: string;

  constructor(
    protected readonly appProperties: BaseAppProperties,
    protected readonly userService: UserService,
    protected readonly strataService: StrataService,
    protected readonly authenticationStore: BaseAuthenticationStore,
    protected readonly appStore: BaseAppStore) {

    this.selectedPlanDetails$.pipe(
      switchMapCatch(selectedStrataId => {

        let isPortal = this.appProperties.portalType === PortalType.Owner;

        const showActivityIndicator = isPortal &&
          this.authenticationStore.loginType === LoginType.BUILDING;

        return this.strataService.getPlanDetails({
          id: selectedStrataId,
          showActivityIndicator
        }).pipe(
          tap(planDetails => this.setSelectedPlanDetails(planDetails)),
          catchError(e => {

            this.setSelectedPlanDetails(undefined);

            return throwError(e);
          }),
          retryDelay(),
        );
        // tslint:disable-next-line: rxjs-no-ignored-subscription
      })).subscribe();

    this.selectedUnitDetails$.pipe(
      tap(selectedUnit => this._selectedUnit ? this._selectedUnit.unitNumber = selectedUnit : this._selectedUnit = { unitNumber: selectedUnit })
    ).subscribe();

    mobxReaction('BaseDomainStore: selectedStrataId',
      () => this.selectedStrataId,
      selectedStrataId => selectedStrataId && this.selectedPlanDetails$.next(selectedStrataId));
  }

  public refreshPlanDetails(strataId: string) {
    this.selectedPlanDetails$.next(strataId);
  }

  @action
  private setSelectedPlanDetails(planDetails: StrataPlanDetails | undefined) {

    this._selectedPlanDetails = planDetails;
    this.selectedStrataDetails$.next(planDetails);
  }

  getCompaniesForUser() {

    if (this.companiesForUser.length > 0) {

      return of(this.companiesForUser);
    }

    return this.userService.getCompanies().pipe(
      tap(companies => this.setCompaniesForUser(companies))
    );
  }

  getBranchesForUser() {

    if (this.branchesForUser.length > 0) {

      return of(this.branchesForUser);
    }

    return this.userService.getBranches().pipe(
      tap(companies => this.setBranchesForUser(companies))
    );
  }

  getSelectedPlanStrataNumber(args: {
    title: string;
  }): string {

    return `Plan ${this.selectedPlanStrataNumber} - ${args.title}`;
  }

  getSelectedPortfolio(args: {
    title: string;
  }): string {

    if (!this.selectedPlanDetails || !this.selectedPlanDetails.manager || !this.selectedPlanDetails.manager.name) {

      return '';
    }

    return `${this.selectedPlanDetails.manager.name} - ${args.title}`;
  }

  refreshNumberOutstandingInvoices() {

    this.getNumberOutstandingInvoices$.next();
  }

  isTaskActionableByLoggedInUser(task: Task): boolean {

    if (task.category === TaskCategory.APPROVEINVOICE || task.onHold) {

      return false;
    }

    const taskGroups = [...(task.groups || []), Authority.Administrator] as Authority[];

    return this.authenticationStore.hasAnyAuthority(...taskGroups);
  }

  @action
  setSelectedStrataFinancialYearId(val: string | undefined) {
    this._selectedStrataFinancialYearId = val;
  }

  get selectedStrataFinancialYearId() {
    return this._selectedStrataFinancialYearId;
  }

  @action
  private setCompaniesForUser(companies: Company[]) {

    this._companiesForUser = companies;
  }

  @action
  private setBranchesForUser(companies: Company[]) {

    this._branchesForUser = companies;
  }

  @action
  setSelectedInvoiceApprovalCompany(val: Company | undefined) {

    this._selectedInvoiceApprovalCompany = val;
  }

  @action
  clearSelectedUnit(): void {
    if (this._selectedUnit) {
      this._selectedUnit.unitNumber = undefined;
      this._selectedUnit.ownerCode = undefined;
    }
    this.selectedUnitDetails$.next(undefined);
  }

  @action
  setSelectedStrataAndUnit(strataId: string, unitNumber: string | undefined, ownerCode: string | undefined, unitId: number | undefined): void {
    this.setSelectedStrataId(strataId);

    if (this._selectedUnit) {
      this._selectedUnit.unitNumber = unitNumber;
      this._selectedUnit.unitId = unitId;
      this._selectedUnit.ownerCode = ownerCode;
    } else {
      this._selectedUnit = {
        unitNumber,
        unitId,
        ownerCode
      };
    }

    this.selectedUnitDetails$.next(unitNumber);
  }

  @computed
  get getSelectedUnitId() {

    return this._selectedUnit?.unitId;
  }

  @computed
  get companiesForUserOptional(): Company[] {

    return [
      {
        id: '',
        name: BaseDomainStore.all
      },
      ...this.companiesForUser
    ];
  }

  @computed
  get branchesForUserOptional(): Company[] {

    return [
      {
        id: '',
        name: '',
        branch: BaseDomainStore.all
      },
      ...this.branchesForUser
    ];
  }

  protected getSelectedStrataId(): string {

    if (!this.authenticationStore.authentication || !this.authenticationStore.authentication.selectedStrataId) {

      return '';
    }

    return this.authenticationStore.authentication.selectedStrataId;
  }

  @computed
  get selectedStrataId() {

    return this.getSelectedStrataId();
  }

  setSelectedStrataId(selectedStrataId: string) {

    this.authenticationStore.setAuthentication({
      // tslint:disable-next-line:no-non-null-assertion
      ...this.authenticationStore.authentication!,
      selectedStrataId
    });
  }

  // update strataID without check and refresh cookie and reset domain
  updateSelectedStrataId(selectedStrataId: string) {

    this.authenticationStore.updateAuthentication({
      // tslint:disable-next-line:no-non-null-assertion
      ...this.authenticationStore.authentication!,
      selectedStrataId
    });
  }

  @computed
  get selectedPortfolioId(): string | undefined {

    if (this.appProperties.portalType !== PortalType.Manager || !this.selectedPlanDetails || !this.selectedPlanDetails.manager) {

      return undefined;
    }

    return this.selectedPlanDetails.manager.value;
  }

  @computed
  get taskRouteData(): TaskRouteData | undefined {

    if (!this.appStore.routeData || this.appStore.routeData.kind !== RouteDataKind.Task) {

      return undefined;
    }

    return this.appStore.routeData;
  }

  @computed
  get isTaskActionable(): boolean {

    if (!this.taskRouteData) {

      return false;
    }

    return this.isTaskActionableByLoggedInUser(this.taskRouteData.task);
  }

  get companiesForUser() {

    return this._companiesForUser;
  }

  get branchesForUser() {

    return this._branchesForUser;
  }

  get selectedPlanDetails() {

    return this._selectedPlanDetails;
  }

  get selectedUnitNumber() {
    const selectedUnitNumber = this._selectedUnit?.unitNumber || '';

    const unitNumber = removeLeadingZeros(selectedUnitNumber);

    return !unitNumber.trim().length
      ? ''
      : this._selectedUnit?.ownerCode 
        ? `| Unit: ${unitNumber} ${this._selectedUnit?.ownerCode}`
        : `Unit: ${unitNumber}`;
  }

  get selectedInvoiceApprovalCompany() {

    return this._selectedInvoiceApprovalCompany;
  }

  @computed
  get selectedPlanFundCodes(): SelectItem[] {

    return mapFundCodesToSelectItems(this._selectedPlanDetails && this._selectedPlanDetails.fundCodes || []);
  }

  @computed
  get selectedPlanFundCodesOptional(): SelectItem[] {

    return [
      {
        label: BaseDomainStore.all,
        value: '',
      },
      ...this.selectedPlanFundCodes
    ];
  }

  @computed
  get selectedPlanStrataNumber(): string {

    return this.selectedPlanDetails && this.selectedPlanDetails.strataNumber || '';
  }

  protected getLocalisationContext(): LocalisationContext {

    if (this.selectedPlanDetails) {

      return {
        state: this.selectedPlanDetails.state,
        planType: this.selectedPlanDetails.planType,
        companyId: this.selectedPlanDetails.companyId
      };

    } else {

      return { state: undefined, planType: undefined, companyId: undefined };
    }
  }

  @computed
  get localisationContext(): LocalisationContext {

    return this.getLocalisationContext();
  }
}
