import { NgClass, NgStyle } from '@angular/common';
import { Injectable } from '@angular/core';
import { action, observable } from 'mobx';
import { Confirmation, ConfirmationService } from 'primeng/api';

import { BaseAppStore } from '../store/base-app.store';
import { calculateDialogWidth } from '../utils/display.utils';

interface ConfirmationOptions extends Confirmation {
  readonly closable?: boolean;
  readonly closeOnEscape?: boolean;
  readonly acceptLabel?: string;
  readonly acceptIcon?: string;
  readonly rejectLabel?: string;
  readonly rejectIcon?: string;
  readonly width?: string;
  readonly height?: string;
  readonly maxWidth?: string;
  readonly maxHeight?: string;
  readonly style?: NgStyle['ngStyle'];
  readonly rejectOnClose?: boolean;
  readonly class?: NgClass['ngClass'];
}

@Injectable({
  providedIn: 'root'
})
export class ConfirmationStore {

  public static readonly AcceptLabels = {

    get Yes() {
      return 'Yes';
    },

    get OK() {
      return 'OK';
    }
  };

  public static readonly RejectLabels = {

    get No() {
      return 'No';
    },

    get Cancel() {
      return 'Cancel';
    }
  };

  public static readonly DEFAULTS = {
    key: 'app',
    closable: true,
    closeOnEscape: true,
    acceptLabel: ConfirmationStore.AcceptLabels.OK,
    acceptIcon: 'pi pi-check',
    rejectLabel: ConfirmationStore.RejectLabels.Cancel,
    rejectIcon: 'pi pi-times',
    rejectVisible: false,
    width: undefined,
    style: {
      'max-width': '90%',
      'max-height': '90%',
      overflow: 'auto',
      'width': '50em',
      'padding': '1em'
    },
    rejectOnClose: true
  };

  @observable
  private _closable = ConfirmationStore.DEFAULTS.closable;

  @observable
  private _closeOnEscape = ConfirmationStore.DEFAULTS.closeOnEscape;

  @observable
  private _acceptLabel = ConfirmationStore.DEFAULTS.acceptLabel;

  @observable
  private _acceptIcon = ConfirmationStore.DEFAULTS.acceptIcon;

  @observable
  private _rejectLabel = ConfirmationStore.DEFAULTS.rejectLabel;

  @observable
  private _rejectIcon = ConfirmationStore.DEFAULTS.rejectIcon;

  @observable
  private _rejectVisible = ConfirmationStore.DEFAULTS.rejectVisible;

  @observable
  private _style: NgStyle['ngStyle'] = ConfirmationStore.DEFAULTS.style;

  @observable
  private _class: NgClass['ngClass'] = '';

  @observable
  private _rejectOnClose = ConfirmationStore.DEFAULTS.rejectOnClose;

  constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly appStore: BaseAppStore) {
  }

  @action
  close() {
      
      this.confirmationService.close();
  }

  @action
  confirm(confirmationOptions: ConfirmationOptions) {

    if (this.appStore.logoutInvoked) {

      return;
    }

    this._closable = confirmationOptions.closable == null ?
      ConfirmationStore.DEFAULTS.closable : confirmationOptions.closable;

    this._closeOnEscape = confirmationOptions.closeOnEscape == null ?
      ConfirmationStore.DEFAULTS.closeOnEscape : confirmationOptions.closeOnEscape;

    this._acceptLabel = confirmationOptions.acceptLabel == null ?
      ConfirmationStore.DEFAULTS.acceptLabel : confirmationOptions.acceptLabel;

    this._acceptIcon = confirmationOptions.acceptIcon == null ?
      ConfirmationStore.DEFAULTS.acceptIcon : confirmationOptions.acceptIcon;

    this._rejectLabel = confirmationOptions.rejectLabel == null ?
      ConfirmationStore.DEFAULTS.rejectLabel : confirmationOptions.rejectLabel;

    this._rejectIcon = confirmationOptions.rejectIcon == null ?
      ConfirmationStore.DEFAULTS.rejectIcon : confirmationOptions.rejectIcon;

    this._rejectVisible = confirmationOptions.rejectVisible == null ?
      ConfirmationStore.DEFAULTS.rejectVisible : confirmationOptions.rejectVisible;

    this._class = confirmationOptions.class == null ? '' : confirmationOptions.class;

    this._style = confirmationOptions.style == null ?
      ConfirmationStore.DEFAULTS.style : confirmationOptions.style;

    this._rejectOnClose = confirmationOptions.rejectOnClose == null ?
      ConfirmationStore.DEFAULTS.rejectOnClose : confirmationOptions.rejectOnClose;

    if (confirmationOptions.width) {

      this._style.width = calculateDialogWidth(confirmationOptions.width);
    }

    if (confirmationOptions.height) {

      this._style.height = confirmationOptions.height;
    }

    if (confirmationOptions.maxWidth) {

      this._style['max-width'] = confirmationOptions.maxWidth;
    }

    if (confirmationOptions.maxHeight) {

      this._style['max-height'] = confirmationOptions.maxHeight;
    }

    const opts: Confirmation = {
      key: ConfirmationStore.DEFAULTS.key,
      rejectVisible: ConfirmationStore.DEFAULTS.rejectVisible,
      ...confirmationOptions,
      // @ts-ignore
      header: confirmationOptions.header ? confirmationOptions.header.toTitleCase() : confirmationOptions.header
    };

    this.confirmationService.confirm(opts);
  }

  get closable() {

    return this._closable;
  }

  get closeOnEscape() {

    return this._closeOnEscape;
  }

  get acceptLabel() {

    return this._acceptLabel;
  }

  get acceptIcon() {

    return this._acceptIcon;
  }

  get rejectLabel() {

    return this._rejectLabel;
  }

  get rejectIcon() {

    return this._rejectIcon;
  }

  get rejectVisible() {

    return this._rejectVisible;
  }

  get style() {

    return this._style;
  }

  get rejectOnClose() {

    return this._rejectOnClose;
  }

  get class() {

    return this._class;
  }
}
