export enum CompanyId {
  Adelaide = '2',
  Darwin = '3',
  Sydney = '5',
  Brisbane = '6',
  SunshineCoast = '7',
  Cleveland = '8',
  GoldCoast = '9',
  Canberra_StrataPartners_Archived = '10',
  Melbourne = '11',
  Albury = '12',
  Canberra = '13'
}

interface Company {
  id: CompanyId;
  name: string;
}

export const CompanyMetadata = {

  get Adelaide(): Company {

    return {
      id: CompanyId.Adelaide,
      name: 'Adelaide'
    };
  },

  get Darwin(): Company {

    return {
      id: CompanyId.Darwin,
      name: 'Darwin/Alice Springs'
    };
  },

  get Sydney(): Company {

    return {
      id: CompanyId.Sydney,
      name: 'Sydney'
    };
  },

  get Brisbane(): Company {

    return {
      id: CompanyId.Brisbane,
      name: 'Brisbane'
    };
  },

  get SunshineCoast(): Company {

    return {
      id: CompanyId.SunshineCoast,
      name: 'Sunshine Coast'
    };
  },

  get Cleveland(): Company {

    return {
      id: CompanyId.Cleveland,
      name: 'Cleveland'
    };
  },

  get GoldCoast(): Company {

    return {
      id: CompanyId.GoldCoast,
      name: 'Gold Coast'
    };
  },

  get Canberra(): Company {

    return {
      id: CompanyId.Canberra,
      name: 'Canberra'
    };
  },

  get Melbourne(): Company {

    return {
      id: CompanyId.Melbourne,
      name: 'Melbourne'
    };
  },

  get Albury(): Company {

    return {
      id: CompanyId.Albury,
      name: 'Albury'
    };
  }
};
