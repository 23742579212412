import { registerLocaleData } from '@angular/common';
import localeAU from '@angular/common/locales/en-AU';
import { Injector } from '@angular/core';

registerLocaleData(localeAU);

import { configure as mobxConfigure, IDerivation, isObservableArray, onReactionError } from 'mobx';
import { enableLogging as mobxEnableLogging } from 'mobx-logger';

import { JSReport } from '../services/jsreport.service';
import { handleError } from '../utils/error.utils';
import { ServiceLocator } from '../utils/service-locator.utils';
import { getFromLocalStorage } from '../utils/storage.utils';

declare const jsreport: JSReport;

export abstract class BaseAppModule {

  constructor(
    protected readonly injector: Injector) {

    (ServiceLocator as any)._injector = injector;

    this.initMobX();
    this.initJSReport();
    this.monkeyPatch();
  }

  private initMobX() {

    mobxConfigure({
      // Enable MOBX 'observed' mode
      enforceActions: 'observed'
    });

    onReactionError((error: any, derivation: IDerivation) => {

      handleError(error);
    });

    mobxEnableLogging({
      predicate: () => getFromLocalStorage<boolean>('mobxLogger', { base64Encoded: false }) === true,
      action: true,
      reaction: true,
      compute: true,
      transaction: true
    });
  }

  private initJSReport() {

    if (typeof jsreport === 'undefined') {

      return;
    }

    jsreport.serverUrl = '/jsreport';
  }

  private monkeyPatch() {

    this.monkeyPatchArray();
  }

  private monkeyPatchArray() {

    const isArray = Array.isArray;

    Array.isArray = (arg: any): arg is any[] => {

      return isArray(arg) || isObservableArray(arg);
    };
  }
}
