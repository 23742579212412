import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { ClientContext } from '../models/client-context';
import { DomainStore } from '../stores/domain.store';

@Injectable()
export class ClientContextHeaderInterceptor implements HttpInterceptor {

  // Need the Injector (rather than injecting DomainStore) to overcome circular dependency which manifests as:
  //
  // HTTP_INTERCEPTORS can't use providedIn injectable/service - https://github.com/angular/angular/issues/24306 AND
  // Service with http call in constructor is injected as undefined in HttpInterceptor - https://github.com/angular/angular/issues/25590
  constructor(private readonly injector: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.domainStore) {

      const clientContext: ClientContext = {
        selectedUnitId: this.domainStore.selectedUnit ? this.domainStore.selectedUnit.unitId : undefined
      };

      const base64EncodedClientContext = btoa(JSON.stringify(clientContext));

      const newReq = req.clone({
        headers: req.headers.set('Whittles-Client-Context', base64EncodedClientContext)
      });

      return next.handle(newReq);

    } else {

      return next.handle(req);
    }
  }

  private get domainStore(): DomainStore | undefined {

    return this.injector.get<DomainStore>(DomainStore);
  }
}
