import { Injectable } from '@angular/core';
import { action, computed, observable } from 'mobx';

import { TableSortMode } from '../../models/table';

@Injectable()
export class Store {

  @observable
  private _processNotesIndicatorVisibility = false;

  @observable
  private _sortByAmount = false;

  @action
  setSortByAmount(val: boolean) {

    this._sortByAmount = val;
  }

  @action
  setProcessNotesIndicatorVisibility(val: boolean) {

    this._processNotesIndicatorVisibility = val;
  }

  get sortByAmount() {

    return this._sortByAmount;
  }

  get processNotesIndicatorVisibility() {

    return this._processNotesIndicatorVisibility;
  }

  @computed
  get sortMode(): TableSortMode {

    return this.sortByAmount ? TableSortMode.Single : TableSortMode.Multiple;
  }

  @computed
  get customSort(): boolean {

    return this.sortByAmount;
  }
}
