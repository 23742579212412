<p-table [dataKey]="dataKey" [value]="value" [rows]="rows" [globalFilterFields]="globalFilterFields"
  [autoLayout]="autoLayout" [paginator]="paginator" [alwaysShowPaginator]="alwaysShowPaginator"
  [resetPageOnSort]="resetPageOnSort" [responsive]="responsive" [first]="first" (firstChange)="onFirstChange($event)"
  [selectionMode]="selectionMode" [selection]="selection" (selectionChange)="selectionChange.emit($event)"
  (onEditInit)="editInit.emit($event)" (onEditComplete)="editComplete.emit($event)"
  (onEditCancel)="editCancel.emit($event)" (onPage)="onPage($event)" (onFilter)="onFilter($event)"
  (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" [stateKey]="stateKey"
  [stateStorage]="stateStorage" [sortMode]="sortMode" [multiSortMeta]="multiSortMeta"
  (sortFunction)="sortFunction.emit($event)" [customSort]="customSort" [sortField]="sortField" [sortOrder]="sortOrder"
  (onSort)="onSort($event)">

  <ng-template *ngIf="showCaption" pTemplate="caption">
    <ng-container *ngTemplateOutlet="captionTemplate"></ng-container>
  </ng-template>

  <ng-template pTemplate="header">
    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
  </ng-template>

  <ng-template pTemplate="body" let-data>
    <ng-container *ngTemplateOutlet="bodyTemplate; context: {$implicit: data}"></ng-container>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <ng-container *ngTemplateOutlet="emptyMessageTemplate"></ng-container>
  </ng-template>

  <ng-template pTemplate="footer">
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>

</p-table>

<ng-template #defaultEmptyMessageTpl>
  <tr appTableEmptyMessage></tr>
</ng-template>
