import { NgModule } from '@angular/core';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
// import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { GMapModule } from 'primeng/gmap';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToolbarModule } from 'primeng/toolbar';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';

@NgModule({
  exports: [
    DropdownModule,
    ButtonModule,
    ToolbarModule,
    ProgressSpinnerModule,
    InputTextModule,
    InputTextareaModule,
    MessagesModule,
    MessageModule,
    OverlayPanelModule,
    KeyFilterModule,
    CheckboxModule,
    FileUploadModule,
    TreeModule,
    GMapModule,
    AutoCompleteModule,
    TableModule,
    MultiSelectModule,
    TabViewModule,
    ProgressBarModule,
    DataViewModule,
    TreeTableModule,
    DialogModule,
    MenuModule
    // ChartModule
  ]
})
export class PrimeNGSharedModule {
}
